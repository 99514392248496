export const damagedTabsList = [
  { name: "ANAKAPALLI", count: 384 },
  { name: "ANANTHAPUR", count: 192 },
  { name: "ANNAMAYYA", count: 141 },
  { name: "ASR", count: 168 },
  { name: "BAPATLA", count: 45 },
  { name: "CHITTOOR", count: 112 },
  { name: "EAST GODAVARI", count: 661 },
  { name: "ELURU", count: 244 },
  { name: "GUNTUR", count: 598 },
  { name: "KAKINADA", count: 319 },
  { name: "KONASEEMA", count: 144 },
  { name: "KRISHNA", count: 119 },
  { name: "KURNOOL", count: 2 },
  { name: "MANYAM", count: 230 },
  { name: "NANDYAL", count: 25 },
  { name: "NTR", count: 192 },
  { name: "PALNADU", count: 94 },
  { name: "PRAKASAM", count: 100 },
  { name: "SPSR NELLORE", count: 285 },
  { name: "SRI SATHYA SAI", count: 106 },
  { name: "SRIKAKULAM", count: 92 },
  { name: "TIRUPATI", count: 199 },
  { name: "VISAKHAPATNAM", count: 142 },
  { name: "VIZIANAGARAM", count: 214 },
  { name: "WEST GODAVARI", count: 174 },
  { name: "YSR KADAPA", count: 122 },
];
