import { Button, Col, Drawer, Row, Table, Tag, Typography } from "antd";
import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";
import {
  getFunctionaryMappedDevices,
  getFunctionarySimsList,
} from "../../../services/inventory.services";
import DrawerForm from "../../Drawer/Drawer";

function FunctionaryDevices() {
  const query = useParams();
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [mobileNums, setMobileNums] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editList, setEditList] = useState(null);
  const userDetails = JSON.parse(getItem(USER));

  const ruralFunctionaryRoles = {
    panchayat_secretary: "Panchayat Secretary",
    welfare_education_assistant: "Welfare & Education Assistant",
    digital_assistant: "Digital Assistant",
    engineering_assistant: "Engineering Assistant",
    energy_assistant: "Energy Assistant",
    vro: "VRO",
    mahila_women_child_assistant:
      "Mahila Police & Women & Child Welfare Assistant",
    anm: "ANM",
    survey_assistant: "Survey Assistant",
    veterinary_fisheries_assistant: "Veterinary / Fisheries Asst",
    agriculture_horticulture_sericulture_assistant:
      "Agriculture / Horticulture /Sericulture Asst ",
  };
  const urbanFunctionaryRoles = {
    ward_administrative_secretary: "Ward Administrative Secretary ",
    ward_welfare_development_secretary: "Ward Welfare & Development Secretary ",
    ward_education_data_processing_secretary:
      "Ward Education & Data Processing Secretary",
    ward_amenities_secretary: "Ward Amenities Secretary ",
    ward_energy_secretary: "Ward Energy Secretary ",
    ward_revenue_secretary: "Ward Revenue Secretary ",
    ward_women_protection_secretary: "Ward Women Protection Secretary ",
    ward_health_secretary: "Ward Health Secretary ",
    ward_planning_regulation_secretary: "Ward Planning & Regulation Secretary ",
    ward_sanitation_environment_secretary:
      "Ward Sanitation & Environment Secretary ",
    ward_agriculture_horticulture_sericulture_secretary:
      "Ward Agriculture / Horticulture /Sericulture Secretary.  ",
  };
  const roles = userDetails.region.is_rural
    ? ruralFunctionaryRoles
    : urbanFunctionaryRoles;

  useEffect(() => {
    async function simsList() {
      try {
        const res = await getFunctionarySimsList(query.id);
        setMobileNums(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    }
    simsList();
  }, []);

  useEffect(() => {
    async function getMappedDevices() {
      setLoading(true);
      try {
        const res = await getFunctionaryMappedDevices(query.id);
        setData(res?.data?.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
    getMappedDevices();
  }, [refresh]);

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const openModal = (data) => {
    setEditList({ ...data });
    setIsDrawerOpen(true);
  };

  const columns = [
    {
      title: "Employee id",
      dataIndex: "functionary_id",
      key: "functionary_id",
      render: (value) => <Tag color="blue">{value}</Tag>,
    },
    {
      title: "Name",
      dataIndex: "functionary_name",
      key: "functionary_name",
    },
    {
      title: "Role",
      dataIndex: "functionary_role",
      key: "functionary_role",
      render: (value) => {
        return <Typography.Text>{roles[value]}</Typography.Text>;
      },
    },
    {
      title: "SIM number",
      dataIndex: "sim_number",
      key: "sim_number",
    },
    // {
    //   title: "Action",
    //   render: (value, record) => (
    //     <Button
    //       type="primary"
    //       disabled={!userDetails?.is_village_secretariat}
    //       onClick={() => {
    //         openModal({
    //           status: "update_functionary_device",
    //           title: "Edit Functionary Devices",
    //           data: {
    //             mobileNums,
    //             sec_id: query.id,
    //             record,
    //             roles,
    //           },
    //         });
    //       }}
    //     >
    //       Edit
    //     </Button>
    //   ),
    // },
  ];

  return (
    <Fragment>
      <Row gutter={[24, 24]}>
        <Col
          style={{ textAlign: "right" }}
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 22, offset: 1 }}
          lg={{ span: 22, offset: 1 }}
        >
          {/* <Button
            type="primary"
            disabled={!userDetails?.is_village_secretariat}
            onClick={() => {
              openModal({
                status: "add_functionary_device",
                title: "Add Functionary Devices",
                data: {
                  mobileNums,
                  sec_id: query.id,
                  roles,
                },
              });
            }}
          >
            Map a device
          </Button> */}
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 22, offset: 1 }}
          lg={{ span: 22, offset: 1 }}
        >
          <Table
            loading={loading}
            dataSource={data}
            columns={columns}
            pagination={false}
          />
        </Col>
      </Row>
      {isDrawerOpen && (
        <DrawerForm
          setVisible={setIsDrawerOpen}
          // id={id}
          editList={editList}
          refresh={refreshPage}
        />
      )}
    </Fragment>
  );
}

export default FunctionaryDevices;
