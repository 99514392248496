import React, { useEffect } from "react";
import Routes from "./routes/Routes";
import "./App.css";
// import Idled from "react-idled";
import UserIdle from "./components/UserIdle/UserIdle";
import useDevToolsDetector from "./hooks/useDeveloperTools";
function App() {
  function isShortcutKey(e, keyCode) {
    // Check for Ctrl + Shift + Key or Cmd + Option + Key
    return (
      (e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0)) ||
      (e.metaKey && e.altKey && e.keyCode === keyCode.charCodeAt(0))
    );
  }

  // const isDevToolsOpen = useDevToolsDetector();

  // useEffect(() => {
  //   document.addEventListener("contextmenu", function (e) {
  //     e.preventDefault();
  //   });

  //   document.onkeydown = (e) => {
  //     // Disable F12, Ctrl + Shift + I/J/C, Cmd + Option + I/J/C, Ctrl + U
  //     if (
  //       e.keyCode === 123 ||
  //       isShortcutKey(e, "I") ||
  //       isShortcutKey(e, "J") ||
  //       isShortcutKey(e, "C") ||
  //       (e.ctrlKey && e.keyCode === "U".charCodeAt(0)) ||
  //       (e.metaKey && e.keyCode === "U".charCodeAt(0))
  //     ) {
  //       e.preventDefault();
  //       return false;
  //     }
  //   };
  // }, []);

  // if (isDevToolsOpen) {
  //   return (
  //     <div>Application stopped. Please close developer tools to continue.</div>
  //   );
  // }

  return (
    <div className="App">
      <UserIdle>
        <Routes />
      </UserIdle>
    </div>
  );
}

export default App;
