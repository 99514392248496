import React, { Fragment, useState } from "react";
import { Table } from "antd";
import Drawer from "../../Drawer/Drawer";
import { isMobileOnly } from "react-device-detect";
import moment from "moment";
import { Link } from "react-router-dom";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";

const WednesdayMeetingsTable = ({ loading, data, refresh, id }) => {
  const [visible, setVisible] = useState(false);
  const [editList, setEditList] = useState(null);
  const userDetails = JSON.parse(getItem(USER));

  const openModal = (data) => {
    setEditList(data);
    setVisible(true);
  };

  const columns = [
    {
      title: "Secretariat Meeting Conducted Date",
      dataIndex: "meeting_date",
      key: "meeting_date",
      fixed: "left",
      width: 150,
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "Not conducted yet"),
      sorter: (a, b) => moment(a.meeting_date).unix() - moment(b.meeting_date).unix(),
    },
    {
      title: "Mobile Not Working",
      dataIndex: "mobiles_not_working",
      key: "mobiles_not_working",
      width: 130,
      sorter: (a, b) => a.mobiles_not_working - b.mobiles_not_working,
    },
    {
      title: "Mobile Not Working Solved",
      dataIndex: "mobiles_not_working_redressed_at_secretariat_level",
      key: "mobiles_not_working_redressed_at_secretariat_level",
      width: 180,
      sorter: (a, b) =>
        a.mobiles_not_working_redressed_at_secretariat_level -
        b.mobiles_not_working_redressed_at_secretariat_level,
    },

    {
      title: "Sim Not Working",
      dataIndex: "sims_not_working",
      key: "sims_not_working",
      width: 130,
      sorter: (a, b) => a.sims_not_working - b.sims_not_working,
    },
    {
      title: "Sim Not Working Solved",
      dataIndex: "sims_not_working_redressed_at_secretariat_level",
      key: "sims_not_working_redressed_at_secretariat_level",
      width: 180,
      sorter: (a, b) =>
        a.sims_not_working_redressed_at_secretariat_level -
        b.sims_not_working_redressed_at_secretariat_level,
    },

    {
      title: "FP Scanner Not Working",
      dataIndex: "fp_scanners_not_working",
      key: "fp_scanners_not_working",
      width: 150,
      sorter: (a, b) => a.fp_scanners_not_working - b.fp_scanners_not_working,
    },
    {
      title: "FP Scanner Not Working Solved",
      dataIndex: "fp_scanners_not_working_redressed_at_secretariat_level",
      key: "fp_scanners_not_working_redressed_at_secretariat_level",
      width: 190,
      sorter: (a, b) =>
        a.fp_scanners_not_working_redressed_at_secretariat_level -
        b.fp_scanners_not_working_redressed_at_secretariat_level,
    },

    {
      title: "CFMS ID Issues Reported Count",
      dataIndex: "cfms_id_issues",
      key: "cfms_id_issues",
      width: 160,
    },

    {
      title: " CFMS ID Issues Solved Count",
      dataIndex: "total_cfms_id_issues_redressed_count",
      key: "total_cfms_id_issues_redressed_count",
      width: 160,
    },

    {
      title: " Salary Issues Reported Count",
      dataIndex: "salary_issues",
      key: "salary_issues",
      width: 160,
    },

    {
      title: " Salary Issues Solved Count",
      dataIndex: "total_salary_issues_redressed_count",
      key: "total_salary_issues_redressed_count",
      width: 160,
    },

    {
      title: " Other Issues Reported Count",
      dataIndex: "other_issues",
      key: "other_issues",
      width: 160,
    },

    {
      title: "Other Issues Solved Count",
      dataIndex: "total_other_issues_redressed_count",
      key: "total_other_issues_redressed_count",
      width: 160,
    },

    {
      title: "Minutes Of Meeting",
      dataIndex: "minutes_of_meeting",
      key: "minutes_of_meeting",
      width: 150,
      sorter: (a, b) => a.minutes_of_meeting.localeCompare(b.minutes_of_meeting),
    },
    {
      title: "Other Issue Types",
      dataIndex: "other_outstanding_issues",
      key: "other_outstanding_issues",
      width: 180,
      render: (data, record) => <span>{record.other_outstanding_issues ? data : "-"}</span>,
      sorter: (a, b) => a.other_outstanding_issues.localeCompare(b.other_outstanding_issues),
    },
    {
      title: "Group Photo",
      dataIndex: "group_photo",
      key: "group_photo",
      width: 250,
      render: (photo, record) =>
        record.group_photo === "" ? (
          "-"
        ) : (
          <Link to={{ pathname: record.group_photo }} target="_blank" rel="noopener noreferrer">
            Click here to view the image.
          </Link>
        ),
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 170,
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "-"),
      sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      fixed: "right",
      render: (text, record) => (
        <span
          className={
            userDetails?.is_village_secretariat ? "footer-nav-links" : "disable-wed-meeting-modify"
          }
          onClick={() => {
            userDetails?.is_village_secretariat &&
              openModal({
                status: "create-wednesday-meeting",
                title: "Update Meeting Notes",
                data: record,
              });
          }}
        >
          Modify
        </span>
      ),
    },
  ];

  return (
    <Fragment>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        scroll={isMobileOnly ? { x: 500, y: 500 } : { x: 1400, y: "80vh" }}
      />
      {visible && (
        <Drawer
          setVisible={setVisible}
          refresh={refresh}
          id={id}
          editList={editList}
          setEditList={setEditList}
        />
      )}
    </Fragment>
  );
};

export default WednesdayMeetingsTable;
