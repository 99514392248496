import React, { useEffect, useState } from "react";
import {
  USER,
  tableFilterDistricts,
  tableFilterDevices,
  akTableFilterDevices,
} from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";
import {
  InputNumber,
  Select,
  Table,
  Popover,
  Button,
  Popconfirm,
  message,
  Menu,
  Dropdown,
  Input,
  Space,
  DatePicker,
  Col,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  getStateIndentRequest,
  updateIndentRequest,
} from "../../../services/serviceRequests.services";
import { DownOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;
const monthFormat = "YYYY-MM";
var inputNumber = 0;

function IndentTable() {
  const curDate = moment().format(monthFormat);
  const [data, setData] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [isPopVisble, setPopVisible] = useState(false);
  const [maxAllocate, setMaxAllocate] = useState(0);
  const userDetails = JSON.parse(getItem(USER));
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    handleGetStateIndentRequest(selectedDate);
  }, [selectedDate]);
  function handleGetStateIndentRequest(selectedDate) {
    setTableLoading(true);
    getStateIndentRequest({ month: selectedDate })
      .then((res) => {
        setData(res.data);
        setTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setTableLoading(false);
      });
  }

  function handleSubmit() {
    setLoading(true);
    if (selectedValue === "Rejected") {
      inputNumber = 0;
    }
    updateIndentRequest(rowId, {
      allocated_count: inputNumber,
      resolution_status: selectedValue,
    })
      .then((res) => {
        message.success("submitted");
        setLoading(false);
        setPopVisible(false);
        handleGetStateIndentRequest();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error(err.response.data.message);
        setPopVisible(false);
      });
  }
  const popContent = (
    <div>
      <label style={{ display: "block" }}>Allocated count</label>
      <InputNumber
        min={0}
        max={maxAllocate}
        onChange={(val) => (inputNumber = val)}
        defaultValue={0}
      />
      <Button type="primary" onClick={handleSubmit} loading={loading}>
        {loading ? "Submitting..." : "Submit"}
      </Button>
    </div>
  );
  const dropDownContent = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setSelectedValue("Approved");
          setPopVisible(true);
        }}
      >
        <Popover content={popContent} trigger="click" visible={isPopVisble}>
          <p>Approved</p>
        </Popover>
      </Menu.Item>
      <Menu.Item onClick={() => setSelectedValue("Rejected")}>
        <Popconfirm title="sure to reject?" onConfirm={handleSubmit}>
          <p>Rejected</p>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columnData = [
    {
      title: "Purchase Order Id",
      dataIndex: "purchase_order",
      key: "purchase_order",
      ...getColumnSearchProps("purchase_order"),
      render: (data, record) =>
        record?.purchase_order ? <span>{data}</span> : "-",
    },
    {
      title: "Requested Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
    {
      title: "District Name",
      dataIndex: "district_name",
      key: "district_name",
      filters: [...tableFilterDistricts],
      filterSearch: true,
      onFilter: (value, record) => record.district_name.includes(value),
    },
    {
      title: "Device Type",
      dataIndex: "inventory_type",
      key: "inventory_type",
      filters: [...tableFilterDevices, ...akTableFilterDevices],
      filterSearch: true,
      onFilter: (value, record) => record.inventory_type.includes(value),
      render: (inventory_type, record) => (
        <span>
          {record?.inventory_type === "Sim"
            ? inventory_type + ":" + " " + record.network
            : inventory_type}
        </span>
      ),
    },

    {
      title: "Requested Count",
      dataIndex: "raised_count",
      key: "raised_count",
      sorter: (a, b) => a.raised_count - b.raised_count,
      render: (data, record) => <span>{record.raised_count ? data : "-"}</span>,
    },
    {
      title: "Allocated Count",
      dataIndex: "allocated_count",
      key: "allocated_count",
      sorter: (a, b) => a.allocated_count - b.allocated_count,
      render: (data, record) => (
        <span>{record.allocated_count ? data : "-"}</span>
      ),
    },
    {
      title: "Vendor",
      dataIndex: "vendor_name",
      key: "vendor_name",
      render: (data, record) => <span>{record.vendor_name ? data : "-"}</span>,
    },
    {
      title: "Signed document",
      dataIndex: "request_form",
      key: "request_form",
      width: 150,
      render: (request_form) => (
        <a
          onClick={() =>
            window.open(
              `http://docs.google.com/gview?url=${request_form}`,
              "_blank"
            )
          }
        >
          View file
        </a>
      ),
    },
    {
      title: "Vendor Status",
      dataIndex: "resolution_status_by_vendor",
      key: "resolution_status_by_vendor",
      filters: [
        {
          text: "Open",
          value: "Open",
        },
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Rejected",
          value: "Rejected",
        },
      ],
      onFilter: (value, record) => record.resolution_status_by_vendor === value,
      render: (status, record) => (
        <span>{record.resolution_status_by_vendor ? status : "-"}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "resolution_status",
      key: "resolution_status",
      filters: [
        {
          text: "Approved",
          value: "Approved",
        },
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Rejected",
          value: "Rejected",
        },
      ],
      onFilter: (value, record) => record.resolution_status === value,
    },
    {
      title: "Action",
      width: 150,
      render: (data) => (
        <>
          <Dropdown
            overlay={dropDownContent}
            trigger={["click"]}
            disabled={!userDetails?.is_state_executive_authority}
          >
            <a>
              Change Status <DownOutlined />
            </a>
          </Dropdown>
        </>
      ),
    },
  ];
  return (
    data && (
      <>
        <Col span={24} style={{ textAlign: "right" }}>
          <DatePicker
            allowClear={false}
            picker="month"
            defaultValue={moment(curDate)}
            onChange={(_, dateString) => {
              setSelectedDate(dateString);
            }}
          />
        </Col>

        <Table
          loading={tableLoading}
          dataSource={data}
          columns={columnData}
          onRow={(record) => {
            return {
              onClick: () => {
                setRowId(record.id);
                setMaxAllocate(record.raised_count);
              },
            };
          }}
        />
      </>
    )
  );
}
export default IndentTable;
