import React, { Fragment } from "react";
import { Row, Col, Typography, Tag, Tabs } from "antd";
import Avatar from "react-avatar";
import moment from "moment";
import Card from "../../blocks/Card/Card";
import { colors, USER } from "../../constants/defaultKeys";
import { getItem } from "../../helpers/localStorage";
import BasicDetails from "./BasicDetails";
import ChangePassword from "./ChangePassword";
import "./MyProfile.css";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const MyProfile = () => {
  const userDetails = JSON.parse(getItem(USER));

  return (
    <Fragment>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={{ span: 10, offset: 7 }}
          xl={{ span: 10, offset: 7 }}
        >
          <Card style={{ minHeight: "60vh" }}>
            <Row
              gutter={[16, 16]}
              type="flex"
              align="middle"
              style={{ padding: "30px" }}
            >
              <Col
                xs={8}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                style={{ textAlign: "center" }}
              >
                <Avatar
                  round={true}
                  color={colors[userDetails?.user_id % 24]}
                  name={userDetails?.first_name ? userDetails.first_name : "-"}
                  size="50px"
                />
              </Col>
              <Col xs={16} sm={18} md={20} lg={20} xl={20}>
                <Title level={5}>
                  {userDetails?.first_name ? userDetails.first_name : "user"}{" "}
                  {userDetails?.designation && (
                    <Tag color="magenta">{userDetails.designation}</Tag>
                  )}
                </Title>
                <span style={{ fontSize: "11px" }}>
                  {userDetails?.last_login && (
                    <Paragraph>
                      Last logged in{" "}
                      {userDetails.last_login
                        ? moment(userDetails?.last_login).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                        : "-"}
                    </Paragraph>
                  )}
                </span>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={{ span: 20, offset: 2 }}
                xl={{ span: 20, offset: 2 }}
              >
                <Tabs defaultActiveKey="1" centered>
                  <TabPane tab="Basic Details" key="1">
                    <BasicDetails user={userDetails} />
                  </TabPane>
                  <TabPane tab="Change Password" key="2">
                    <ChangePassword />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default MyProfile;
