import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import InventorySummaryCard from "../InventorySummaryCard";
import InventoryTableCard from "./InventoryTableCard";
import {
  getDistrictInventory,
  getDistrictInventoryStats,
} from "../../../services/inventory.services";

const DistrictCard = ({ id }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [inventoryStatsLoading, setInventoryStatsLoading] = useState(true);
  const [districtInventoryStats, setDistrictInventoryStats] = useState({});

  useEffect(() => {
    getDistrictInventory(id)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    getDistrictInventoryStats(id)
      .then((response) => {
        setDistrictInventoryStats(response.data);
        setInventoryStatsLoading(false);
      })
      .catch((error) => {
        setInventoryStatsLoading(false);
        console.error(error);
      });
  }, [id]);

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <InventorySummaryCard
          data={data}
          loading={loading}
          inventoryStats={districtInventoryStats}
          inventoryStatsLoading={inventoryStatsLoading}
        />
      </Col>
      <Col span={24}>
        <InventoryTableCard
          data={data?.device_count_mandal_wise}
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default DistrictCard;
