import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Menu, Layout, Row, Col, Button } from "antd";
import Overview from "./Sections/Overview";
import UserProfile from "./Sections/UserProfile";
import AddInventory from "./Sections/AddInventory";
import Analytics from "./Sections/Analytics";
import Monitor from "./Sections/Monitor";
import Cpu from "./Sections/Cpu";
import Mobile from "./Sections/mobile";
import LGMobile from "./Sections/LGMobile";
import Ups from "./Sections/Ups";
import Fingerprint from "./Sections/FingerPrint";
import SecurityCertificates from "./Sections/SecurityCertificates";
import ServiceRequests from "./Sections/ServiceRequests";
import Contact from "./Sections/Contact";
import Logo from "../../Logo/Logo";
import MobileLogo from "../../Logo/MobileLogo";
import { isMobileOnly } from "react-device-detect";
import "./Instructions.css";

const { SubMenu } = Menu;
const { Content, Sider, Header } = Layout;

// submenu keys of first level
const rootSubmenuKeys = ["sub1", "sub2", "sub4"];

const Instructions = () => {
  const history = useHistory();
  const query = useParams();

  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const [activeKey, setActiveKey] = useState("overview");

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  useEffect(() => {
    setActiveKey(query?.tab);
  }, [query]);

  const handleClick = (event) => {
    setActiveKey(event.key);
    history.push(`/instructions/${event.key}`);
  };
  return (
    <Layout>
      <Header>
        <Row>
          <Col xs={3} sm={3} md={6} lg={6} xl={6}>
            <div className="menu-logo">{isMobileOnly ? <MobileLogo /> : <Logo />}</div>
          </Col>
          <Col
            xs={21}
            sm={21}
            md={18}
            lg={18}
            xl={18}
            style={{ textAlign: "right", color: "white" }}
          >
            <Button type="primary">
              <a href={window.location.origin} target="_blank" rel="noopener noreferrer">
                Back Home Or Login
              </a>
            </Button>
          </Col>
        </Row>
      </Header>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider width={200} className="instructions-layout-sider" collapsible>
          <div className="logo" />
          <Menu
            className="Menu"
            mode="inline"
            openKeys={openKeys}
            theme="dark"
            onOpenChange={onOpenChange}
            defaultSelectedKeys={[activeKey]}
            style={{ height: "100%", borderRight: 0 }}
            onClick={handleClick}
          >
            <Menu.Item key="overview" title="Overview">
              Overview
            </Menu.Item>
            <Menu.Item className="user_profile" key="userprofile" title="Login/Register">
              User Account
            </Menu.Item>
            <Menu.Item className="add_inventory" key="addinventory" title="Add Inventory">
              Add Inventory
            </Menu.Item>

            <SubMenu className="manage_inventory" key="manage_inventory" title="Manage Inventory">
              <Menu.Item key="monitor">Monitor</Menu.Item>
              <Menu.Item key="cpu">Cpu</Menu.Item>
              <Menu.Item key="ups">Ups</Menu.Item>
              <Menu.Item key="fingerprint">Fingerprint Scanner</Menu.Item>
              <SubMenu className="mobile_service" key="mobile" title="Mobile">
                <Menu.Item key="mobile">MI</Menu.Item>
                <Menu.Item key="lgmobile">LG</Menu.Item>
              </SubMenu>
            </SubMenu>
            <Menu.Item key="securitycertificates">Security Certificates</Menu.Item>
            <Menu.Item key="servicerequests">Service Requests</Menu.Item>
            <Menu.Item key="viewanalytics">District/State Analytics</Menu.Item>
            <Menu.Item key="contact">Contact</Menu.Item>
          </Menu>
        </Sider>
        <Content
          className="instructions-layout-content"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          {activeKey === "overview" && <Overview />}
          {activeKey === "userprofile" && <UserProfile />}
          {activeKey === "addinventory" && <AddInventory />}
          {activeKey === "monitor" && <Monitor />}
          {activeKey === "mobile" && <Mobile />}
          {activeKey === "lgmobile" && <LGMobile />}
          {activeKey === "cpu" && <Cpu />}
          {activeKey === "ups" && <Ups />}
          {activeKey === "fingerprint" && <Fingerprint />}
          {activeKey === "securitycertificates" && <SecurityCertificates />}
          {activeKey === "servicerequests" && <ServiceRequests />}
          {activeKey === "viewanalytics" && <Analytics />}
          {activeKey === "contact" && <Contact />}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Instructions;
