import { Fragment, useState } from "react";
import { Table, Input, Button, Space, Spin } from "antd";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import { akColumns, tableColumns } from "../../../constants/defaultKeys";
import { SearchOutlined } from "@ant-design/icons";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";
const InventoryTable = ({ data, loading }) => {
  const userDetails = JSON.parse(getItem(USER));
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const filteredData = data?.filter((item) => item.aadhar_kits_supplied);
  const aekUser =
    userDetails?.is_aek_hod ||
    userDetails?.is_aek_hod_dept ||
    userDetails?.is_aek_dc;
  const filterCols = aekUser ? [...akColumns] : [...tableColumns];
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Secretariat Name",
      dataIndex: "secretariat_name",
      fixed: true,
      width: 200,
      ...getColumnSearchProps("secretariat_name"),
      render: (regionName, row) => (
        <Link to={`/village-secretariat/${regionName}/${row.secretariat_id}/`}>
          {regionName}
        </Link>
      ),
    },
    ...filterCols,
  ];

  return (
    <Fragment>
      <Table
        loading={
          loading && {
            indicator: (
              <Spin
                style={{
                  fontSize: "14px",
                  width: "350px",
                  marginLeft: "-180px",
                }}
                tip="Fetching results.. please wait for a min or two..."
              />
            ),
          }
        }
        dataSource={aekUser ? filteredData : data}
        columns={columns}
        pagination={false}
        scroll={isMobileOnly ? { x: 500, y: 500 } : { x: 1300, y: "80vh" }}
      />
    </Fragment>
  );
};

export default InventoryTable;
