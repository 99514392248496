import React, { useEffect, useState } from "react";
import { Table, Space, Button, Input, message, Col, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  tableFilterDevices,
  akTableFilterDevices,
} from "../../../constants/defaultKeys";
import ActionModal from "./Indent/ActionModal";
import { getDistrictInventoryRequests } from "../../../services/serviceRequests.services";

import moment from "moment";
import Drawer from "../../Drawer/Drawer";

import { Link, useParams } from "react-router-dom";
import { getItem } from "../../../helpers/localStorage";
import { USER } from "../../../constants/defaultKeys";
const monthFormat = "YYYY-MM";
const filterDevices = [
  ...tableFilterDevices,
  {
    text: "Sim",
    value: "Sim",
  },
  ...akTableFilterDevices,
];
const status = [
  {
    text: "Open",
    value: "Open",
  },
  {
    text: "Approved",
    value: "Approved",
  },
  {
    text: "Pending",
    value: "Pending",
  },
  {
    text: "Rejected",
    value: "Rejected",
  },
];
const InventoryRequestTable = ({ id, data_for }) => {
  const [searchText, setSearchText] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [editList, setEditList] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const curDate = moment().format(monthFormat);
  const [selectedMonth, setSelectedMonth] = useState();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const userDetails = JSON.parse(getItem(USER));
  const query = useParams();

  useEffect(() => {
    getDetails(selectedMonth);
  }, [selectedMonth, refresh]);

  function getDetails(selectedMonth) {
    setLoading(true);
    getDistrictInventoryRequests(id, { month: selectedMonth })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  const openModal = (data) => {
    setEditList(data);
    setShowModal(true);
  };
  const openDateModal = (data) => {
    setEditList(data);
    setDrawerVisible(true);
  };
  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Ticket Id",
      dataIndex: "ticket_id",
      key: "ticket_id",
      fixed: "left",
      width: 120,
      render: (value, record) =>
        value
          ? record?.ticket_id.split(",").map((ticket_id) => (
              <Link to={`/issues/${ticket_id.replace("#", "")}/`}>{`
                SRID #${ticket_id.replace("#", "")}  `}</Link>
            ))
          : "-",
    },
    {
      title: "Employee Id",
      dataIndex: "employee_id",
      key: "employee_id",
      width: 150,
      render: (value) => (value ? <span>{value}</span> : "-"),
    },
    {
      title: "Device Type",
      width: 150,
      dataIndex: "inventory_type",
      key: "inventory_type",
      filters: [...filterDevices],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.inventory_type.includes(value),
      render: (inventory_type, record) => (
        <span>
          {record?.inventory_type === "Sim"
            ? inventory_type + ":" + " " + record?.network.join(" / ")
            : inventory_type}
        </span>
      ),
    },
    {
      title: "Requested Count From Secretariat",
      width: 180,
      dataIndex: "count",
      key: "count",
      sorter: (a, b) => a.count - b.count,
      render: (count, record) => <span>{record?.count ? count : "-"}</span>,
    },
    {
      title: "Received Count By Secretariat",
      width: 180,
      dataIndex: "received_count",
      key: "received_count",
      sorter: (a, b) => a.received_count - b.received_count,
      render: (count, record) => (
        <span>{record?.received_count ? count : "-"}</span>
      ),
    },
    {
      title: "Requested Date",
      width: 150,
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
    {
      title: "Secretariat Name",
      width: 200,
      dataIndex: "secretariat_name",
      key: "secretariat_name",
      ...getColumnSearchProps("secretariat_name"),
      render: (data) => <span>{data}</span>,
    },
    {
      title: "Mandal",
      width: 200,
      dataIndex: "mandal",
      key: "mandal",
      ...getColumnSearchProps("mandal"),
      render: (data) => <span>{data}</span>,
    },
    {
      title: "Reasons",
      dataIndex: "reason",
      key: "reason",
      width: 250,
      filters: [
        {
          text: "Existing device is not working / damaged and is beyond repair",
          value: "Existing device is not working/damaged and is beyond repair",
        },
        {
          text: "The device is lost",
          value: "The device is lost",
        },
        {
          text: "Have not received any device till date",
          value: "Have not received any device till date",
        },
        {
          text: "New employee recruited",
          value: "New employee recruited",
        },
        {
          text: "Existing SIM(s) with poor signal",
          value: "Existing sim with poor signal",
        },
        {
          text: "Unknow mobile number and 20 digit SIM number",
          value: "Unknow mobile number and 20 digit SIM number",
        },
      ],
      onFilter: (value, record) => record.reason.includes(value),
      render: (reason, record) => (
        <ul>
          {reason.map((item, id) =>
            item === "Device Damage" ? (
              <li key="DD">
                Existing device is not working/damaged and is beyond repair
              </li>
            ) : (
              <li key={id}>{item}</li>
            )
          )}
        </ul>
      ),
    },
    {
      title: "Status",
      width: 150,
      dataIndex: "resolution_status",
      key: "resolution_status",
      filters: [...status],
      onFilter: (value, record) => {
        let shouldReturn = value === record.resolution_status;
        return shouldReturn;
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: 200,
      render: (data, record) => <span>{record?.remarks ? data : "-"}</span>,
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      fixed: "right",
      render: (record) => (
        <>
          {!(
            record?.resolution_status === "Rejected" ||
            record?.resolution_status === "Approved"
          ) ? (
            <Button
              type="primary"
              size="small"
              onClick={() => openModal(record)}
              disabled={
                !(
                  userDetails?.is_indent_authority &&
                  userDetails?.region?.region_name == query?.region
                )
              }
            >
              Change status
            </Button>
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Col style={{ textAlign: "right", marginBottom: "10px" }}>
        <Space>
          <DatePicker
            picker="month"
            onChange={(_, date) => setSelectedMonth(date)}
            defaultValue={moment(curDate)}
            allowClear={false}
          />
          <Button
            type="primary"
            onClick={() =>
              openDateModal({
                title: "Download",
                status: "fileDownload",
                key: "indent",
              })
            }
            loading={btnLoading}
          >
            Download as excel
          </Button>
        </Space>
      </Col>

      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{ x: 1200 }}
      />
      {showModal && (
        <ActionModal
          setVisible={setShowModal}
          editList={editList}
          refresh={refreshPage}
          status={status}
        />
      )}
      {drawerVisible && (
        <Drawer
          setVisible={setDrawerVisible}
          data_for={data_for}
          editList={editList}
          id={id}
        />
      )}
    </>
  );
};

export default InventoryRequestTable;
