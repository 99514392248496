import React, { Fragment, useState } from "react";
import { Row, Col, Button, Typography } from "antd";
import { EditFilled } from "@ant-design/icons";
import Card from "../../../blocks/Card/Card";
import internetLogo from "../../../assets/devices/internet.png";
import wifiLogo from "../../../assets/devices/wifi.png";
import logo3g from "../../../assets/devices/3G.png";
import logo4g from "../../../assets/devices/4G.png";
import Drawer from "../../Drawer/Drawer";
import useVSAccess from "../../../hooks/useVSAccess";

const { Title } = Typography;

const Internet = ({ hasPermission, data, userDetails, query, refresh }) => {
  const [editList, setEditList] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const internetBrandType = data?.internet_connection?.internet_primarily_used;

  const openModal = (data) => {
    setEditList(data);
    setDrawerVisible(true);
  };
  const { canVSAccess } = useVSAccess(false);

  return (
    <Fragment>
      <Card
        title={
          <>
            <Typography.Paragraph
              style={{ marginBottom: "0px", textAlign: "center" }}
            >
              Internet
            </Typography.Paragraph>
          </>
        }
        className="internet"
        extra={
          canVSAccess ? (
            hasPermission &&
            data?.internet_connection?.internet_primarily_used ? (
              <span
                style={{
                  fontSize: "15px",
                  color: "#1890ff",
                  cursor: "pointer",
                }}
                onClick={() =>
                  openModal({
                    status: "internet",
                    title: "Edit Internet",
                    action: "edit",
                  })
                }
              >
                <EditFilled />
              </span>
            ) : (
              <>
                {hasPermission && (
                  <Button
                    type="primary"
                    size="small"
                    onClick={() =>
                      openModal({
                        status: "internet",
                        title: "Add Internet",
                        action: "add",
                      })
                    }
                  >
                    +
                  </Button>
                )}
              </>
            )
          ) : null
        }
      >
        {data?.internet_connection?.internet_primarily_used ? (
          <Row type="flex">
            <Col span={24} style={{ textAlign: "center" }}>
              <Title
                style={{
                  fontSize: "13px",
                  marginBottom: "10px",
                  textAlign: "center",
                  display: "block",
                }}
              >
                {data?.internet_connection?.service_provider}{" "}
              </Title>
              <img
                src={
                  internetBrandType === "Mobile-3G-connection"
                    ? logo3g
                    : internetBrandType === "Wifi-Dongle"
                    ? wifiLogo
                    : internetBrandType === "Mobile-4G-connection"
                    ? logo4g
                    : internetLogo
                }
                height="32px"
                alt="internet"
              />
              {data?.internet_connection?.is_poor_connection && (
                <p
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    paddingTop: "5px",
                  }}
                >
                  Poor
                </p>
              )}
            </Col>
          </Row>
        ) : (
          "No Connection"
        )}
      </Card>
      {drawerVisible && (
        <Drawer
          setVisible={setDrawerVisible}
          editList={editList}
          id={query?.id}
          refresh={refresh}
          userDetails={userDetails}
          data={data}
          setEditList={setEditList}
        />
      )}
    </Fragment>
  );
};

export default Internet;
