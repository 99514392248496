import React, { Fragment, useEffect, useState } from "react";
import { Modal, Button, Form, message, Select } from "antd";
import { approveSimRequest } from "../../../services/inventory.services";

const { Option } = Select;

const ActionModal = ({ editList, setVisible, refresh, status }) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);
  const [selectedStaus, setSelectedStatus] = useState(
    editList?.resolution_status
  );

  useEffect(() => {
    form.setFieldsValue({
      resolution_status: editList?.resolution_status,
    });
    setFormValues({
      ...formValues,
      resolution_status: editList?.resolution_status,
    });
  }, [editList]);

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    approveSimRequest(editList.id, {
      resolution_status: values.resolution_status,
      remarks: values.remarks,
    })
      .then((response) => {
        message.success(response.data.message);
        setVisible(false);
        refresh(true);
      })
      .catch((error) => console.log(error));
  };

  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  const pendingRemarks = [
    {
      text: "Invalid 20 digit SIM number",
    },
  ];
  const rejectedRemarks = [
    {
      text: "Mobile number not provided by GSWS",
    },
    {
      text: `This mobile number doesn't belongs to ${editList?.network}`,
    },
  ];
  return (
    <Fragment>
      <Modal
        title="Change Status"
        visible={true}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="basic"
          onFinish={onFinish}
          layout="vertical"
          onValuesChange={onFormValuesChange}
          form={form}
        >
          <Form.Item
            name="resolution_status"
            rules={[
              {
                required: true,
                message: "Please choose resolution status",
              },
            ]}
          >
            <Select
              placeholder="Resolution Status"
              size="large"
              onSelect={(val) => setSelectedStatus(val)}
            >
              {status.map((item) => (
                <Option value={item.value} key={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {(formValues?.resolution_status === "Pending" ||
            formValues?.resolution_status === "Rejected") && (
            <Form.Item
              name="remarks"
              rules={[
                {
                  required: true,
                  message: "Please input remarks",
                },
              ]}
            >
              <Select placeholder="Remarks" size="large">
                {selectedStaus === "Pending"
                  ? pendingRemarks.map((item) => (
                      <Option value={item.text} key={item.text}>
                        {item.text}
                      </Option>
                    ))
                  : rejectedRemarks.map((item) => (
                      <Option value={item.text} key={item.text}>
                        {item.text}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit" size="large">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default ActionModal;
