import React from "react";
import { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "./Dashboard.css";
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p style={{ fontSize: "14px", fontWeight: "bold" }}>
          {payload[0].payload.inventory_type}
        </p>
        <p className="label">Working : {payload[0].payload.working}%</p>
        <p className="label">Not Working : {payload[0].payload.not_working}%</p>
      </div>
    );
  }
  return null;
};

const DeviceByTypeGraph = ({ data, showAadharDevices }) => {
  const [aadharData, setAadharData] = useState(null);

  useEffect(() => {
    let allData = [];
    for (let i = 0; i < data?.length; i++) {
      if (!(data[i].working === 0 && data[i].not_working === 0)) {
        if (data[i].inventory_type.includes("AK") && showAadharDevices) {
          allData.push(data[i]);
        } else if (
          !data[i].inventory_type.includes("AK") &&
          !showAadharDevices
        ) {
          allData.push(data[i]);
        }
      }
    }
    setAadharData(allData);
  }, [showAadharDevices]);

  return (
    <ResponsiveContainer className="Device" width="100%" height={350}>
      <BarChart
        data={aadharData}
        layout="vertical"
        margin={{
          top: 5,
          right: 5,
          left: 30,
          bottom: 5,
        }}
      >
        <XAxis type="number" />
        <YAxis
          type="category"
          dataKey="inventory_type"
          tick={{ fontSize: 10 }}
          interval={0}
        />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="working" stackId="a" fill="#9DC59A" barSize={18} />
        <Bar dataKey="not_working" stackId="a" fill="#F99494" barSize={18} />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default DeviceByTypeGraph;
