import React, { Fragment, useState, useEffect } from "react";
import { InputNumber, Form, Button, message, Alert } from "antd";
import {
  damagedSecureCertificateRequests,
  getDamagedSecureCertificateRequests,
} from "../../../services/serviceRequests.services";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";

const ReportDamagedCertificates = ({ id }) => {
  const userDetails = JSON.parse(getItem(USER));
  const [data, setData] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    getDamagedSecureCertificateRequests(id)
      .then((response) => setData(response.data))
      .catch((error) => {
        message.error(error?.response?.data?.message);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const onFinish = (values) => {
    damagedSecureCertificateRequests(id, values)
      .then((response) => message.success(response.data.message))
      .catch((error) => {
        message.error(error?.response?.data?.message);
        console.log(error);
      });
  };

  return (
    <Fragment>
      <Form
        form={form}
        onFinish={onFinish}
        colon={false}
        initialValues={{
          damaged_count: data?.damaged_count,
        }}
        layout="vertical"
      >
        <Form.Item
          label="Damaged Certificates Count"
          name="damaged_count"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <InputNumber size="large" style={{ minWidth: "120px" }} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!userDetails?.is_village_secretariat}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default ReportDamagedCertificates;
