import React, { useState, useEffect } from "react";
import { message, Form } from "antd";
import { aekDcHodServiceRequests } from "../../../services/serviceRequests.services";
import moment from "moment";
import { aekVendorDownload } from "../../../services/downloadFile.services";
import AekFilters from "./AekFilters";
import { getRegionsData } from "../../../services/auth.services";
import { getItem } from "../../../helpers/localStorage";
import { USER } from "../../../constants/defaultKeys";
import { getUidaiTickets } from "../../../services/inventory.services";
const defaultVal = { label: "ALL", val: "ALL" };
const defaultRegions = { name: "ALL", id: 0 };
const AekVendor = ({ tab }) => {
  const [form] = Form.useForm();
  const userDetails = JSON.parse(getItem(USER));
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusParam, setStatusParam] = useState(
    userDetails?.is_uidai ? "forward-to-UIDAI" : null
  );
  const [btnLoading, setBtnLoading] = useState(false);
  const [regionsData, setRegionsData] = useState(null);
  const [districts, setDistricts] = useState([defaultRegions]);
  const [mandals, setMandals] = useState([defaultRegions]);
  const [secretariats, setSecretariats] = useState([defaultRegions]);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [dates, setDates] = useState({
    startDate: moment().subtract(30, "days"),
    endDate: moment(),
  });
  let mandalData = regionsData?.find(
    (district) => district?.id === userDetails?.region?.region_id
  );

  useEffect(() => {
    getRegionsData()
      .then((response) => {
        setRegionsData(response?.data);
        setDistricts([...districts, ...response?.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (userDetails?.region) {
      setMandals(
        mandalData?.mandals && [defaultRegions, ...mandalData?.mandals]
      );
    }
  }, [regionsData]);

  useEffect(() => {
    setLoading(true);
    let queryParams = {
      page: currentPage,
      start_date: dates.startDate.format("YYYY-MM-DD"),
      end_date: dates.endDate.format("YYYY-MM-DD"),
    };

    if (statusParam) {
      queryParams.status = statusParam;
    }
    if (tab === "self" && userDetails?.is_aek_dc) {
      queryParams.self = true;
    }
    if (tab === "self" && !userDetails?.is_aek_dc) {
      queryParams.raised_by_dc = true;
    }
    if (
      tab === "self" &&
      !userDetails?.is_aek_dc &&
      selectedFilters?.district
    ) {
      queryParams.dc_district = selectedFilters.district;
    }
    if (tab === "secretariat" && selectedFilters) {
      queryParams = {
        ...queryParams,
        ...(selectedFilters?.district && {
          district: selectedFilters.district,
        }),
        ...(selectedFilters?.mandal && { mandal: selectedFilters.mandal }),
        ...(selectedFilters?.secretariat && {
          secretariat: selectedFilters.secretariat,
        }),
      };
    }
    const queryString = new URLSearchParams(queryParams).toString();
    aekDcHodServiceRequests(queryString)
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [statusParam, dates, currentPage, selectedFilters, tab, refresh]);

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };
  const onStatusChange = (value) => {
    setCurrentPage(1);
    setStatusParam(null);

    if (value === "ALL") {
      setStatusParam([defaultVal]);
    } else {
      setStatusParam(value);
    }
  };
  const onDistrictChange = (val) => {
    setSelectedFilters(null);
    form.resetFields(["mandal"]);
    form.resetFields(["secretariat"]);
    setMandals([defaultRegions]);
    setSecretariats([defaultRegions]);
    const res = regionsData?.find((item) => item?.id === val);
    if (res === undefined || val === 0) {
      setDistricts([defaultRegions]);
      setMandals([defaultRegions]);
    } else {
      setDistricts([res]);
      setMandals([defaultRegions, ...res?.mandals]);
      setSelectedFilters({ district: val });
    }
  };

  const onMandalChange = (val) => {
    form.resetFields(["secretariat"]);

    const mandal = mandals?.find((item) => item?.id === val);
    if (mandal === undefined || val === 0) {
      setSelectedFilters({ district: districts[0]?.id });
      setSecretariats([defaultRegions]);
    } else {
      setSecretariats(mandal);
      setSelectedFilters({ mandal: val });
    }
  };
  const onSecretariatChange = (val) => {
    if (val === 0) {
      setSelectedFilters({ mandal: secretariats?.id });
    } else setSelectedFilters({ secretariat: val });
  };
  const handleDownload = () => {
    let queryParams = {
      start_date: dates.startDate.format("YYYY-MM-DD"),
      end_date: dates.endDate.format("YYYY-MM-DD"),
    };
    if (statusParam) {
      queryParams.status = statusParam;
    }
    if (tab === "self" && userDetails?.is_aek_dc) {
      queryParams.self = true;
    }
    if (
      tab === "self" &&
      !userDetails?.is_aek_dc &&
      selectedFilters?.district
    ) {
      queryParams.dc_district = selectedFilters.district;
    }
    if (tab === "self" && !userDetails?.is_aek_dc) {
      queryParams.raised_by_dc = true;
    }
    if (tab === "secretariat" && selectedFilters) {
      queryParams = {
        ...queryParams,
        ...(selectedFilters?.district && {
          district: selectedFilters.district,
        }),
        ...(selectedFilters?.mandal && { mandal: selectedFilters.mandal }),
        ...(selectedFilters?.secretariat && {
          secretariat: selectedFilters.secretariat,
        }),
      };
    }
    const queryString = new URLSearchParams(queryParams).toString();
    setBtnLoading(true);
    aekVendorDownload(queryString)
      .then((response) => {
        let blob = response.data;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "AEK-Issues.xlsx";
        a.click();
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong, Please try after sometime.");
        setBtnLoading(false);
      });
  };

  return (
    <AekFilters
      tab={tab}
      onStatusChange={onStatusChange}
      defaultVal={defaultVal}
      dates={dates}
      data={data}
      loading={loading}
      handleDownload={handleDownload}
      btnLoading={btnLoading}
      setDates={setDates}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      statusParam={statusParam}
      defaultRegions={defaultRegions}
      regionsData={regionsData}
      districts={districts}
      form={form}
      onDistrictChange={onDistrictChange}
      onMandalChange={onMandalChange}
      mandals={mandals}
      onSecretariatChange={onSecretariatChange}
      secretariats={secretariats}
      selectedFilters={selectedFilters}
      userDetails={userDetails}
      refresh={refreshPage}
    />
  );
};
export default AekVendor;
