import { Button, Col, Row, Space, Table } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";

import VolunteerInventoryTrackingForm from "./VolunteerInventoryTrackingForm";
import { getVolunteerInventoryTracking } from "../../../services/inventory.services";

import "./VolunteerInventoryTracking.css";
import VolunteerInventoryAcknowledgementForm from "./VolunteerInventoryAcknowledgementForm";
import useVSAccess from "../../../hooks/useVSAccess";
import useViewAccess from "../../../hooks/useViewAccess";

export default function VolunteerInventoryTracking({ userDetails }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState({ status: false, type: null });
  useVSAccess();
  useViewAccess(
    "is_ward_welfare_data_processing_secretary",
    "is_village_secretariat"
  );
  async function getVolunteerInventoryTrackingData() {
    try {
      const response = await getVolunteerInventoryTracking();
      setData(response.data?.data || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getVolunteerInventoryTrackingData();
  }, []);

  const showDrawer = (type) => {
    setOpen({ status: true, type });
  };

  const onClose = () => {
    setOpen({ status: false, type: null });
    getVolunteerInventoryTrackingData();
  };

  const columns = [
    {
      title: "Cluster Code",
      dataIndex: "cluster_code",
      key: "cluster_code",
      width: 150,
      fixed: "left",
      sorter: (a, b) => a.cluster_code - b.cluster_code,
    },
    // {
    //   title: "Volunteer Name",
    //   dataIndex: "volunteer_name",
    //   key: "volunteer_name",
    //   width: 100,
    // },
    // {
    //   title: "CFMS ID",
    //   dataIndex: "cfms_id",
    //   key: "cfms_id",
    //   width: 100,
    // },
    {
      title: "Mobile",
      children: [
        {
          title: "Brand",
          dataIndex: "mobile_brand",
          key: "mobile_brand",
          render: (value) => <span>{value ? value : "-"}</span>,
          width: 80,
        },
        {
          title: "IMEI",
          dataIndex: "mobile_imei_number",
          key: "mobile_imei_number",
          render: (value) => <span>{value ? value : "-"}</span>,
          width: 120,
        },
        {
          title: "Status",
          dataIndex: "is_mobile_working",
          key: "is_mobile_working",
          render: (value) => (
            <span>
              {typeof value === "boolean"
                ? value
                  ? "Working"
                  : "Not Working"
                : "-"}
            </span>
          ),
          width: 90,
        },
      ],
    },
    {
      title: "SIM",
      children: [
        {
          title: "Brand",
          dataIndex: "simcard_provider",
          key: "simcard_provider",
          render: (value) => <span>{value ? value : "-"}</span>,
          width: 80,
        },
        {
          title: "Mobile Number",
          dataIndex: "mobile_number",
          key: "mobile_number",
          render: (value) => <span>{value ? value : "-"}</span>,
          width: 120,
        },
        {
          title: "Status",
          dataIndex: "is_simcard_working",
          key: "is_simcard_working",
          render: (value) => (
            <span>
              {typeof value === "boolean"
                ? value
                  ? "Working"
                  : "Not Working"
                : "-"}
            </span>
          ),
          width: 90,
        },
      ],
    },
    {
      title: "Finger Print Scanner",
      children: [
        {
          title: "Brand",
          dataIndex: "FPS_brand",
          key: "FPS_brand",
          render: (value) => <span>{value ? value : "-"}</span>,
          width: 80,
        },
        {
          title: "Serial Number",
          dataIndex: "FPS_serial_no",
          key: "FPS_serial_no",
          render: (value) => <span>{value ? value : "-"}</span>,
          width: 120,
        },
        {
          title: "Status",
          dataIndex: "is_FPS_working",
          key: "is_FPS_working",
          render: (value) => (
            <span>
              {typeof value === "boolean"
                ? value
                  ? "Working"
                  : "Not Working"
                : "-"}
            </span>
          ),
          width: 90,
        },
      ],
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      width: 120,
    },
    // {
    //   title: "Collected By",
    //   dataIndex: "inventory_collector",
    //   key: "inventory_collector",
    //   width: 100,
    //   render: (value, record) => (
    //     <span>
    //       {value === "Others" ? record?.inventory_collector_designation : value}
    //     </span>
    //   ),
    // },
    // {
    //   title: "Authorization Person Name",
    //   dataIndex: "inventory_collector_name",
    //   key: "inventory_collector_name",
    //   fixed: "right",
    //   width: 130,
    // },
  ];

  return (
    <Row gutter={[0, 24]} style={{ paddingRight: "20px", paddingLeft: "20px" }}>
      <Col span={24} style={{ textAlign: "right" }}>
        <Space>
          <Button type="primary" onClick={() => showDrawer("acknowledgement")}>
            Inventory Acknowledgement
          </Button>
          <Button type="primary" onClick={() => showDrawer("inventory")}>
            Add Inventory
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        <Table
          bordered
          dataSource={data}
          loading={loading}
          columns={columns}
          pagination={false}
          className="custom-header"
          scroll={{ x: 1400, y: "80vh" }}
        />
      </Col>

      {open?.status && open?.type === "inventory" && (
        <VolunteerInventoryTrackingForm
          open={open}
          onClose={onClose}
          userDetails={userDetails}
        />
      )}
      {open?.status && open?.type === "acknowledgement" && (
        <VolunteerInventoryAcknowledgementForm
          open={open}
          onClose={onClose}
          userDetails={userDetails}
        />
      )}
    </Row>
  );
}
