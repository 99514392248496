import React, { Fragment, useEffect, useState } from "react";
import { Modal, Button, Form, message, Select, Input } from "antd";
import { approveOffice365Request } from "../../../services/inventory.services";

const { Option } = Select;
const { TextArea } = Input;

const ActionModal = ({ editList, setVisible, refresh, status }) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    form.setFieldsValue({
      resolution_status: editList?.resolution_status,
    });
    setFormValues({
      ...formValues,
      resolution_status: editList?.resolution_status,
    });
  }, [status]);

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    approveOffice365Request(editList.id, {
      resolution_status: values.resolution_status,
      remarks: values.remarks,
    })
      .then((response) => {
        message.success(response.data.message);
        setVisible(false);
        refresh(true);
      })
      .catch((error) => console.log(error));
  };

  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  return (
    <Fragment>
      <Modal
        title="Change Status"
        visible={true}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="basic"
          onFinish={onFinish}
          layout="vertical"
          onValuesChange={onFormValuesChange}
          form={form}
        >
          <Form.Item
            name="resolution_status"
            rules={[
              {
                required: true,
                message: "Please choose resolution status",
              },
            ]}
          >
            <Select placeholder="Resolution Status" size="large">
              {status.map((item) => (
                <Option value={item.value} key={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {(formValues?.resolution_status === "Closed" ||
            formValues?.resolution_status === "waiting-on-user") && (
            <Form.Item
              name="remarks"
              rules={[
                {
                  required: true,
                  message: "Please input remarks",
                },
              ]}
            >
              <TextArea placeholder="Remarks" />
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit" size="large">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default ActionModal;
