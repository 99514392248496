import React, { Fragment } from "react";
import { PageHeader } from "antd";
import { useHistory } from "react-router-dom";

const PageHeaders = ({ title }) => {
  const history = useHistory();

  return (
    <Fragment>
      <PageHeader
        className="service-request-page-header"
        onBack={() => history.push(`${history.location.pathname}`)}
        title={title}
      />
    </Fragment>
  );
};

export default PageHeaders;
