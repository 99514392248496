import React, { useRef, useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { aadhaarIssuesList } from "../../../../constants/defaultKeys";
import {
  Form,
  Select,
  InputNumber,
  Button,
  message,
  Upload,
  Input,
} from "antd";
import * as config from "../../../../config/validation.config";
import { useParams } from "react-router-dom";
import { uploadFile } from "../../../../services/downloadFile.services";
import {
  convertToFormData,
  handleMultipleFileExt,
  replaceDotsExceptLast,
} from "../../../../helpers/utility";
import axios from "axios";
import { raiseAekServiceRequest } from "../../../../services/serviceRequests.services";
const { TextArea } = Input;
function AekForm({ data }) {
  const formRef = useRef();
  const query = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [fileUploadError, setFileUploadError] = useState();
  const [uploadedFileName, setuploadedFileName] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      mobile_number: data?.contact_number_unmasked,
      email_id: data?.email,
    });
  }, [query?.id]);
  const uploadProps = {
    beforeUpload: (file, fileList) => {
      if (handleMultipleFileExt(file)) return Upload.LIST_IGNORE;

      const fileType =
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      let fileSize = (file.size / 5260006).toFixed(0);
      const multitext = file.name.split(".").length > 2;
      let file_status = false;
      if (fileType && !multitext) {
        file_status = true;
        if (file.size > 5260006) {
          file_status = false;
          message.error(
            `File size should be less than 5MB, uploaded file is ${fileSize}MB, exceeding the maximum 5MB limit`
          );
        }
      } else {
        message.error(`${file.name} is not a valid file`);
      }
      return file_status ? true : Upload.LIST_IGNORE;
    },
    async onChange(info) {
      if (info.file.status === "done") {
        setUploadFileLoading(true);
        try {
          const res = await uploadFile({
            folder: "issues-images",
            filename: replaceDotsExceptLast(info.file.name),
            uploading_to_vsws: true,
            is_private_file: false,
          });
          const formData = convertToFormData(res, info);
          await axios({
            method: "POST",
            url: res?.data?.url?.url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          setuploadedFileName(
            "https://resources.vsws.co.in/" + "" + res?.data?.url?.fields?.key
          );
          message.success(`${info.file.name} file uploaded successfully`);
        } catch (err) {
          console.log(err);
          // message.error(err?.response?.data?.message);
        } finally {
          setUploadFileLoading(false);
        }
      }
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  function handleSubmit(values) {
    const formData = {
      ...values,
      file_ref: uploadedFileName,
      village_secretariat: query?.id,
    };
    setLoading(true);
    raiseAekServiceRequest(formData)
      .then((response) => {
        message.success(response.data.message);
        form.resetFields();
        setLoading(false);
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  }

  const sortedIssues = aadhaarIssuesList.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return (
    <Form
      id="form"
      form={form}
      ref={formRef}
      wrapperCol={{
        span: 10,
      }}
      layout="vertical"
      onFinish={handleSubmit}
      onValuesChange={onFormValuesChange}
    >
      <Form.Item
        label="Station Id"
        name="station_id"
        rules={[{ required: true, message: "Please enter station Id" }]}
      >
        <InputNumber
          maxLength={5}
          className="input-num-field-w-100"
          placeholder="Station Id"
        />
      </Form.Item>
      <Form.Item
        label="User Id"
        name="user_id"
        rules={[{ required: true, message: "Please enter user Id" }]}
      >
        <Input maxLength={22} placeholder="User Id" />
      </Form.Item>
      <Form.Item
        label="Machine Id"
        name="machine_id"
        rules={[{ required: true, message: "Please enter Machine Id" }]}
      >
        <Input placeholder="Machine Id" />
      </Form.Item>
      <Form.Item
        label="Anydesk Id"
        name="anydesk_id"
        rules={[{ required: true, message: "Please enter Anydesk Id" }]}
      >
        <Input placeholder="Anydesk Id" />
      </Form.Item>
      <Form.Item
        label="Mobile number"
        name="mobile_number"
        rules={config.mobileNumberRegex}
      >
        <Input
          autoComplete="off"
          size="large"
          maxLength="10"
          placeholder="Mobile Number"
          addonBefore={"+91"}
        />
      </Form.Item>

      <Form.Item
        label="Email address"
        name="email_id"
        rules={config.emailValidation}
      >
        <Input placeholder="Email address" />
      </Form.Item>

      <Form.Item
        label="Client Version"
        name="client_version"
        rules={[{ required: true, message: "Please mention client version" }]}
      >
        <Input placeholder="Application name – ECMP" />
      </Form.Item>
      <Form.Item label="Register Code">
        <Input disabled={true} value={250} />
      </Form.Item>
      <Form.Item label="Enrolment Code">
        <Input disabled={true} value={2935} />
      </Form.Item>
      <Form.Item label="Log File" name="log_file">
        <Input placeholder="Log File" />
      </Form.Item>
      <Form.Item label="RO Name">
        <Input disabled={true} value={"UIDAI-RO-HYD"} />
      </Form.Item>
      <Form.Item
        label="Issue Type"
        name="issues"
        rules={[{ required: true, message: "Please choose issues" }]}
      >
        <Select
          mode="multiple"
          placeholder="Issue type"
          value={selectedItems}
          onChange={setSelectedItems}
          style={{
            width: "100%",
          }}
          options={sortedIssues?.map(({ id, label }) => ({
            value: id,
            label: label,
          }))}
        />
      </Form.Item>
      {formValues?.issues?.includes("OTHERS") && (
        <Form.Item name="other_issue">
          <TextArea placeholder="Please specify other issues" />
        </Form.Item>
      )}
      <Form.Item
        label="Issue description"
        name="issue_description"
        rules={[{ required: true, message: "Please enter issue descripition" }]}
      >
        <Input.TextArea rows={4} placeholder="please be detailed" />
      </Form.Item>
      <Form.Item
        label="File upload (PDF , Word doc & png/jpg/jpeg only and file size should be less than 5MB)"
        name="file_ref"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: "Upload file " }]}
      >
        <Upload {...uploadProps} customRequest={dummyRequest} maxCount={1}>
          <Button icon={<UploadOutlined />}>Upload File</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={loading || uploadFileLoading}
          disabled={uploadFileLoading}
        >
          {loading ? "Submiting..." : "Submit"}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AekForm;
