import React, { useState, useEffect } from "react";
import { Col, Progress, Row, Skeleton, Typography } from "antd";
import Charts from "../../Charts/Charts";
import Card from "../../../blocks/Card/Card";
import TicketTable from "./TicketTable";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";
import { getAllInventoryIssues } from "../../../services/issue.services";
import "../Admin.css";
import "../../custom.css";
import useViewAccess from "../../../hooks/useViewAccess";

const { Paragraph } = Typography;

const Tickets = ({ stateLevelIssues }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const userDetails = JSON.parse(getItem(USER));
  useViewAccess(
    "is_state_executive_authority",
    "is_admin",
    "is_aek_hod",
    "is_aek_hod_dept"
  );
  useEffect(() => {
    const params = aekUsers
      ? { data_for: "state", filter_ak: "-AK" }
      : { data_for: "state", filter_ak: "" };
    getAllInventoryIssues(params)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const aekUsers = userDetails?.is_aek_hod || userDetails?.is_aek_hod_dept;

  return (
    <Row gutter={[8, 24]}>
      {!aekUsers && (
        <>
          {" "}
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
          >
            <Card title="Tickets Raised" className="ticket-raised">
              {loading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <Charts data={stateLevelIssues?.raised_tickets_graph_data} />
              )}
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
          >
            <Card title="Tickets Pending" className="ticket-pending">
              {loading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <Charts data={stateLevelIssues?.pending_tickets_graph_data} />
              )}
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
          >
            <Card title="Tickets Closed" className="ticket-closed">
              {loading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <Charts data={stateLevelIssues?.resolved_tickets_graph_data} />
              )}
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 9 }}
            lg={{ span: 9 }}
          >
            <Card
              title="Resolution Metrics (Avg)"
              className="ticket-resolution-metrics"
            >
              {loading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <Row gutter={[16, 16]}>
                  {stateLevelIssues?.issues_percentage_data_by_resolution.map(
                    (item, index) => (
                      <Col span={6} style={{ textAlign: "center" }} key={index}>
                        <Progress
                          type="dashboard"
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          width={65}
                          strokeWidth={12}
                          percent={item?.percentage?.toFixed(0) || 0}
                        />
                        <Paragraph style={{ fontSize: "13px" }}>
                          {item?.resolved_status?.replace(/([A-Z])/g, " $1")}
                        </Paragraph>
                      </Col>
                    )
                  )}
                </Row>
              )}
            </Card>
          </Col>
        </>
      )}

      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
      >
        <TicketTable
          data={data?.issues_data}
          data_for="state"
          loading={loading}
          aekUsers={aekUsers}
        />
      </Col>
    </Row>
  );
};

export default Tickets;
