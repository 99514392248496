import { getDistrictCoordinate } from "./utility";

export const convertToDeviceByRegionGraph = (data) => {
  return data?.map((region) => {
    return {
      name: region.district,
      working: region.working,
      notWorking: region.not_working,
    };
  });
};

export const convertToDeviceByTypeGraph = (data) => {
  return data?.map((region) => {
    return {
      inventoryType: region.inventory_type,
      working: region.working,
      notWorking: region.not_working,
    };
  });
};

export const convertToMapData = (data) => {
  return data?.map((region) => {
    const [x, y] = getDistrictCoordinate(region.district);
    return { x, y, district: region.district, count: region.count };
  });
};
