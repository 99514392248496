import fpIcon from "../assets/devices/fingerprint.png";
import monitorIcon from "../assets/devices/desktop.png";
import monitorAKIcon from "../assets/devices/monitorAK.png";
import LaptopAKIcon from "../assets/devices/LaptopAK.png";
import WebcamAKIcon from "../assets/devices/WebcamAK.png";
import PrinterAKIcon from "../assets/devices/PrinterAK.png";
import SlapScannerAKIcon from "../assets/devices/SlapScannerAK.png";
import IRISAKIcon from "../assets/devices/IRISAK.png";
import GPSAKIcon from "../assets/devices/GPSDeviceAK.png";
import cpuIcon from "../assets/devices/cpu.png";
import upsIcon from "../assets/devices/ups.png";
import laminatingIcon from "../assets/devices/laminator.png";
import mobileIcon from "../assets/devices/mobile.png";
import simIcon from "../assets/devices/sim.png";
import irisIcon from "../assets/devices/eye.png";
import printerIcon from "../assets/devices/printer.png";
import BPE_UPS from "../assets/devices/InfoDevicesIcons/BPE-UPS.jpg";
import Consol_UPS from "../assets/devices/InfoDevicesIcons/Consol-Ups.jpg";
import Epson from "../assets/devices/InfoDevicesIcons/Epson.jpg";
import FP_Mantra from "../assets/devices/InfoDevicesIcons/FP-Mantra.jpg";
import FP_Starker from "../assets/devices/InfoDevicesIcons/FP-Starker.png";
import FP_Next from "../assets/devices/InfoDevicesIcons/FP-Next.png";
import FP_ARATEK from "../assets/devices/InfoDevicesIcons/ARATEK.jpeg";
import IRIS from "../assets/devices/InfoDevicesIcons/IRIS.png";
import cpu from "../assets/devices/InfoDevicesIcons/cpu.jpg";
import monitor from "../assets/devices/InfoDevicesIcons/monitor.jpg";
import Redmi from "../assets/devices/InfoDevicesIcons/Redmi.png";
import LMachine from "../assets/devices/InfoDevicesIcons/LMachine.png";
import laptop from "../assets/devices/InfoDevicesIcons/Laptop.jpeg";
import gps from "../assets/devices/InfoDevicesIcons/GPS.jpeg";
import IRISInfoAK from "../assets/devices/InfoDevicesIcons/IRISAK.jpeg";
import PrinterInfoAK from "../assets/devices/InfoDevicesIcons/PrinterInfoAK.png";
import SlapInfoAK from "../assets/devices/InfoDevicesIcons/SlapInfoAK.jpeg";
import MonitorInfoAK from "../assets/devices/InfoDevicesIcons/MonitorInfoAK.png";
import SlapThalesInfoAK from "../assets/devices/InfoDevicesIcons/SlapThalesInfoAK.png";
import HPLaptopInfoAK from "../assets/devices/InfoDevicesIcons/HPLaptopInfoAK.png";
import gpsFoxinInfoAK from "../assets/devices/InfoDevicesIcons/gpsFoxinInfoAK.png";
import MonitorFoxinInfoAK from "../assets/devices/InfoDevicesIcons/MonitorFoxinInfoAK.png";
import IriShieldInfoAK from "../assets/devices/InfoDevicesIcons/IriShieldInfoAK.png";
import PrinterEpsonInfoAK from "../assets/devices/InfoDevicesIcons/PrinterEpsonInfoAK.png";
import WebcamLogitechInfoAK from "../assets/devices/InfoDevicesIcons/WebcamLogitechInfoAK.png";
import mantraInfoAk from "../assets/devices/InfoDevicesIcons/mantraInfoAk.png";
import InternetRef from "../assets/devices/InternetRef.png";

export const devices = {
  Monitor: {
    icon: monitorIcon,
    makenmodels: {
      Lenovo: {
        sninfo: "Serial Number starts with VK. Example sticker is shown below",
        sninfo_image: monitor,
      },
    },
    warranty_check_list: [],
    questions: [
      {
        name: "Lines on monitor screen display ",
        label: "Lines on monitor screen display ",
      },
      {
        name: "Patches on screen ",
        label: "Patches on screen ",
      },
      {
        name: "Flickering screen",
        label: "Flickering screen",
      },
      {
        name: "Others",
        label: "Others",
      },
    ],
  },
  CPU: {
    icon: cpuIcon,
    makenmodels: {
      Lenovo: {
        sninfo: "Serial Number starts with PG. A sample sticker is shown below",
        sninfo_image: cpu,
      },
    },

    questions: [
      {
        name: "No power ",
        label: "No power ",
      },
      {
        name: "No boot  ",
        label: "No boot  ",
      },
      {
        name: "Automatic Repair screen is shown (BSOD) ",
        label: "Automatic Repair screen is shown (BSOD) ",
      },
      {
        name: "Auto shutdown ",
        label: "Auto shutdown ",
      },
      {
        name: "Seeing Error Code: 1962 on screen  ",
        label: "Seeing Error Code: 1962 on screen  ",
      },
      {
        name: "Lenovo logo displays and gets powered off",
        label: "Lenovo logo displays and gets powered off",
      },
      {
        name: "Beep sound",
        label: "Beep sound",
      },
      {
        name: "Others",
        label: "Others",
      },
    ],
  },
  UPS: {
    icon: upsIcon,
    makenmodels: {
      BPE: {
        sninfo: "Serial Number starts with M0. A sample sticker is shown below",
        sninfo_image: BPE_UPS,
      },
      ConsulNeoWatt: {
        sninfo: "Serial Number starts with C. A sample sticker is shown below",
        sninfo_image: Consol_UPS,
      },
    },
    questions: [
      {
        name: "Charging getting drained off quickly ",
        label: "Charging getting drained off quickly ",
      },
      {
        name: "Long beep sound",
        label: "Long beep sound",
      },
      {
        name: "Error code on UPS display",
        label: "Error code on UPS display",
      },
      {
        name: "UPS fails to start",
        label: "UPS fails to start",
      },
      {
        name: "Others",
        label: "Others",
      },
    ],
  },
  Printer: {
    icon: printerIcon,
    makenmodels: {
      EPSON: {
        models: ["M200", "M2140"],
        sninfo:
          "Serial number usually starts with SL. A sample sticker is shown below",
        sninfo_image: Epson,
      },
      Canon: {
        models: ["G3000", "G3010", "G3020"],
        sninfo:
          "Serial number usually starts with SL. A sample sticker is shown below",
        sninfo_image: Epson,
      },
    },
    questions: [
      {
        name: "Hardware Problem ( No power supply/ Paper Feeding issue)",
        label: "Hardware Problem ( No power supply/ Paper Feeding issue)",
      },
      {
        name: "Paper Jam Issue",
        label: "Paper Jam Issue",
      },
      {
        name: "Printer Error or Scanner Error",
        label: "Printer Error or Scanner Error",
      },
      {
        name: "Ink Tank issues",
        label: "Ink Tank issues",
      },
      {
        name: "White paper out issue",
        label: "White paper out issue",
      },
      {
        name: "Software related issues",
        label: "Software related issues",
      },
      {
        name: "Others",
        label: "Others",
      },
    ],
  },
  FPScanner: {
    title: "Biometric Device",
    icon: fpIcon,
    makenmodels: {
      Startek: {
        sninfo:
          "A sample sticker is shown below, you may find yours on backside of the device",
        sninfo_image: FP_Starker,
      },
      Mantra: {
        sninfo:
          "A sample sticker is shown below, you may find yours on backside of the device",
        sninfo_image: FP_Mantra,
      },
      "Next Biometric": {
        sninfo:
          "A sample sticker is shown below, you may find yours on backside of the device",
        sninfo_image: FP_Next,
      },
      ARATEK: {
        sninfo:
          "A sample sticker is shown below, you may find yours on backside of the device",
        sninfo_image: FP_ARATEK,
      },
    },
    questions: [
      {
        name: "Device failing to connect to the mobile",
        label: "Device failing to connect to the mobile",
      },
      {
        name: "Sensor issue (continuous LED glow on Scanner glass)",
        label: "Sensor issue (continuous LED glow on Scanner glass)",
      },
      {
        name: "Device (or cable) physical damage",
        label: "Device (or cable) physical damage",
      },
      {
        name: "Others",
        label: "Others",
      },
    ],
  },
  LnMachine: {
    title: "Laminating Machine",
    icon: laminatingIcon,
    makenmodels: {
      HUANDA: {
        sninfo:
          "A sample sticker is shown below, you may find yours on backside of the device",
        sninfo_image: LMachine,
      },
    },
    questions: [
      {
        name: "Hardware Problem",
        label: "Hardware Problem",
      },
      {
        name: "Issue with Over Heating",
        label: "Issue with Over Heating",
      },
      {
        name: "Power supply issue",
        label: "Power supply issue",
      },
      {
        name: "Others",
        label: "Others",
      },
    ],
  },
  Mobile: {
    sninfo: "Monitor Info",
    sninfo_images: [],
    icon: mobileIcon,
    makenmodels: {
      "Redmi 8A": {
        sninfo: "Please dial *#06# for IMEI Number",
        sninfo_image: Redmi,
      },
      LG: {
        sninfo: "Please dial *#06# for IMEI Number",
        sninfo_image: Redmi,
      },
      "Nokia C20 Plus": {
        sninfo: "Please dial *#06# for IMEI Number",
        sninfo_image: Redmi,
      },
    },
    questions: [
      {
        name: "Phone gets hung frequently",
        label: "Phone gets hung frequently",
      },
      {
        name: "Apps won't run on the phone",
        label: "Apps won't run on the phone",
      },
      {
        name: "Phone doesn't start",
        label: "Phone doesn't start",
      },
      {
        name: "Others",
        label: "Others",
      },
    ],
  },
  Sim: {
    sninfo: "Monitor Info",
    sninfo_images: [],
    icon: simIcon,
    makenmodels: {
      Airtel: {},
      Jio: {},
      Vi: {},
      BSNL: {},
    },
    questions: [],
  },
  IrisScanner: {
    title: "Iris Scanner",
    sninfo: "Monitor Info",
    sninfo_images: [],
    icon: irisIcon,
    makenmodels: {
      IRITECH: {
        sninfo_image: IRIS,
      },
    },

    questions: [
      {
        name: "Cable damage",
        label: "Cable damage",
      },
      {
        name: "Physical damage",
        label: "Physical damage",
      },
      {
        name: "Others",
        label: "Others",
      },
    ],
  },
};

export const akDevices = {
  "Monitor-AK": {
    icon: monitorAKIcon,
    makenmodels: {
      AOC: {
        sninfo: "Example sticker is shown below with Serial Number",
        sninfo_image: MonitorInfoAK,
        models: ["E970S"],
      },
      FOXIN: {
        sninfo: "Example sticker is shown below with Serial Number",
        sninfo_image: MonitorFoxinInfoAK,
        models: ["15.6 Inch", "17.1 Inch"],
      },
    },
    questions: [
      {
        name: "Lines on monitor screen display ",
        label: "Lines on monitor screen display ",
      },
      {
        name: "Patches on screen ",
        label: "Patches on screen ",
      },
      {
        name: "Flickering screen",
        label: "Flickering screen",
      },
      {
        name: "Others",
        label: "Others",
      },
    ],
  },
  "Laptop-AK": {
    icon: LaptopAKIcon,
    makenmodels: {
      Acer: {
        sninfo: "Example sticker is shown below with Serial Number",
        sninfo_image: laptop,
        models: ["315-42"],
      },
      HP: {
        sninfo: "Example sticker is shown below with Serial Number",
        sninfo_image: HPLaptopInfoAK,
        models: ["245 G7 Notebook PC", "245 G8 Notebook PC"],
      },
    },
    questions: [
      {
        name: "Lines on laptop screen display ",
        label: "Lines on laptop screen display ",
      },
      {
        name: "Patches on screen ",
        label: "Patches on screen ",
      },
      {
        name: "Flickering screen",
        label: "Flickering screen",
      },
      {
        name: "Others",
        label: "Others",
      },
    ],
  },
  "Printer-AK": {
    icon: PrinterAKIcon,
    makenmodels: {
      Canon: {
        sninfo: "Example sticker is shown below with Serial Number",
        sninfo_image: PrinterInfoAK,
        models: ["G2010"],
      },
      Epson: {
        sninfo: "Example sticker is shown below with Serial Number",
        sninfo_image: PrinterEpsonInfoAK,
        models: ["L3210", "L3215", "L3216"],
      },
    },
    questions: [
      {
        name: "Hardware Problem ( No power supply/ Paper Feeding issue)",
        label: "Hardware Problem ( No power supply/ Paper Feeding issue)",
      },
      {
        name: "Paper Jam Issue",
        label: "Paper Jam Issue",
      },
      {
        name: "Printer Error or Scanner Error",
        label: "Printer Error or Scanner Error",
      },
      {
        name: "Ink Tank issues",
        label: "Ink Tank issues",
      },
      {
        name: "White paper out issue",
        label: "White paper out issue",
      },
      {
        name: "Software related issues",
        label: "Software related issues",
      },
      {
        name: "Others",
        label: "Others",
      },
    ],
  },
  "GPSDevice-AK": {
    icon: GPSAKIcon,
    makenmodels: {
      BioEnable: {
        sninfo: "Example sticker is shown below with Serial Number",
        sninfo_image: gps,
        models: ["G1"],
      },
      FOXbase: {
        sninfo: "Example sticker is shown below with Serial Number",
        sninfo_image: gpsFoxinInfoAK,
        models: ["FX100"],
      },
    },
    questions: [
      {
        name: "Not enough satellites",
        label: "Not enough satellites",
      },
      {
        name: "Multipath signals",
        label: "Multipath signals",
      },
      {
        name: "Others",
        label: "Others",
      },
    ],
  },
  "SlapScanner-AK": {
    icon: SlapScannerAKIcon,
    makenmodels: {
      GreenBit: {
        sninfo: "Example sticker is shown below with Serial Number",
        sninfo_image: SlapInfoAK,
        models: ["Dacty Scan 84 C"],
      },
      Thales: {
        sninfo: "Example sticker is shown below with Serial Number",
        sninfo_image: SlapThalesInfoAK,
        models: ["CS500F"],
      },
    },
    questions: [
      {
        name: "Device failing to connect to the computer",
        label: "Device failing to connect to the computer",
      },
      {
        name: "Sensor issue (continuous LED glow on Scanner glass)",
        label: "Sensor issue (continuous LED glow on Scanner glass)",
      },
      {
        name: "Device (or cable) physical damage",
        label: "Device (or cable) physical damage",
      },
      {
        name: "Others",
        label: "Others",
      },
    ],
  },
  "IrisScanner-AK": {
    title: "Iris Scanner",
    icon: IRISAKIcon,
    makenmodels: {
      CMITech: {
        sninfo: "Example sticker is shown below with Serial Number",
        sninfo_image: IRISInfoAK,
        models: ["BMT 20"],
      },
      Iritech: {
        sninfo: "Example sticker is shown below with Serial Number",
        sninfo_image: IriShieldInfoAK,
        models: ["BK 2121U"],
      },
      Mantra: {
        sninfo: "Example sticker is shown below with Serial Number",
        sninfo_image: mantraInfoAk,
        models: ["MATISX"],
      },
    },

    questions: [
      {
        name: "Cable damage",
        label: "Cable damage",
      },
      {
        name: "Physical damage",
        label: "Physical damage",
      },
      {
        name: "Others",
        label: "Others",
      },
    ],
  },
  "WebCamera-AK": {
    icon: WebcamAKIcon,
    makenmodels: {
      IPSPL: {
        models: ["PWC 09"],
      },
      Logitech: {
        sninfo: "Example sticker is shown below with Serial Number",
        sninfo_image: WebcamLogitechInfoAK,
        models: ["Logitech"],
      },
    },
    questions: [
      {
        name: "Shows a black screen",
        label: "Shows a black screen",
      },
      {
        name: "Device (or cable) physical damage",
        label: "Device (or cable) physical damage",
      },
      {
        name: "Others",
        label: "Others",
      },
    ],
  },
};

export const stationary = [
  {
    name: "Secure Based Certificates",
    key: "Secure-Based-Certificates",
  },
  {
    name: "Canon Printer Ink",
    key: "Cannon-Printer-Ink",
  },
  {
    name: "Epson Printer Ink",
    key: "Epson-Printer-Ink",
  },
  {
    name: "Rice Cards",
    key: "Rice-Cards",
  },
  {
    name: "Pension Cards",
    key: "Pension-Cards",
  },
  {
    name: "Laminating Pouch (Rice Card / Pension Card)",
    key: "Laminating-Pouch(Rice-Card&Pension-Card)",
  },
  {
    name: "Laminating Pouch (Aadhar Card)",
    key: "Laminating-Pouch(Aadhar-Card)",
  },
  {
    name: "Laminating Pouch (Secure Based Certificates)",
    key: "Laminating-Pouch(Secure-Based-Certificates)",
  },
  // {
  //   name: "Laminating Pouch (Arogyasri Card)",
  //   key: "Laminating-Pouch(Arogyasri-Card)",
  // },
];
