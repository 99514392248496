import React, { useState, useEffect } from "react";
import {
  List,
  Avatar,
  Col,
  Row,
  Progress,
  Statistic,
  Tooltip,
  Collapse,
  Skeleton,
  Switch,
  message,
  Button,
} from "antd";
import {
  InfoCircleOutlined,
  LoadingOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import Card from "../../../blocks/Card/Card";
import { Link } from "react-router-dom";
import DeviceByTypeGraph from "./DeviceByTypeGraph";
import DeviceByRegionGraph from "./DeviceByRegionGraph";
import GoldMedal from "../../../assets/images/gold_medal.png";
import SilverMedal from "../../../assets/images/silver_medal.png";
import BronzeMedal from "../../../assets/images/bronze_medal.png";
import AndhraMap from "./AndhraMap";
import { getStateLevelHealthStats } from "../../../services/inventory.services";
import { downloadStateLEHSecretariats } from "../../../services/downloadFile.services";
import "../Admin.css";
import "../../custom.css";
import useViewAccess from "../../../hooks/useViewAccess";

const { Panel } = Collapse;

const Dashboard = ({ stateLevelIssues, loading }) => {
  const [stateLevelHealth, setStateLevelHealth] = useState(null);
  const [graphLoading, setGraphLoading] = useState(true);
  const [aadharKitDataSwitchChecked, setAadharKitDataSwitchChecked] =
    useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  useViewAccess("is_state_executive_authority", "is_admin");
  function switchOnChange(checked) {
    setAadharKitDataSwitchChecked(checked);
  }

  useEffect(() => {
    getStateLevelHealthStats()
      .then((response) => {
        setStateLevelHealth(response.data);
        setGraphLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setGraphLoading(false);
      });
  }, []);

  const download = () => {
    setBtnLoading(true);
    downloadStateLEHSecretariats()
      .then((response) => {
        let blob = response.data;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "State-LEH-secretariats.xlsx";
        a.click();
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong, Please try after sometime.");
        setBtnLoading(false);
      });
  };

  return (
    <Row gutter={[8, 8]}>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 4 }}
        lg={{ span: 4 }}
      >
        <Card title="Overall Health" className="device-health">
          {graphLoading ? (
            <Skeleton active paragraph={{ rows: 1 }} />
          ) : (
            <div style={{ textAlign: "center" }}>
              <Progress
                type="dashboard"
                strokeColor={{
                  "0%": "#108ee9",
                  "100%": "#87d068",
                }}
                width={80}
                strokeWidth={14}
                percent={
                  stateLevelHealth?.overall_device_health?.toFixed(0) || 0
                }
              />
            </div>
          )}
        </Card>
      </Col>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 6 }}
        lg={{ span: 6 }}
      >
        <Card title="Last 7 Days Activity" className="days-activity">
          {graphLoading ? (
            <Skeleton active paragraph={{ rows: 1 }} />
          ) : (
            <Row
              gutter={16}
              type="flex"
              align="middle"
              style={{ textAlign: "center" }}
            >
              <Col span={12}>
                <Statistic
                  title="Users logged in"
                  value={
                    stateLevelHealth?.week_statistics
                      ?.users_logged_in_last_7_days
                  }
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Tickets Raised"
                  value={
                    stateLevelHealth?.week_statistics
                      ?.issues_raised_in_last_7_days
                  }
                />
              </Col>
            </Row>
          )}
        </Card>
      </Col>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 4 }}
        lg={{ span: 4 }}
      >
        <Card
          title={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                LEH Secretariats{" "}
                <Tooltip title="Secretariats Lacking Essential Hardware">
                  <InfoCircleOutlined style={{ fontSize: "12px" }} />
                </Tooltip>
              </span>
              <Button type="primary" size="small" onClick={download}>
                {btnLoading ? <LoadingOutlined /> : <DownloadOutlined />}
              </Button>
            </div>
          }
          className="critical-secretariats"
        >
          <Row
            gutter={16}
            type="flex"
            align="middle"
            style={{ textAlign: "center" }}
          >
            <Col span={24}>
              {graphLoading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <Statistic
                  value={stateLevelHealth?.critical_secretariats_count}
                />
              )}
            </Col>
          </Row>
        </Card>
      </Col>

      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 6 }}
        lg={{ span: 6 }}
      >
        <Card title="Resolution Metrics (Avg)" className="resolution-metrics">
          {loading ? (
            <Skeleton active paragraph={{ rows: 1 }} />
          ) : (
            <Row
              gutter={16}
              type="flex"
              align="middle"
              style={{ textAlign: "center" }}
            >
              <Col span={12}>
                <Statistic
                  title="Response Time"
                  value={
                    stateLevelIssues?.overall_resolution_metrics
                      ?.avg_response_time
                  }
                  suffix="hrs"
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Resolution Time"
                  value={
                    stateLevelIssues?.overall_resolution_metrics
                      ?.avg_age_of_ticket
                  }
                  suffix="days"
                />
              </Col>
            </Row>
          )}
        </Card>
      </Col>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 4 }}
        lg={{ span: 4 }}
      >
        <Card className="leaderboard-card">
          {graphLoading ? (
            <Skeleton active />
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={stateLevelHealth?.top_3_districts}
              size="small"
              split={false}
              renderItem={(item, idx) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={
                          idx === 0
                            ? GoldMedal
                            : idx === 1
                            ? SilverMedal
                            : BronzeMedal
                        }
                      />
                    }
                    title={
                      <Link
                        to={`/district/${item.district}/${item.district_id}`}
                      >
                        {item.district}
                      </Link>
                    }
                  />
                </List.Item>
              )}
            />
          )}
        </Card>
      </Col>
      <Col
        xs={{ span: 0 }}
        sm={{ span: 0 }}
        md={{ span: 18 }}
        lg={{ span: 18 }}
      >
        <Row gutter={[8, 8]}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 13 }}
            lg={{ span: 13 }}
          >
            <Card
              title="Issues Distribution Map"
              className="issues-distribution-map"
            >
              {loading ? <Skeleton active /> : <AndhraMap />}
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 11 }}
            lg={{ span: 11 }}
          >
            <Card
              title="Device Health By Type"
              className="device-health-by-type"
              extra={
                <Switch
                  unCheckedChildren="Non-Aadhar"
                  checkedChildren="Aadhar"
                  onClick={switchOnChange}
                  defaultChecked={false}
                ></Switch>
              }
            >
              {graphLoading ? (
                <Skeleton active />
              ) : (
                <DeviceByTypeGraph
                  data={
                    stateLevelHealth?.inventory_level_device_health_graph_data
                  }
                  showAadharDevices={aadharKitDataSwitchChecked}
                />
              )}
            </Card>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
          >
            <Card
              title="Device Health By Region"
              className="device-health-by-region"
            >
              {graphLoading ? (
                <Skeleton active />
              ) : (
                <DeviceByRegionGraph
                  data={stateLevelHealth?.region_level_device_health_graph_data}
                />
              )}
            </Card>
          </Col>
        </Row>
      </Col>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 6 }}
        lg={{ span: 6 }}
      >
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="LEH Secretariats" key="1">
            {graphLoading ? (
              <Skeleton active />
            ) : (
              <>
                {stateLevelHealth?.critical_secretariats?.map((item) => (
                  <li key={item?.village_secretariat_id}>
                    <Link
                      to={`/village-secretariat/${item.village_secretariat_name}/${item.village_secretariat_id}/`}
                    >
                      {item.village_secretariat_name}
                    </Link>
                  </li>
                ))}
              </>
            )}
          </Panel>
          <Panel
            header="Secretariats running out of security certificates"
            key="2"
          >
            {stateLevelHealth?.top_ten_secretariats_running_out_of_certs?.map(
              (item) => (
                <li key={item?.village_secretariat_id}>
                  <Link
                    to={`/village-secretariat/${item.village_secretariat_name}/${item.village_secretariat_id}/`}
                  >
                    {item.village_secretariat_name}
                  </Link>
                </li>
              )
            )}
          </Panel>
          <Panel header="Secretariats running out of Mobile Devices" key="3">
            {stateLevelHealth?.top_ten_secretariats_running_out_of_mobiles?.map(
              (item) => (
                <li key={item?.village_secretariat_id}>
                  <Link
                    to={`/village-secretariat/${item.village_secretariat_name}/${item.village_secretariat_id}/`}
                  >
                    {item.village_secretariat_name}
                  </Link>
                </li>
              )
            )}
          </Panel>
          <Panel header="Secretariats running out of FP Scanners" key="4">
            {stateLevelHealth?.top_ten_secretariats_running_out_of_scanners?.map(
              (item) => (
                <li key={item?.village_secretariat_id}>
                  <Link
                    to={`/village-secretariat/${item.village_secretariat_name}/${item.village_secretariat_id}/`}
                  >
                    {item.village_secretariat_name}
                  </Link>
                </li>
              )
            )}
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default Dashboard;
