import React, { Fragment } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "./Dashboard.css";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p style={{ fontSize: "14px", fontWeight: "bold" }}>
          {payload[0].payload.district}
        </p>
        <p className="label">Working : {payload[0].payload.working}</p>
        <p className="label">Not Working : {payload[0].payload.not_working}</p>
      </div>
    );
  }

  return null;
};

const DeviceByRegionGraph = ({ data }) => {
  return (
    <Fragment>
      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 5,
            bottom: 40,
          }}
        >
          <XAxis
            dataKey="district"
            textAnchor="end"
            interval={0}
            angle={335}
            tick={{ fontSize: 10 }}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="working" fill="#9DC59A" barSize={14} />
          <Bar dataKey="not_working" fill="#F99494" barSize={14} />
        </BarChart>
      </ResponsiveContainer>
    </Fragment>
  );
};
export default DeviceByRegionGraph;
