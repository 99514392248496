import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Tag,
  Typography,
  Descriptions,
  Form,
  Select,
  Input,
  Button,
  Divider,
  Badge,
  message,
  Modal,
  Result,
  Skeleton,
  Space,
} from "antd";
import Card from "../../../blocks/Card/Card";
import { getItem } from "../../../helpers/localStorage";
import { USER } from "../../../constants/defaultKeys";
import {
  getIssue,
  updateIssue,
  forwardTicketDetails,
} from "../../../services/issue.services";
import TimeStamp from "../../../blocks/TimeStamp/index";
import { useParams, useHistory } from "react-router";
import moment from "moment";
import "./Issues.css";
import IssueClosedByDA from "./IssueClosedByDA";
import useViewAccess from "../../../hooks/useViewAccess";

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const Issues = () => {
  const query = useParams();
  const [data, setData] = useState(null);
  const userDetails = JSON.parse(getItem(USER));
  const [formValues, setFormValues] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDACloseModal, setShowDACloseModal] = useState(false);
  const history = useHistory();
  useViewAccess(
    "is_vendor",
    "is_state_executive_authority",
    "is_admin",
    "is_indent_authority",
    "is_district_authority",
    "is_aek_dc",
    "is_village_secretariat",
    "is_ward_welfare_data_processing_secretary"
  );

  const showModal = () => {
    setIsModalVisible(true);
  };
  useEffect(() => {
    if (query?.id) {
      setLoading(true);
      getIssue(query.id)
        .then((response) => {
          setData(response.data);

          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [query, refresh]);

  const onFinish = (values) => {
    updateIssue(query.id, values)
      .then((response) => {
        message.success(response.data.message);
        refreshPage();
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        console.log(error);
      });
  };

  const forwardIssue = (value) => {
    forwardTicketDetails(data.id, value)
      .then((response) => {
        message.success(response.data.message);
        setIsModalVisible(false);
      })
      .catch((error) => console.log(error));
  };
  const onFormValuesChange = async (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const deviceStatus = (status) => {
    switch (status) {
      case "InProgress":
        return <Badge color="orange" status="processing" text="In Progress" />;
      case "Forwarded":
        return <Badge color="yellow" text="Forwarded" />;
      case "Closed":
        return <Badge color="green" text="Closed" />;
      case "Re-Opened":
        return <Badge color="red" text="ReOpened" />;
      case "waiting-on-user":
        return <Badge color="cyan" text="Waiting On User" />;
      default:
        return <Badge color="blue" status="default" text="Open" />;
    }
  };

  return (
    <Fragment>
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          md={{ span: 18, offset: 3 }}
          lg={{ span: 18, offset: 3 }}
        >
          {loading ? (
            <Skeleton />
          ) : data ? (
            <Card
              title={
                <Fragment>
                  <Title level={4}>{data?.ticket_id}</Title>
                  <span style={{ fontSize: "12px" }}>
                    {deviceStatus(data?.ticket_status)}
                  </span>

                  {data?.ticket_status == "Closed" &&
                    data?.ticket_close_type == "ClosedByDA" && (
                      <Tag
                        color="blue"
                        style={{
                          fontSize: "12px",
                          marginLeft: "10px",
                        }}
                      >
                        Closed by Secretariat Authority
                      </Tag>
                    )}
                </Fragment>
              }
              className="issues-card"
              extra={
                <Space>
                  {userDetails?.is_village_secretariat &&
                    userDetails?.region.region_id === data?.region_id &&
                    data?.ticket_status !== "Closed" && (
                      <Button
                        type="primary"
                        onClick={() => setShowDACloseModal(true)}
                      >
                        Close
                      </Button>
                    )}
                  <Tag
                    color="magenta"
                    style={{
                      height: "33px",
                      lineHeight: "33px",
                      fontSize: "14px",
                    }}
                  >
                    Age Of Ticket{" "}
                    <TimeStamp
                      timeStyle="round"
                      value={data && data.created_at}
                    />
                  </Tag>
                  {userDetails?.is_vendor && (
                    <>
                      <Button type="primary" onClick={showModal}>
                        Forward
                      </Button>
                      <Modal
                        title="Forward Issue"
                        visible={isModalVisible}
                        footer={false}
                        onCancel={handleCancel}
                      >
                        <Form
                          name="normal_login"
                          layout="inline"
                          onFinish={forwardIssue}
                        >
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message: "Please input your email",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Email"
                              style={{ minWidth: "250px" }}
                            />
                          </Form.Item>
                          <Form.Item>
                            <Button
                              size="large"
                              type="primary"
                              htmlType="submit"
                              block
                              className="login-form-button"
                            >
                              Submit
                            </Button>
                          </Form.Item>
                        </Form>
                      </Modal>
                    </>
                  )}
                </Space>
              }
            >
              <Descriptions>
                <Descriptions.Item label="Device Type">
                  {data?.inventory_type}
                </Descriptions.Item>
                <Descriptions.Item label="Device Id">
                  {data?.device_id ? data.device_id : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Device Make">
                  {data?.device_make}
                </Descriptions.Item>
                <Descriptions.Item label="Device Model">
                  {data?.device_model ? data.device_model : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Last Updated At">
                  {moment(data && data.updated_at).format("YYYY-MM-DD")}
                </Descriptions.Item>
                <Descriptions.Item label="Village">
                  {data?.village_secretariat_name}
                </Descriptions.Item>
                <Descriptions.Item label="Village Secretariat Code">
                  {data?.village_secretariat_code}
                </Descriptions.Item>
                <Descriptions.Item label="Village Pin Code">
                  {data?.village_secretariat_pin_code}
                </Descriptions.Item>
                <Descriptions.Item label="Secretariat Address">
                  {data?.secretariat_address
                    ? data.secretariat_address
                    : data?.village_secretariat_address}
                </Descriptions.Item>
                <Descriptions.Item label="Mandal">
                  {data?.mandal}
                </Descriptions.Item>
                {userDetails?.is_vendor && (
                  <>
                    <Descriptions.Item label=" Ticket Raised By">
                      {data?.raised_by_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Contact Person Mobile">
                      {data?.ticket_contact_person_mobile}
                    </Descriptions.Item>
                    <Descriptions.Item label="Alternate Mobile">
                      {data?.ticket_contact_person_alternate_mobile
                        ? data.ticket_contact_person_alternate_mobile
                        : "-"}
                    </Descriptions.Item>

                    <Descriptions.Item label="Contact Person Email">
                      {data?.ticket_contact_person_email
                        ? data?.ticket_contact_person_email
                        : "-"}
                    </Descriptions.Item>
                  </>
                )}
                {data?.anydesk_id && (
                  <Descriptions.Item label="AnyDesk ID">
                    {data?.anydesk_id}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="Additional Details">
                  {data?.additional_info ? data?.additional_info : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Applicable Issues">
                  <ul>
                    {data?.issues.map((issue) => (
                      <li>{issue}</li>
                    ))}
                  </ul>
                </Descriptions.Item>
                {(data?.inventory_type === "Monitor" ||
                  data?.inventory_type === "CPU") && (
                  <>
                    <Descriptions.Item label="Associated Device ID">
                      {data?.associated_device_id}
                    </Descriptions.Item>
                    <Descriptions.Item label="UPS Working">
                      {data?.is_ups_working ? "Yes" : "No"}
                    </Descriptions.Item>
                    <Descriptions.Item label="UPS Working Since">
                      {data?.ups_working_since !== "" ||
                      data?.ups_working_since !== null
                        ? data.ups_working_since
                        : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="System Connected To UPS">
                      {data?.is_system_connected_to_ups ? "Yes" : "No"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Earth Connection">
                      {data?.is_earth_connection_available ? "Yes" : "No"}
                    </Descriptions.Item>

                    <Descriptions.Item label="UPS Snapshot">
                      {data?.ups_snapshot !== "" ||
                      data?.ups_snapshot !== null ? (
                        <a
                          href={data?.ups_snapshot}
                          target="_blank"
                          rel="noopener"
                        >
                          {data.ups_snapshot}
                        </a>
                      ) : (
                        "-"
                      )}
                    </Descriptions.Item>
                  </>
                )}
                {/* {data?.image_for_reference !== "undefined" && ( */}
                <Descriptions.Item label="Reference Image">
                  {data?.image_for_reference === "undefined" ||
                  data?.image_for_reference === "" ||
                  data?.image_for_reference === null ? (
                    "-"
                  ) : (
                    <a
                      href={data?.image_for_reference}
                      target="_blank"
                      rel="noopener"
                    >
                      {data?.image_for_reference}
                    </a>
                  )}
                </Descriptions.Item>
                {/* )} */}
                <Descriptions.Item label="Remarks">
                  {data?.comments ? data.comments : "-"}
                </Descriptions.Item>

                {data?.inventory_type === "Mobile" && (
                  <>
                    <Descriptions.Item label="Job Sheet ID">
                      {data?.job_sheet_id ? data.job_sheet_id : "-"}
                    </Descriptions.Item>

                    <Descriptions.Item label="Job Sheet Reference Image">
                      {data?.job_sheet !== "undefined" ? (
                        <a
                          href={data?.job_sheet}
                          target="_blank"
                          rel="noopener"
                        >
                          {data.job_sheet}
                        </a>
                      ) : (
                        "-"
                      )}
                    </Descriptions.Item>
                  </>
                )}

                {data?.ticket_status === "Re-Opened" && (
                  <Descriptions.Item label="Reason for reopened">
                    {data?.reopen_ticket_reason
                      ? data.reopen_ticket_reason
                      : "-"}
                  </Descriptions.Item>
                )}
              </Descriptions>
              {userDetails?.is_vendor && (
                <>
                  <Divider orientation="left">Update Ticket Status</Divider>
                  <Form
                    name="issues"
                    colon={false}
                    className="issues-form"
                    layout="inline"
                    onFinish={onFinish}
                    onValuesChange={onFormValuesChange}
                  >
                    <Form.Item
                      name="ticket_status"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        placeholder="Status"
                        style={{ minWidth: "150px" }}
                        disabled={data?.ticket_status === "Closed" && true}
                      >
                        <Option value="Open" key="Open">
                          Open
                        </Option>
                        <Option value="InProgress" key="InProgress">
                          In Progress
                        </Option>
                        <Option value="Forwarded" key="Forwarded">
                          Forwarded
                        </Option>
                        <Option value="Closed" key="Closed">
                          Closed
                        </Option>
                        <Option value="waiting-on-user" key="waiting-on-user">
                          Waiting On User
                        </Option>
                      </Select>
                    </Form.Item>

                    {formValues?.ticket_status === "Closed" && (
                      <>
                        <Form.Item
                          name="resolved_status"
                          rules={[
                            {
                              required: true,
                              message: "This field is required!",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            placeholder="Resolution Status"
                            style={{ width: "200px" }}
                          >
                            <Option value="NotAnIssue" key="NotAnIssue">
                              Not An Issue
                            </Option>
                            <Option
                              value="RepairedAndFixed"
                              key="RepairedAndFixed"
                            >
                              Repaired And Fixed
                            </Option>
                            <Option value="Replaced" key="Replaced">
                              Replaced
                            </Option>
                            <Option
                              value="NotUnderWarranty"
                              key="NotUnderWarranty"
                            >
                              Not Under Warranty
                            </Option>
                          </Select>
                        </Form.Item>
                        {/* 
                        {formValues?.resolved_status === "Replaced" && (
                          <Form.Item name="device_id">
                            <Input
                              size="large"
                              placeholder="Serial Number (Optional)"
                              style={{ width: "250px" }}
                            />
                          </Form.Item>
                        )} */}
                      </>
                    )}

                    <Form.Item
                      name="comments"
                      rules={[
                        {
                          required:
                            formValues?.resolved_status === "waiting-on-user"
                              ? true
                              : false,
                          message: "This field is required!",
                        },
                      ]}
                    >
                      <TextArea
                        rows={1}
                        size="large"
                        placeholder={
                          formValues?.resolved_status === "waiting-on-user"
                            ? "Resolutioin Message"
                            : "Resolutioin Message (Optional)"
                        }
                        style={{ width: "250px" }}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button size="large" type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
            </Card>
          ) : (
            <Card>
              <Result
                status="warning"
                title="No data found with this Ticket ID"
                extra={
                  <Button type="primary" onClick={() => history.goBack()}>
                    Go Back
                  </Button>
                }
              />
            </Card>
          )}
        </Col>
      </Row>
      {showDACloseModal && (
        <IssueClosedByDA
          closeModal={showDACloseModal}
          setCloseModal={setShowDACloseModal}
          refreshPage={setRefresh}
        />
      )}
    </Fragment>
  );
};

export default Issues;
