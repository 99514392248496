export const mobileNumberRegex = [
  {
    required: true,
    message: "Please enter number without any spaces or dashes",
    pattern: new RegExp("^[0-9]{10}$"),
  },
];

export const emailValidation = [
  {
    required: true,
    pattern: new RegExp(
      "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,13})+$"
    ),
    message: "Please enter a valid email address.",
  },
];

export const passwordValidation = [
  {
    required: true,
    pattern: new RegExp("^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"),
    message: "Please enter a valid password",
  },
];

export const numberValidation = [
  {
    required: true,
    pattern: new RegExp("^[0-9]*$"),
    message: "Please enter valid digits only",
  },
];

export const minNumberValidation = [
  {
    required: true,
    pattern: new RegExp("^[0-9]{4,}$"),
    message: "Please enter minimum 4 digits/enter valid digits only",
  },
];
