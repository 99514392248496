import React, { Fragment, useEffect, useState } from "react";
import { Input, Select, Form, Button, message, Radio } from "antd";
import {
  addInternet,
  editInternet,
} from "../../../services/inventory.services";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";

const { Option } = Select;
const { TextArea } = Input;

const internetTypes = [
  {
    value: "Broadband",
    name: "Broadband",
  },
  {
    value: "Wifi-Dongle",
    name: "Wifi Dongle",
  },
  {
    value: "Mobile-4G-connection",
    name: "Mobile 4G Connection",
  },
  {
    value: "Mobile-3G-connection",
    name: "Mobile 3G Connection",
  },
  {
    value: "Others",
    name: "Others",
  },
];

const wifiServices = [
  {
    value: "Airtel",
    name: "Airtel",
  },
  {
    value: "Jio",
    name: "Jio",
  },
  {
    value: "BSNL",
    name: "BSNL",
  },
  {
    value: "Others",
    name: "Others",
  },
];

const internetServices = [
  {
    value: "AP-State-Fibernet",
    name: "AP State Fibernet",
  },
  {
    value: "ACT-Fibernet",
    name: "ACT Fibernet",
  },
  {
    value: "Local-ISP",
    name: "Local-ISP",
  },
  ...wifiServices,
];

const InternetModal = ({
  id,
  refresh,
  internetEventType,
  data,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);
  const userDetails = JSON.parse(getItem(USER));

  useEffect(() => {
    if (internetEventType === "edit") {
      form.setFieldsValue({
        internet_primarily_used: data?.internet_primarily_used,
        service_provider: data?.service_provider,
        is_poor_connection: data?.is_poor_connection,
        available_providers: data?.available_providers,
      });
      setFormValues({
        ...formValues,
        is_poor_connection: data?.is_poor_connection,
      });
    }
  }, [id, internetEventType, data]);

  const onFinish = (values) => {
    if (internetEventType === "edit") {
      editInternet(data.id, values)
        .then((response) => {
          message.success(response.data.message);
          setVisible(false);
          refresh();
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
          console.log(error);
        });
    }
    if (internetEventType === "add") {
      addInternet(id, values)
        .then((response) => {
          message.success(response.data.message);
          setVisible(false);
          refresh();
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
          console.log(error);
        });
    }
  };
  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
    if (changedValues.internet_primarily_used) {
      const formValue = changedValues.internet_primarily_used;
      form.resetFields();
      form.setFieldsValue({
        internet_primarily_used: formValue,
      });
    }
  };

  return (
    <Fragment>
      <Form
        name="Internet"
        form={form}
        onFinish={onFinish}
        colon={false}
        onValuesChange={onFormValuesChange}
        layout="vertical"
        initialValues={{
          is_poor_connection: false,
        }}
      >
        <Form.Item
          label="Select primarily used Internet"
          name="internet_primarily_used"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <Select size="large">
            {internetTypes.map((item) => (
              <Option value={item.value} key={item.value}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {(formValues?.internet_primarily_used === "Wifi-Dongle" ||
          formValues?.internet_primarily_used === "Broadband") && (
          <Form.Item label="Choose service provider" name="service_provider">
            <Select size="large">
              {(formValues?.internet_primarily_used === "Wifi-Dongle"
                ? wifiServices
                : internetServices
              ).map((item) => (
                <Option value={item.value} key={item.value}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label="Is your Internet connection poor?"
          name="is_poor_connection"
        >
          <Radio.Group>
            <Radio value={true} key={true}>
              Yes
            </Radio>
            <Radio value={false} key={false}>
              No
            </Radio>
          </Radio.Group>
        </Form.Item>

        {formValues?.is_poor_connection && (
          <>
            <Form.Item
              label="Add other available ISPs in the region"
              name="available_providers"
            >
              <Input size="large" placeholder="Optional" />
            </Form.Item>
            {/* <Form.Item label="Remarks" name="remarks">
              <TextArea size="large" placeholder="Optional" />
            </Form.Item> */}
          </>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            disabled={!userDetails?.is_village_secretariat}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default InternetModal;
