import React, { Fragment, useState } from "react";
import { Table, Input, Button, Space, Row, Col, Spin, Switch } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import Drawer from "../../Drawer/Drawer";
import VendorTableFilter from "./VendorTableFilter";
import {
  tableFilterDistricts,
  tableFilterDevices,
  akTableFilterDevices,
  defaultIssueStatus,
} from "../../../constants/defaultKeys";
import KeyboardMouseTable from "./KeyboardMouse/KeyboardMouseTable";

import moment from "moment";

const VendorTable = ({ data, data_for, loading }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [editList, setEditList] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [showKMIssues, setShowKMIssues] = useState(false);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Issue Id",
      dataIndex: "ticket_id",
      key: "ticket_id",
      width: 100,
      fixed: true,
      ...getColumnSearchProps("ticket_id"),
      render: (name, data) => (
        <Link to={`/issues/${data.ticket_id}/`}>{name}</Link>
      ),
    },
    {
      title: "Issue Status",
      dataIndex: "ticket_status",
      key: "ticket_status",
      width: 120,
      filters: [
        ...defaultIssueStatus,
        {
          text: "Reopened",
          value: "Re-Opened",
        },
        {
          text: "Waiting On User",
          value: "waiting-on-user",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.ticket_status.includes(value),
    },
    {
      title: "Resolution Status",
      dataIndex: "resolved_status",
      key: "resolved_status",
      width: 140,
      render: (data, record) => (
        <span>{record.resolved_status ? data : "-"}</span>
      ),
      filters: [
        {
          text: "Not Under Warranty",
          value: "NotUnderWarranty",
        },
        {
          text: "Replaced",
          value: "Replaced",
        },
        {
          text: "Repaired And Fixed",
          value: "RepairedAndFixed",
        },
        {
          text: "Not An Issue",
          value: "NotAnIssue",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record?.resolved_status?.includes(value),
    },
    {
      title: "Device Type",
      dataIndex: "inventory_type",
      key: "inventory_type",
      width: 100,
      filters: [...tableFilterDevices, ...akTableFilterDevices],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.inventory_type.includes(value),
    },
    {
      title: "Device Make",
      dataIndex: "device_make",
      key: "device_make",
      width: 130,
      sorter: (a, b) => {
        a = a.device_make || "";
        b = b.device_make || "";
        return a.localeCompare(b);
      },
      render: (data, record) => <span>{record.device_make ? data : "-"}</span>,
    },
    {
      title: "Device Model",
      dataIndex: "device_model",
      key: "device_model",
      width: 130,
      sorter: (a, b) => {
        a = a.device_model || "";
        b = b.device_model || "";
        return a.localeCompare(b);
      },
      render: (data, record) => <span>{record.device_model ? data : "-"}</span>,
    },
    {
      title: "Device Id",
      dataIndex: "device_id",
      key: "device_id",
      width: 130,
      sorter: (a, b) => a.device_id - b.device_id,
      ...getColumnSearchProps("device_id"),
      render: (data, record) => <span>{record.device_id ? data : "-"}</span>,
    },
    {
      title: "Secretariat",
      dataIndex: "village_secretariat_name",
      key: "village_secretariat_name",
      width: 120,
      sorter: (a, b) =>
        a.village_secretariat_name.localeCompare(b.village_secretariat_name),
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      width: 120,
      filters: [...tableFilterDistricts],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.district.includes(value),
    },
    {
      title: "Issue Raised",
      dataIndex: "created_at",
      key: "created_at",
      width: 100,
      render: (date) => moment(date).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
    {
      title: "Issue Closed",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 100,
      render: (date, record) => (
        <span>
          {record.ticket_status === "Closed"
            ? moment(date).format("YYYY-MM-DD")
            : "-"}
        </span>
      ),
      sorter: (a, b) =>
        moment(a.updated_at).unix() - moment(b.updated_at).unix(),
    },
    {
      title: "Age of days",
      dataIndex: "age_of_the_ticket",
      key: "age_of_the_ticket",
      width: 120,
      render: (date) => <span>{date} days</span>,
      sorter: (a, b) => a.age_of_the_ticket - b.age_of_the_ticket,
    },
    {
      title: "Remarks",
      dataIndex: "comments",
      key: "comments",
      width: 250,
      render: (data, record) => <span>{record.comments ? data : "-"}</span>,
    },
  ];

  const openModal = (data) => {
    setEditList(data);
    setDrawerVisible(true);
  };

  function onChange(checked) {
    setShowKMIssues(checked);
  }

  return (
    <Fragment>
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ textAlign: "right" }}>
          <Space style={{ marginTop: "30px" }}>
            {!showKMIssues && (
              <>
                <VendorTableFilter
                  data_for={data_for}
                  setFilterData={setFilterData}
                  setFilterLoading={setFilterLoading}
                />

                {data_for === "vendor" && (
                  <Button
                    type="primary"
                    onClick={() =>
                      openModal({
                        title: "Download",
                        status: "fileDownload",
                        key: "vendor",
                      })
                    }
                  >
                    Download as excel
                  </Button>
                )}
              </>
            )}
          </Space>
        </Col>
        <Col span={24}>
          <Table
            loading={
              (loading || filterLoading) && {
                indicator: (
                  <Spin
                    style={{
                      fontSize: "14px",
                      width: "350px",
                      marginLeft: "-180px",
                    }}
                    tip="Fetching results.. please wait for a min or two..."
                  />
                ),
              }
            }
            size="small"
            dataSource={filterData ? filterData.issues_data : data}
            columns={columns}
            pagination={{ pageSize: 50, showSizeChanger: false }}
            scroll={isMobileOnly ? { x: 500, y: 500 } : { y: "80vh" }}
          />
        </Col>
      </Row>
      {drawerVisible && (
        <Drawer
          setVisible={setDrawerVisible}
          setFilterData={setFilterData}
          data_for={data_for}
          editList={editList}
        />
      )}
    </Fragment>
  );
};

export default VendorTable;
