import { Button, Col, Form, message, Modal, Radio, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { USER } from "../../constants/defaultKeys";
import { getItem, setItem } from "../../helpers/localStorage";
import { secretariatLocalityDetails } from "../../services/inventory.services";
import { getUserMetaDataApi } from "../../services/metaData.services";

function LoactionSurveySemi({ id }) {
  const userDetails = JSON.parse(getItem(USER));
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(
    !userDetails?.overall_survey_completed
  );
  const [parkingArea, setParkingArea] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function getUserData() {
    getUserMetaDataApi()
      .then((res) => {
        setItem(USER, res.data?.encrypted_data);
        setItem("encrypted_data", res.data?.encrypted_data);
      })
      .catch((err) => {});
  }
  useEffect(() => {
    if (!isModalVisible) {
      getUserData();
    }
  }, [isModalVisible]);
  const onFinish = (values) => {
    secretariatLocalityDetails(id, values)
      .then((response) => {
        // userDetails["overall_survey_completed"] = true;
        // setItem(USER, JSON.stringify(userDetails));
        setIsModalVisible(false);
        message.success(response.data.message);
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.response?.data?.message);
      });
  };
  function parking() {
    setParkingArea(false);
    form.resetFields(["parking_capacity"]);
  }
  return (
    <Modal
      title="Thanks for answering the questions related to your secretariat premises earlier just a few more question"
      visible={isModalVisible}
      footer={false}
      onCancel={handleCancel}
      style={{ marginTop: "-60px", maxHeight: "600px" }}
    >
      <Form name="basic" onFinish={onFinish} layout="vertical" form={form}>
        <Form.Item
          label="Availability of terrace space in the secretariat"
          name="availability_of_terrace_space"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Availability of parking area within secretariat premises"
          name="availability_of_parking_area"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <Radio.Group
            onChange={(e) =>
              e.target.value == true ? setParkingArea(true) : parking()
            }
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          hidden={!parkingArea}
          label="Parking capacity in the secretariat"
          name="parking_capacity"
        >
          <Radio.Group>
            <Radio value="2">2 Cars</Radio>
            <Radio value="3">3 Cars</Radio>
            <Radio value="4">4 Cars</Radio>
            <Radio value=">5">5 Cars and above</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Availability of space in secretariat for cafeteria"
          name="space_cafeteria"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <Radio.Group>
            <Radio value="0">No space</Radio>
            <Radio value="4">4 Seater</Radio>
            <Radio value="4-10">4 to 10 Seater</Radio>
            <Radio value=">10">10 Seater and above</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Row style={{ textAlign: "right" }}>
            <Col>
              <Space>
                <Button type="primary" htmlType="submit" size="large">
                  Submit
                </Button>
                <Button size="large" onClick={handleCancel}>
                  Remind Later
                </Button>
              </Space>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default LoactionSurveySemi;
