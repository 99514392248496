import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  InputNumber,
  Upload,
  message,
  Divider,
  Row,
  Col,
  Checkbox,
  DatePicker,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  addSecretariatMeeting,
  updateSecretariatMeeting,
} from "../../../services/wednesdayMeeting.services";
import {
  ImageUploadProps,
  handleFileUploadError,
} from "../../../helpers/utility";
const { TextArea } = Input;

const WednesdayMeetingsForm = ({ id, setVisible, refresh, editList }) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [fileUploadError, setFileUploadError] = useState();
  const [uploadedFileName, setuploadedFileName] = useState(null);
  const [uploadFileLoading, setUploadFileLoading] = useState(null);

  useEffect(() => {
    if (editList?.data) {
      form.setFieldsValue({
        ...editList?.data,
        meeting_date: moment(editList?.data.meeting_date),
        group_photo: [
          {
            uid: 1,
            name: editList?.data?.group_photo,
            status: "done",
          },
        ],
      });
    }
  }, [editList]);

  const onFinish = (values) => {
    const formData = new FormData();
    for (const [field, value] of Object.entries(values)) {
      if (field == "group_photo") {
        formData.append("group_photo", uploadedFileName?.btn1);
      } else if (field === "other_outstanding_issues") {
        if (values?.other_outstanding_issues)
          formData.append(
            "other_outstanding_issues",
            values?.other_outstanding_issues
          );
      } else if (field === "meeting_date") {
        formData.append(
          "meeting_date",
          values?.meeting_date.format("YYYY-MM-DD")
        );
      } else {
        formData.append(field, value);
      }
    }

    setBtnLoading(true);
    if (editList?.title === "Update Meeting Notes") {
      if (formData.get("group_photo") === String(undefined)) {
        formData.delete("group_photo");
      }
      updateSecretariatMeeting(editList?.data?.id, formData)
        .then((response) => {
          message.success(response.data.message);
          setVisible(false);
          refresh();
          setBtnLoading(false);
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
          console.log(error);
          setBtnLoading(false);
        });
    } else {
      addSecretariatMeeting(id, formData)
        .then((response) => {
          message.success(response.data.message);
          setVisible(false);
          setBtnLoading(false);

          refresh();
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
          console.log(error);
          setBtnLoading(false);
        });
    }
  };

  const inputNumberField = (
    <InputNumber
      size="large"
      min={0}
      style={{
        width: 80,
      }}
    />
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  // const uploadButtonProps = {
  //   beforeUpload: (file) => {
  //     const fileTypes =
  //       file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg";
  //     if (!fileTypes) {
  //       message.error(`${file.name} is not a valid file`);
  //     }
  //     return fileTypes ? true : Upload.LIST_IGNORE;
  //   },
  //   maxCount: 1,
  // };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onFormValuesChange = async (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }

  return (
    <Form
      name="weekly meeting"
      layout="vertical"
      onFinish={onFinish}
      form={form}
      onValuesChange={onFormValuesChange}
    >
      <Form.Item
        name="meeting_date"
        label="Choose meeting date"
        rules={[
          {
            required: true,
            message: "Please choose meeting held date",
          },
        ]}
      >
        <DatePicker
          disabled={editList?.title?.includes("Update Meeting Notes")}
          disabledDate={disabledDate}
        />
      </Form.Item>
      <Row>
        <Divider orientation="left">Mobiles</Divider>
        <Col span={12}>
          <Form.Item
            label="Not Working"
            name="mobiles_not_working"
            rules={[
              {
                required: true,
                message: "Please input mobiles not working!",
              },
            ]}
          >
            {inputNumberField}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Not Working Solved"
            name="mobiles_not_working_redressed_at_secretariat_level"
            rules={[
              {
                required: true,
                message: "Please input mobiles not working solved!",
              },
            ]}
          >
            {inputNumberField}
          </Form.Item>
        </Col>

        <Divider orientation="left">SIM's</Divider>

        <Col span={12}>
          <Form.Item
            label="Not Working"
            name="sims_not_working"
            rules={[
              {
                required: true,
                message: "Please input sims not working!",
              },
            ]}
          >
            {inputNumberField}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Not Working Solved"
            name="sims_not_working_redressed_at_secretariat_level"
            rules={[
              {
                required: true,
                message: "Please input sims not working Solved!",
              },
            ]}
          >
            {inputNumberField}
          </Form.Item>
        </Col>

        <Divider orientation="left">FP Scanners</Divider>
        <Col span={12}>
          <Form.Item
            label="Not Working"
            name="fp_scanners_not_working"
            rules={[
              {
                required: true,
                message: "Please input your fp scanner not working!",
              },
            ]}
          >
            {inputNumberField}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Not Working Solved"
            name="fp_scanners_not_working_redressed_at_secretariat_level"
            rules={[
              {
                required: true,
                message: "Please input fp scanner not working Solved!",
              },
            ]}
          >
            {inputNumberField}
          </Form.Item>
        </Col>

        <Divider orientation="left">CFMS ID Issues</Divider>
        <Col span={12}>
          <Form.Item
            label="Reported Count"
            name="cfms_id_issues"
            rules={[
              {
                required: true,
                message: "Please input your CFMS ID issues count !",
              },
            ]}
          >
            {inputNumberField}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Solved Count"
            name="cfms_id_issues_redressed_at_secretariat_level"
            rules={[
              {
                required: true,
                message: "Please input CFMS ID issues solved count!",
              },
            ]}
          >
            {inputNumberField}
          </Form.Item>
        </Col>

        <Divider orientation="left">Salary Issues</Divider>
        <Col span={12}>
          <Form.Item
            label="Reported Count"
            name="salary_issues"
            rules={[
              {
                required: true,
                message: "Please input your salary issues count!",
              },
            ]}
          >
            {inputNumberField}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Solved Count"
            name="salary_issues_redressed_at_secretariat_level"
            rules={[
              {
                required: true,
                message: "Please input salary issues solved count!",
              },
            ]}
          >
            {inputNumberField}
          </Form.Item>
        </Col>

        <Divider orientation="left">Other Issues</Divider>
        <Col span={12}>
          <Form.Item
            label="Reported Count"
            name="other_issues"
            rules={[
              {
                required: true,
                message: "Please input your other issues count!",
              },
            ]}
          >
            {inputNumberField}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Solved Count"
            name="other_issues_redressed_at_secretariat_level"
            rules={[
              {
                required: true,
                message: "Please input other issues solved count!",
              },
            ]}
          >
            {inputNumberField}
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="Other Issue Types" name="other_outstanding_issues">
            <TextArea
              maxLength={500}
              showCount
              placeholder="Eg:- Volunteer ID Cards, App Issues,etc.."
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Form.Item
        label="Minutes Of Meeting"
        name="minutes_of_meeting"
        rules={[
          {
            required: true,
            message: "Please add minutes of meeting!",
          },
        ]}
      >
        <TextArea maxLength={250} showCount />
      </Form.Item>

      <Form.Item
        name="group_photo"
        label="Upload Group Photo Of The Meeting"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        // extra="Please upload images with .png or .jpg format"
        extra={
          fileUploadError?.btn1 ? (
            handleFileUploadError("btn1", fileUploadError, setFileUploadError)
          ) : (
            <span>
              Please upload images with .png,.jpg or .jpeg format and file size
              should be less than 5MB
            </span>
          )
        }
        rules={[
          {
            required: true,
            message: "Please upload group photo!",
          },
        ]}
      >
        <Upload
          {...ImageUploadProps(
            "btn1",
            fileUploadError,
            setFileUploadError,
            {
              folder: "review-meetings",
              pvt: true,
            },
            uploadedFileName,
            setuploadedFileName,
            uploadFileLoading,
            setUploadFileLoading
          )}
          customRequest={dummyRequest}
        >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>

      <Form.Item name="confirm" valuePropName="checked">
        <Checkbox>
          I confirm, added all the devices to the inventory in the portal.
        </Checkbox>
      </Form.Item>
      <Form.Item name="raised" valuePropName="checked">
        <Checkbox>
          I confirm, raised service request for all the devices with issues
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={btnLoading || uploadFileLoading?.btn1}
          disabled={
            (formValues?.confirm && formValues?.raised ? false : true) ||
            uploadFileLoading?.btn1
          }
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default WednesdayMeetingsForm;
