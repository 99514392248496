import React, { useState } from "react";
import { Table, Input, Button, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";

const InventoryTableCard = ({ data, loading }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Mandal Name",
      dataIndex: "mandal_name",
      key: "mandal_name",
      width: 100,
      fixed: "left",
      ...getColumnSearchProps("mandal_name"),
      render: (mandalName, mandal) => (
        <Link
          to={`/inventory-stats/?mandalName=${mandal.mandal_name}&mandalId=${mandal.mandal_id}`}
        >
          {mandalName}
        </Link>
      ),
    },
    {
      title: "Total Devices Added",
      dataIndex: "total_inventory_count",
      key: "total_inventory_count",
      width: 100,
      sorter: (a, b) => a.total_inventory_count - b.total_inventory_count,
    },
    {
      title: "Fingerprint Scanners",
      dataIndex: "fpscanner",
      key: "fpscanner",
      width: 80,
      sorter: (a, b) => a.fpscanner - b.fpscanner,
    },
    {
      title: "Mobiles",
      dataIndex: "mobile",
      key: "mobile",
      width: 80,
      sorter: (a, b) => a.mobile - b.mobile,
    },
  ];

  return (
    <Table
      size="small"
      dataSource={data}
      columns={columns}
      loading={loading}
      pagination={{ pageSize: 50, showSizeChanger: false }}
      scroll={isMobileOnly && { x: 500, y: 500 }}
    />
  );
};

export default InventoryTableCard;
