import React, { useState } from "react";
import {
  Form,
  Select,
  InputNumber,
  Button,
  message,
  Upload,
  Input,
  Space,
} from "antd";
import { devices, akDevices, stationary } from "../../../../constants/devices";
import {
  UploadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { createIndentRequest } from "../../../../services/serviceRequests.services";
import { uploadFile } from "../../../../services/downloadFile.services";
import {
  convertToFormData,
  handleMultipleFileExt,
  replaceDotsExceptLast,
} from "../../../../helpers/utility";
import axios from "axios";
const { Option } = Select;

const formRef = React.createRef();

function IndentRequest() {
  const query = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [selectValueChange, setSelectValueChange] = useState([]);
  const [selectedDeviceCategory, setSelectedDeviceCategory] = useState(devices);
  const [uploadedFileName, setuploadedFileName] = useState(null);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);

  const uploadProps = {
    beforeUpload: (file) => {
      const fileType = file.type === "application/pdf";
      if (handleMultipleFileExt(file)) return Upload.LIST_IGNORE;
      if (!fileType) {
        message.error(`${file.name} is not a valid file`);
      }
      return fileType ? true : Upload.LIST_IGNORE;
    },
    async onChange(info) {
      if (info.file.status === "done") {
        setUploadFileLoading(true);
        try {
          const res = await uploadFile({
            folder: "indent-requests",
            filename: replaceDotsExceptLast(info.file.name),
            uploading_to_vsws: true,
            is_private_file: false,
          });
          const formData = convertToFormData(res, info);
          const newRes = await axios({
            method: "POST",
            url: res?.data?.url?.url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          setuploadedFileName(
            "https://resources.vsws.co.in/" + "" + res?.data?.url?.fields?.key
          );
          message.success(`${info.file.name} file uploaded successfully`);
        } catch (err) {
          console.log(err);
          message.error(err?.response?.data?.message);
        } finally {
          setUploadFileLoading(false);
        }
      }
    },
  };

  const refereshPage = () => {
    formRef.current?.resetFields();
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  function handleSelectedDevices(selectedCategory) {
    if (selectedCategory === "GSWS-Inventory") {
      setSelectedDeviceCategory(devices);
    } else if (selectedCategory === "Adhar-Inventory") {
      setSelectedDeviceCategory(akDevices);
    } else {
      setSelectedDeviceCategory(stationary);
    }
  }
  function handleSubmit(values) {
    const formData = new FormData();
    for (const [field, value] of Object.entries(values)) {
      if (field == "request_form") {
        formData.append("request_form", uploadedFileName);
      } else if (field == "inventory_types") {
        formData.append(
          "inventory_types",
          JSON.stringify(values.inventory_types)
        );
      } else {
        formData.append(field, value);
      }
    }
    if (
      formValues?.inventory_types &&
      String(formValues?.inventory_types) !== ""
    ) {
      setLoading(true);
      createIndentRequest(query.id, formData)
        .then((res) => {
          message.success("Request send successfully");
          setLoading(false);
          refereshPage();
        })
        .catch((err) => {
          console.log(err);
          message.error("Request failed");
          setLoading(false);
        });
    } else {
      message.error("Please add device type to request inventory");
    }
  }

  const onFormValuesChange = (changedValues) => {
    if (changedValues?.inventory_catergory) {
      form.resetFields();
      form.setFieldsValue({
        inventory_catergory: changedValues?.inventory_catergory,
      });
      setSelectValueChange([]);
    }
    setFormValues({ ...formValues, ...changedValues });
  };

  return (
    <Form
      id="form"
      form={form}
      ref={formRef}
      wrapperCol={{
        span: 10,
      }}
      layout="vertical"
      onFinish={handleSubmit}
      onValuesChange={onFormValuesChange}
    >
      <Form.Item
        label="Choose Device Category"
        name="inventory_catergory"
        rules={[{ required: true, message: "Please choose device category" }]}
      >
        <Select
          placeholder="Please choose device category"
          size="large"
          onSelect={(seletedValue) => {
            handleSelectedDevices(seletedValue);
          }}
        >
          <Option value="GSWS-Inventory">Gsws-Inventory</Option>
          <Option value="Adhar-Inventory">Aadhar Inventory</Option>
          <Option value="Stationary">Stationary</Option>
        </Select>
      </Form.Item>

      <Form.List name="inventory_types">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, fieldKey, name, ...restField }) => (
              <Space
                key={key}
                style={{ display: "flex", marginBottom: 8 }}
                align="baseline"
              >
                <Form.Item
                  {...restField}
                  // label="Choose Device Types"
                  name={[name, "inventory_type"]}
                  fieldKey={[fieldKey, "inventory_type"]}
                  rules={[
                    {
                      required: true,
                      message: "Please choose device type",
                    },
                  ]}
                >
                  <Select
                    placeholder="Please choose device type"
                    size="large"
                    onSelect={(val) =>
                      setSelectValueChange([
                        ...new Map(
                          [...selectValueChange, { id: key, val: val }]?.map(
                            (item) => [item["id"], item]
                          )
                        ).values(),
                      ])
                    }
                    style={{ minWidth: "320px" }}
                  >
                    {formValues?.inventory_catergory === "Stationary" ? (
                      <>
                        {stationary.map((device) => (
                          <Option value={device.key} key={device.key}>
                            {device.name}
                          </Option>
                        ))}
                      </>
                    ) : (
                      <>
                        {Object.keys(selectedDeviceCategory).map((device) => (
                          <>
                            <Option value={device} key={device}>
                              {device === "FPScanner"
                                ? "Finger Print Scanner"
                                : device === "LnMachine"
                                ? "Laminating Machine"
                                : device === "A4-Papers"
                                ? "A4 Papers"
                                : device === "Epson-Printer-Ink"
                                ? "Epson Printer Ink"
                                : device === "Canon-Printer-Ink"
                                ? "Canon Printer Ink"
                                : device}
                            </Option>
                          </>
                        ))}
                      </>
                    )}
                  </Select>
                </Form.Item>
                {selectValueChange.map(
                  (item) =>
                    item.val === "Sim" &&
                    item.id === key && (
                      <Form.Item
                        {...restField}
                        // label="Choose Carrier Provider which has good network"
                        name={[name, "network"]}
                        fieldKey={[fieldKey, "network"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required!",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          style={{ width: "200px" }}
                          placeholder="Choose Carrier Provider which has good network"
                        >
                          <Option value="Airtel" key="Airtel">
                            Airtel
                          </Option>
                          <Option value="Jio" key="Jio">
                            Jio
                          </Option>
                          <Option value="Vi" key="Vi">
                            Vi (Vodafone/Idea)
                          </Option>
                          <Option value="BSNL" key="BSNL">
                            BSNL
                          </Option>
                        </Select>
                      </Form.Item>
                    )
                )}

                <Form.Item
                  {...restField}
                  // label="Count"
                  name={[name, "raised_count"]}
                  fieldKey={[fieldKey, "raised_count"]}
                  rules={[{ required: true, message: "Provide count " }]}
                >
                  <InputNumber
                    min={1}
                    placeholder="Count"
                    style={{ width: "100px" }}
                    size="large"
                  ></InputNumber>
                </Form.Item>

                <MinusCircleOutlined
                  onClick={() => remove(name)}
                  style={{ color: "red" }}
                />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Device Type
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item
        label="Upload authorized proceeding letter signed by joint collector (only PDF)"
        name="request_form"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: "Upload file " }]}
      >
        <Upload {...uploadProps} customRequest={dummyRequest}>
          <Button
            title="Upload authorized letter signed by joint collector"
            icon={<UploadOutlined />}
          >
            Upload File
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={loading || uploadFileLoading}
          disabled={uploadFileLoading}
        >
          {loading ? "Requesting..." : "Request Inventory"}
        </Button>
      </Form.Item>
    </Form>
  );
}
export default IndentRequest;
