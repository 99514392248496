import React, { useEffect, useState } from "react";
import { Row, Col, Radio } from "antd";
import Dashboard from "./Dashboard/Dashboard";
import {
  getDistrictInventory,
  getDistrictInventoryStats,
} from "../../services/inventory.services";
import { useParams, useHistory } from "react-router-dom";
import RegionBreadCrumb from "../RegionBreadCrumb/RegionBreadCrumb";
import Inventory from "./Inventory/Inventory";
import ServiceRequest from "./ServiceRequest/ServiceRequest";
import WednesdayMeeting from "./WednesdayMeeting/WednesdayMeeting";
import Tickets from "./Tickets/Tickets";
import queryString from "query-string";
import "./District.css";
import "../custom.css";
import VendorServiceContacts from "./ServiceRequest/VendorServiceContacts/VendorServiceContacts";
import { USER } from "../../constants/defaultKeys";
import { getItem } from "../../helpers/localStorage";
import InventoryManager from "../Amc/InventoryManager/InventoryManager";
import { isUserFromNTRorASR } from "../../helpers/utility";
import CollectionTabs from "../District/InventoryCollection/CollectionTabs";
const District = () => {
  const query = useParams();
  const history = useHistory();
  const location = history?.location?.search;
  let param = queryString.parse(location);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(null);
  const [inventoryStatsLoading, setInventoryStatsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(param?.tab || "dashboard");
  const [districtInventoryStats, setDistrictInventoryStats] = useState({});
  const userDetails = JSON.parse(getItem(USER));
  // const isUserFromNTRorASR =
  //   userDetails?.region?.region_id === 22 ||
  //   userDetails?.region?.region_id === 14;
  // useEffect(() => {
  //   setCurrentTab(
  //     param?.tab === "requests"
  //       ? "requests"
  //       : param?.tab === "contacts"
  //       ? "contacts"
  //       : param?.tab === "inventory"
  //       ? "inventory"
  //       : param?.tab === "tickets"
  //       ? "tickets"
  //       : param?.tab === "wednesday-meeting"
  //       ? "wednesday-meeting"
  //       : "dashboard"
  //   );
  // }, [query]);

  useEffect(() => {
    if (query) {
      getDistrictInventory(query.id)
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
      getDistrictInventoryStats(query.id)
        .then((response) => {
          setDistrictInventoryStats(response.data);
          setInventoryStatsLoading(false);
        })
        .catch((error) => {
          setInventoryStatsLoading(false);
          console.error(error);
        });
      userAccess();
    }
  }, [query]);

  function changeTab(e) {
    const value = e.target.value;
    setCurrentTab(value);
    if (value)
      history.push(`/district/${query.region}/${query.id}/?tab=${value}`);
    else history.push(`/district/${query.region}/${query.id}/`);
  }
  function userAccess() {
    if (
      userDetails?.is_state_executive_authority ||
      userDetails?.is_admin ||
      userDetails?.is_district_authority ||
      userDetails?.is_indent_authority
    ) {
      setHasPermission(true);
    } else {
      setHasPermission(false);
    }
  }
  return (
    <Row gutter={[16, 16]}>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 12, offset: 1 }}
        lg={{ span: 7, offset: 1 }}
      >
        {!userDetails?.is_aek_dc && <RegionBreadCrumb bcrdata={data && data} />}
      </Col>
      <Col
        xs={{ span: 0, offset: 0 }}
        sm={{ span: 0, offset: 0 }}
        md={{ span: 10, offset: 0 }}
        lg={{ span: 15, offset: 0 }}
        style={{ textAlign: "right" }}
      >
        {!userDetails?.is_aek_dc && (
          <Radio.Group
            value={currentTab}
            onChange={changeTab}
            buttonStyle="solid"
          >
            <Radio.Button value="dashboard">Dashboard</Radio.Button>
            <Radio.Button value="inventory">Inventory</Radio.Button>
            <Radio.Button value="tickets">Tickets</Radio.Button>
            {hasPermission && (
              <>
                <Radio.Button value="requests">Service Requests</Radio.Button>
                <Radio.Button value="wednesday-meeting">
                  Wednesday Meetings
                </Radio.Button>
              </>
            )}
            {(userDetails?.is_district_authority ||
              userDetails?.is_indent_authority) && (
              <Radio.Button value="inventory-collection">
                Inventory Collection
              </Radio.Button>
            )}
            {userDetails?.is_indent_authority && (
              <Radio.Button value="contacts">
                Vendor Service Contacts
              </Radio.Button>
            )}
            {/* {isUserFromNTRorASR() && (
              <Radio.Button value="amc">Mobile services</Radio.Button>
            )} */}
          </Radio.Group>
        )}
      </Col>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 22, offset: 1 }}
        lg={{ span: 22, offset: 1 }}
      >
        {currentTab === "requests" ? (
          <ServiceRequest id={query?.id} />
        ) : currentTab === "contacts" ? (
          <VendorServiceContacts id={query?.id} />
        ) : currentTab === "inventory" ? (
          <Inventory
            data={data}
            id={query?.id}
            loading={loading}
            districtInventoryStats={districtInventoryStats}
            inventoryStatsLoading={inventoryStatsLoading}
          />
        ) : currentTab === "wednesday-meeting" ? (
          <WednesdayMeeting id={query?.id} />
        ) : currentTab === "tickets" ? (
          <Tickets />
        ) : currentTab === "inventory-collection" ? (
          <CollectionTabs />
        ) : (
          <Dashboard
            data={data}
            loading={loading}
            inventoryStatsLoading={inventoryStatsLoading}
            districtInventoryStats={districtInventoryStats}
          />
        )}
      </Col>
    </Row>
  );
};
export default District;
