import React, { useEffect, useState } from "react";
import { Row, Col, Result, Button, message, Space } from "antd";
import InventoryTable from "./InventoryTable/InventoryTable";
import { getMandalInventory } from "../../services/inventory.services";
import { downloadMandalData } from "../../services/downloadFile.services";
import { isMobileOnly } from "react-device-detect";
import { useParams, useHistory, useLocation } from "react-router-dom";
import RegionBreadCrumb from "../RegionBreadCrumb/RegionBreadCrumb";
import MandalStatsCards from "./MandalStatsCards";
import WednesdayMeeting from "./WednesdayMeeting/WednesdayMeeting";
import queryString from "query-string";
import "../custom.css";
import "./Mandal.css";
import useViewAccess from "../../hooks/useViewAccess";

const Mandal = () => {
  const query = useParams();
  const location = useLocation();
  const history = useHistory();
  const urlQueryParams = history?.location?.search;
  let param = queryString.parse(urlQueryParams);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  useViewAccess("is_indent_authority", "is_district_authority");

  useEffect(() => {
    if (query) {
      getMandalInventory(query.id)
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [query]);

  const download = () => {
    setBtnLoading(true);
    downloadMandalData(query.id)
      .then((response) => response.data)
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "mandal-inventory.xlsx";
        a.click();
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong, Please try after sometime.");
        setBtnLoading(false);
      });
  };

  const weeklyMeeting = () => {
    history.push(`${location.pathname}?tab=wednesday-meeting`);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 15, offset: 1 }}
        lg={{ span: 15, offset: 1 }}
      >
        <RegionBreadCrumb bcrdata={data} />
      </Col>
      {Object.keys(urlQueryParams).length === 0 && (
        <Col
          xs={{ span: 0 }}
          sm={{ span: 0 }}
          md={{ span: 7 }}
          lg={{ span: 7 }}
          style={{ textAlign: "right" }}
        >
          <Space>
            <Button type="primary" onClick={weeklyMeeting}>
              Wednesday Meetings
            </Button>
            <Button type="primary" onClick={download} loading={btnLoading}>
              {btnLoading ? "Downloading..." : "Download inventory in excel"}
            </Button>
          </Space>
        </Col>
      )}
      {query && query.id !== "null" ? (
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 22, offset: 1 }}
          lg={{ span: 22, offset: 1 }}
        >
          {isMobileOnly && param?.tab === "inventory" ? (
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <InventoryTable data={data?.inventory_data} loading={loading} />
              </Col>
            </Row>
          ) : (
            <Row gutter={[16, 16]}>
              {Object.keys(param).length === 0 && (
                <>
                  <Col span={24}>
                    <MandalStatsCards data={data} loading={loading} />
                  </Col>

                  {!isMobileOnly && (
                    <Col span={24}>
                      <InventoryTable
                        data={data?.device_count_status_wise_inv_data}
                        loading={loading}
                      />
                    </Col>
                  )}
                </>
              )}
            </Row>
          )}
        </Col>
      ) : (
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 20, offset: 2 }}
          lg={{ span: 18, offset: 3 }}
        >
          <Result title="Your are not part of any mandal, please contact your admin" />
        </Col>
      )}
      {param?.tab === "wednesday-meeting" && (
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 22, offset: 1 }}
          lg={{ span: 22, offset: 1 }}
        >
          <WednesdayMeeting id={query?.id} />
        </Col>
      )}
    </Row>
  );
};
export default Mandal;
