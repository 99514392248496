import React, { useState, useEffect } from "react";
import { Select, Row, Col, Space, Radio } from "antd";
import UpdateTable from "./UpdateTable";
import { districtAndAssembly } from "../../../constants/districtAssembly";
import UnifromSummary from "./UnifromSummary";
import {
  getUniform,
  getUniformSummary,
} from "../../../services/serviceRequests.services";

const { Option } = Select;

const UniformVendor = () => {
  const districts = Object.keys(districtAndAssembly);
  const [villageData, setVillageData] = useState(null);
  const [uniformSummaryData, setUniformSummaryData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isInventorySummary, setIsInventorySummary] =
    useState("uniform_supplied");
  const [defaultAssembly, setDefaultAssembly] = useState(
    districtAndAssembly[districts[0]][0]
  );
  const [assemblyData, setAssemblyData] = useState(
    districtAndAssembly[districts[0]]
  );

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  function uniformApi(value) {
    getUniform(value)
      .then((response) => {
        setVillageData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    let defValue = districtAndAssembly[districts[0]];
    setDefaultAssembly(defValue[0]);
    setAssemblyData(defValue);
    uniformApi(defValue[0]);

    getUniformSummary()
      .then((response) => {
        setUniformSummaryData(response.data);
      })
      .catch((error) => console.log(error));
  }, [, refresh, isInventorySummary]);

  const districtChange = (value) => {
    setVillageData(null);
    setAssemblyData(districtAndAssembly[value]);
    setDefaultAssembly(districtAndAssembly[value][0]);
    uniformApi(districtAndAssembly[value][0]);
  };

  const assemblyChange = (value) => {
    setVillageData(null);
    setDefaultAssembly(value);
    uniformApi(value);
  };

  const onChange = (e) => {
    setIsInventorySummary(e.target.value);
  };

  return (
    <Row gutter={[32, 32]}>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 22, offset: 1 }}
        lg={{ span: 22, offset: 1 }}
      >
        <Row gutter={[24, 24]}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Radio.Group
              defaultValue="uniform_supplied"
              buttonStyle="solid"
              onChange={onChange}
            >
              <Radio.Button value="uniform_supplied">
                Uniform Supplied
              </Radio.Button>
              <Radio.Button value="uniform_summary">
                Uniform Summary
              </Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={24}>
            {isInventorySummary === "uniform_summary" ? (
              <UnifromSummary data={uniformSummaryData} />
            ) : (
              <Space>
                <Select
                  defaultValue={districts[0]}
                  style={{ width: 200 }}
                  onChange={districtChange}
                >
                  {districts.map((item) => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
                <Select
                  style={{ width: 200 }}
                  onChange={assemblyChange}
                  value={defaultAssembly}
                >
                  {assemblyData.map((item) => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Space>
            )}
          </Col>
        </Row>
      </Col>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 22, offset: 1 }}
        lg={{ span: 22, offset: 1 }}
      >
        {villageData && isInventorySummary === "uniform_supplied" && (
          <UpdateTable data={villageData} refresh={refreshPage} />
        )}
      </Col>
    </Row>
  );
};

export default UniformVendor;
