import React, { Fragment } from "react";
import { Alert, Form, Button, Input, Radio, message } from "antd";
import { confirmUniform } from "../../../services/inventory.services";

const { TextArea } = Input;

const UniformModal = ({ id, refresh }) => {
  const onFinish = (values) => {
    confirmUniform(id, values)
      .then((response) => {
        message.success(response.data.message);
        refresh();
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.response?.data?.message);
      });
  };

  return (
    <Fragment>
      <Alert
        type="info"
        showIcon
        message={
          <Form name="basic" onFinish={onFinish} layout="inline">
            <Form.Item
              name="uniform_supplied"
              label="I confirm that uniforms have been received"
              rules={[
                {
                  required: true,
                  message: "Please confirm",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Remarks (If any)" name="uniform_remarks">
              <TextArea rows={1} />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit">Submit</Button>
            </Form.Item>
          </Form>
        }
      />
    </Fragment>
  );
};

export default UniformModal;
