import React, { Fragment, useState } from "react";
import { Col, Row, Radio } from "antd";
import WednesdayMeetingTable from "./WednesdayMeetingTable";
import MandalMeetingTable from "./MandalMeetingTable";

const WednesdayMeeting = ({ id }) => {
  const [currentTab, setCurrentTab] = useState("mandal_meeting");

  function changeTab(e) {
    setCurrentTab(e.target.value);
  }

  return (
    <Fragment>
      <Row>
        <Col span={24} style={{ textAlign: "right" }}>
          <Radio.Group
            value={currentTab}
            onChange={changeTab}
            buttonStyle="solid"
          >
            <Radio.Button value="mandal_meeting">
              Mandal Level Meetings
            </Radio.Button>
            <Radio.Button value="secretariat_meeting">
              Secretariat Info
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col span={24}>
          {currentTab === "mandal_meeting" ? (
            <MandalMeetingTable id={id} />
          ) : (
            <WednesdayMeetingTable id={id} />
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

export default WednesdayMeeting;
