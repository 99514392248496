import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Skeleton,
  Input,
  Space,
  Pagination,
  DatePicker,
  message,
} from "antd";
import Card from "../../../blocks/Card/Card";
import { isMobileOnly } from "react-device-detect";
import {
  vendorInternetServiceRequests,
  internetServiceRequestStatistics,
  vendorInternetServiceRequestsDownload,
} from "../../../services/inventory.services";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { tableFilterDistricts } from "../../../constants/defaultKeys";
// import ActionModal from "./ActionsModal";
import moment from "moment";
const { RangePicker } = DatePicker;
const InternetVendor = () => {
  const searchInput = useRef(null);
  const [data, setData] = useState([]);
  const [cardsData, setCardsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [btnLoading, setBtnLoading] = useState(false);
  const [dates, setDates] = useState({
    startDate: moment().subtract(60, "days"),
    endDate: moment(),
  });

  useEffect(() => {
    setLoading(true);

    vendorInternetServiceRequests(
      currentPage,
      pageSize,
      dates.startDate.format("YYYY-MM-DD"),
      dates.endDate.format("YYYY-MM-DD")
    )
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    setCardLoading(true);
    internetServiceRequestStatistics()
      .then((response) => {
        setCardsData(response.data);
        setCardLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setCardLoading(false);
      });
  }, [currentPage, pageSize, dates]);

  const handleDownload = () => {
    setBtnLoading(true);
    vendorInternetServiceRequestsDownload(
      dates?.startDate.format("YYYY-MM-DD"),
      dates.endDate.format("YYYY-MM-DD")
    )
      .then((response) => {
        let blob = response.data;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "Internet-Issues-APFSL.xlsx";
        a.click();
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong, Please try after sometime.");
        setBtnLoading(false);
      });
  };

  const getColumnSearchProps = (dataIndex, placeHolderText) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);

              confirm({
                closeDropdown: true,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
          fontSize: "20px",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const columns = [
    {
      title: "Ticket Id",
      dataIndex: "ticket_id",
      key: "ticket_id",
      width: 170,
      ...getColumnSearchProps("ticket_id", "Search Ticket ID"),
      render: (name, data) => <Link to={`/internet-issues/${data.ticket_id}/`}>{name}</Link>,
    },
    {
      title: "Issue Status",
      dataIndex: "issue_status",
      key: "issue_status",
      width: 170,
      filterMode: "tree",
      filterSearch: true,
      filters: [
        {
          text: "Open",
          value: "open",
        },
        {
          text: "Self Closed",
          value: "self_closed",
        },
        {
          text: "In Progress",
          value: "in_progress",
        },
        {
          text: "Waiting On User",
          value: "waiting_on_user",
        },
        {
          text: "Reopen",
          value: "re_open",
        },
        {
          text: "Closed",
          value: "closed",
        },
      ],
      onFilter: (value, record) => record.issue_status.includes(value),
      render: (value) =>
        value === "open"
          ? "Open"
          : value === "self_closed"
          ? "Closed"
          : value === "in_progress"
          ? "In Progress"
          : value === "waiting_on_user"
          ? "Waiting On User"
          : value === "re_open"
          ? "Reopen"
          : value === "closed" && "Closed",
    },
    {
      title: "Issue Type",
      dataIndex: "issue_type",
      key: "issue_type",
      width: 170,
      filterMode: "tree",
      filterSearch: true,
      filters: [
        {
          text: "No Internet",
          value: "no_internet",
        },
        {
          text: "Slow Internet",
          value: "slow_internet",
        },
        {
          text: "Location Changed",
          value: "location_changed",
        },
        {
          text: "Disconnected Due To Non Payment Of Bill",
          value: "disconnected_due_to_bill",
        },
        {
          text: "Other",
          value: "outher",
        },
      ],
      onFilter: (value, record) => record.issue_type.includes(value),
      render: (value) =>
        value === "no_internet"
          ? "No Internet"
          : value === "slow_internet"
          ? "Slow Internet"
          : value === "location_changed"
          ? "Location Changed"
          : value === "disconnected_due_to_bill"
          ? " Disconnected Due To Non Payment Of Bill"
          : value === "outher" && "Other",
    },
    {
      title: "GPON S/N",
      dataIndex: "gpon_serial_number",
      key: "gpon_serial_number",
      width: 170,
      sorter: (a, b) => a.gpon_serial_number - b.gpon_serial_number,
      ...getColumnSearchProps("gpon_serial_number", "Search Ticket ID"),
    },
    {
      title: "Secretariat",
      dataIndex: "secretariat_name",
      key: "secretariat_name",
      width: 170,
      sorter: (a, b) => a.secretariat_name.localeCompare(b.secretariat_name),
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      width: 170,
      sorter: (a, b) => a.district.localeCompare(b.district),
    },
    {
      title: "Age of Ticket",
      dataIndex: "ticket_age",
      key: "ticket_age",
      width: 170,
      sorter: (a, b) => a.ticket_age - b.ticket_age,
    },
    {
      title: "Ticked Raised Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 170,
      sorter: (a, b) => a.created_at - b.created_at,
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Ticked Closed Date",
      dataIndex: "ticket_closed_date",
      key: "ticket_closed_date",
      width: 170,
      sorter: (a, b) => a.ticket_age - b.ticket_age,
    },
  ];

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Row gutter={[24, 24]} style={{ padding: "24px" }}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }}>
            <Card title="Open" className="ticket-status">
              {cardLoading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <p>{cardsData?.open_tickets}</p>
              )}
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }}>
            <Card title="In Progress" className="ticket-status">
              {cardLoading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <p>{cardsData?.in_progress_tickets}</p>
              )}
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
            <Card title="Waiting On User" className="ticket-status">
              {cardLoading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <p>{cardsData?.waiting_on_user_tickets}</p>
              )}
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }}>
            <Card title="Closed" className="ticket-status">
              {cardLoading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <p>{`${cardsData?.closed_tickets + cardsData?.self_close_tickets} `}</p>
              )}
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }}>
            <Card title="Reopened" className="ticket-status">
              {loading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <p>{cardsData?.re_open_tickets}</p>
              )}
            </Card>
          </Col>
        </Row>
        <Col span={24} style={{ textAlign: "right" }}>
          <RangePicker
            allowClear={false}
            value={[dates?.startDate, dates?.endDate]}
            onChange={(date) => {
              setDates({
                startDate: date[0],
                endDate: date[1],
              });
            }}
          />
          <Button
            type="primary"
            style={{ marginLeft: "10px", marginRight: "10px" }}
            onClick={handleDownload}
            loading={btnLoading}
          >
            <DownloadOutlined /> Download
          </Button>
        </Col>
        <Table
          style={{ padding: "24px" }}
          columns={columns}
          loading={tableLoading}
          dataSource={data?.results}
          size="small"
          scroll={isMobileOnly && { x: 500, y: 500 }}
          pagination={false}
        />
        <div style={{ textAlign: "right" }}>
          <Pagination
            onChange={(page) => {
              setCurrentPage(page);
            }}
            defaultPageSize={pageSize}
            style={{ paddingTop: "10px", alignItems: "right" }}
            current={currentPage}
            total={data?.count}
            showSizeChanger={false}
            showQuickJumper={false}
            onShowSizeChange={(pageSize) => {
              setPageSize(pageSize);
            }}
            showLessItems={true}
          />
        </div>
      </Col>
    </Row>
  );
};

export default InternetVendor;
