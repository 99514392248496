import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  message,
} from "antd";
import {
  getInternetConnection,
  updateInternetConnection,
  postInternetConnection,
} from "../../services/serviceRequests.services";

const InternetConnection = ({ isNetDrawerVisible, setNetDrawerVisible }) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);
  const [internetData, setInternetData] = useState(null);

  const onClose = () => {
    setNetDrawerVisible(false);
  };
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  useEffect(() => {
    getInternetConnection()
      .then((response) => {
        setInternetData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (internetData) {
      const data = { ...internetData };
      form.setFieldsValue({
        ...data,
      });
    }
  }, [internetData]);

  const onFinish = (value) => {
    if (internetData) {
      updateInternetConnection(value)
        .then((response) => {
          message.success(response?.data?.message);
          setNetDrawerVisible(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      postInternetConnection(value)
        .then((response) => {
          message.success(response?.data?.message);
          setNetDrawerVisible(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  return (
    <>
      <Drawer
        title="INTERNET STATUS"
        width={520}
        onClose={onClose}
        open={isNetDrawerVisible}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onFormValuesChange}
        >
          <Form.Item
            label="CONNECTION TYPE"
            name="connection_type"
            rules={[
              {
                required: true,
                message: "Enter Your CONNECTION TYPE",
              },
            ]}
          >
            <Select
              placeholder="Enter Your CONNECTION TYPE"
              optionFilterProp="children"
              onChange={onChange}
              options={[
                {
                  value: "wired",
                  label: "WIRED",
                },
                {
                  value: "wireless",
                  label: "WIRELESS",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="CONNECTION WORKING STATUS"
            name="status"
            rules={[
              {
                required: true,
                message: " Enter your working status",
              },
            ]}
          >
            <Select
              placeholder="CONNECTION WORKING STATUS"
              optionFilterProp="children"
              onChange={onChange}
              name="net_connection"
              options={[
                {
                  value: "working",
                  label: "WORKING",
                },
                {
                  value: "not working",
                  label: "NOT WORKING",
                },
              ]}
            />
          </Form.Item>

          {(formValues?.status === "not working" ||
            (internetData?.status === "not working" &&
              formValues?.status !== "working")) && (
            <Form.Item
              label="ENTER YOUR REMARKS"
              name="remarks"
              rules={[
                {
                  required: true,
                  message: " Enter your Remarks",
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder="Enter your Remarks" />
            </Form.Item>
          )}
          <Form.Item
            label="SERVICE PROVIDER"
            name="service_provider"
            rules={[
              {
                required: true,
                message: " Enter your service provider",
              },
            ]}
          >
            <Select
              placeholder="SERVICE PROVIDER"
              optionFilterProp="children"
              onChange={onChange}
              options={[
                {
                  value: "APSFL",
                  label: "APSFL",
                },
                {
                  value: "BBNL",
                  label: "BBNL",
                },
                {
                  value: "pvt network",
                  label: "PVT NETWORK",
                },
                {
                  value: "local broadband",
                  label: "LOCAL BROD BAND",
                },
                {
                  value: "BSNL",
                  label: "BSNL",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="NET WORK THROUGH"
            name="network_through"
            rules={[
              {
                required: true,
                message: "NET WORK THROUGH",
              },
            ]}
          >
            <Select
              placeholder="NET WORK THROUGH"
              optionFilterProp="children"
              onChange={onChange}
              name="network_through"
              options={[
                {
                  value: "lan",
                  label: "LAN",
                },
                {
                  value: "modem",
                  label: "MODEM",
                },
                {
                  value: "dongle",
                  label: "DONGLE",
                },
                {
                  value: "hotspot",
                  label: "HOT SPOT",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="DATA LIMIT"
            name="data_limit_type"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select your data limit"
              optionFilterProp="children"
              onChange={onChange}
              options={[
                {
                  value: "unlimited",
                  label: "UNLIMITED",
                },
                {
                  value: "limited",
                  label: "LIMITED",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="DATA SPEED"
            name="speed"
            rules={[{ required: true }]}
          >
            <InputNumber
              placeholder="Enter Here"
              style={{ width: "200px" }}
              addonAfter="MBPS"
            />
          </Form.Item>

          {(formValues?.data_limit_type === "limited" ||
            (internetData?.data_limit_type === "limited" &&
              formValues?.data_limit_type !== "unlimited")) && (
            <Form.Item
              label="DATA LIMIT"
              name="data_limit"
              rules={[{ required: true }]}
            >
              <InputNumber
                placeholder="Enter Here"
                style={{ width: "200px" }}
                addonAfter="GB"
              />
            </Form.Item>
          )}
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default InternetConnection;
