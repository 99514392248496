import { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  Select,
  Radio,
  message,
  Upload,
} from "antd";
import {
  getVolunteerInventoryTracking,
  addVolunteerInventoryTracking,
  getSecretariatClusters,
  searchInventory,
  getCMFSId,
} from "../../../services/inventory.services";
import {
  convertToFormData,
  debounce,
  handleMultipleFileExt,
  replaceDotsExceptLast,
} from "../../../helpers/utility";
import * as config from "../../../config/validation.config";
import { searchIMEINumbersAPI } from "../../../services/amc.services";
import { uploadFile } from "../../../services/downloadFile.services";
import axios from "axios";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;

const simcard_provider = ["Airtel", "Jio", "BSNL", "VI"];
const FPS_brand = ["Startek", "Mantra", "Aratek", "NEXT"];
const mobile_brand = ["RedMi", "LG", "Nokia"];

export default function VolunteerInventoryTracking({
  open,
  onClose,
  userDetails,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [formSubmittedData, setFormSubmittedData] = useState({ data: {} });
  const [searchData, setSearchedData] = useState({
    fps: [],
    sim: [],
    imei: [],
    cfms: [],
    cluster: [],
  });
  const [mobileKey, setMobileKey] = useState("");
  const [fpsKey, setFpsKey] = useState("");
  const onFinish = async (values) => {
    setLoading(true);
    try {
      if (values?.is_cfms_rec_not_found) {
        values.cfms_id = values.is_cfms_rec_not_found;
        values.is_cfms_rec_not_found = true;
      }
      if (values?.is_FPS_rec_not_found) {
        values.FPS_serial_no = values.is_FPS_rec_not_found;
        values.is_FPS_rec_not_found = true;
      }
      if (values?.is_mobile_rec_not_found) {
        values.mobile_imei_number = values.is_mobile_rec_not_found;
        values.is_mobile_rec_not_found = true;
      }
      if (values?.is_sim_rec_not_found) {
        values.mobile_number = values.is_sim_rec_not_found;
        values.is_sim_rec_not_found = true;
      }
      await addVolunteerInventoryTracking({
        ...values,
        FPS_acknowledgement: fpsKey,
        mobile_acknowledgement: mobileKey,
      });
      onClose();
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const onFormValuesChange = async (changedValues) => {
    if (Object.keys(changedValues)?.includes("cluster_code")) {
      getFormSubmittedData(changedValues);
    }
    setFormValues({ ...formValues, ...changedValues });
  };

  async function onIMEISearch(searchText) {
    if (searchText?.length > 0) {
      try {
        setSearchLoading(true);
        const res = await searchIMEINumbersAPI(searchText);
        const data = (res.data || []).map((eachNum) => ({
          value: eachNum.imei_number,
          label: eachNum.imei_number,
        }));
        setSearchedData({
          ...searchData,
          imei: [...data, { value: 0, label: "Not Found" }],
        });
      } catch (error) {
        console.log(error);
      } finally {
        setSearchLoading(false);
      }
    }
  }

  async function onClusterSearch() {
    try {
      setSearchLoading(true);
      const res = await getSecretariatClusters(userDetails?.region?.region_id);
      setSearchedData({
        ...searchData,
        cluster: [...(res?.data || []), { value: 0, label: "Not Found" }],
      });
    } catch (error) {
      console.log(error);
    } finally {
      setSearchLoading(false);
    }
  }

  async function onSearchInventory(searchText, type) {
    try {
      setSearchLoading(true);
      const res = await searchInventory({
        query: searchText,
        query_type: type,
      });

      const data = (res.data || []).map((eachNum) => ({
        value: eachNum.value,
        label: eachNum.value,
      }));
      setSearchedData({
        ...searchData,
        [type === "Sim" ? "sim" : "fps"]: [
          ...data,
          { value: "0000000000", label: "Not Found" },
        ],
      });
    } catch (error) {
      console.log(error);
    } finally {
      setSearchLoading(false);
    }
  }

  async function onCFMSearch(searchText) {
    try {
      setSearchLoading(true);
      const res = await getCMFSId({ query: searchText });

      const data = (res.data || []).map((eachNum) => ({
        value: eachNum.value,
        label: eachNum.value,
      }));
      setSearchedData({
        ...searchData,
        cfms: [...data, { value: 0, label: "Not Found" }],
      });
    } catch (error) {
      console.log(error);
    } finally {
      setSearchLoading(false);
    }
  }

  async function getFormSubmittedData(cluster) {
    try {
      form.resetFields();
      const res = await getVolunteerInventoryTracking(cluster);
      setFormSubmittedData(res.data);
      form.setFieldsValue(cluster);
      if (res.data?.data) {
        form.setFieldsValue(res.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    onClusterSearch();
  }, []);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = (file) => {
    if (handleMultipleFileExt(file)) return Upload.LIST_IGNORE;

    const fileType = file.type === "application/pdf";
    if (!fileType) {
      message.error(`${file.name} is not a valid file`);
    }
    return fileType ? true : Upload.LIST_IGNORE;
  };

  function uploadFileApi(info, type) {
    if (info.file.status === "done") {
      uploadFile({
        folder: "vsws-resources",
        filename: replaceDotsExceptLast(info.file.name),
        uploading_to_vsws: true,
        is_private_file: true,
      })
        .then(async (res) => {
          const formData = convertToFormData(res, info);
          const data = res.data.url;

          await axios({
            method: "POST",
            url: data.url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((res) => {
              if (type === "mobile") setMobileKey(data.fields.key);
              if (type === "fps") setFpsKey(data.fields.key);
            })
            .catch((err) => console.log(err));
        })
        .catch((error) => message.error(error.response.data.message));
      // .finally(() => setUploadFileLoading(false));
    }
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  return (
    <Drawer
      title="Volunteer Inventory Collection"
      placement="right"
      onClose={onClose}
      open={open?.status}
    >
      <Form
        name="Volunteer Inventory Collection"
        onValuesChange={onFormValuesChange}
        disabled={Object.keys(formSubmittedData?.data).length}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Form.Item
          name="cluster_code"
          label="Select Cluster code"
          rules={[{ required: true, message: "Please select cluster code!" }]}
        >
          <Select loading={searchLoading}>
            {searchData?.cluster?.map(({ cluster_code }) => (
              <Option key={cluster_code} value={cluster_code}>
                {cluster_code}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {formValues?.cluster_code && (
          <>
            {/* <Form.Item
              name="volunteer_name"
              label="Enter Volunteer Name"
              rules={[
                { required: true, message: "Please enter volunteer's name!" },
              ]}
            >
              <Input />
            </Form.Item> */}

            {/* <Form.Item
              name="cfms_id"
              label="Select CFMS ID"
              rules={config.numberValidation}
            >
              <Select
                showSearch
                filterOption={false}
                loading={searchLoading}
                options={searchData?.cfms}
                value={formValues?.cfms_id}
                onSearch={debounce(onCFMSearch, 300)}
                placeholder={"Search with an CFMS number"}
              />
            </Form.Item> */}

            {formValues?.cfms_id === 0 && (
              <Form.Item
                name="is_cfms_rec_not_found"
                label="Enter CFMS ID"
                rules={config.numberValidation}
              >
                <Input />
              </Form.Item>
            )}

            <Form.Item
              name="is_FPS_available"
              rules={[
                {
                  required: true,
                  message: "Please choose is fps collected from volunteer!",
                },
              ]}
              label="Have you collected the Finger Print Scanner from Volunteer?"
            >
              <Radio.Group>
                <Radio value={true}> Yes </Radio>
                <Radio value={false}> No </Radio>
              </Radio.Group>
            </Form.Item>

            {formValues?.is_FPS_available === true ? (
              <>
                <Form.Item
                  name="FPS_brand"
                  label="Select FPS Brand Name"
                  rules={[
                    {
                      required: true,
                      message: "Please select fps brand name!",
                    },
                  ]}
                >
                  <Select>
                    {FPS_brand?.map((item) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="FPS_serial_no"
                  label="Select FPS serial number"
                  rules={[
                    {
                      required: true,
                      message: "Please select fps serial number!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={false}
                    options={searchData?.fps}
                    loading={searchLoading}
                    value={formValues?.FPS_serial_no}
                    onSearch={debounce(
                      (searchText) =>
                        onSearchInventory(searchText, "FPScanner"),
                      300
                    )}
                    placeholder={"Search with an Serail number"}
                  />
                </Form.Item>

                {formValues?.FPS_serial_no === "0000000000" && (
                  <Form.Item
                    name="is_FPS_rec_not_found"
                    label="Enter FPS serial number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter fps serial number!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
                <Form.Item
                  label="FPS Status"
                  name="is_FPS_working"
                  rules={[
                    { required: true, message: "Please choose fps status!" },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}> Working </Radio>
                    <Radio value={false}> Not Working </Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            ) : (
              formValues?.is_FPS_available === false && (
                <Form.Item
                  label="Upload Acknowledgment from any designated Authority(.pdf only)"
                  name="FPS_acknowledgement"
                  rules={[
                    {
                      required: true,
                      message: "Please Upload acknowledgement!",
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf"
                    maxCount={1}
                    onChange={(info) => uploadFileApi(info, "fps")}
                    beforeUpload={(file) => beforeUpload(file)}
                    customRequest={dummyRequest}
                  >
                    <Button
                      //loading={uploadFileLoading}
                      //className="upload-button"
                      icon={<UploadOutlined />}
                      //style={{ backgroundColor: "#261dcf", color: "#fff" }}
                    >
                      Upload Acknowledgment Document
                    </Button>
                  </Upload>
                </Form.Item>
              )
            )}

            <Form.Item
              name="is_mobile_available"
              rules={[
                {
                  required: true,
                  message: "Please choose is mobile collected from volunteer!",
                },
              ]}
              label="Have you collected the Mobile from Volunteer?"
            >
              <Radio.Group>
                <Radio value={true}> Yes </Radio>
                <Radio value={false}> No </Radio>
              </Radio.Group>
            </Form.Item>

            {formValues?.is_mobile_available === true ? (
              <>
                <Form.Item
                  name="mobile_brand"
                  label="Select Mobile Brand Name"
                  rules={[
                    {
                      required: true,
                      message: "Please select mobile brand name!",
                    },
                  ]}
                >
                  <Select>
                    {mobile_brand?.map((item) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="mobile_imei_number"
                  label="Select Mobile IMEI Number"
                  rules={[
                    {
                      required: true,
                      message: "Please select mobile IMEI number!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={false}
                    loading={searchLoading}
                    options={searchData?.imei}
                    value={formValues?.mobile_imei_number}
                    onSearch={debounce(onIMEISearch, 300)}
                    placeholder={"Search with an IMEI number"}
                  />
                </Form.Item>
                {formValues?.mobile_imei_number === 0 && (
                  <Form.Item
                    name="is_mobile_rec_not_found"
                    label="Enter Mobile IMEI Number"
                    rules={[
                      {
                        required: true,
                        message: "Please select mobile IMEI number!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}
                <Form.Item
                  label="Mobile Status"
                  name="is_mobile_working"
                  rules={[
                    { required: true, message: "Please choose mobile status!" },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}> Working </Radio>
                    <Radio value={false}> Not Working </Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            ) : (
              formValues?.is_mobile_available === false && (
                <Form.Item
                  label="Upload Acknowledgment from any designated Authority(.pdf only)"
                  name="mobile_acknowledgement"
                  rules={[
                    {
                      required: true,
                      message: "Please Upload acknowledgement!",
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf"
                    maxCount={1}
                    onChange={(info) => uploadFileApi(info, "mobile")}
                    beforeUpload={(file) => beforeUpload(file)}
                    customRequest={dummyRequest}
                  >
                    <Button
                      //loading={uploadFileLoading}
                      //className="upload-button"
                      icon={<UploadOutlined />}
                      //style={{ backgroundColor: "#261dcf", color: "#fff" }}
                    >
                      Upload Acknowledgment Document
                    </Button>
                  </Upload>
                </Form.Item>
              )
            )}

            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please choose sim card collected from volunteer!",
                },
              ]}
              name="is_simcard_available"
              label="Have you collected the Sim card from Volunteer?"
            >
              <Radio.Group>
                <Radio value={true}> Yes </Radio>
                <Radio value={false}> No </Radio>
              </Radio.Group>
            </Form.Item>

            {formValues?.is_simcard_available && (
              <>
                <Form.Item
                  name="simcard_provider"
                  label="Select Service Provider"
                  rules={[
                    {
                      required: true,
                      message: "Please select service provider!",
                    },
                  ]}
                >
                  <Select>
                    {simcard_provider?.map((item) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="mobile_number"
                  label="Select Mobile Number"
                  rules={config.mobileNumberRegex}
                >
                  <Select
                    showSearch
                    filterOption={false}
                    options={searchData?.sim}
                    loading={searchLoading}
                    value={formValues?.mobile_number}
                    onSearch={debounce(
                      (searchText) => onSearchInventory(searchText, "Sim"),
                      300
                    )}
                    placeholder={"Search with an Serail number"}
                  />
                </Form.Item>
                {formValues?.mobile_number === "0000000000" && (
                  <Form.Item
                    name="is_sim_rec_not_found"
                    label="Enter Mobile Number"
                    rules={config.mobileNumberRegex}
                  >
                    <Input />
                  </Form.Item>
                )}
                <Form.Item
                  name="is_simcard_working"
                  label="Sim Status"
                  rules={[
                    { required: true, message: "Please choose sim status!" },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}> Working </Radio>
                    <Radio value={false}> Not Working </Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}

            {/* <Form.Item
              name="inventory_collector"
              label="Select the Authorized person collecting the Inventory"
              rules={[
                {
                  required: true,
                  message:
                    "Please select the authorized person collecting the inventory!",
                },
              ]}
            >
              <Select>
                {formSubmittedData?.utility?.inv_collectors?.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item> */}

            {/* <Form.Item
              name="inventory_collector_name"
              label="Enter Authorized  Person Name"
              rules={[
                {
                  required: true,
                  message: "Please enter authorized person name!",
                },
              ]}
            >
              <Input />
            </Form.Item> */}

            {formValues?.inventory_collector === "Others" && (
              <Form.Item
                name="inventory_collector_designation"
                label="Enter Authorized Person Designation"
                rules={[
                  {
                    required: true,
                    message: "Please enter authorized person designation!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </Drawer>
  );
}
