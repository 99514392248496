import React, { useState, useRef, useEffect } from "react";

import {
  Form,
  Input,
  Button,
  message,
  Upload,
  InputNumber,
  Select,
  Tag,
} from "antd";
import { uploadFile } from "../../../services/downloadFile.services";
import {
  convertToFormData,
  handleMultipleFileExt,
  replaceDotsExceptLast,
} from "../../../helpers/utility";
import { aadhaarIssuesList } from "../../../constants/defaultKeys";
import * as config from "../../../config/validation.config";
import {
  getKitStatusApi,
  raiseAekServiceRequest,
} from "../../../services/serviceRequests.services";
import axios from "axios";
import { UploadOutlined } from "@ant-design/icons";
import { getItem } from "../../../helpers/localStorage";
import { USER } from "../../../constants/defaultKeys";
const { TextArea } = Input;
const { Option } = Select;
const RaiseAekDcTicket = ({ tab, setVisible, refresh }) => {
  const userDetails = JSON.parse(getItem(USER));
  const formRef = useRef();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [uploadedFileName, setuploadedFileName] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [secData, setSecData] = useState(null);
  const [mandals, setMandals] = useState(null);

  const uploadProps = {
    beforeUpload: (file, fileList) => {
      if (handleMultipleFileExt(file)) return Upload.LIST_IGNORE;
      const fileType =
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      let fileSize = (file.size / 5260006).toFixed(0);
      const multitext = file.name.split(".").length > 2;
      let file_status = false;
      if (fileType && !multitext) {
        file_status = true;
        if (file.size > 5260006) {
          file_status = false;
          message.error(
            `File size should be less than 5MB, uploaded file is ${fileSize}MB, exceeding the maximum 5MB limit`
          );
        }
      } else {
        message.error(`${file.name} is not a valid file`);
      }
      return file_status ? true : Upload.LIST_IGNORE;
    },
    async onChange(info) {
      if (info.file.status === "done") {
        setUploadFileLoading(true);
        try {
          const res = await uploadFile({
            folder: "issues-images",
            filename: replaceDotsExceptLast(info.file.name),
            uploading_to_vsws: true,
            is_private_file: false,
          });
          const formData = convertToFormData(res, info);
          await axios({
            method: "POST",
            url: res?.data?.url?.url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          setuploadedFileName(
            "https://resources.vsws.co.in/" + "" + res?.data?.url?.fields?.key
          );
          message.success(`${info.file.name} file uploaded successfully`);
        } catch (err) {
          console.log(err);
          // message.error(err?.response?.data?.message);
        } finally {
          setUploadFileLoading(false);
        }
      }
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  function handleSubmit(values) {
    const formData = {
      ...values,
      file_ref: uploadedFileName,
      ...(tab === "self" && { district: userDetails?.region?.region_id }),
    };
    setLoading(true);
    raiseAekServiceRequest(formData)
      .then((response) => {
        message.success(response.data.message);
        form.resetFields();
        refresh();
        setLoading(false);
        setVisible(false);
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  }

  useEffect(() => {
    getKitStatusApi()
      .then((response) => {
        // setKitStatusData(response?.data);
        setMandals(response?.data?.vs_list);
        // setSecData(response?.data?.vs_list);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const kitStatusDc = (id) => {
    getKitStatusApi(id)
      .then((response) => {
        // setKitStatusData(response?.data);
        // setSecretariat(id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sortedIssues = aadhaarIssuesList.sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  return (
    <Form
      id="form"
      form={form}
      ref={formRef}
      layout="vertical"
      onFinish={handleSubmit}
      onValuesChange={onFormValuesChange}
      scrollToFirstError
    >
      {/* {tab !== "self" && ( */}
      <>
        <Form.Item
          label="Choose Mandal"
          name="mandal"
          rules={[
            {
              required: true,
              message: "Choose Mandal",
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Choose Mandal"
            showSearch
            optionFilterProp="label"
            onChange={(id, children) => {
              console.log(children);
              form.resetFields(["village_secretariat"]);
              // setSecData(null);
              setSecData(children?.data?.secretariats);
            }}
            filterOption={(input, option) =>
              option.label &&
              typeof option.label === "string" &&
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {mandals?.map((mandal) => (
              <Option
                value={mandal?.id}
                key={mandal?.id}
                label={`${mandal?.name}`}
                data={mandal}
              >
                <div>{mandal?.name}</div>
              </Option>
            ))}
          </Select>
        </Form.Item>

        {secData !== null && (
          <Form.Item
            label="Choose Secretariat"
            name="village_secretariat"
            rules={[
              {
                required: true,
                message: "Choose secretariat",
              },
            ]}
          >
            <Select
              allowClear
              placeholder="Choose secretariat"
              showSearch
              optionFilterProp="label"
              onChange={(id) => kitStatusDc(id)}
              filterOption={(input, option) =>
                option.label &&
                typeof option.label === "string" &&
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {secData?.map((sec) => (
                <Option
                  value={sec?.id}
                  key={sec?.id}
                  label={`${sec?.code} - ${sec?.name}`}
                >
                  <div>
                    {sec?.name}{" "}
                    <Tag style={{ marginLeft: "5px" }}>{sec?.code}</Tag>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {/* <Form.Item
          label="Choose Mandal"
          name="mandal"
          rules={[
            {
              required: true,
              message: "Please choose Mandal",
            },
          ]}
        >
          <Select
            placeholder="Select Mandal"
            defaultValue={"ALL"}
            onChange={onMandalChange}
          >
            {mandals?.map((school) => (
              <Option key={school.id} value={school?.id}>
                {school.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="village_secretariat"
          label="Choose Secretariat"
          rules={[
            {
              required: true,
              message: "Please choose Mandal",
            },
          ]}
        >
          <Select defaultValue={"ALL"}>
            {secretariats?.secretariats &&
              [defaultRegions, ...secretariats?.["secretariats"]]?.map(
                (item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                )
              )}
          </Select>
        </Form.Item> */}
      </>
      {/* )} */}

      <Form.Item
        label="Station Id"
        name="station_id"
        // rules={[{ required: true, message: "Please enter station Id" }]}
      >
        <InputNumber
          maxLength={5}
          className="input-num-field-w-100"
          placeholder="Station Id"
        />
      </Form.Item>
      <Form.Item
        label="User Id"
        name="user_id"
        // rules={[{ required: true, message: "Please enter user Id" }]}
      >
        <Input maxLength={22} placeholder="User Id" />
      </Form.Item>
      <Form.Item
        label="Machine Id"
        name="machine_id"
        // rules={[{ required: true, message: "Please enter Machine Id" }]}
      >
        <Input placeholder="Machine Id" />
      </Form.Item>
      <Form.Item
        label="Anydesk Id"
        name="anydesk_id"
        // rules={[{ required: true, message: "Please enter Anydesk Id" }]}
      >
        <Input placeholder="Anydesk Id" />
      </Form.Item>
      <Form.Item
        label="Mobile number"
        name="mobile_number"
        rules={config.mobileNumberRegex}
      >
        <Input
          autoComplete="off"
          size="large"
          maxLength="10"
          placeholder="Mobile Number"
          addonBefore={"+91"}
        />
      </Form.Item>

      <Form.Item
        label="Email address"
        name="email_id"
        // rules={config.emailValidation}
      >
        <Input placeholder="Email address" />
      </Form.Item>

      <Form.Item
        label="Client Version"
        name="client_version"
        // rules={[{ required: true, message: "Please mention client version" }]}
      >
        <Input placeholder="Application name – ECMP" />
      </Form.Item>
      <Form.Item label="Register Code">
        <Input disabled={true} value={250} />
      </Form.Item>
      <Form.Item label="Enrolment Code">
        <Input disabled={true} value={2935} />
      </Form.Item>
      <Form.Item label="Log File" name="log_file">
        <Input placeholder="Log File" />
      </Form.Item>
      <Form.Item label="RO Name">
        <Input disabled={true} value={"UIDAI-RO-HYD"} />
      </Form.Item>
      <Form.Item
        label="Issue Type"
        name="issues"
        rules={[{ required: true, message: "Please choose issues" }]}
      >
        <Select
          mode="multiple"
          placeholder="Issue type"
          value={selectedItems}
          onChange={setSelectedItems}
          style={{
            width: "100%",
          }}
          options={sortedIssues?.map(({ id, label }) => ({
            value: id,
            label: label,
          }))}
        />
      </Form.Item>
      {formValues?.issues?.includes("OTHERS") && (
        <Form.Item name="other_issue">
          <TextArea placeholder="Please specify other issues" />
        </Form.Item>
      )}
      <Form.Item
        label="Issue description"
        name="issue_description"
        rules={[{ required: true, message: "Please enter issue descripition" }]}
      >
        <Input.TextArea rows={4} placeholder="please be detailed" />
      </Form.Item>
      <Form.Item
        label="File upload (PDF , Word doc & png/jpg/jpeg only and file size should be less than 5MB)"
        name="file_ref"
        getValueFromEvent={normFile}
        // rules={[{ required: true, message: "Upload file " }]}
      >
        <Upload {...uploadProps} customRequest={dummyRequest} maxCount={1}>
          <Button icon={<UploadOutlined />}>Upload File</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={loading || uploadFileLoading}
          disabled={uploadFileLoading}
        >
          {loading ? "Submiting..." : "Submit"}
        </Button>
      </Form.Item>
    </Form>
  );
};
export default RaiseAekDcTicket;
