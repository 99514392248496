import React, { useState, useEffect } from "react";
import { Row, Col, Button, Layout, PageHeader, Badge, Skeleton } from "antd";
import { useHistory } from "react-router-dom";
import Card from "../../blocks/Card/Card";
import Logo from "../../Logo/Logo";
import MobileLogo from "../../Logo/MobileLogo";
import { isMobileOnly } from "react-device-detect";
import District from "./District/District";
import Mandal from "./Mandal/Mandal";
import { districtsWithZeroInventory } from "../../services/inventory.services";
import queryString from "query-string";
import "./InventoryStats.css";

const { Header } = Layout;

const Dashboard = () => {
  let history = useHistory();
  const location = history?.location?.search;
  let param = queryString.parse(location);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    districtsWithZeroInventory()
      .then(async (response) => {
        const res = response.data;
        const sortResponse = await res.sort((a, b) => {
          return a?.zero_added_secretariats - b?.zero_added_secretariats;
        });
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const districtCards = () => (
    <Row gutter={[16, 16]}>
      {data?.map((district) => (
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
        >
          <Badge.Ribbon
            text={
              district?.zero_added_secretariats
                ? `Zero Secretariats:${district?.zero_added_secretariats}`
                : "No Zero Secretariats"
            }
            color={district?.zero_added_secretariats ? "#FC8078" : "green"}
          >
            <Card
              className="district-cards"
              onClick={() =>
                history.push(
                  `/inventory-stats/?districtName=${district.name}&districtId=${district.id}`
                )
              }
            >
              {district.name}
            </Card>
          </Badge.Ribbon>
        </Col>
      ))}
    </Row>
  );

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Header>
          <Row>
            <Col xs={3} sm={3} md={6} lg={6} xl={6}>
              <div className="menu-logo" onClick={() => history.push("/")}>
                {isMobileOnly ? <MobileLogo /> : <Logo />}
              </div>
            </Col>
            <Col
              xs={21}
              sm={21}
              md={18}
              lg={18}
              xl={18}
              style={{ textAlign: "right", color: "white" }}
            >
              <Button type="primary" onClick={() => history.push("/")}>
                Back Home Or Login
              </Button>
            </Col>
          </Row>
        </Header>
      </Col>

      <Col span={22} offset={1}>
        {location.includes("district") ? (
          <Row>
            <Col span={24}>
              <PageHeader
                onBack={() => history.push("/inventory-stats")}
                className="site-page-header"
                title={`${param?.districtName} Inventory Added Till Now`}
              />
              <District id={param.districtId} />
            </Col>
          </Row>
        ) : location.includes("mandal") ? (
          <Row>
            <Col span={24}>
              <PageHeader
                onBack={() => history.push("/inventory-stats")}
                className="site-page-header"
                title={`${param?.mandalName} Inventory Added Till Now`}
              />
              <Mandal id={param?.mandalId} />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={24}>
              <PageHeader
                className="site-page-header"
                title="Choose districts for inventory added till now"
              />
              {loading ? <Skeleton active /> : districtCards()}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default Dashboard;
