import React, { Fragment, useEffect, useState } from "react";
import {
  Input,
  Select,
  Form,
  Button,
  message,
  Result,
  Alert,
  Upload,
  Skeleton,
  Typography,
} from "antd";
import {
  simRequest,
  simNumbers,
  getSecretariatClusters,
} from "../../../services/inventory.services";
import { useLocation, useHistory } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";
import {
  ImageUploadProps,
  handleFileUploadError,
} from "../../../helpers/utility";
const { Option } = Select;
const { Text } = Typography;
const allSims = [
  {
    value: "Airtel",
    name: "Airtel",
  },
  {
    value: "Jio",
    name: "Jio",
  },
  {
    value: "Vi",
    name: "Vi (Vodafone/Idea)",
  },
  {
    value: "BSNL",
    name: "BSNL",
  },
];

const SimActivation = ({ id }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const userDetails = JSON.parse(getItem(USER));

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  // const [clusterData, setClusterData] = useState(null);
  const [fileUploadError, setFileUploadError] = useState();
  const [uploadedFileName, setuploadedFileName] = useState(null);
  const [uploadFileLoading, setUploadFileLoading] = useState(null);

  useEffect(() => {
    setLoading(true);
    simNumbers(id)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.response?.data?.message);
        setLoading(false);
      });
  }, [id]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onFinish = (values) => {
    const formData = new FormData();
    for (const [field, value] of Object.entries(values)) {
      if (field == "id_proof") {
        formData.append("id_proof", uploadedFileName?.btn1);
      } else {
        formData.append(field, value);
      }
    }

    simRequest(id, formData)
      .then((response) => {
        message.success(response.data.message);
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.response?.data?.message);
      });
  };

  const routeBack = () => {
    history.push(`${location.pathname}`);
  };

  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  return (
    <Fragment>
      <Alert
        message="To place a SIM replacement request, make sure the SIM is already added to inventory, and the SIM should be provided by GSWS Department"
        type="info"
      />
      <br />
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {data?.length !== 0 ? (
            <Form
              form={form}
              name="Request For Sim"
              onFinish={onFinish}
              onValuesChange={onFormValuesChange}
              wrapperCol={{
                span: 10,
              }}
              colon={false}
              layout="vertical"
            >
              <Form.Item
                label="Choose mobile number"
                name="old_sim_number"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select size="large">
                  {data.map((numbers) => (
                    <Option
                      value={numbers?.sim_number}
                      key={numbers?.sim_number}
                    >
                      {numbers?.sim_number}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Carrier Provider"
                name="network"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Select size="large">
                  {allSims.map((item) => (
                    <Option value={item.value} key={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {formValues?.network === "BSNL" ? (
                <Result
                  title="BSNL SIM Replcament Process"
                  extra={
                    <Text>
                      If your BSNL Sim is damaged/lost, get an authorised letter
                      from mpdo/municipal office for SIM replacement request and
                      submit the authorised letter at district BSNL Office for
                      re-activation
                    </Text>
                  }
                />
              ) : (
                <>
                  <Form.Item
                    label="Enter new Sim number"
                    name="sim_number"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter 20 digit number"
                      minLength={20}
                      maxLength={20}
                      size="large"
                      type="password"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Re-enter new Sim number"
                    name="new_sim_number"
                    rules={[
                      {
                        required: true,
                        message: "Please input new sim number!",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("sim_number") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "The new sim number that you entered do not match!"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Re-enter 20 digit number"
                      size="large"
                      minLength={20}
                      maxLength={20}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Enter Volunteer name"
                    name="contact_person_name"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Volunteer full name"
                      size="large"
                      type="text"
                    />
                  </Form.Item>

                  {/* <Form.Item
                    label="Choose Cluster"
                    name="cluster_id"
                    rules={[
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter cluster code"
                      size="large"
                      type="text"
                    />
                  </Form.Item> */}
                  <Form.Item
                    name="id_proof"
                    label="Upload Volunteer ID proof for verification."
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ]}
                    extra={
                      fileUploadError?.btn1 ? (
                        handleFileUploadError(
                          "btn1",
                          fileUploadError,
                          setFileUploadError
                        )
                      ) : (
                        <span>
                          Please upload images with .png,.jpg or .jpeg format
                          and file size should be less than 5MB
                        </span>
                      )
                    }
                    // extra="Please upload images with .png or .jpg format"
                  >
                    <Upload
                      {...ImageUploadProps(
                        "btn1",
                        fileUploadError,
                        setFileUploadError,
                        {
                          folder: "telecom-images",
                          pvt: false,
                        },
                        uploadedFileName,
                        setuploadedFileName,
                        uploadFileLoading,
                        setUploadFileLoading
                      )}
                      customRequest={dummyRequest}
                    >
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={
                        !userDetails?.is_village_secretariat ||
                        uploadFileLoading?.btn1
                      }
                      loading={uploadFileLoading?.btn1}
                    >
                      Request for activation
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form>
          ) : (
            <Result
              title="Please add all the SIM's in your secretariat"
              extra={
                <Button type="primary" key="console" onClick={routeBack}>
                  Add Inventory
                </Button>
              }
            />
          )}
        </>
      )}
    </Fragment>
  );
};

export default SimActivation;
