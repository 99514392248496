import React, { useState, useEffect } from "react";
import { Button, Checkbox, Row, Col, Popover, Divider, Space } from "antd";
import { FilterFilled, FilterOutlined } from "@ant-design/icons";

const InventoryFilters = ({ devices, data, setInventoryFilterData, id }) => {
  const [checkedList, setCheckedList] = useState([]);
  const [visible, setVisible] = useState(false);
  const plainOptions = Object.keys(devices);

  function onChange(value) {
    setCheckedList(value);
  }

  useEffect(() => {
    if (id) {
      setCheckedList([]);
      setInventoryFilterData(null);
    }
  }, [id]);

  function reset() {
    setCheckedList([]);
    setInventoryFilterData(null);
    setVisible(false);
  }

  function onSubmit() {
    const filteredData = data.filter((devices) =>
      checkedList.includes(devices.inventory_type)
    );

    setInventoryFilterData(filteredData);
    setVisible(false);
  }

  const content = (
    <div>
      <Checkbox.Group onChange={onChange} value={checkedList}>
        <Row style={{ maxWidth: "150px" }}>
          {plainOptions.map((item) => (
            <Col span={24}>
              <Checkbox value={item}>{item}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
      <Divider />
      <Space>
        <Button type="primary" onClick={onSubmit}>
          Submit
        </Button>
        <Button onClick={reset}>Reset</Button>
      </Space>
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      placement="bottom"
      visible={visible}
    >
      <span style={{ cursor: "pointer" }} onClick={() => setVisible(true)}>
        {checkedList.length !== 0 ? (
          <FilterFilled style={{ color: "#41A8F7" }} />
        ) : (
          <FilterOutlined style={{ color: "#00000085" }} />
        )}
      </span>
    </Popover>
  );
};

export default InventoryFilters;
