import { Button, Form, Input, message, Select } from "antd";
import React, { Fragment, useState } from "react";
import {
  addFunctionaryDevice,
  updateFunctionaryDevice,
} from "../../../services/inventory.services";
const { Option } = Select;

function FunctionaryDevicesForm({ id, setVisible, refresh, editList }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    values = { ...values, village_secretariat: editList.data.sec_id };
    try {
      if (editList.status == "add_functionary_device")
        await addFunctionaryDevice(values);
      else await updateFunctionaryDevice(editList.data.record.id, values);
    } catch (err) {
      console.log(err);
      message.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
      setVisible(false);
      refresh();
    }
  };
  return (
    <Fragment>
      <Form
        onFinish={onSubmit}
        form={form}
        layout="vertical"
        initialValues={{
          ...editList.data.record,
        }}
      >
        <Form.Item
          label="Choose SIM no"
          name="sim_number"
          rules={[{ required: true, message: "Required" }]}
        >
          <Select placeholder="Choose SIM no" allowClear>
            {editList.data.mobileNums.map((eachNum) => (
              <Option value={eachNum} key={eachNum}>
                {eachNum}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Choose functionary role"
          name="functionary_role"
          rules={[{ required: true, message: "Required" }]}
        >
          <Select placeholder="Choose functionary role" allowClear>
            {Object.keys(editList.data.roles).map((eachRole) => (
              <Option value={eachRole} key={eachRole}>
                {editList.data.roles[eachRole]}
              </Option>
            ))}
          </Select>
        </Form.Item>{" "}
        <Form.Item
          label="Functionary name"
          name="functionary_name"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input placeholder="Functionary name" />
        </Form.Item>{" "}
        <Form.Item
          label="Employee id"
          name="functionary_id"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input placeholder="Employee id" />
        </Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          loading={loading}
          disabled={loading}
        >
          Submit
        </Button>
      </Form>
    </Fragment>
  );
}

export default FunctionaryDevicesForm;
