import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Statistic, Radio } from "antd";
import Card from "../../blocks/Card/Card";
import Charts from "../Charts/Charts";
import {
  getAllInventoryIssues,
  getVendorIssueStatistics,
} from "../../services/issue.services";
import VendorTable from "./VendorTable/VendorTable";
import KeyboardMouseTable from "./VendorTable/KeyboardMouse/KeyboardMouseTable";
import VendorInventoryRequests from "./VendorInventoryRequests/VendorInventoryRequests";
import { getItem } from "../../helpers/localStorage";
import { USER } from "../../constants/defaultKeys";
import "./Vendor.css";

const Vendor = () => {
  const userDetails = JSON.parse(getItem(USER));
  const [data, setData] = useState(null);
  const [issueStats, setIssueStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [onRadioChange, setOnRadioChange] = useState("inventory");

  const aekUsers =
    userDetails?.is_aek_hod ||
    userDetails?.is_aek_dc ||
    userDetails?.is_aek_hod_dept;

  useEffect(() => {
    const params = aekUsers
      ? { data_for: "vendor", filter_ak: "-AK" }
      : { data_for: "vendor", filter_ak: "" };
    getVendorIssueStatistics()
      .then((response) => setIssueStats(response.data))
      .catch((error) => console.log(error));
    getAllInventoryIssues(params)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const onChange = (e) => {
    setOnRadioChange(e.target.value);
  };

  const issueStatistic = (data) => {
    return (
      <Row gutter={16} type="flex" align="middle">
        <Col span={6}>
          <Statistic title="Open" value={data?.open_tickets} />
        </Col>
        <Col span={6}>
          <Statistic title="In Progress" value={data?.in_progress_tickets} />
        </Col>
        <Col span={6}>
          <Statistic title="Forwarded" value={data?.forwarded_tickets} />
        </Col>
        <Col span={6}>
          <Statistic title="Avg Age Of Issue" value={data?.avg_age_of_ticket} />
        </Col>
      </Row>
    );
  };

  return (
    <Fragment>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 22, offset: 1 }}
          lg={{ span: 22, offset: 1 }}
        >
          <Row gutter={[12, 12]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Card
                title="Weekly Issues"
                className="card-css weekly-issues-status"
              >
                {issueStatistic(issueStats?.week_statistics)}
              </Card>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Card
                title="Monthly Issues"
                className="card-css monthly-issues-status"
              >
                {issueStatistic(issueStats?.last_one_month_statistics)}
              </Card>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Card title="2 Months Issues" className="card-css avg-age-issues">
                {issueStatistic(issueStats?.last_two_months_statistics)}
              </Card>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Card title="Open Issues" className="card-css issues-open">
                <Charts data={issueStats?.open_tickets_graph_data} />
              </Card>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Card
                title="Resolved Issues"
                className="card-css issues-resolved"
              >
                <Charts data={issueStats?.resolved_tickets_graph_data} />
              </Card>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Radio.Group
                    defaultValue="a"
                    buttonStyle="solid"
                    style={{ float: "right" }}
                    onChange={onChange}
                    defaultValue={onRadioChange}
                  >
                    <Radio.Button value="inventory">
                      Inventory Issues
                    </Radio.Button>
                    {userDetails?.vendor_name === "BodhTree" && (
                      <Radio.Button value="kmIssues">
                        Keyboard/Mouse Issues
                      </Radio.Button>
                    )}
                    {/* <Radio.Button value="requests">
                      Inventory Requests
                    </Radio.Button> */}
                  </Radio.Group>
                </Col>
                <Col span={24}>
                  {onRadioChange === "inventory" ? (
                    <VendorTable
                      data={data?.issues_data}
                      data_for="vendor"
                      loading={loading}
                    />
                  ) : onRadioChange === "requests" ? (
                    <VendorInventoryRequests />
                  ) : (
                    <KeyboardMouseTable />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Vendor;
