import React, { useState, useEffect, Fragment } from "react";
import {
  Table,
  InputNumber,
  message,
  Popconfirm,
  Form,
  Typography,
} from "antd";
import moment from "moment";
import { tableFilterDevices } from "../../../../constants/defaultKeys";
import {
  lostDeviceColumns,
  reopenColumns,
  highVoltageColumns,
  office365Columns,
  bsnlSimColumns,
  keyboardAndMouseColumns,
  internetColumns,
} from "../../../../constants/vsServiceRequestsColumns";
import ReOpenTicketForm from "./ReOpenTicketForm";
import {
  getSecretariatInventoryRequests,
  getSecretariatSimReplaceRequests,
  getLostInventory,
  getHighVoltage,
  getSecretariatInventoryIssues,
  getOffice365Requests,
  getBsnlSimRequests,
  getSecretariatKMRequests,
  updateInventoryRequest,
} from "../../../../services/serviceRequests.services";
import { secretariatInternetServiceRequests } from "../../../../services/inventory.services";
import { useParams } from "react-router-dom";
import SimRequestsTable from "./SimRequestsTable";
import ReOpenInternetForm from "./ReOpenInternetForm";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          defaultValue={record?.received_count}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TrackRequests = ({ action }) => {
  const [form] = Form.useForm();

  const query = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [recordData, SetRecordData] = useState(null);
  const [editingKey, setEditingKey] = useState("");
  const [editedValue, setEditedValue] = useState(null);
  const isEditing = (record) => record.id === editingKey;

  useEffect(() => {
    apiData();
  }, [, action, refresh]);

  const edit = (record) => {
    form.setFieldsValue({
      received_count: "",
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    const row = await form.validateFields();
    const newData = [...data.data];
    const index = newData.findIndex((item) => key === item.id);

    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });
      const editData = newData[index];
      updateInventoryRequest(editData?.id, {
        acknowledged: true,
        received_count: editData.received_count,
      })
        .then((res) => {
          message.success("submitted");
          refreshPage();
        })
        .catch((err) => {
          console.log(err);
          message.error("Someting went wrong please try later");
        });
      setEditingKey("");
    } else {
      newData.push(row);
      setEditingKey("");
    }
  };

  function apiData() {
    switch (action) {
      case "sim":
        return getSecretariatSimReplaceRequests(query?.id)
          .then((response) => {
            setData({ data: response.data });
            setLoading(false);
          })
          .catch((error) => {
            setData({ data: [] });
            setLoading(false);
            console.log(error);
          });
      case "lost_device":
        return getLostInventory(query?.id)
          .then((response) => {
            setData({
              data: response.data.inventory_data,
              columns: lostDeviceColumns,
            });
            setLoading(false);
          })
          .catch((error) => {
            setData({ data: [], columns: lostDeviceColumns });
            setLoading(false);
            console.log(error);
          });
      case "reopen_ticket":
        return getSecretariatInventoryIssues(query?.id)
          .then((response) => {
            setData({ data: response.data, columns: reOpenIssueTicketColumns });
            setLoading(false);
          })
          .catch((error) => {
            setData({ data: [], columns: reOpenIssueTicketColumns });
            setLoading(false);
            console.log(error);
          });
      case "report_damaged_km":
        return getSecretariatKMRequests(query?.id)
          .then((response) => {
            setData({ data: response.data, columns: keyboardAndMouseColumns });
            setLoading(false);
          })
          .catch((error) => {
            setData({ data: [], columns: keyboardAndMouseColumns });
            setLoading(false);
            console.log(error);
          });
      case "report_high_voltage":
        return getHighVoltage({ secretariat_id: query?.id })
          .then((response) => {
            setData({ data: response.data, columns: highVoltageColumns });
            setLoading(false);
          })
          .catch((error) => {
            setData({ data: [], columns: highVoltageColumns });
            setLoading(false);
            console.log(error);
          });
      case "office_365":
        return getOffice365Requests(query?.id)
          .then((response) => {
            setData({ data: response.data, columns: office365Columns });
            setLoading(false);
          })
          .catch((error) => {
            setData({ data: [], columns: office365Columns });
            setLoading(false);
            console.log(error);
          });
      case "request_empty_bsnl":
        return getBsnlSimRequests(query?.id)
          .then((response) => {
            setData({ data: response.data, columns: bsnlSimColumns });
            setLoading(false);
          })
          .catch((error) => {
            setData({ data: [], columns: bsnlSimColumns });
            setLoading(false);
            console.log(error);
          });
      case "request_inventory":
        return secretariatInternetServiceRequests(query?.id)
          .then((response) => {
            setData({
              data: response?.data?.results,
              columns: reOpenInternetTicketColumns,
            });
            setLoading(false);
          })
          .catch((error) => {
            setData({ data: [], columns: reOpenInternetTicketColumns });
            setLoading(false);
            console.log(error);
          });
      default:
        return getSecretariatInventoryRequests(query?.id)
          .then((response) => {
            setData({ data: response.data, columns: requestInventoryColumns });
            setLoading(false);
          })
          .catch((error) => {
            setData({ data: [], columns: requestInventoryColumns });
            setLoading(false);
            console.log(error);
          });
    }
  }

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const reOpenIssueTicketColumns = [
    ...reopenColumns,
    {
      title: "Action",
      key: "action",
      width: 150,
      fixed: "right",
      render: (_, record) => (
        <>
          {record.ticket_status === "Closed" ? (
            <ReOpenTicketForm data={record} refresh={refreshPage} />
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];
  const reOpenInternetTicketColumns = [
    ...internetColumns,
    {
      title: "Action",
      key: "action",
      width: 150,
      fixed: "right",
      render: (_, record) => (
        <>
          {record?.issue_status === "closed" ? (
            <ReOpenInternetForm
              data={record}
              id={query?.id}
              refresh={refreshPage}
            />
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];

  const requestInventoryColumns = [
    {
      title: "Device Type",
      dataIndex: "inventory_type",
      key: "inventory_type",
      fixed: "left",
      filters: [...tableFilterDevices],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.inventory_type.includes(value),
      render: (inventory_type, record) => (
        <span>
          {record?.inventory_type === "Sim"
            ? inventory_type + ":" + " " + record.network
            : inventory_type}
        </span>
      ),
    },
    {
      title: "Requested Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
    {
      title: "Requested Count",
      dataIndex: "count",
      key: "count",
      width: "10%",
      render: (count, record) => <span>{record?.count ? count : "-"}</span>,
    },
    {
      title: "Resolved By",
      dataIndex: "resolved_by_name",
      width: "10%",
      key: "resolved_by_name",
      render: (name, record) => (
        <span>{record?.resolved_by_name ? name : "-"}</span>
      ),
    },
    {
      title: "Resolution Status",
      dataIndex: "resolution_status",
      key: "resolution_status",
      render: (status, record) => (
        <span>{record?.resolution_status ? status : "-"}</span>
      ),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: 200,
      render: (data, record) => <span>{record?.remarks ? data : "-"}</span>,
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (date) => moment(date).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.updated_at).unix() - moment(b.updated_at).unix(),
    },
    {
      title: "Received count",
      dataIndex: "received_count",
      key: "received_count",
      editable: true,
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <span
              className="footer-nav-links"
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              save
            </span>
            <Popconfirm title="sure to cancel" onConfirm={cancel}>
              <a>cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            // disabled={editingKey !== "" || record?.resolution_status !== "Approved"}
            onClick={() =>
              record?.resolution_status !== "Approved"
                ? message.error(
                    "Cannot updated received count as your request is not yet approved."
                  )
                : edit(record)
            }
          >
            Edit-Received-Count
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = requestInventoryColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "received_count" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Fragment>
      {action !== "sim" ? (
        <Form form={form} component={false}>
          <Table
            components={
              action === "inventory" && {
                body: {
                  cell: EditableCell,
                },
              }
            }
            scroll={{ x: 800 }}
            loading={loading}
            dataSource={data?.data}
            columns={action === "inventory" ? mergedColumns : data.columns}
            pagination={{ pageSize: 25, showSizeChanger: false }}
            // onRow={(record) => {
            //   return {
            //     onClick: (a) => {
            //       SetRecordData(record);
            //     },
            //   };
            // }}
          ></Table>
        </Form>
      ) : (
        <SimRequestsTable data={data?.data} refresh={refreshPage} />
      )}
    </Fragment>
  );
};
export default TrackRequests;
