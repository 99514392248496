import React from "react";
import { Row, Alert } from "antd";
import { StopFilled, ExperimentOutlined } from "@ant-design/icons";
const Fingerprint = () => {
  return (
    <Row>
      <Alert
        message={
          <span>
            DO NOT USE Sanitizer <ExperimentOutlined />
          </span>
        }
        description="Please do not apply hand sanitizer on FingerPrint Scanners.
             The PCB on the device would get short
            circuited because of hand sanitizer"
        type="error"
        icon={<StopFilled />}
        showIcon
      />
    </Row>
  );
};

export default Fingerprint;
