import React, { Fragment, useState, useEffect, useRef } from "react";
import { Form, Input, Button, message, Divider, Modal, Result } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import Card from "../../blocks/Card/Card";
import Logo from "../../assets/images/Editedlogo.png";
import {
  getEmailCaptchaAPI,
  refreshEmailCaptchaAPI,
  refreshLoginCaptcha,
  verifyEmailAPI,
  verifyEmailLogin,
  verifyPassword,
} from "../../services/auth.services";
import { emailValidation } from "../../config/validation.config";
import { setItem, clear } from "../../helpers/localStorage";
import { USER, ACCESS_TOKEN, REFRESH_TOKEN } from "../../constants/defaultKeys";
import { SyncOutlined } from "@ant-design/icons";
import { JSEncrypt } from "jsencrypt";
import { publicKey } from "../../constants/defaultKeys";
import "./Login.css";
import { isUserFromNTRorASR } from "../../helpers/utility";

// const { Text } = Typography;
let emailVerified = 0;
const EmailLogin = ({ setShowEmailLogin }) => {
  const [emailDetails, setEmailDetails] = useState(null);
  const [captchaVal, setCaptchaVal] = useState(null);
  const [addEmailCaptcha, setAddEmailCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef();
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    if (addEmailCaptcha || emailDetails) {
      var captchaImg = document.getElementById("source");
      console.log("captchaImg", captchaImg);
      var image = new Image();
      image.src = captchaImg.innerHTML || "";
      image.setAttribute("id", `captcha0`);
      insertAfter(captchaImg, image);
      setAddEmailCaptcha(false);
    }
  }, [captchaVal, addEmailCaptcha]);

  useEffect(() => {
    getEmailCaptchaAPI()
      .then((res) => {
        console.log(res);
        setCaptchaVal(res.data.captcha);
        setCaptchaToken(res.data.token);
        setAddEmailCaptcha(true);
      })
      .catch((err) => console.log(err));
  }, []);

  function insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  const onFinish = async (values) => {
    const validate =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi.test(
        values?.username
      );
    if (emailVerified == 0) {
      try {
        setButtonLoading(true);
        const res = await verifyEmailAPI(
          validate
            ? {
                email: values?.username,
                token: captchaToken,
                captcha_code: values?.captcha,
              }
            : {
                user_id: values?.username,
                token: captchaToken,
                captcha_code: values?.captcha,
              }
        );
        emailVerified++;
        setEmailDetails({ ...res?.data, emailId: values?.username });
        setCaptchaVal(res?.data?.captcha_code);
      } catch (err) {
        message.error(
          err?.response?.data?.message ||
            "Something went wrong, please try again later"
        );
        refreshEmailCaptcha();
        console.log(err);
      } finally {
        setButtonLoading(false);
        form.resetFields(["captcha"]);
      }
    } else if (emailVerified == 1) {
      try {
        setButtonLoading(true);
        const res = await verifyPassword({
          token: emailDetails?.token,
          password: encrypt.encrypt(values.password),
          captcha: values?.captcha,
          is_logout: showModal ? showModal : undefined,
        });
        setShowModal(false);
        setButtonLoading(false);
        emailVerified = 0;
        clear();
        const data = res.data.payload;
        setItem(ACCESS_TOKEN, data.access_token);
        setItem(REFRESH_TOKEN, data.refresh_token);
        setItem(USER, res.data.encrypted_data);
        setItem("encrypted_data", res.data.encrypted_data);

        if (data.is_set_profile_completed) {
          if (data.is_service_center_representative) {
            history.push("/state/dashboard");
            return;
          }
          if (data.is_amc_district_incharge) {
            history.push(`/state/dashboard`);
            return;
          }
          if (
            data.region === null &&
            !(
              data.is_vendor ||
              data?.is_aek_hod_dept ||
              data?.is_aek_hod ||
              data?.is_tabs_admin ||
              data?.is_uidai
            )
          ) {
            history.push(`/state/dashboard`);
          } else {
            // if (data.is_amc_district_incharge)
            //   history.push(`/district-amc-tickets`);

            if (data.is_village_secretariat) {
              history.push(
                `/village-secretariat/${data.region.region_name}/${data.region.region_id}/`
              );
            }

            if (data.is_mandal_authority)
              history.push(
                `/mandal/${data.region.region_name}/${data.region.region_id}/`
              );
            if (data.is_district_authority || data.is_indent_authority)
              history.push(
                `/district/${data.region.region_name}/${data.region.region_id}/`
              );
            if (
              data?.is_welfare_and_edu_assistant
              // || data?.is_ward_welfare_data_processing_secretary
            )
              history.push(`/raise-tab-ticket/${data.region.region_id}`);
            if (data.is_admin) history.push(`/state/dashboard`);
            if (data.is_vendor) history.push(`/vendor`);
            if (
              data?.is_aek_hod_dept ||
              data?.is_aek_dc ||
              data?.is_aek_hod ||
              data?.is_uidai
            )
              history.push(`/aek-vendor`);
            if (data?.is_tabs_admin) history.push(`/tabs/aggregate`);
            if (data.is_telecom_operator) history.push(`/sim-requests`);
            if (data.is_uniform_supplier) history.push(`/uniform-vendor`);
            if (data.is_office365_vendor) history.push(`/office-365`);
            if (data.is_internet_vendor) history.push(`/internet-vendor`);
          }
        } else {
          history.push("/set-profile");
        }
      } catch (error) {
        if (
          error?.response?.status == 401 &&
          error?.response?.data?.code === "user_already_loggedin"
        ) {
          setShowModal(true);
          return;
        } else {
          document.getElementById(`captcha0`)?.remove();
          setCaptchaVal(error?.response?.data?.captcha_code);
          if (error.response?.status == 403) {
            setEmailDetails(null);
            emailVerified = 0;
          }
          setButtonLoading(false);
          error.response?.data?.message &&
            message.info(error.response?.data?.message);

          form.resetFields(["username", "password", "captcha"]);
        }
      }
    }
  };

  async function getCaptcha() {
    try {
      const res = await refreshLoginCaptcha({
        token: emailDetails?.token,
      });
      document.getElementById(`captcha0`).remove();
      setCaptchaVal(res.data.captcha);
    } catch (err) {
      console.log(err);
    }
  }

  async function refreshEmailCaptcha() {
    try {
      const res = await refreshEmailCaptchaAPI({
        token: captchaToken,
      });
      document.getElementById(`captcha0`).remove();
      setCaptchaVal(res.data.captcha);
      setAddEmailCaptcha(true);
    } catch (err) {
      message.error(
        err?.response?.data?.message ||
          "Something went wrong, please try again later"
      );
      console.log(err);
    }
  }

  return (
    <Fragment>
      <Card bordered={false}>
        <div style={{ textAlign: "center", paddingBottom: "20px" }}>
          <img src={Logo} alt="check logo" height="64px" />
        </div>
        <Form
          name="login"
          onFinish={onFinish}
          form={form}
          Autocomplete="off"
          ref={formRef}
        >
          {/* <Text>Email Address or User Id followed by designation</Text> */}
          {emailDetails ? (
            <>
              <span>
                You are logging in as <i> {emailDetails?.emailId}</i>{" "}
              </span>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password",
                  },
                ]}
              >
                <Input.Password
                  autocomplete="new-password"
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <div>
                <span id="source" style={{ display: "none" }}>
                  {"data:image/png;base64," + captchaVal}
                </span>
                <SyncOutlined
                  style={{ fontSize: "22px", marginLeft: "12px" }}
                  onClick={getCaptcha}
                />
              </div>
              <Form.Item
                rules={[{ required: true, message: "Required" }]}
                name="captcha"
              >
                <Input
                  placeholder="Submit captcha"
                  style={{ padding: "10px", marginTop: "5px" }}
                />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your email address",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input
                  autoFocus={true}
                  size="large"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  // placeholder="s***@gmail.com or 1233210-da"
                  placeholder="Email or User ID"
                />
              </Form.Item>
              <div>
                <span id="source" style={{ display: "none" }}>
                  {"data:image/png;base64," + captchaVal}
                </span>
                <SyncOutlined
                  style={{ fontSize: "22px", marginLeft: "12px" }}
                  onClick={refreshEmailCaptcha}
                />
              </div>
              <Form.Item
                rules={[{ required: true, message: "Required" }]}
                name="captcha"
              >
                <Input
                  placeholder="Submit captcha"
                  style={{ padding: "10px", marginTop: "5px" }}
                />
              </Form.Item>
            </>
          )}

          <div style={{ textAlign: "right", paddingBottom: "10px" }}>
            <Link to="/forgot-password" className="footer-links">
              Forgot password?
            </Link>
          </div>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              block
              className="login-form-button"
              loading={buttonLoading}
            >
              Sign In
            </Button>
          </Form.Item>
        </Form>
        <Divider orientation="center">Or</Divider>
        <div
          style={{ textAlign: "center" }}
          className="footer-nav-links"
          onClick={() => setShowEmailLogin(false)}
        >
          Mobile Number Login
        </div>
      </Card>
      {showModal && (
        <Modal open={showModal} footer={false} closable={false}>
          <Result
            status="warning"
            title={
              <p>
                {
                  "Oops! It seems you're already logged in from another device. Please log out from the other device before attempting to log in again."
                }
              </p>
            }
            extra={
              <>
                <Button
                  type="default"
                  onClick={() => {
                    setShowModal(false);
                    setButtonLoading(false);
                    // setShowEmailLogin(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    formRef?.current?.submit();
                  }}
                >
                  Continue
                </Button>
              </>
            }
          />
        </Modal>
      )}
    </Fragment>
  );
};

export default EmailLogin;
