import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { USER } from "../../constants/defaultKeys";
import { clear, getItem } from "../../helpers/localStorage";
import { ExpireRefreshToken } from "../../services/auth.services";
import { isAuthenticated } from "../../helpers/utility";

const Logout = () => {
  const history = useHistory();
  useEffect(() => {
    isAuthenticated()
      ? ExpireRefreshToken({ refresh: JSON.parse(getItem(USER)).refresh_token })
          .then(() => {
            clear();
            history.push("/");
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            clear();
            history.push("/");
          })
      : history.push("/");
  }, []);

  return <Fragment></Fragment>;
};

export default Logout;
