import { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "antd";

import { commonCols } from "../commonCols";
import TicketDrawer from "./TicketDrawer";
import {
  getServiceCenterAndServicesAPI,
  getTicketListAPI,
} from "../../../services/amc.services";
import TicketInfo from "../TicketInfo";
import { isUserFromNTRorASR } from "../../../helpers/utility";

function DAWedps() {
  const [open, setOpen] = useState(false);
  const [serviceCenters, setServiceCenters] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [activeTicketInfo, setActiveTicketInfo] = useState(null);
  const showDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  useEffect(() => {
    if (!isUserFromNTRorASR()) {
      window?.history?.go(-1);
    }
  }, []);

  useEffect(() => {
    setTableLoading(true);
    getTicketListAPI({ bypass: false })
      .then((res) => setTableData(res.data))
      .catch((err) => console.log(err))
      .finally(() => setTableLoading(false));

    if (!serviceCenters.length) {
      getServiceCenterAndServicesAPI()
        .then((res) => setServiceCenters(res.data || []))
        .catch((err) => console.log(err));
    }
  }, [open]);

  const columns = [...commonCols];

  function openDrawer() {
    setOpen(true);
  }

  return (
    <Row gutter={[12, 12]}>
      <Col span={22} offset={1} style={{ textAlign: "end" }}>
        <Button
          style={{ textAlign: "right", marginBottom: "10px" }}
          type="primary"
          onClick={openDrawer}
        >
          Raise Ticket
        </Button>
      </Col>
      <Col span={22} offset={1}>
        <Table
          dataSource={tableData}
          columns={columns}
          pagination={false}
          loading={tableLoading}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                setActiveTicketInfo(record);
              },
              className: "clickable-row",
            };
          }}
        />
      </Col>
      {open && (
        <TicketDrawer
          open={open}
          closeDrawer={closeDrawer}
          serviceCenters={serviceCenters}
          setServiceCenters={setServiceCenters}
        />
      )}

      {activeTicketInfo && (
        <TicketInfo ticket={activeTicketInfo} onClose={setActiveTicketInfo} />
      )}
    </Row>
  );
}

export default DAWedps;
