import React, { useEffect, useState } from "react";
import { Table, Space, Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { tableFilterDevices } from "../../../constants/defaultKeys";
import { getDistrictLostInventory } from "../../../services/serviceRequests.services";
import moment from "moment";

const filterDevices = [
  ...tableFilterDevices,
  {
    text: "Sim",
    value: "Sim",
  },
];

const LostDeviceReportsTable = ({ id }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDistrictLostInventory(id)
      .then((response) => {
        setData(response.data.inventory_data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Device Type",
      dataIndex: "inventory_type",
      key: "inventory_type",
      fixed: "left",
      filters: [...filterDevices],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.inventory_type.includes(value),
    },
    {
      title: "Device ID",
      dataIndex: "device_id",
      key: "device_id",
      render: (id, record) => <span>{record?.device_id ? id : "-"}</span>,
    },
    {
      title: "Device Make",
      dataIndex: "make",
      key: "make",
      render: (make, record) => <span>{record?.make ? make : "-"}</span>,
    },
    {
      title: "Device Model",
      dataIndex: "model",
      key: "model",
      render: (model, record) => <span>{record?.model ? model : "-"}</span>,
    },
    {
      title: "Device Source",
      dataIndex: "device_source",
      key: "device_source",
      render: (device_source, record) => (
        <span>
          {record?.device_source === "provided_by_gsws_dept"
            ? "Provided by GSWS department"
            : "-"}
        </span>
      ),
    },
    {
      title: "Secretariat Name",
      dataIndex: "village_secretariat_name",
      key: "village_secretariat_name",
      ...getColumnSearchProps("village_secretariat_name"),
      render: (data) => <span>{data}</span>,
    },
    {
      title: "Mandal",
      dataIndex: "mandal",
      key: "mandal",
      ...getColumnSearchProps("mandal"),
      render: (data) => <span>{data}</span>,
    },
    {
      title: "Reported By",
      dataIndex: "raised_by",
      key: "raised_by",
      render: (user, record) => <span>{record?.raised_by ? user : "-"}</span>,
    },
    {
      title: "Reported Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
  ];

  return (
    <Table
      loading={loading}
      dataSource={data}
      columns={columns}
      scroll={{ x: 1200 }}
      pagination={{ pageSize: 25, showSizeChanger: false }}
    />
  );
};

export default LostDeviceReportsTable;
