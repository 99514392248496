import { Col, Form, Modal, Radio, Row, message } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { IssueClosedByDAApi } from "../../../services/issue.services";

function IssueClosedByDA({ closeModal, setCloseModal, refreshPage }) {
  const params = useParams();
  const [form] = Form.useForm();
  function closeIssueByDA(closed_by_da) {
    IssueClosedByDAApi(params.id, { closed_by_da })
      .then((res) => {
        setCloseModal(false);
        message.success(res?.data?.message);
        form.resetFields();
        refreshPage(true);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
      });
  }
  function handleRadioClick(e) {
    if (e.target.value === "no") {
      setCloseModal(false);
    } else {
      closeIssueByDA("YES");
    }
  }
  return (
    <Row>
      <Col>
        <Modal
          title=""
          visible={closeModal}
          footer={false}
          onCancel={() => setCloseModal(false)}
        >
          <Form layout="vertical">
            <Form.Item
              name="ticket_close_type"
              label="Are you sure you want to close this ticket ?"
              rules={[{ required: true, message: "Required" }]}
            >
              <Radio.Group onChange={(e) => handleRadioClick(e)}>
                <Radio value={"yes"}>Yes</Radio>
                <Radio value={"no"}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      </Col>
    </Row>
  );
}

export default IssueClosedByDA;
