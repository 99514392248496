import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row, Skeleton, Typography } from "antd";
import { getImgAPI } from "../../services/metaData.services";

function ViewImg() {
  const location = useLocation();
  const id = location.pathname?.slice(11);
  const [imageUrl, setImageUrl] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function fetchImage() {
      if (!id) return;
      setLoading(true);
      setError(false);
      try {
        const response = await getImgAPI({ file_path: id });
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const contentType = response.headers["content-type"];

        if (contentType.includes("image")) {
          const reader = new FileReader();
          reader.onloadend = function () {
            setImageUrl(reader.result);
          };
          reader.readAsDataURL(blob); // Converts blob to Base64
        } else if (contentType.includes("application/pdf")) {
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
        } else {
          setError(true);
        }
      } catch (err) {
        console.error("Error fetching image:", err);
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    fetchImage();
  }, [id]);

  return (
    <Row justify="center" align="middle">
      <Col
        span={24}
        style={{
          textAlign: "center",
        }}
      >
        {loading ? (
          <Skeleton.Image />
        ) : error ? (
          <Typography.Text>Content not found</Typography.Text>
        ) : imageUrl ? (
          <img
            src={imageUrl}
            alt="img"
            style={{
              minWidth: "50%",
              maxWidth: "50%",
              objectFit: "contain",
              maxHeight: "50%",
            }}
          />
        ) : pdfUrl ? (
          <Typography.Link
            href={pdfUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            View PDF
          </Typography.Link>
        ) : null}
      </Col>
    </Row>
  );
}

export default ViewImg;
