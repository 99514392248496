import { USER } from "../constants/defaultKeys";
import CryptoJS from "crypto-js";

export const setItem = (key, value, isObject = false) => {
  const data = isObject ? JSON.stringify(value) : value;
  localStorage.setItem(key, data);
};

export const getItem = (key, isObject = false) => {
  let value = localStorage.getItem(key);
  if (key === USER && value) {
    let token = localStorage.getItem("encrypted_data");
    if (token) {
      let data = decryptToken(token);
      value = JSON.parse(data);
      value = JSON.stringify(value.payload);
    }
  }
  const data = isObject ? JSON.parse(value) : value;
  return data;
};

export const clear = () => {
  localStorage.clear();
};

export function decryptToken(encryptedText) {
  var encryptedData = encryptedText?.replaceAll("=A0p-", "/");
  var key = process.env.REACT_APP_DECRYPTION_KEY; //key used in Python

  key = CryptoJS.enc.Utf8.parse(key);
  var decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
    mode: CryptoJS.mode.ECB,
  });
  var decryptedData = decrypted.toString(CryptoJS.enc.Utf8); // "I love Medium"

  return decryptedData;
}
