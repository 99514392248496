import React, { useState, useEffect } from "react";
import { Table, Spin } from "antd";
import { isMobileOnly } from "react-device-detect";
import {
  nonAkDeviceColumns,
  akDeviceColumns,
} from "../../../constants/defaultKeys";
import { Link } from "react-router-dom";
import "../Admin.css";

const InventoryTable = ({ data, loading, aadharState, aekUsers }) => {
  const [tableData, setTabelData] = useState(nonAkDeviceColumns);
  useEffect(() => {
    aadharState
      ? setTabelData(akDeviceColumns)
      : setTabelData(nonAkDeviceColumns);
  }, [aadharState]);
  const columns = [
    {
      title: "Name",
      dataIndex: "region_name",
      fixed: true,
      width: 230,
      render: (regionName, row) =>
        aekUsers && row.is_district ? (
          regionName
        ) : (
          <Link
            to={`/${row?.is_district ? "district" : "mandal"}/${regionName}/${
              row.region_id
            }/`}
          >
            {regionName}
          </Link>
        ),
    },
    ...tableData,
  ];
  return (
    <Table
      loading={
        loading && {
          indicator: (
            <Spin
              style={{
                fontSize: "14px",
                width: "350px",
                marginLeft: "-180px",
              }}
              tip="Fetching results.. please wait for a min or two..."
            />
          ),
        }
      }
      columns={columns}
      dataSource={data}
      rowKey={(record) => record.region_id}
      pagination={false}
      scroll={isMobileOnly ? { x: 500, y: 500 } : { x: 1400, y: "80vh" }}
    />
  );
};

export default InventoryTable;
