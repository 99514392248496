import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Tag,
  Typography,
  Descriptions,
  Form,
  Select,
  Input,
  Button,
  Divider,
  Badge,
  message,
  Modal,
  Result,
  Skeleton,
  Space,
} from "antd";
import Card from "../../../blocks/Card/Card";
import { getItem } from "../../../helpers/localStorage";
import { USER } from "../../../constants/defaultKeys";
// import {
//   getIssue,
//   updateIssue,
//   forwardTicketDetails,
// } from "../../../services/issue.services";
import { internetServiceTicketUpdate } from "../../../services/inventory.services";
import { internetServiceRequestTicketInfo } from "../../../services/inventory.services";
import TimeStamp from "../../../blocks/TimeStamp/index";
import { useParams, useHistory } from "react-router";
import moment from "moment";
import "./Issues.css";
import InternetIssueClosedByDA from "./InternetIssueClosedByDA";

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const InternetIssue = () => {
  const query = useParams();
  const [data, setData] = useState(null);
  const userDetails = JSON.parse(getItem(USER));
  const [formValues, setFormValues] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDACloseModal, setShowDACloseModal] = useState(false);
  const history = useHistory();

  const showModal = () => {
    setIsModalVisible(true);
  };
  useEffect(() => {
    if (query?.id) {
      setLoading(true);
      internetServiceRequestTicketInfo(query.id)
        .then((response) => {
          setData(response.data);

          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [query, refresh]);

  const onFinish = (values) => {
    internetServiceTicketUpdate(query.id, values)
      .then((response) => {
        message.success(response.data.message);
        refreshPage();
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        console.log(error);
      });
  };
  const onFormValuesChange = async (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const deviceStatus = (status) => {
    switch (status) {
      case "in_progress":
        return <Badge color="orange" status="processing" text="In Progress" />;
      case "closed":
        return <Badge color="green" text="Closed" />;
      case "re_open":
        return <Badge color="red" text="ReOpened" />;
      case "waiting_on_user":
        return <Badge color="cyan" text="Waiting On User" />;
      case "self_closed":
        return <Badge color="cyan" text="Closed" />;
      default:
        return <Badge color="blue" status="default" text="Open" />;
    }
  };

  return (
    <Fragment>
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          md={{ span: 18, offset: 3 }}
          lg={{ span: 18, offset: 3 }}
        >
          {loading ? (
            <Skeleton />
          ) : data ? (
            <Card
              title={
                <Fragment>
                  <Title level={4}>{data?.ticket_id}</Title>
                  <span style={{ fontSize: "12px" }}>
                    {deviceStatus(data?.issue_status)}
                  </span>

                  {data?.issue_status == "self_closed" && (
                    <Tag
                      color="blue"
                      style={{
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      Closed by Secretariat Authority
                    </Tag>
                  )}
                </Fragment>
              }
              className="issues-card"
              extra={
                <Space>
                  {userDetails?.is_village_secretariat &&
                    // userDetails?.region.region_id === data?.region_id &&
                    data?.issue_status !== "closed" &&
                    data?.issue_status !== "self_closed" && (
                      <Button
                        type="primary"
                        onClick={() => setShowDACloseModal(true)}
                        data={data}
                      >
                        Close
                      </Button>
                    )}

                  <Tag
                    color="magenta"
                    style={{
                      height: "33px",
                      lineHeight: "33px",
                      fontSize: "14px",
                    }}
                  >
                    Age Of Ticket{" "}
                    <TimeStamp
                      timeStyle="round"
                      value={data && data.created_at}
                    />
                  </Tag>
                </Space>
              }
            >
              <Descriptions>
                <Descriptions.Item label="Issue Type">
                  {data?.issue_type.replace("_", " ")}
                </Descriptions.Item>
                <Descriptions.Item label="GPON S/N">
                  {data?.gpon_serial_number ? data.gpon_serial_number : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Box Signal Status">
                  {data?.box_signal_status ? data.box_signal_status : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Created  At">
                  {moment(data && data.created_at).format("YYYY-MM-DD")}
                </Descriptions.Item>
                <Descriptions.Item label="Last Updated At">
                  {moment(data && data.updated_at).format("YYYY-MM-DD")}
                </Descriptions.Item>
                <Descriptions.Item label="Village">
                  {data?.secretariat_name}
                </Descriptions.Item>
                <Descriptions.Item label="Village Secretariat Code">
                  {data?.village_secretariat_code}
                </Descriptions.Item>
                <Descriptions.Item label="Village Pin Code">
                  {data?.pincode}
                </Descriptions.Item>
                <Descriptions.Item label="Secretariat Address">
                  {data?.primary_address
                    ? data.primary_address
                    : data?.secretariat_name}
                </Descriptions.Item>
                <Descriptions.Item label="Mandal">
                  {data?.mandal}
                </Descriptions.Item>
                <Descriptions.Item label="Vendor Remarks">
                  {data?.vendor_remarks}
                </Descriptions.Item>
                {userDetails?.is_vendor && (
                  <>
                    <Descriptions.Item label=" Ticket Raised By">
                      {data?.raised_by}
                    </Descriptions.Item>
                    <Descriptions.Item label="Contact Person Mobile">
                      {data?.primary_contact_number}
                    </Descriptions.Item>
                    <Descriptions.Item label="Alternate Mobile">
                      {data?.alternative_contact_number
                        ? data.alternative_contact_number
                        : "-"}
                    </Descriptions.Item>

                    <Descriptions.Item label="Contact Person Email">
                      {data?.primary_email ? data?.primary_email : "-"}
                    </Descriptions.Item>
                  </>
                )}

                <Descriptions.Item label="Additional Details">
                  {data?.outher_issue_query ? data?.outher_issue_query : "-"}
                </Descriptions.Item>

                <Descriptions.Item label="Reference Image">
                  {data?.image_for_reference === "undefined" ||
                  data?.image_for_reference === "" ||
                  data?.image_for_reference === null ? (
                    "-"
                  ) : (
                    <a
                      href={data?.image_for_reference}
                      target="_blank"
                      rel="noopener"
                    >
                      {data?.image_for_reference}
                    </a>
                  )}
                </Descriptions.Item>

                {data?.issue_status === "re_open" && (
                  <Descriptions.Item label="Reason for reopened">
                    {data?.re_open_reason ? data.re_open_reason : "-"}
                  </Descriptions.Item>
                )}
              </Descriptions>
              {userDetails?.is_vendor && (
                <>
                  <Divider orientation="left">Update Ticket Status</Divider>
                  <Form
                    name="issues"
                    colon={false}
                    className="issues-form"
                    layout="inline"
                    onFinish={onFinish}
                    onValuesChange={onFormValuesChange}
                  >
                    <Form.Item
                      name="issue_status"
                      rules={[
                        {
                          required: true,
                          message: "This field is required!",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        placeholder="Status"
                        style={{ minWidth: "150px" }}
                        disabled={data?.ticket_status === "Closed" && true}
                      >
                        <Option value="open" key="open">
                          Open
                        </Option>
                        <Option value="in_progress" key="in_progress">
                          In Progress
                        </Option>

                        <Option value="waiting_on_user" key="waiting_on_user">
                          Waiting On User
                        </Option>
                        <Option value="closed" key="closed">
                          Closed
                        </Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="vendor_remarks"
                      rules={[
                        {
                          required:
                            formValues?.issue_status === "waiting_on_user" ||
                            formValues?.issue_status === "closed"
                              ? true
                              : false,
                          message: "This field is required!",
                        },
                      ]}
                    >
                      <TextArea
                        rows={1}
                        size="large"
                        placeholder={
                          formValues?.issue_status === "waiting_on_user" ||
                          formValues?.issue_status === "closed"
                            ? "Resolutioin Message"
                            : "Resolutioin Message (Optional)"
                        }
                        style={{ width: "250px" }}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button size="large" type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
            </Card>
          ) : (
            <Card>
              <Result
                status="warning"
                title="No data found with this Ticket ID"
                extra={
                  <Button type="primary" onClick={() => history.goBack()}>
                    Go Back
                  </Button>
                }
              />
            </Card>
          )}
        </Col>
      </Row>
      {showDACloseModal && (
        <InternetIssueClosedByDA
          closeModal={showDACloseModal}
          setCloseModal={setShowDACloseModal}
          refreshPage={setRefresh}
          data={data}
        />
      )}
    </Fragment>
  );
};

export default InternetIssue;
