import React, { useState } from "react";
import { Row, Col, Radio } from "antd";
import AekVendor from "./AekVendor";
import { getItem } from "../../../helpers/localStorage";
import { USER } from "../../../constants/defaultKeys";
import { useLocation } from "react-router-dom";
import AekIssues from "../IssuesPage/AekIssues";
import useViewAccess from "../../../hooks/useViewAccess";
const AekType = () => {
  const userDetails = JSON.parse(getItem(USER));
  const [selectedMenu, setSelectedMenu] = useState("secretariat");
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const onTabChange = (e) => {
    setSelectedMenu(e?.target?.value);
  };

  useViewAccess("is_aek_hod", "is_aek_hod_dept", "is_aek_dc");

  return (
    <>
      {params?.ticket ? (
        <AekIssues params={params?.ticket} />
      ) : (
        <>
          <Row>
            <Col span={24}>
              {!userDetails?.is_uidai && (
                <Radio.Group
                  className="aek-radio"
                  style={{
                    marginLeft: "20px",
                    paddingBottom: "20px",
                  }}
                  defaultValue={selectedMenu}
                  mode="horizontal"
                  theme="dark"
                  onChange={onTabChange}
                >
                  <Radio.Button value="secretariat">
                    Secretariat Tickets
                  </Radio.Button>

                  <Radio.Button value="self">DC Tickets</Radio.Button>
                </Radio.Group>
              )}

              {selectedMenu && <AekVendor tab={selectedMenu} />}
            </Col>
          </Row>
        </>
      )}
      {/* {is_uidai ? (
        <AekIssues params={params?.ticket} />
      ) : (
        <Row>
          <Col span={24}>
            <Radio.Group
              className="aek-radio"
              style={{
                marginLeft: "20px",
                paddingBottom: "20px",
              }}
              defaultValue={selectedMenu}
              mode="horizontal"
              theme="dark"
              onChange={onTabChange}
            >
              <Radio.Button value="secretariat">
                Secretariat Tickets
              </Radio.Button>
              <Radio.Button value="self">DC Tickets</Radio.Button>
            </Radio.Group>
            {selectedMenu && <AekVendor tab={selectedMenu} />}
          </Col>
        </Row>
      )} */}
    </>
  );
};
export default AekType;
