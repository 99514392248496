import { Col, Row, Result, Button } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getItem, clear } from "../../helpers/localStorage";
import { ExpireRefreshToken } from "../../services/auth.services";
import { USER } from "../../constants/defaultKeys";
import { isAuthenticated } from "../../helpers/utility";
function Sessionexpiry() {
  const history = useHistory();
  useEffect(() => {
    ExpireRefreshToken({ refresh: JSON.parse(getItem(USER)).refresh_token })
      .then(() => {
        clear();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  function handleLogOut() {
    history.push("/");
  }
  return (
    <Row>
      <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "70vh",
        }}
      >
        <Result
          status="warning"
          title="Your session has expired due to inactivity, please login again to continue."
          extra={
            <Button type="primary" onClick={handleLogOut}>
              Login
            </Button>
          }
        />
      </Col>
    </Row>
  );
}

export default Sessionexpiry;
