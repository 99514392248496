import React from "react";
import { Row, Alert } from "antd";
import { ThunderboltFilled } from "@ant-design/icons";
const Ups = () => {
  return (
    <Row>
      <Alert
        message="Report high voltage"
        description="In case your secretariat is experiencing power (electricity) fluctuations - High/Low Voltage, 
  please report to mandal level or district level authorities immediately. 
  This often results in UPS system failure"
        type="error"
        icon={<ThunderboltFilled />}
        showIcon
      />
    </Row>
  );
};

export default Ups;
