import React, { useState, useEffect } from "react";
import { Button, Modal, Result } from "antd";
import Idled from "react-idled";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../helpers/utility";
import { clear } from "../../helpers/localStorage";
let timer;
let timeCountdown;
function UserIdle({ children }) {
  const [isUserIdle, setUserIdle] = useState(false);
  const history = useHistory();
  function handleUserIdle(idle) {
    if (idle.idle && !isUserIdle && isAuthenticated()) {
      setUserIdle(true);
      startTimeCountDown();
    }
  }

  function startTimeCountDown() {
    timer = 60;
    timeCountdown = setInterval(countdown, 1000);
  }
  function countdown() {
    if (timer == 0) {
      setUserIdle(false);
      window.location.href = "/sessionexpired";
      clearInterval(timeCountdown);
      // clear();
    } else {
      document.getElementById("logout-time").textContent = timer;
      timer--;
    }
  }
  return (
    <Idled
      onChange={!isUserIdle && handleUserIdle}
      timeout={20 * 60000}
      events={[
        "mousemove",
        "keydown",
        "wheel",
        "scroll",
        "DOMMouseScroll",
        "mousewheel",
        "mousedown",
        "touchstart",
        "touchmove",
        "MSPointerDown",
        "MSPointerMove",
        "visibilitychange",
      ]}
      render={({ idle }) =>
        isUserIdle ? (
          <Modal visible={isUserIdle} footer={false} closable={false}>
            <Result
              status="warning"
              title={
                <>
                  <span>Your session is about to expire.</span>
                  <p>
                    you will be logged out in{" "}
                    <b id="logout-time" style={{ fontSize: "1.5rem" }}>
                      {60}
                    </b>
                  </p>
                </>
              }
              extra={
                <>
                  <Button
                    type="default"
                    onClick={() => {
                      setUserIdle(false);
                      clearInterval(timeCountdown);
                      history.push("/logout");
                    }}
                  >
                    Log out
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      setUserIdle(false);
                      clearInterval(timeCountdown);
                    }}
                  >
                    Continue Session
                  </Button>
                </>
              }
            />
          </Modal>
        ) : (
          children
        )
      }
    />
  );
}

export default UserIdle;
