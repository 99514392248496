import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Typography,
  Statistic,
  Avatar,
  Skeleton,
  Button,
  message,
  Badge,
  Switch,
  Space,
  Menu,
  Dropdown,
} from "antd";
import targetIcon from "../../../assets/images/target.png";
import workingIcon from "../../../assets/images/check.png";
import notWorkingIcon from "../../../assets/images/close.png";
import SecurityIcon from "../../../assets/devices/policy.png";
import internetLogo from "../../../assets/devices/internet.png";
import totalIcon from "../../../assets/images/total.png";
import Card from "../../../blocks/Card/Card";
import { downloadStateData } from "../../../services/downloadFile.services";
import InventoryTable from "./InventoryTable";
import { devices, akDevices } from "../../../constants/devices";
import DeviceBadge from "../../../blocks/Badges/DeviceBadge";
import IndentTable from "./IndentTable";
import {
  getStateInventory,
  getStateInventoryStatistics,
} from "../../../services/inventory.services";
import { DownloadOutlined, DownOutlined } from "@ant-design/icons";
import "../Admin.css";
import "../../custom.css";
import useViewAccess from "../../../hooks/useViewAccess";
const { Paragraph } = Typography;

const Inventory = ({ userDetails }) => {
  const aekUsers =
    userDetails?.is_aek_hod ||
    userDetails?.is_aek_hod_dept ||
    userDetails?.is_aek_dc;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [inventoryData, setInventoryData] = useState(null);
  const [inventoryDataLoading, setInventoryDataLoading] = useState(true);
  const [aadharDevices, setShowAadharDevices] = useState(
    aekUsers ? akDevices : devices
  );
  const [isAadhar, setIsAadhar] = useState(aekUsers ? true : false);
  const [buttonType, setButtonType] = useState(false);

  // useViewAccess(
  //   "is_state_executive_authority",
  //   "is_admin",
  //   "is_aek_hod",
  //   "is_aek_hod_dept",
  //   "is_aek_dc"
  // );
  useEffect(() => {
    getStateInventoryStatistics()
      .then((response) => {
        setInventoryData(response.data);
        setInventoryDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setInventoryDataLoading(false);
      });

    getStateInventory()
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const download = (apiCategory, fileName) => {
    setBtnLoading(true);
    downloadStateData(apiCategory)
      .then((response) => response.data)
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${fileName}`;
        a.click();
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong, Please try after sometime.");
        setBtnLoading(false);
      });
  };
  function onChange(checked) {
    setIsAadhar(checked);
    setShowAadharDevices(checked ? akDevices : devices);
  }
  const menu = (
    <Menu>
      {!aekUsers && (
        <>
          <Menu.Item
            key="Download_Non-Aadhar Inventory"
            icon={<DownloadOutlined />}
            onClick={() => {
              download("non_aadhar_inventory", "non-aadhar-inventory.xlsx");
            }}
          >
            Non-Aadhar Inventory
          </Menu.Item>
          <Menu.Item
            key="Download_ Non-Aadhar_Added_Stats"
            icon={<DownloadOutlined />}
            onClick={() => {
              download(
                "non_aadhar_inventory_stats",
                "non-aadhar-inventory-stats-inventory.xlsx"
              );
            }}
          >
            Non-Aadhar Added Stats
          </Menu.Item>
        </>
      )}

      <Menu.Item
        key="Download_Aadhar_Inventory_Stats"
        icon={<DownloadOutlined />}
        onClick={() => {
          download("aadhar_inventory", "aadhar-inventory.xlsx");
        }}
      >
        Aadhar Inventory
      </Menu.Item>
      <Menu.Item
        key="Download_Aadhar_Added_Stats"
        icon={<DownloadOutlined />}
        onClick={() => {
          download("aadhar_inventory_stats", "aadhar-inventory-stats.xlsx");
        }}
      >
        Aadhar Added Stats
      </Menu.Item>
    </Menu>
  );

  return (
    <Row gutter={[8, 8]}>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
      >
        <Card
          title={
            <span>
              Inventory Summary
              <Badge
                style={{ backgroundColor: "#2A8FF7", marginLeft: "5px" }}
                count={`Total: ${
                  isAadhar
                    ? inventoryData?.aadhar_inventory_count
                      ? inventoryData?.aadhar_inventory_count
                      : "-"
                    : inventoryData?.non_aadhar_inventory_count
                    ? inventoryData?.non_aadhar_inventory_count
                    : "-"
                }`}
              />
            </span>
          }
          className="state-inventory-summary"
          extra={
            <Space>
              {!aekUsers && (
                <Switch
                  checkedChildren={aekUsers ? "Non-Aadhar" : "Aadhar"}
                  unCheckedChildren={aekUsers ? "Aadhar" : "Non-Aadhar"}
                  defaultChecked={false}
                  onChange={onChange}
                />
              )}

              <Dropdown overlay={menu} trigger={["click"]}>
                <Button
                  loading={btnLoading}
                  type="primary"
                  icon={<DownloadOutlined />}
                >
                  Download Inventory <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          }
        >
          {inventoryDataLoading ? (
            <Skeleton active paragraph={{ rows: 2 }} />
          ) : (
            <Row gutter={[48, 10]} style={{ marginLeft: "25px" }}>
              {Object.keys(aadharDevices).map((item, index) => (
                <Col flex="110px" key={index} style={{ padding: "0px" }}>
                  <DeviceBadge deviceType={item} badgeType="allCount" />
                  <Paragraph style={{ marginTop: "8px" }}>
                    <Statistic
                      prefix={
                        <img src={workingIcon} alt="working" height="12px" />
                      }
                      value={
                        inventoryData !== null
                          ? inventoryData?.overall_inventory_statistics[item]
                              ?.working
                          : 0
                      }
                    />
                    <Statistic
                      prefix={
                        <img
                          src={notWorkingIcon}
                          alt="notWorking"
                          height="12px"
                        />
                      }
                      value={
                        inventoryData !== null
                          ? inventoryData?.overall_inventory_statistics[item]
                              ?.not_working
                          : 0
                      }
                    />

                    <Statistic
                      prefix={<img src={totalIcon} alt="total" height="12px" />}
                      value={
                        inventoryData !== null
                          ? inventoryData?.overall_inventory_statistics[item]
                              ?.total
                          : 0
                      }
                    />
                    <Statistic
                      prefix={
                        <img src={targetIcon} alt="target" height="12px" />
                      }
                      value={
                        inventoryData !== null
                          ? !item.includes("AK")
                            ? item === "Monitor"
                              ? inventoryData?.overall_inventory_statistics[
                                  "CPU"
                                ]?.total_delivered
                              : inventoryData?.overall_inventory_statistics[
                                  item
                                ]?.total_delivered
                            : 500
                          : "-"
                      }
                    />
                  </Paragraph>
                </Col>
              ))}
              {!isAadhar && (
                <>
                  <Col flex="110px" style={{ padding: "0px" }}>
                    <Avatar shape="square" size={42} src={SecurityIcon} />
                    <Paragraph style={{ marginTop: "8px" }}>
                      <Statistic
                        prefix={
                          <img src={workingIcon} alt="total" height="12px" />
                        }
                        value={
                          inventoryData !== null
                            ? inventoryData?.security_certificates?.remaining
                            : 0
                        }
                      />
                      <Statistic
                        prefix={
                          <img
                            src={notWorkingIcon}
                            alt="notWorking"
                            height="12px"
                          />
                        }
                        value={
                          inventoryData !== null
                            ? inventoryData?.security_certificates?.damaged
                            : 0
                        }
                      />
                    </Paragraph>
                  </Col>
                  <Col flex="110px" style={{ padding: "0px" }}>
                    <Avatar shape="square" size={42} src={internetLogo} />
                    <Paragraph style={{ marginTop: "8px" }}>
                      <Statistic
                        prefix={
                          <img src={workingIcon} alt="working" height="12px" />
                        }
                        value={
                          inventoryData !== null
                            ? inventoryData?.internet_connection
                                ?.total_connections -
                              inventoryData?.internet_connection
                                ?.poor_connections
                            : 0
                        }
                      />
                      <Statistic
                        prefix={
                          <img
                            src={notWorkingIcon}
                            alt="notWorking"
                            height="12px"
                          />
                        }
                        value={
                          inventoryData !== null
                            ? inventoryData?.internet_connection
                                ?.poor_connections
                            : 0
                        }
                      />
                    </Paragraph>
                  </Col>
                </>
              )}
            </Row>
          )}
        </Card>
      </Col>
      <Col span={24} style={{ display: "flex", paddingBottom: "16px" }}>
        <Col span={12}>
          <Button
            type={buttonType ? "default" : "primary"}
            onClick={() => {
              setButtonType(false);
            }}
            block
          >
            Inventory Data
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type={buttonType ? "primary" : "default"}
            block
            onClick={() => {
              setButtonType(true);
            }}
          >
            Indent Request Data
          </Button>
        </Col>
      </Col>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
      >
        {buttonType ? (
          <IndentTable />
        ) : (
          <InventoryTable
            data={data?.inventory_data}
            loading={loading}
            aadharState={isAadhar}
            aekUsers={aekUsers}
          />
        )}
      </Col>
    </Row>
  );
};

export default Inventory;
