import React, { useEffect, useState } from "react";
import { Tag, Row, Col, Button, Table } from "antd";
import { Link } from "react-router-dom";
import Drawer from "../../Drawer/Drawer";
import moment from "moment";
import { getTabTicket } from "../../../services/inventory.services";
import useVSAccess from "../../../hooks/useVSAccess";
import useViewAccess from "../../../hooks/useViewAccess";
const TabTicketTable = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editList, setEditList] = useState(null);
  const [data, setData] = useState(null);
  useVSAccess();
  useViewAccess(
    "is_ward_welfare_data_processing_secretary",
    "is_village_secretariat"
  );

  useEffect(() => {
    setLoading(true);
    getTabTicket()
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh]);

  const openModal = (data) => {
    setEditList({ ...data });
    setDrawerVisible(true);
  };
  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };
  const columns = [
    {
      width: 150,
      title: "Ticket ID",
      dataIndex: "ticket_id",
      key: "ticket_id",
      render: (value, row) => (
        <Link to={`/ticket-details/${row?.id}`}>{value}</Link>
      ),
    },
    {
      width: 150,
      title: "Device Serial No",
      dataIndex: "serial_no",
      key: "serial_no",
    },
    {
      width: 150,
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => (date ? moment(date).format("MMM D, YYYY") : "-"),
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
    {
      width: 150,
      title: "Job Sheet ID",
      dataIndex: "jobsheet_id",
      key: "jobsheet_id",
      render: (value) => (value ? value : "-"),
    },
    {
      width: 150,
      title: "Repair Status",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Repaired",
          value: "Resolved",
        },
        {
          text: "Delivered back to the student",
          value: "Delivered back to the student",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.status.includes(value),
      render: (value) =>
        value === "Pending" ? (
          <Tag color="red" style={{ fontSize: "14px" }}>
            Pending
          </Tag>
        ) : value === "Resolved" ? (
          <Tag color="lime" style={{ fontSize: "14px" }}>
            Repaired
          </Tag>
        ) : value === "Delivered back to the student" ? (
          <Tag color="green" style={{ fontSize: "14px" }}>
            Delivered back to the student
          </Tag>
        ) : (
          "-"
        ),
    },
    {
      width: 150,
      title: "Resolution Status",
      dataIndex: "resolution",
      key: "resolution",
      filters: [
        {
          text: "Repaired And Fixed",
          value: "RepairedAndFixed",
        },
        {
          text: "Replaced",
          value: "Replaced",
        },
        {
          text: "NotCoveredUnderWarranty",
          value: "Not Covered Under Warranty",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.resolution.includes(value),
      render: (value) =>
        value === "RepairedAndFixed"
          ? "Repaired And Fixed"
          : value === "Replaced"
          ? "Replaced"
          : value === "NotCoveredUnderWarranty"
          ? "Not Covered Under Warranty"
          : "-",
    },
    {
      width: 150,
      title: "Repair Cost",
      dataIndex: "cost_of_repair",
      key: "cost_of_repair",
      //   render: (value) => (value ? convertToCurrency(value) : "-"),
      //   sorter: (a, b) => a.raised_amount - b.raised_amount,
    },
  ];
  return (
    <>
      <Row gutter={[12, 12]}>
        <Col span={22} offset={1} style={{ textAlign: "end" }}>
          <Button
            style={{ textAlign: "right", marginBottom: "10px" }}
            type="primary"
            onClick={() => {
              openModal({
                title: "Raise Ticket",
                status: "raise-ticket",
              });
            }}
          >
            Raise Ticket
          </Button>
        </Col>
        <Col span={22} offset={1}>
          <Table
            bordered
            locale={{ emptyText: "No Tickets" }}
            dataSource={data}
            columns={columns}
            pagination={false}
            loading={loading}
          />
        </Col>
      </Row>

      {drawerVisible && (
        <Drawer
          setVisible={setDrawerVisible}
          editList={editList}
          refresh={refreshPage}
        />
      )}
    </>
  );
};
export default TabTicketTable;
