import React from "react";
import { Row, Alert } from "antd";

const Monitor = () => {
  return (
    <Row>
      <Alert
        message="ALWAYS use Monitor with UPS"
        description="Please ensure monitor is connected to the UPS while you're using it.
         Warranty could become void if you don't connect monitor to the UPS"
        type="warning"
        showIcon
      />
    </Row>
  );
};

export default Monitor;
