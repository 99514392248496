import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  downloadVolunteerRegionWiseReport,
  getVolunteerClusterReport,
  getVolunteerRegionWiseReport,
} from "../../../services/inventory.services";
import AggregateTable from "./AggregateTable";
import { InfoCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Col, message, Row } from "antd";
function AggregateReport() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const comp = Object.keys(params);
  useEffect(() => {
    if (!comp?.includes("sec")) {
      setLoading(true);
      let param = {};
      if (comp?.includes("district") && !comp?.includes("sec")) {
        param.district = params?.district;
      }
      if (comp?.includes("mandal") && !comp?.includes("sec")) {
        param.mandal = params?.mandal;
      }

      getVolunteerRegionWiseReport(param)
        .then((res) => {
          setData(res?.data?.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [params?.district, params?.mandal]);

  useEffect(() => {
    if (params?.sec) {
      getVolunteerClusterReport({ secretariat: params?.sec })
        ?.then((res) => setData(res?.data?.data))
        .catch((err) => {
          console.log(err);
        });
    }
  }, [params?.sec]);

  const handleAggregateDownload = (value) => {
    setBtnLoading(true);

    downloadVolunteerRegionWiseReport()
      .then((response) => response.data)
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `Inventory Collection Aggregate Report.xlsx`;
        a.click();
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong, Please try after sometime.");
        setBtnLoading(false);
      });
  };
  return (
    <Row gutter={[12, 12]}>
      {!comp?.includes("sec") && (
        <Col span={24} style={{ textAlign: "right" }}>
          <Button
            loading={btnLoading}
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleAggregateDownload}
          >
            Download Cluster Level Report
          </Button>
        </Col>
      )}
      <Col span={24}>
        <AggregateTable
          data={data}
          loading={loading}
          params={params}
          comp={comp}
        />
      </Col>
    </Row>
  );
}

export default AggregateReport;
