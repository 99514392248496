import React, { useState } from "react";
import { Menu, Row, Col, Radio } from "antd";
import Card from "../../../blocks/Card/Card";
import RequestInventory from "../RequestInventory/RequestInventory";
import SimActivation from "../SimActivation/SimActivation";
import TrackRequests from "./TrackRequests/TrackRequests";
import ReportHighVoltage from "../ReportHighVoltage/ReportHighVoltage";
import LostDevices from "../LostDevice/LostDevice";
import { useParams, useHistory } from "react-router-dom";
import ReportForm from "./TrackRequests/ReportForm";
import Office365Request from "./TrackRequests/Office365Requst";
import EmptyBsnlSimRequests from "./TrackRequests/EmptyBsnlSimRequests";
import KeyboardAndMouse from "./TrackRequests/KeyboardAndMouse";
import queryString from "query-string";
import ReportDamagedCertificates from "../ReportDamagedCertificates/ReportDamagedCertificates";
import InternetIssues from "./TrackRequests/InternetIssuesForm";
import AekForm from "./TrackRequests/AekForm";
import AekTrack from "./TrackRequests/AekTrack";
import useVSAccess from "../../../hooks/useVSAccess";
import useViewAccess from "../../../hooks/useViewAccess";

const ServiceRequest = ({ data }) => {
  const query = useParams();
  const history = useHistory();
  const urlQueryParams = history?.location?.search;
  let param = queryString.parse(urlQueryParams);
  const [selectedMenu, setSelectedMenu] = useState(
    data?.region?.is_aadhar_kit_enable ? "aek" : "reopen_ticket"
  );
  const [currentTab, setCurrentTab] = useState("request");
  useVSAccess();
  useViewAccess(
    "is_ward_welfare_data_processing_secretary",
    "is_village_secretariat"
  );

  function changeTab(e) {
    const value = e.target.value;
    setCurrentTab(value);
  }

  const onMenuClick = (e) => {
    setSelectedMenu(e.key);
    history.push(
      `${history.location.pathname}?tab=service-requests&&innerTab=${e.key}`
    );
  };

  const radioButton = (
    <Radio.Group
      value={currentTab}
      onChange={changeTab}
      buttonStyle="solid"
      style={{ marginBottom: "10px" }}
    >
      <Radio.Button value="request">New Request</Radio.Button>
      <Radio.Button value="track">Track Existing Requests</Radio.Button>
    </Radio.Group>
  );

  const serviceComponents = (selectedMenu) => {
    switch (param?.innerTab) {
      case "activate_new_sim":
        return (
          <>
            {currentTab === "request" ? (
              <Card style={{ padding: "32px" }}>
                <SimActivation id={query?.id} />
              </Card>
            ) : (
              <TrackRequests action="sim" />
            )}
          </>
        );
      case "report_damaged_km":
        return (
          <>
            {currentTab === "request" ? (
              <Card style={{ padding: "32px" }}>
                <KeyboardAndMouse id={query?.id} />
              </Card>
            ) : (
              <TrackRequests action="report_damaged_km" />
            )}
          </>
        );
      case "lost_device":
        return (
          <>
            {currentTab === "request" ? (
              <Card style={{ padding: "32px" }}>
                <LostDevices id={query?.id} />
              </Card>
            ) : (
              <TrackRequests action="lost_device" />
            )}
          </>
        );
      case "report_high_voltage":
        return (
          <>
            {currentTab === "request" ? (
              <Card style={{ padding: "32px" }}>
                <ReportHighVoltage id={query?.id} />
              </Card>
            ) : (
              <TrackRequests action="report_high_voltage" />
            )}
          </>
        );
      case "request_inventory":
        return (
          <>
            {currentTab === "request" ? (
              <Card style={{ padding: "32px" }}>
                <RequestInventory />
              </Card>
            ) : (
              <TrackRequests action="inventory" />
            )}
          </>
        );
      case "office_365":
        return (
          <>
            {currentTab === "request" ? (
              <Card style={{ padding: "32px" }}>
                <Office365Request id={query?.id} />
              </Card>
            ) : (
              <TrackRequests action="office_365" />
            )}
          </>
        );
      case "damaged_count":
        return (
          <>
            <Card style={{ padding: "32px" }}>
              <ReportDamagedCertificates id={query?.id} />
            </Card>
          </>
        );

      case "internet":
        return (
          <>
            {currentTab === "request" ? (
              <Card style={{ padding: "32px" }}>
                <InternetIssues id={query?.id} />
              </Card>
            ) : (
              <TrackRequests action="request_inventory" />
            )}
          </>
        );
      case "aek":
        return (
          <>
            {currentTab === "request" ? (
              <Card style={{ padding: "32px" }}>
                <AekForm data={data} />
              </Card>
            ) : (
              <AekTrack />
            )}
          </>
        );
      default:
        return (
          <>
            {currentTab === "request" ? (
              <Card style={{ padding: "32px" }}>
                <ReportForm />
              </Card>
            ) : (
              <TrackRequests action="reopen_ticket" />
            )}
          </>
        );
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col
        xs={{ span: 0, offset: 0 }}
        sm={{ span: 0, offset: 0 }}
        md={{ span: 22, offset: 1 }}
        lg={{ span: 22, offset: 1 }}
      >
        <Menu
          selectedKeys={param?.innerTab}
          mode="horizontal"
          theme="dark"
          onClick={onMenuClick}
        >
          {data?.region?.is_aadhar_kit_enable && (
            <Menu.Item key="aek">Aadhar software issues</Menu.Item>
          )}
          <Menu.Item key="reopen_ticket">Hardware Services</Menu.Item>
          <Menu.Item key="request_inventory">Request New Inventory</Menu.Item>
          <Menu.Item key="activate_new_sim">Activate Replaced SIM</Menu.Item>
          <Menu.Item key="report_damaged_km">
            Report Damaged Keyboard/Mouse
          </Menu.Item>
          <Menu.Item key="internet">Report APSFL Internet Issue</Menu.Item>
          <Menu.Item key="office_365">Office 365 Issue</Menu.Item>
          <Menu.Item key="lost_device">Report Lost Device</Menu.Item>
          <Menu.Item key="report_high_voltage">Report High Voltage</Menu.Item>
          <Menu.Item key="damaged_count">Report Damaged Certificates</Menu.Item>
        </Menu>
      </Col>
      <Col
        xs={{ span: 0, offset: 0 }}
        sm={{ span: 0, offset: 0 }}
        md={{ span: 22, offset: 1 }}
        lg={{ span: 22, offset: 1 }}
      >
        <Row>
          <Col span={24}>
            {param?.innerTab !== "damaged_count" && (
              <div style={{ textAlign: "right" }}>{radioButton}</div>
            )}
            <>{serviceComponents(selectedMenu)}</>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ServiceRequest;
