import axiosInstance from "../config/axios/axios.config";

export const mobileLogin = (payload) => {
  return axiosInstance.post("/accounts/mobile-login/vsws/", payload);
};
export const generateCaptcha = (payload) => {
  return axiosInstance.post("/accounts/generate-captcha/", payload);
};
export const emailLogin = (payload) => {
  return axiosInstance.post("/accounts/email-login/", payload);
};

export const verifyEmailLogin = (payload) => {
  return axiosInstance.post("/accounts/login-verify-email/", payload);
};
export const verifyPassword = (payload) => {
  return axiosInstance.post("/accounts/login-verify-password/", payload);
};
export const refreshLoginCaptcha = (payload) => {
  return axiosInstance.put("/accounts/refresh-email-login-captcha/", payload);
};

export const resetPassword = (payload) => {
  return axiosInstance.post("/accounts/reset-password/", payload);
};

export const setPassword = (payload) => {
  return axiosInstance.post("/accounts/set-password/", payload);
};

export const changePassword = (payload) => {
  return axiosInstance.put("/accounts/change-password/", payload);
};

export const requestForLogin = (payload) => {
  return axiosInstance.post("/accounts/request-for-login/", payload);
};

export const getMandalsAndVillages = (name) => {
  return axiosInstance.get(`/get-mandals-and-villages/${name}/`);
};

export const editProfile = (payload) => {
  return axiosInstance.put("/accounts/edit-profile/", payload);
};

export const setProfile = (payload) => {
  return axiosInstance.post("/accounts/set-profile/", payload);
};

export const resendOtp = (payload) => {
  return axiosInstance.post("/accounts/resend-otp/vsws/", payload);
};

export const verifyOtp = (payload) => {
  return axiosInstance.post("/accounts/verify-otp/vsws/", payload);
};

export const getSecretariatMembers = (secretariatCode) => {
  return axiosInstance.get(
    `/accounts/get-secretariat-members/${secretariatCode}/`
  );
};

export const ExpireRefreshToken = (payload) => {
  return axiosInstance.post("/accounts/invalidate-refresh-token/", payload);
};

export const refreshCaptcha = (payload) => {
  return axiosInstance.put("/accounts/refresh-captcha/", payload);
};
export const getRegionsData = () => {
  return axiosInstance.get(`/cr-v2/fetch-regions/`);
};

export const getEmailCaptchaAPI = () => {
  return axiosInstance.get("/accounts/captcha/");
};

export const refreshEmailCaptchaAPI = (payload) => {
  // refresh captcha, send token. it returns captcha image.
  return axiosInstance.put("/accounts/captcha/", payload);
};

export const verifyEmailAPI = (payload) => {
  return axiosInstance.post("/accounts/login-verify-user/", payload);
  // send username, token, captcha code. returns captcha image for password screen.
};

export const verifyMobileAPI = (payload) => {
  // end contact_number, token, captcha_code. sends otp to the contact_number.
  return axiosInstance.post("/accounts/mobile-login/vsws/", payload);
};
