import axiosInstance from "../config/axios/axios.config";

export const addInventory = (payload) => {
  return axiosInstance.post("/add-inventory/", payload);
};

export const deleteInventory = (id, payload) => {
  return axiosInstance.delete(`/delete-inventory/${id}/`, {
    params: {
      ...payload,
    },
  });
};

export const editInventory = (id, payload) => {
  return axiosInstance.put(`/edit-inventory/${id}/`, payload);
};

export const getVSInventory = (id) => {
  return axiosInstance.get(`/village-inventory/${id}/`);
};

export const getMandalInventory = (id) => {
  return axiosInstance.get(`/mandal-inventory/${id}/`);
};

export const getDistrictInventory = (id) => {
  return axiosInstance.get(`/district-inventory/${id}/`);
};

export const getStateInventory = () => {
  return axiosInstance.get("/state-inventory/");
};

export const getStateInventoryStatistics = () => {
  return axiosInstance.get("/state-inventory-statistics/");
};

export const getStateLevelHealthStats = () => {
  return axiosInstance.get("/state-level-health-statistics/");
};

export const getStateLevelIssuesStats = () => {
  return axiosInstance.get("/state-level-issues-statistics/");
};

export const stationeryInventory = (id, payload) => {
  return axiosInstance.post(`/add-stationery/${id}/`, payload);
};

export const refillStationeryInventory = (id, payload) => {
  return axiosInstance.put(`/refill-stationery/${id}/manual/`, payload);
};

export const getStationeryInventory = (id) => {
  return axiosInstance.get(`/get-stationery/${id}/`);
};

export const simRequest = (id, payload) => {
  return axiosInstance.post(`/sim-replace-request/${id}/`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const simNumbers = (id) => {
  return axiosInstance.get(`/secretariat-sim-numbers/${id}/`);
};

export const addInternet = (id, payload) => {
  return axiosInstance.post(`/add-internet/${id}/`, payload);
};

export const editInternet = (id, payload) => {
  return axiosInstance.put(`/edit-internet/${id}/`, payload);
};

export const getDistrictInventoryStats = (id) => {
  return axiosInstance.get(`/district-inventory-statistics/${id}/`);
};

export const getMandalInventoryStats = (id) => {
  return axiosInstance.get(`/mandal-inventory-statistics/${id}/`);
};

export const getDistrictHealthStats = (id) => {
  return axiosInstance.get(`/district-level-health-statistics/${id}/`);
};

export const getDistrictIssuesStats = (id) => {
  return axiosInstance.get(`/district-level-issues-statistics/${id}/`);
};

export const requestForInventory = (id, payload) => {
  return axiosInstance.post(`/request-for-inventory/${id}/`, payload);
};

export const getSimRequests = (currentPage, mobileNumber) => {
  if (mobileNumber) {
    return axiosInstance.get(
      `/get-sim-replace-requests-for-vendor/?page=1&old_sim_number=${mobileNumber}`
    );
  } else {
    return axiosInstance.get(
      `/get-sim-replace-requests-for-vendor/?page=${currentPage}`
    );
  }
};

export const approveSimRequest = (id, payload) => {
  return axiosInstance.post(`/approve-sim-activate-request/${id}/`, payload);
};

export const districtsWithZeroInventory = () => {
  return axiosInstance.get("districts-with-zero-added-secretariats");
};

export const getOffice365Requests = () => {
  return axiosInstance.get(`/get-office365-requests-for-vendor/`);
};

export const getSimStatistics = () => {
  return axiosInstance.get("/sim-statistics/");
};

export const approveOffice365Request = (id, payload) => {
  return axiosInstance.post(`/resolve-office-365-requests/${id}/`, payload);
};

export const approveKMRequest = (id, payload) => {
  return axiosInstance.post(
    `/resolve-keyboard-and-mouse-requests/${id}/`,
    payload
  );
};

export const confirmUniform = (id, payload) => {
  return axiosInstance.post(`/confirm-uniform/${id}/`, payload);
};

export const secretariatLocalityDetails = (id, payload) => {
  return axiosInstance.post(`add-secretariat-locality-details/${id}/`, payload);
};

export const getSecretariatClusters = (id) => {
  return axiosInstance.get(`/get-secretariat-clusters/${id}/`);
};

export const searchInventory = (query) => {
  return axiosInstance.get(`/search-inventory/`, { params: query });
};

export const updateClustersVolunteerInfo = (code, payload) => {
  return axiosInstance.put(`update-clusters-volunteer-info/${code}/`, payload);
};

export const updateSecDimensions = (code) => {
  return axiosInstance.put(`update-dimensions-flag/${code}/`);
};

export const submitSurveyMeasurements = (payload) => {
  return axiosInstance.post(
    "https://sheet.best/api/sheets/39c4ee43-3b48-4538-8c1e-18d517f43db5",
    payload
  );
};

export const raiseInternetServiceRequest = (id, payload) => {
  return axiosInstance.post(`raise-internet-service-request/${id}/`, payload);
};
export const secretariatInternetServiceRequests = (id) => {
  return axiosInstance.get(`secretariat-internet-service-requests-all/${id}/`);
};
export const reOpenInternetServiceRequest = (id, ticket_id, payload) => {
  return axiosInstance.put(
    `internet-service-request-reopen/${id}/${ticket_id}/`,
    payload
  );
};
export const internetServiceRequestTicketInfo = (id) => {
  return axiosInstance.get(`internet-service-request-ticket-info/${id}/`);
};
export const internetServiceRequestSelfClose = (id, ticket_id, payload) => {
  return axiosInstance.put(
    `internet-service-request-selfclose/${id}/${ticket_id}/`,
    payload
  );
};
export const vendorInternetServiceRequests = (
  currentPage,
  pageSize,
  startDate,
  endDate
) => {
  return axiosInstance.get(
    `vendor-internet-service-requests-all/?page=${currentPage}&page_size=${pageSize}&start_date=${startDate}&end_date=${endDate}`
  );
};
export const internetServiceTicketUpdate = (ticket_id, payload) => {
  return axiosInstance.put(
    `vendor-internet-service-requests-ticket-update/${ticket_id}/`,
    payload
  );
};
export const vendorInternetServiceRequestsDownload = (startDate, endDate) => {
  return axiosInstance.get(
    `download-internet-service-requests/?start_date=${startDate}&end_date=${endDate}`,
    {
      responseType: "blob",
    }
  );
};
export const internetServiceRequestStatistics = () => {
  return axiosInstance.get(`internet-service-request-statistics/`);
};

export const getFunctionarySimsList = (secretariatId) => {
  return axiosInstance.get(`functionary-sims-list/${secretariatId}/`);
};

export const addFunctionaryDevice = (payload) => {
  return axiosInstance.post(`add-functionary-device-mapping/`, payload);
};

export const updateFunctionaryDevice = (secretariatId, payload) => {
  return axiosInstance.put(
    `update-functionary-device-mapping/${secretariatId}/`,
    payload
  );
};

export const getFunctionaryMappedDevices = (secretariatId) => {
  return axiosInstance.get(`list-functionary-device-mapping/${secretariatId}/`);
};
export const createTabTicket = (payload) => {
  return axiosInstance.post(`/tab-services/ `, payload);
};
export const getTabTicket = () => {
  return axiosInstance.get(`/tab-services/ `);
};
export const getTabPageTicket = (id) => {
  return axiosInstance.get(`/tab-services/${id} `);
};
export const updateTabPageTicket = (id, payload) => {
  return axiosInstance.patch(`/tab-services/${id}/ `, payload);
};
export const deleteTabTicket = (id, payload) => {
  return axiosInstance.delete(`/tab-services/${id}/ `, payload);
};
export const tabsRegionWiseAggregates = (params) => {
  return !params
    ? axiosInstance.get(`/tabs-region-wise-aggregates `)
    : axiosInstance.get(`/tabs-region-wise-aggregates/?${params} `);
};
export const getServiceCenters = (id) => {
  return axiosInstance.get(`/get-service-centers/${id}`);
};
export const getUidaiTickets = () => {
  return axiosInstance.get(`/uidai-state-aek-service-requests/`);
};

export const addVolunteerInventoryTracking = (payload) => {
  return axiosInstance.post(`/volunteer-inventory-form/`, payload);
};

export const getVolunteerInventoryTracking = (query) => {
  return axiosInstance.get(`/volunteer-inventory-form/`, {
    params: query,
  });
};

export const getCMFSId = (query) => {
  return axiosInstance.get(`/search-cmfs-id/`, {
    params: query,
  });
};

export const addVolunteerInventoryAcknowledgement = (payload) => {
  return axiosInstance.post(`/inventory-acknowledgment-form/`, payload);
};
export const getVolunteerDeviceWiseReport = (query) => {
  return axiosInstance.get(`/volunteer-inventory-report/`, {
    params: query,
  });
};
export const getVolunteerRegionWiseReport = (query) => {
  return axiosInstance.get(`/volunteer-inventory-region-wise-agg-report/`, {
    params: query,
  });
};
export const getVolunteerClusterReport = (query) => {
  return axiosInstance.get(`/sec-inventory-report/`, {
    params: query,
  });
};
export const downloadVolunteerRegionWiseReport = (params) => {
  return params
    ? axiosInstance.get(`volunteer-inventory-cluster-report/?${params}`, {
        responseType: "blob",
      })
    : axiosInstance.get(`volunteer-inventory-cluster-report/`, {
        responseType: "blob",
      });
};
export const downloadVolunteerDeviceReport = (query) => {
  return axiosInstance.get(`/volunteer-inventory-master-report-download/`, {
    params: query,
    responseType: "blob",
  });
};
