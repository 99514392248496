import React, { useEffect, useState } from "react";
import { Table, Space, Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getHighVoltage } from "../../../services/serviceRequests.services";

const HighVoltageReportsTable = ({ id }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getHighVoltage({ district_id: id })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Voltage",
      dataIndex: "voltage",
      key: "voltage",
      fixed: "left",
    },
    {
      title: "Issue Status",
      dataIndex: "resolved",
      key: "resolved",
      render: (_, record) => (
        <span>{record?.resolved ? "Resolved" : "Not Resolved"}</span>
      ),
    },
    {
      title: "Secretariat Name",
      dataIndex: "village_secretariat_name",
      key: "village_secretariat_name",
      ...getColumnSearchProps("village_secretariat_name"),
      render: (data) => <span>{data}</span>,
    },

    {
      title: "Mandal",
      dataIndex: "mandal",
      key: "mandal",
      ...getColumnSearchProps("mandal"),
      render: (data) => <span>{data}</span>,
    },
    {
      title: "Reported By",
      dataIndex: "raised_by_first_name",
      key: "raised_by_first_name",
      render: (name, record) => (
        <span>{record?.raised_by_first_name && name}</span>
      ),
    },
  ];

  return (
    <Table
      loading={loading}
      dataSource={data}
      columns={columns}
      pagination={{ pageSize: 25, showSizeChanger: false }}
    />
  );
};

export default HighVoltageReportsTable;
