export const tabsAggregateColumns = [
  {
    title: "Total no of tabs collected by WEA / WEDPS",
    dataIndex: "raised",
    key: "raised",
    width: 150,
    sorter: (a, b) => a?.raised - b?.raised,
  },
  {
    title: "Tabs taken to repair center",
    dataIndex: "submitted",
    key: "submitted",
    width: 150,
    sorter: (a, b) => a?.submitted - b?.submitted,
  },
  {
    title: "Tickets Closed",
    dataIndex: "closed",
    key: "closed",
    width: 150,
    sorter: (a, b) => a?.closed - b?.closed,
  },
  {
    title: "Resolution",
    children: [
      {
        title: "Repaired And Fixed",
        dataIndex: "repair_and_fixed",
        key: "repair_and_fixed",
        width: 150,
        sorter: (a, b) => a.repair_and_fixed - b.repair_and_fixed,
      },
      {
        title: "Replaced",
        dataIndex: "replaced",
        key: "replaced",
        width: 150,
        sorter: (a, b) => a.replaced - b.replaced,
      },
      {
        title: "Not Covered Under Warranty",
        dataIndex: "not_covered_under_warranty",
        key: "not_covered_under_warranty",
        width: 150,
        sorter: (a, b) =>
          a.not_covered_under_warranty - b.not_covered_under_warranty,
      },
    ],
  },
];
