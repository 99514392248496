import React, { Fragment, useState, useEffect } from "react";
import { Table, Button, Row, Col, message } from "antd";
import { getVendorKMIssues } from "../../../../services/issue.services";
import { isMobileOnly } from "react-device-detect";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { DownloadOutlined, DownOutlined } from "@ant-design/icons";
import KeyboardMouseModal from "./KeyboardMouseModal";
import { downloadKeyboardMouseTableData } from "../../../../services/downloadFile.services";
const status = [
  {
    text: "Open",
    value: "Open",
  },
  {
    text: "In Progress",
    value: "InProgress",
  },
  {
    text: "Waiting On User",
    value: "waiting-on-user",
  },
  {
    text: "Forwarded",
    value: "Forwarded",
  },
  {
    text: "Closed",
    value: "Closed",
  },
];

const KeyboardMouseTable = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editList, setEditList] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const openModal = (data) => {
    setEditList(data);
    setShowModal(true);
  };

  const columns = [
    {
      title: "Device Type",
      dataIndex: "inventory_type",
      key: "inventory_type",
      width: 100,
      fixed: "left",
    },
    {
      title: "CPU Serial Number",
      dataIndex: "cpu_serial_number",
      key: "cpu_serial_number",
      width: 160,
    },
    {
      title: "Front Side Image",
      dataIndex: "front_image",
      key: "front_image",
      width: 250,
      render: (photo, record) =>
        record?.front_image ? (
          <Link
            to={{ pathname: record?.front_image }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {photo}
          </Link>
        ) : (
          "-"
        ),
    },
    {
      title: "Back Side Image",
      dataIndex: "back_image",
      key: "back_image",
      width: 250,
      render: (photo, record) =>
        record?.back_image ? (
          <Link
            to={{ pathname: record?.back_image }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {photo}
          </Link>
        ) : (
          "-"
        ),
    },
    {
      title: "Secretariat",
      dataIndex: "secretariat_name",
      key: "secretariat_name",
      width: 120,
      sorter: (a, b) => a.secretariat_name.localeCompare(b.secretariat_name),
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      width: 120,
      sorter: (a, b) => a.district.localeCompare(b.district),
    },
    {
      title: "Issue Raised By",
      dataIndex: "raised_by_user",
      key: "raised_by_user",
      width: 130,
      sorter: (a, b) => a.raised_by_user.localeCompare(b.raised_by_user),
    },
    {
      title: "User Contact",
      dataIndex: "raised_by_user_contact",
      key: "raised_by_user_contact",
      width: 150,
    },
    {
      title: "Issue Raised",
      dataIndex: "created_at_date",
      key: "created_at_date",
      width: 100,
      render: (date) => moment(date).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.created_at_date).unix() - moment(b.created_at_date).unix(),
    },
    {
      title: "Issue Closed",
      dataIndex: "updated_at_date",
      key: "updated_at_date",
      width: 100,
      render: (date, record) => (
        <span>
          {record.resolution_status === "Closed"
            ? moment(date).format("YYYY-MM-DD")
            : "-"}
        </span>
      ),
      sorter: (a, b) =>
        moment(a.updated_at_date).unix() - moment(b.updated_at_date).unix(),
    },
    {
      title: "Issue Description",
      dataIndex: "issue",
      key: "issue",
      width: 250,
      render: (data, record) => <span>{record.issue ? data : "-"}</span>,
    },
    {
      title: "Resolution Status",
      dataIndex: "resolution_status",
      key: "resolution_status",
      width: 140,
      render: (data, record) => (
        <span>{record.resolution_status ? data : "-"}</span>
      ),
      filters: [...status],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record?.resolution_status?.includes(value),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: 250,
      render: (data, record) => <span>{record.remarks ? data : "-"}</span>,
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      fixed: "right",
      render: (record) => (
        <>
          {!(record?.resolution_status === "Closed") ? (
            <Button
              type="primary"
              size="small"
              onClick={() => openModal(record)}
            >
              Change status
            </Button>
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    getVendorKMIssues("")
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [, refresh]);

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };
  const download = () => {
    setBtnLoading(true);
    downloadKeyboardMouseTableData()
      .then((response) => {
        let blob = response.data;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "keyboard-mouse-data.xlsx";
        a.click();
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong, Please try after sometime.");
        setBtnLoading(false);
      });
  };

  return (
    <Fragment>
      <Row>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "30px",
          }}
        >
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            loading={btnLoading}
            onClick={download}
          >
            Download as excel
          </Button>
        </Col>
      </Row>

      <Table
        loading={loading}
        size="small"
        dataSource={data}
        columns={columns}
        pagination={{ pageSize: 50, showSizeChanger: false }}
        scroll={isMobileOnly ? { x: 500, y: 500 } : { y: "80vh" }}
      />
      {showModal && (
        <KeyboardMouseModal
          setVisible={setShowModal}
          editList={editList}
          refresh={refreshPage}
        />
      )}
    </Fragment>
  );
};

export default KeyboardMouseTable;
