import React from "react";
import { Row, Col } from "antd";

const SecurityCertificates = () => {
  return (
    <Row>
      <Col span={8}></Col>
    </Row>
  );
};

export default SecurityCertificates;
