import moment from "moment";
import { Link } from "react-router-dom";
import { defaultIssueStatus } from "./defaultKeys";
import { tableFilterDevices, akTableFilterDevices } from "./defaultKeys";

export const inventoryColumns = [
  {
    title: "Device Type",
    dataIndex: "inventory_type",
    key: "inventory_type",
    fixed: "left",
    filters: [...tableFilterDevices],
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value, record) => record.inventory_type.includes(value),
    render: (inventory_type, record) => (
      <span>
        {record?.inventory_type === "Sim"
          ? inventory_type + ": " + record.network
          : inventory_type}
      </span>
    ),
  },
  {
    title: "Requested Date",
    dataIndex: "created_at",
    key: "created_at",
    render: (date) => moment(date).format("YYYY-MM-DD"),
    sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
  },
  {
    title: "Requested Count",
    dataIndex: "count",
    key: "count",
    render: (count, record) => <span>{record?.count ? count : "-"}</span>,
  },
  {
    title: "Resolved By",
    dataIndex: "resolved_by_first_name",
    key: "resolved_by_first_name",
    render: (name, record) => (
      <span>
        {record?.resolved_by_first_name !== null
          ? name + record.resolved_by_last_name
          : "-"}
      </span>
    ),
  },
  {
    title: "Resolution Status",
    dataIndex: "resolution_status",
    key: "resolution_status",
    render: (status, record) => (
      <span>{record?.resolution_status ? status : "-"}</span>
    ),
  },
  {
    title: "Last Updated",
    dataIndex: "updated_at",
    key: "updated_at",
    render: (date) => moment(date).format("YYYY-MM-DD"),
    sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
  },
];

export const simColumns = [
  {
    title: "Mobile Number",
    dataIndex: "old_sim_number",
    key: "old_sim_number",
  },
  {
    title: "New SIM Number",
    dataIndex: "new_sim_number",
    key: "new_sim_number",
  },
  {
    title: "Carrier",
    dataIndex: "network",
    key: "network",
  },
  {
    title: "Requested Date",
    dataIndex: "created_at",
    key: "created_at",
    render: (date) => moment(date).format("YYYY-MM-DD"),
    sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
  },
  {
    title: "Resolved By",
    dataIndex: "resolved_by_first_name",
    key: "resolved_by_first_name",
    render: (name, record) => (
      <span>
        {record?.resolved_by_first_name !== null
          ? name + record.resolved_by_last_name
          : "-"}
      </span>
    ),
  },
  {
    title: "Resolution Status",
    dataIndex: "resolution_status",
    key: "resolution_status",
    render: (status, record) => (
      <span>{record?.resolution_status !== null ? status : "-"}</span>
    ),
  },
  {
    title: "Last Updated",
    dataIndex: "updated_at",
    key: "updated_at",
    render: (date) => moment(date).format("YYYY-MM-DD"),
    sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
  },
];

export const lostDeviceColumns = [
  {
    title: "Device Type",
    dataIndex: "inventory_type",
    key: "inventory_type",
    fixed: "left",
    filters: [...tableFilterDevices, ...akTableFilterDevices],
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value, record) => record.inventory_type.includes(value),
  },
  {
    title: "Device ID",
    dataIndex: "device_id",
    key: "device_id",
    render: (id, record) => <span>{record?.device_id ? id : "-"}</span>,
  },
  {
    title: "Device Make",
    dataIndex: "make",
    key: "make",
    render: (make, record) => <span>{record?.make ? make : "-"}</span>,
  },
  {
    title: "Device Model",
    dataIndex: "model",
    key: "model",
    render: (model, record) => <span>{record?.model ? model : "-"}</span>,
  },
  {
    title: "Issue Raised Date",
    dataIndex: "created_at",
    key: "created_at",
    render: (date) => moment(date).format("YYYY-MM-DD"),
    sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
  },
  {
    title: "Issue Raised",
    dataIndex: "created_by",
    key: "created_by",
    render: (user, record) => <span>{record?.created_by ? user : "-"}</span>,
  },
];

export const reopenColumns = [
  {
    title: "Issue Id",
    dataIndex: "ticket_id",
    key: "ticket_id",
    width: 150,
    fixed: true,
    render: (name, data) => (
      <Link to={`/issues/${data.ticket_id}/`}>{name}</Link>
    ),
  },
  {
    title: "Issue Status",
    dataIndex: "ticket_status",
    key: "ticket_status",
    width: 150,
    filters: [
      ...defaultIssueStatus,
      {
        text: "Reopened",
        value: "Re-Opened",
      },
      {
        text: "Waiting on user",
        value: "waiting-on-user",
      },
    ],
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value, record) => record.ticket_status.includes(value),
  },
  {
    title: "Resolution Status",
    dataIndex: "resolved_status",
    key: "resolved_status",
    width: 170,
    render: (data, record) => (
      <span>{record.resolved_status ? data : "-"}</span>
    ),
    filters: [
      {
        text: "Not Under Warranty",
        value: "NotUnderWarranty",
      },
      {
        text: "Replaced",
        value: "Replaced",
      },
      {
        text: "Repaired And Fixed",
        value: "RepairedAndFixed",
      },
      {
        text: "Not An Issue",
        value: "NotAnIssue",
      },
    ],
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value, record) => record?.resolved_status?.includes(value),
  },
  {
    title: "Device Type",
    dataIndex: "inventory_type",
    key: "inventory_type",
    width: 130,
    filters: [...tableFilterDevices, ...akTableFilterDevices],
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value, record) => record.inventory_type.includes(value),
  },
  {
    title: "Device Make",
    dataIndex: "device_make",
    key: "device_make",
    width: 140,
    sorter: (a, b) => {
      a = a.device_make || "";
      b = b.device_make || "";
      return a.localeCompare(b);
    },
    render: (data, record) => <span>{record.device_make ? data : "-"}</span>,
  },
  {
    title: "Device Modal",
    dataIndex: "device_model",
    key: "device_model",
    width: 150,
    sorter: (a, b) => {
      a = a.device_model || "";
      b = b.device_model || "";
      return a.localeCompare(b);
    },
    render: (data, record) => <span>{record.device_model ? data : "-"}</span>,
  },
  {
    title: "Device Id",
    dataIndex: "device_id",
    key: "device_id",
    width: 130,
    sorter: (a, b) => a.device_id - b.device_id,
    render: (data, record) => <span>{record.device_id ? data : "-"}</span>,
  },
  {
    title: "Issue Resolved By",
    dataIndex: "resolved_by_name",
    key: "resolved_by_name",
    width: 160,
    render: (data, record) => (
      <span>{record.resolved_by_name ? data : "-"}</span>
    ),
  },
  {
    title: "Issue Closed",
    dataIndex: "updated_at",
    key: "updated_at",
    width: 150,
    render: (date, record) => (
      <span>
        {record.ticket_status === "Closed"
          ? moment(date).format("YYYY-MM-DD")
          : "-"}
      </span>
    ),
    sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
  },
  {
    title: "Remarks",
    dataIndex: "comments",
    key: "comments",
    width: 250,
    render: (data, record) => <span>{record.comments ? data : "-"}</span>,
  },
];

export const highVoltageColumns = [
  {
    title: "Voltage",
    dataIndex: "voltage",
    key: "voltage",
  },
  {
    title: "Issue Status",
    dataIndex: "resolved",
    key: "resolved",
    render: (_, record) => (
      <span>{record?.resolved ? "Resolved" : "Not Resolved"}</span>
    ),
  },
  {
    title: "Raised By",
    dataIndex: "raised_by_first_name",
    key: "raised_by_first_name",
    render: (name, record) => (
      <span>
        {record?.raised_by_first_name &&
          name + " " + record?.raised_by_last_name}
      </span>
    ),
  },
];

export const office365Columns = [
  {
    title: "Issue Raised On",
    dataIndex: "created_at",
    key: "created_at",
    fixed: "left",
    render: (date) => moment(date).format("YYYY-MM-DD"),
    sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
  },
  {
    title: "Type Of Request",
    dataIndex: "type_of_request",
    key: "type_of_request",
  },
  {
    title: "Issue Status",
    dataIndex: "resolution_status",
    key: "resolution_status",
  },
  {
    title: "Raised By",
    dataIndex: "raised_by_user",
    key: "raised_by_user",
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    key: "remarks",
    width: 250,
    render: (data, record) => <span>{record.remarks ? data : "-"}</span>,
  },
  {
    title: "Resolved By",
    dataIndex: "resloved_by_user",
    key: "resloved_by_user",
    render: (name, record) => (
      <span>{record?.resloved_by_user ? name : "-"}</span>
    ),
  },
];

export const bsnlSimColumns = [
  {
    title: "Mobile Number",
    dataIndex: "old_sim_number",
    key: "old_sim_number",
    fixed: "left",
  },
  {
    title: "Requested Date",
    dataIndex: "created_at",
    key: "created_at",
    render: (date) => moment(date).format("YYYY-MM-DD"),
    sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
  },
  {
    title: "Resolved By",
    dataIndex: "resolved_by_first_name",
    key: "resolved_by_first_name",
    render: (name, record) => (
      <span>{record?.resolved_by_first_name ? name : "-"}</span>
    ),
  },
  {
    title: "Last Updated",
    dataIndex: "updated_at",
    key: "updated_at",
    render: (date) => moment(date).format("YYYY-MM-DD"),
    sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
  },
];
export const internetColumns = [
  {
    title: "Ticket Id",
    dataIndex: "ticket_id",
    key: "ticket_id",
    fixed: "left",
    render: (name, data) => (
      <Link to={`/internet-issues/${data.ticket_id}/`}>{name}</Link>
    ),
  },

  {
    title: "Issue Type",
    dataIndex: "issue_type",
    key: "issue_type",
    filterMode: "tree",
    filterSearch: true,
    filters: [
      {
        text: "No Internet",
        value: "no_internet",
      },
      {
        text: "Slow Internet",
        value: "slow_internet",
      },
      {
        text: "Location Changed",
        value: "location_changed",
      },
      {
        text: "Disconnected Due To Non Payment Of Bill",
        value: "disconnected_due_to_bill",
      },
      {
        text: "Other",
        value: "outher",
      },
    ],
    onFilter: (value, record) => record.issue_type.includes(value),
    render: (value) =>
      value === "no_internet"
        ? "No Internet"
        : value === "slow_internet"
        ? "Slow Internet"
        : value === "location_changed"
        ? "Location Changed"
        : value === "disconnected_due_to_bill"
        ? " Disconnected Due To Non Payment Of Bill"
        : value === "outher" && "Other",
  },
  {
    title: "Issue Status",
    dataIndex: "issue_status",
    key: "issue_status",

    filterMode: "tree",
    filterSearch: true,
    filters: [
      {
        text: "Open",
        value: "open",
      },
      {
        text: "Self Closed",
        value: "self_closed",
      },
      {
        text: "In Progress",
        value: "in_progress",
      },
      {
        text: "Waiting On User",
        value: "waiting_on_user",
      },
      {
        text: "Reopen",
        value: "re_open",
      },
      {
        text: "Closed",
        value: "closed",
      },
    ],
    onFilter: (value, record) => record.issue_status.includes(value),
    render: (value) =>
      value === "open"
        ? "Open"
        : value === "self_closed"
        ? "Self Closed"
        : value === "in_progress"
        ? "In Progress"
        : value === "waiting_on_user"
        ? "Waiting On User"
        : value === "re_open"
        ? "Reopen"
        : value === "closed" && "Closed",
  },
  {
    title: "GPON S/N",
    dataIndex: "gpon_serial_number",
    key: "gpon_serial_number",
  },
  {
    title: "Raised By",
    dataIndex: "raised_by",
    key: "raised_by",
  },
  {
    title: "Closed Date",
    dataIndex: "ticket_closed_date",
    key: "ticket_closed_date",
    // render: (date) => moment(date).format("YYYY-MM-DD"),
    sorter: (a, b) =>
      moment(a.ticket_closed_date).unix() - moment(b.ticket_closed_date).unix(),
  },
  {
    title: "Ticket Age",
    dataIndex: "ticket_age",
    key: "ticket_age",
    render: (value) => `${value} days`,
    sorter: (a, b) => a.ticket_age - b.ticket_age,
  },
  {
    title: "Vendor Remarks",
    dataIndex: "vendor_remarks",
    key: "vendor_remarks",
    render: (name, record) => (
      <span>{record?.vendor_remarks ? name : "-"}</span>
    ),
  },
  // {
  //   title: "Requested Date",
  //   dataIndex: "created_at",
  //   key: "created_at",
  //   render: (date) => moment(date).format("YYYY-MM-DD"),
  //   sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
  // },
  // {
  //   title: "Resolved By",
  //   dataIndex: "resolved_by_first_name",
  //   key: "resolved_by_first_name",
  //   render: (name, record) => (
  //     <span>{record?.resolved_by_first_name ? name : "-"}</span>
  //   ),
  // },
  // {
  //   title: "Last Updated",
  //   dataIndex: "updated_at",
  //   key: "updated_at",
  //   render: (date) => moment(date).format("YYYY-MM-DD"),
  //   sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
  // },
];

export const keyboardAndMouseColumns = [
  {
    title: "Issue Raised On",
    dataIndex: "created_at_date",
    key: "created_at_date",
    fixed: "left",
    width: 140,
    render: (date) => moment(date).format("YYYY-MM-DD"),
    sorter: (a, b) =>
      moment(a.created_at_date).unix() - moment(b.created_at_date).unix(),
  },
  {
    title: "Device Type",
    dataIndex: "inventory_type",
    key: "inventory_type",
    width: 150,
  },
  {
    title: "CPU Serial Number",
    dataIndex: "cpu_serial_number",
    key: "cpu_serial_number",
    width: 160,
  },
  {
    title: "Issue Status",
    dataIndex: "resolution_status",
    key: "resolution_status",
    width: 150,
  },
  {
    title: "Raised By",
    dataIndex: "raised_by_user",
    key: "raised_by_user",
    width: 150,
  },
  {
    title: "Front Side Image",
    dataIndex: "front_image",
    key: "front_image",
    width: 250,
    render: (photo, record) =>
      record?.front_image ? (
        <Link
          to={{ pathname: record?.front_image }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {photo}
        </Link>
      ) : (
        "-"
      ),
  },
  {
    title: "Back Side Image",
    dataIndex: "back_image",
    key: "back_image",
    width: 250,
    render: (photo, record) =>
      record?.back_image ? (
        <Link
          to={{ pathname: record?.back_image }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {photo}
        </Link>
      ) : (
        "-"
      ),
  },
  {
    title: "Issue Description",
    dataIndex: "issue",
    key: "issue",
    width: 250,
    render: (data, record) => <span>{record.issue ? data : "-"}</span>,
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    key: "remarks",
    width: 250,
    render: (data, record) => <span>{record.remarks ? data : "-"}</span>,
  },
  {
    title: "Resolved By",
    dataIndex: "resloved_by_user",
    key: "resloved_by_user",
    width: 150,
    render: (name, record) => (
      <span>{record?.resloved_by_user ? name : "-"}</span>
    ),
  },
];
