import React from "react";
import { Breadcrumb, Tag } from "antd";
import "../custom.css";
import "./RegionBreadCrumb.css";
import { useHistory } from "react-router-dom";

const RegionBreadCrumb = ({ bcrdata }) => {
  const history = useHistory();

  const navigation = (data) => {
    history.push(data.path);
  };

  return (
    <Breadcrumb separator=">">
      <Breadcrumb.Item
        style={{ cursor: "pointer" }}
        onClick={() =>
          navigation({
            path: `/state/dashboard`,
          })
        }
      >
        State
      </Breadcrumb.Item>
      {bcrdata?.district_id && (
        <Breadcrumb.Item
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigation({
              path: `/district/${bcrdata?.district_name}/${bcrdata?.district_id}`,
            })
          }
        >
          <Tag color="blue"> {bcrdata?.district_name}</Tag>
          <span className="region-subtext">District</span>
        </Breadcrumb.Item>
      )}
      {bcrdata?.mandal_id && (
        <Breadcrumb.Item
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigation({
              path: `/mandal/${bcrdata?.mandal_name}}/${bcrdata?.mandal_id}/`,
            })
          }
        >
          <Tag color="green"> {bcrdata?.mandal_name}</Tag>
          <span className="region-subtext">Mandal</span>
        </Breadcrumb.Item>
      )}
      {bcrdata?.village_secretariat_code && (
        <Breadcrumb.Item>
          <Tag color="purple">{bcrdata?.village_secretariat_name}</Tag>
          <span className="region-subtext">
            Code: {bcrdata?.village_secretariat_code}
          </span>
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};

export default RegionBreadCrumb;
