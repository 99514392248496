import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Tag,
  Typography,
  Divider,
  Modal,
  Result,
} from "antd";
import { CloseCircleOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import { TOKEN_KEY, USER } from "../../constants/defaultKeys";
import { clear, setItem, getItem } from "../../helpers/localStorage";
import Card from "../../blocks/Card/Card";
import Logo from "../../assets/images/Editedlogo.png";
import * as config from "../../config/validation.config";
import {
  mobileLogin,
  generateCaptcha,
  getEmailCaptchaAPI,
  refreshEmailCaptchaAPI,
  verifyMobileAPI,
} from "../../services/auth.services";
import { isMobileOnly } from "react-device-detect";
import EmailLogin from "./EmailLogin";
import { SyncOutlined } from "@ant-design/icons";
import "./Login.css";
const { Paragraph } = Typography;

const Login = () => {
  const history = useHistory();
  const userData = JSON.parse(getItem(USER));
  const [showModal, setShowModal] = useState(false);
  const [showEmailLogin, setShowEmailLogin] = useState(true);
  const [showRequestLogin, setShowRequestLogin] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [mobileDetails, setMobileDetails] = useState(null);
  const [captchaVal, setCaptchaVal] = useState(null);
  const [addMobileCaptcha, setAddMobileCaptcha] = useState(false);
  const [showMobileView, setShowMobileView] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);
  const formRef = useRef();
  const [form] = Form.useForm();

  useEffect(() => {
    if (userData !== null)
      if (
        userData.region === null &&
        !(
          userData.is_vendor ||
          userData?.is_aek_hod_dept ||
          userData?.is_aek_hod
        )
      ) {
        history.push(`/state/dashboard`);
      } else {
        if (userData.is_village_secretariat) {
          history.push(
            `/village-secretariat/${userData.region?.region_name}/${userData.region.region_id}/`
          );
        }
        if (userData.is_mandal_authority)
          history.push(
            `/mandal/${userData.region?.region_name}/${userData.region.region_id}/`
          );
        if (userData.is_district_authority || userData.is_indent_authority)
          history.push(
            `/district/${userData.region?.region_name}/${userData.region.region_id}/`
          );
        if (
          userData?.is_welfare_and_edu_assistant
          // || userData?.is_ward_welfare_data_processing_secretary
        )
          history.push(`/raise-tab-ticket/${userData.region.region_id}`);
        if (userData.is_admin) history.push(`/state/dashboard`);
        if (userData.is_vendor) history.push(`/vendor`);
        if (
          userData?.is_aek_hod_dept ||
          userData?.is_aek_dc ||
          userData?.is_aek_hod ||
          userData?.is_uidai
        )
          history.push(`/aek-vendor`);
        if (userData.is_telecom_operator) history.push(`/sim-requests`);
        if (userData.is_uniform_supplier) history.push(`/uniform-vendor`);
        if (userData.is_office365_vendor) history.push(`/office-365`);
        if (userData.is_internet_vendor) history.push(`/internet-vendor`);
      }
  }, []);

  useEffect(() => {
    if (captchaVal) {
      var captchaImg = document.getElementById("source");
      var image = new Image();
      image.src = captchaImg.innerHTML || "";
      image.setAttribute("id", `captcha0`);
      insertAfter(captchaImg, image);
    }
  }, [captchaVal]);

  function insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  const onFinish = (values) => {
    setButtonLoading(true);
    clear();
    if (captchaVal) {
      console.log("VALUES--->", {
        contact_number: values?.username,
        captcha_code: values?.captcha,
        token: captchaToken,
      });
      verifyMobileAPI({
        contact_number: values?.username,
        captcha_code: values?.captcha,
        token: captchaToken,
      })
        .then((response) => {
          setButtonLoading(false);
          const data = response.data;
          setItem(TOKEN_KEY, data.token);
          setItem(USER, JSON.stringify(data));
          setItem("captcha_token", data.captcha_token);
          setItem("captcha_code", data.captcha_code);

          history.push("/verify-otp");
        })
        .catch((error) => {
          console.log(error?.response);
          refreshEmailCaptcha();
          setButtonLoading(false);
          if (error?.response?.status === 404) {
            setShowRequestLogin({
              message: error.response.data.message,
              status: true,
            });
          } else if (error && error.response) {
            message.error(
              error.response.message || error.response.data.message
            );
          } else {
            message.error("Something went wrong, Please try after sometime");
          }
        });
    } else {
      generateCaptcha({ username: values?.username })
        .then((response) => {
          setButtonLoading(false);
          setMobileDetails(values?.username);
          setCaptchaVal(response?.data?.captcha);
        })
        .catch((err) => {
          document.getElementById(`captcha0`).remove();
          setCaptchaVal(err?.response?.data?.captcha);
          setButtonLoading(false);
          err.response?.data?.message &&
            message.info(err.response?.data?.message);
          console.log(err);
          form.resetFields(["username", "captcha"]);
        });
    }
  };

  // async function getCaptcha() {
  //   try {
  //     const res = await generateCaptcha({ username: mobileDetails });
  //     document.getElementById(`captcha0`).remove();
  //     setCaptchaVal(res.data.captcha);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  useEffect(() => {
    if (!showEmailLogin)
      getEmailCaptchaAPI()
        .then((res) => {
          console.log(res);
          setCaptchaVal(res.data.captcha);
          setCaptchaToken(res.data.token);
          // setAddMobileCaptcha(true);
        })
        .catch((err) => console.log(err));
  }, [showEmailLogin]);

  async function refreshEmailCaptcha() {
    try {
      form.resetFields(["captcha"]);
      const res = await refreshEmailCaptchaAPI({
        token: captchaToken,
      });
      document.getElementById(`captcha0`).remove();
      setCaptchaVal(res.data.captcha);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Fragment>
      <Row type="flex" justify="center" align="middle" className="login">
        <Col
          lg={{ span: 8, offset: 8 }}
          md={{ span: 12, offset: 6 }}
          sm={{ span: 20, offset: 2 }}
          xs={{ span: 20, offset: 2 }}
          style={{ marginLeft: 0 }}
        >
          {!showEmailLogin ? (
            <Card bordered={false}>
              <div style={{ textAlign: "center", paddingBottom: "20px" }}>
                {isMobileOnly ? (
                  <img src={Logo} alt="check logo" height="48px" />
                ) : (
                  <img src={Logo} alt="check logo" height="72px" />
                )}
              </div>

              <Fragment>
                <Form
                  form={form}
                  ref={formRef}
                  onFinish={onFinish}
                  name="login-with-opt"
                >
                  <Paragraph>Enter the mobile number for login</Paragraph>
                  <Form.Item name="username" rules={config.mobileNumberRegex}>
                    <Input
                      autoFocus={true}
                      autoComplete="off"
                      size="large"
                      maxLength="10"
                      placeholder="Mobile Number"
                      addonBefore={"+91"}
                    />
                  </Form.Item>
                  <div>
                    <span id="source" style={{ display: "none" }}>
                      {"data:image/png;base64," + captchaVal}
                    </span>
                    <SyncOutlined
                      style={{ fontSize: "22px", marginLeft: "12px" }}
                      onClick={refreshEmailCaptcha}
                    />
                  </div>
                  <Form.Item
                    rules={[{ required: true, message: "Required" }]}
                    name="captcha"
                  >
                    <Input
                      placeholder="Enter captcha"
                      style={{ padding: "10px", marginTop: "5px" }}
                    />
                  </Form.Item>
                  <>
                    <Form.Item>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        block
                        className="login-form-button"
                        loading={buttonLoading}
                      >
                        {!showMobileView ? "Send OTP" : "submit"}
                      </Button>
                    </Form.Item>
                  </>
                  <Divider orientation="center">Or</Divider>
                  <div
                    style={{ textAlign: "center" }}
                    className="footer-nav-links"
                    onClick={() => {
                      setCaptchaVal(null);
                      form.resetFields();
                      setShowEmailLogin(true);
                    }}
                  >
                    Email Login
                  </div>
                </Form>

                {showRequestLogin?.status && (
                  <div
                    style={{ textAlign: "center" }}
                    className="request-login-message"
                  >
                    <Tag icon={<CloseCircleOutlined />} color="error">
                      {showRequestLogin?.message}
                    </Tag>
                    <br />
                    <Link to="/request-for-login" className="footer-links">
                      Request for a new account
                    </Link>
                  </div>
                )}
              </Fragment>
            </Card>
          ) : (
            <EmailLogin setShowEmailLogin={setShowEmailLogin} />
          )}
          <Row>
            <Col span={24} className="instructions">
              <a
                href="https://www.youtube.com/watch?v=VL__2qt8TeI&feature=youtu.be"
                target="_blank"
                rel="noopener noreferrer"
              >
                <VideoCameraOutlined /> Watch this video for instructions
              </a>
            </Col>
          </Row>
          {/* <div
            style={{
              marginTop: "10px",
              color: "#ffffff",
              fontWeight: "500",
              background: "rgba(0,0,0,0.4)",
              padding: "10px 5px",
            }}
          >
            ఈ వెబ్సైట్ లో లాగిన్ అవ్వటానికి మీ ఫోన్ నెంబర్ VSWS డేటాబేస్ లో రిజిస్టర్ అయ్యి ఉండాలి.
            మీ సచివాలయం లాగిన్ కి ఏ మొబైల్ నెంబర్ మ్యాప్ అయిందో తెలుసుకోవటానికి{" "}
            <Link to="/check-login-number" className="footer-links">
              ఇక్కడ క్లిక్ చెయ్యండి.{" "}
            </Link>
            లేదా కొత్త అకౌంట్ రిక్వెస్ట్ చెయ్యటానికి{" "}
            <Link to="/request-for-login" className="footer-links">
              ఇక్కడ క్లిక్ చెయ్యండి.
            </Link>
          </div> */}

          {showModal && (
            <Modal open={showModal} footer={false} closable={false}>
              <Result
                status="warning"
                title={
                  <p>
                    {
                      "Oops! It seems you're already logged in from another device. Please log out from the other device before attempting to log in again."
                    }
                  </p>
                }
                extra={
                  <>
                    <Button
                      type="default"
                      onClick={() => {
                        setShowModal(false);
                        setButtonLoading(false);
                        // setShowEmailLogin(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        formRef?.current?.submit();
                      }}
                    >
                      Continue
                    </Button>
                  </>
                }
              />
            </Modal>
          )}
        </Col>
      </Row>
    </Fragment>
  );
};
export default Login;
