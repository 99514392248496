import React, { Fragment, useState } from "react";
import { Form, Button, DatePicker, message } from "antd";
import {
  vendorIssuesFileDownload,
  stateIssuesFileDownload,
  downloadDistrictInventoryRequests,
} from "../../services/downloadFile.services";

const FileDownloadWithFilters = ({ setVisible, editList, id, aekUsers }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const fileDownload =
    editList.key === "state"
      ? stateIssuesFileDownload
      : editList.key === "indent"
      ? downloadDistrictInventoryRequests
      : vendorIssuesFileDownload;

  function disabledDate(current) {
    return current && current.valueOf() > Date.now();
  }

  const onFinish = (values) => {
    setBtnLoading(true);

    const formData = {
      ...values,
      start_date: values["start_date"].format("YYYY-MM-DD"),
      end_date: values["end_date"].format("YYYY-MM-DD"),
    };
    {
      editList.key === "indent"
        ? fileDownload(id, {
            start_date: formData.start_date,
            end_date: formData.end_date,
          })
            .then((response) => response.data)
            .then((blob) => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = url;
              a.download = "secretariat-inventory.xlsx";
              a.click();
              setBtnLoading(false);
              setVisible(false);
            })
            .catch((error) => {
              console.log(error);
              setBtnLoading(false);
              message.error("Something went wrong, Please try after sometime.");
            })
        : fileDownload({
            start_date: formData.start_date,
            end_date: formData.end_date,
            filter_ak: aekUsers ? "-AK" : "",
          })
            .then((response) => response.data)
            .then((blob) => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = url;
              a.download = "tickets.xlsx";
              a.click();
              setBtnLoading(false);
              setVisible(false);
            })
            .catch((error) => {
              console.log(error);
              setBtnLoading(false);
              message.error("Something went wrong, Please try after sometime.");
            });
    }
  };

  return (
    <Fragment>
      <Form
        name="basic"
        onFinish={onFinish}
        layout="vertical"
        style={{ marginTop: "20px" }}
      >
        <Form.Item
          name="start_date"
          label="Start Date"
          rules={[{ required: true, message: "Please choose start date" }]}
        >
          <DatePicker size="large" disabledDate={disabledDate} />
        </Form.Item>

        <Form.Item
          name="end_date"
          label="End Date"
          rules={[{ required: true, message: "Please choose end date" }]}
        >
          <DatePicker size="large" disabledDate={disabledDate} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={btnLoading}
          >
            {btnLoading ? "Downloading..." : "Download as excel"}
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default FileDownloadWithFilters;
