export const districtAndAssembly = {
  ANANTAPUR: [
    "PUTTAPARTHI",
    "HINDUPUR",
    "KADARI",
    "URAVAKONDA",
    "ANANTAPUR",
    "RAYADURG",
    "MADAKASIRA (SC)",
    "TADIPATRI",
    "PENUKONDA",
    "GUNTAKAL",
    "RAPTADU",
    "SINGANAMALA (SC)",
    "KALYANDURG",
    "DHARMAVARAM",
    
  ],

  CHITTOOR: [
    "CHANDRAGIRI",
    "THAMALLAPALLE",
    "KUPPAM",
    "PUTHALAPATTU (SC)",
    "GANGADHARANELLORE (SC)",
    "TIRUPATI",
    "NAGARI",
    "CHITOOR",
    "SRIKALAHASTI",
    "MADANAPALLE",
    "PUNGANUR",
    "SATYAVEDU (SC)",
    "PALAMANER",
    "PILERU",
  ],

  "EAST GODAVARI": [
    "KAKINADA CITY",
    "RAMPACHODAVARAM (ST)",
    "GANNAVARAM (SC)",
    "KOTHAPETA",
    "AMALAPURAM (SC)",
    "RAZOLE (SC)",
    "PITHAPURAM",
    "RAJAHMUNDRY CITY",
    "PEDDAPURAM",
    "JAGGAMPETA",
    "ANAPARTHY",
    "KAKINADA RURAL",
    "RAJANAGARAM",
    "MANDAPETA",
    "PRATHIPADU",
    "RAJAHMUNDRY RURAL",
    "RAMACHENDRAPURAM",
    "MUMMIDIVARAM",
    "TUNI",
  ],
  GUNTUR: [
    "TADIKONDA (SC)",
    "GUNTUR WEST",
    "REPALLE",
    "GUNTUR EAST",
    "VINUKONDA",
    "MACHERLA",
    "PRATHIPADU (SC)",
    "SATTENAPALLE",
    "MANGALAGIRI",
    "BAPATLA",
    "CHILAKALURIPET",
    "GURAJALA",
    "PONNUR",
    "VEMURU (SC)",
    "TENALI",
    "PEDAKURAPADU",
    "NARASARAOPET",
  ],

  KRISHNA: [
    "AVANIGADDA",
    "Viajayawada west",
    "PENAMALURU",
    "KAIKALUR",
    "Viajayawada EAST",
    "NUZVID",
    "NANDIGAMA (SC)",
    "TIRUVURU (SC)",
    "Gannavaram",
    "Viajayawada cenral",
    "MYLAVARAM",
    "PAMARRU (SC)",
    "PEDANA",
    "GUDIVADA",
    "MACHILIPATNAM",
    "JAGGAYYAPETA",
  ],

  KURNOOL: [
    "BANAGANAPALLE",
    "SRISAILAM",
    "PATTIKONDA",
    "ADONI",
    "DHONE",
    "MANTRALAYAM",
    "NANDYAL",
    "YEMMIGANUR",
    "ALLAGADDA",
    "KODUMURU (SC)",
    "ALUR",
    "KURNOOL",
    "NANDIKOTKUR (SC)",
    "PANYAM",
  ],

  PRAKASAM: [
    "ONGOLE",
    "DARSI",
    "KANDUKUR",
    "MARKAPURAM",
    "KANIGIRI",
    "YERRAGONDAPALEM (SC)",
    "SANTANUTHALAPADU (SC)",
    "GIDDALUR",
    "KONDAPI (SC)",
    "CHIRALA",
    "PARCHUR",
    "ADDANKI",
  ],
  "SPSR NELLORE": [
    "SULLURUPETA (SC)",
    "SARVEPALLI",
    "NELLORE RURAL",
    "ATMAKUR",
    "Nellore City",
    "VENKATAGIRI",
    "GUDUR (SC)",
    "KOVURU",
    "UDAYAGIRI",
    "KAVALI",
  ],
  SRIKAKULAM: [
    "ETCHERLA",
    "PATHAPATNAM",
    "AMADALAVALASA",
    "TEKKALI",
    "PALAKONDA (ST)",
    "SRIKAKULAM",
    "RAJAM (SC)",
    "NARASANNAPETA",
    "ICHCHAPURAM",
    "PALASA",
  ],
  VISAKHAPATANAM: [
    "VISHAKAPATNAM SOUTH",
    "PAYAKARAOPET (SC)",
    "VISHAKAPATNAM EAST",
    "MADUGULA",
    "GAJUWAKA",
    "PENDURTHI",
    "ARAKU VALLEY (ST)",
    "VISHAKAPATNAM NORTH",
    "NARSIPATNAM",
    "CHODAVARM",
    "PADERU (ST)",
    "YLAMANCHILI",
    "ANAKAPALLE",
    "VISHAKAPATNAM WEST",
    "BHIMILI",
  ],
  VIZIANAGARAM: [
    "KURUPAM (ST)",
    "NELLIMARLA",
    "CHEPURUPALLE",
    "SRUNGAVARAPUKOTA",
    "PARVATHIPURAM (SC)",
    "SALUR (ST)",
    "GAJAPATHINAGARAM",
    "BOBBILI",
    "VIZIANAGARM",
  ],
  "WEST GODAVARI": [
    "NARSAPURAM",
    "POLAVARAM (ST)",
    "GOPALAPURAM (SC)",
    "ACHANTA",
    "UNDI",
    "UNGUTUR",
    "TADEPALLIGUDEM",
    "PALACOLE",
    "NIDADAVOLE",
    "ELURU",
    "TANUKU",
    "DENDULURU",
    "CHINTALAPUDI (SC)",
    "BHIMAVARAM",
    "KOVVUR (SC)",
  ],

  "Y S R Kadapa": [
    "RAYACHOTI",
    "PRODDATUR",
    "KADAPA",
    "JAMMALAMADUGU",
    "RAJAMPET",
    "MYDUKUR",
    "BADVEL (SC)",
    "KODUR (SC)",
    "PULIVENDLA",
    "KAMALAPURAM",
  ],
};
