import React, { Fragment } from "react";
import { Row, Col, Statistic, Progress, Skeleton } from "antd";
import Card from "../../blocks/Card/Card";

const MandalStatsCards = ({ loading, data }) => {
  return (
    <Fragment>
      <Row gutter={[16, 16]}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 4 }}
          lg={{ span: 4 }}
        >
          <Card title="Overall Health" className="device-health">
            {loading ? (
              <Skeleton active paragraph={{ rows: 1 }} />
            ) : (
              <div style={{ textAlign: "center" }}>
                <Progress
                  type="dashboard"
                  strokeColor={{
                    "0%": "#108ee9",
                    "100%": "#87d068",
                  }}
                  width={80}
                  strokeWidth={14}
                  percent={data?.device_health?.toFixed(0) || 0}
                />
              </div>
            )}
          </Card>
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
        >
          <Card title="Device Issues" className="device-issues">
            {loading ? (
              <Skeleton active paragraph={{ rows: 1 }} />
            ) : (
              <Row
                gutter={16}
                type="flex"
                align="middle"
                style={{ textAlign: "center" }}
              >
                <Col span={12}>
                  <Statistic
                    title="Pending"
                    value={data?.inventory_issues?.pending}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Resolved"
                    value={data?.inventory_issues?.resolved}
                  />
                </Col>
              </Row>
            )}
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 6 }}
          lg={{ span: 6 }}
        >
          <Card
            title="Secured Base Certificates"
            className="security-certificates"
          >
            {loading ? (
              <Skeleton active paragraph={{ rows: 1 }} />
            ) : (
              <Row
                gutter={16}
                type="flex"
                align="middle"
                style={{ textAlign: "center" }}
              >
                <Col span={12}>
                  <Statistic
                    title="Remaining"
                    value={data?.security_certificates?.remaining}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Damaged"
                    value={data?.security_certificates?.damaged}
                  />
                </Col>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default MandalStatsCards;
