import React, { useState, useEffect, Fragment } from "react";
import {
  Descriptions,
  Row,
  Col,
  Divider,
  Skeleton,
  Typography,
  Badge,
  Result,
  Button,
  message,
} from "antd";
import { getItem } from "../../../helpers/localStorage";
import { USER } from "../../../constants/defaultKeys";
import Card from "../../../blocks/Card/Card";
import { useParams, useHistory } from "react-router-dom";
import {
  aekVendorServiceRequestUpdate,
  serviceRequestTicketInfo,
} from "../../../services/serviceRequests.services";
import moment from "moment";
import AekVendorForm from "./AekVendorForm";
const { Title } = Typography;

const AekIssues = ({ params }) => {
  const query = useParams();
  const history = useHistory();
  const userDetails = JSON.parse(getItem(USER));
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setLoading(true);
    serviceRequestTicketInfo(query?.token ? query?.token : params)
      .then((response) => {
        setData(response?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh, params]);
  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };
  const deviceStatus = (status) => {
    switch (status) {
      case "in-progress":
        return <Badge color="orange" status="processing" text="In Progress" />;
      case "forward-to-UIDAI":
        return <Badge color="yellow" text="Forwarded" />;
      case "closed":
        return <Badge color="green" text="Closed" />;
      //   case "Re-Opened":
      //     return <Badge color="red" text="ReOpened" />;
      case "waiting-on-user":
        return <Badge color="cyan" text="Waiting On User" />;
      default:
        return <Badge color="blue" status="default" text="Open" />;
    }
  };
  return (
    <Fragment>
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          md={{ span: 18, offset: 3 }}
          lg={{ span: 18, offset: 3 }}
        >
          {loading ? (
            <Skeleton />
          ) : data ? (
            <Card
              title={
                <Fragment>
                  <Title level={4}>{data?.ticket_id}</Title>
                  <span style={{ fontSize: "12px" }}>
                    {deviceStatus(data?.issue_status)}
                  </span>
                </Fragment>
              }
              className="issues-card"
            >
              <Descriptions>
                <Descriptions.Item label="Station Id">
                  {data?.station_id}
                </Descriptions.Item>
                <Descriptions.Item label="User Id">
                  {data?.user_id ? data.user_id : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Machine Id">
                  {data?.machine_id}
                </Descriptions.Item>
                <Descriptions.Item label="AnyDesk ID">
                  {data?.anydesk_id}
                </Descriptions.Item>
                <Descriptions.Item label="Created At">
                  {moment(data && data.created_at).format("YYYY-MM-DD")}
                </Descriptions.Item>
                <Descriptions.Item label="Updated At">
                  {moment(data && data.updated_at).format("YYYY-MM-DD")}
                </Descriptions.Item>
                <Descriptions.Item label="Enrollment Code">
                  {data?.enrolment_code}
                </Descriptions.Item>
                <Descriptions.Item label="Register Code">
                  {data?.register_code}
                </Descriptions.Item>
                <Descriptions.Item label="RO Name">
                  {data?.ro_name}
                </Descriptions.Item>
                <Descriptions.Item label="Issue Description">
                  {data?.issue_description}
                </Descriptions.Item>
                <Descriptions.Item label="Log File">
                  {data?.log_file}
                </Descriptions.Item>
                <Descriptions.Item label="Resolved By">
                  {data?.resolved_by}
                </Descriptions.Item>
                <Descriptions.Item label="District Name">
                  {data?.district_name
                    ? data?.district_name
                    : data?.dc_district_name}
                </Descriptions.Item>
                <Descriptions.Item label="District Code">
                  {data?.district_code
                    ? data?.district_code
                    : data?.dc_district_code}
                </Descriptions.Item>
                <Descriptions.Item label="Mandal Name">
                  {data?.mandal_name ? data?.mandal_name : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Mandal Code">
                  {data?.mandal_code ? data?.mandal_code : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Secretariat Name">
                  {data?.secretariat_name ? data?.secretariat_name : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Village Secretariat Code">
                  {data?.secretariat_code ? data?.secretariat_code : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="DA Name">
                  {data?.da_name}
                </Descriptions.Item>
                <Descriptions.Item label="DA Mail">
                  {data?.da_mail}
                </Descriptions.Item>
                <Descriptions.Item label="DA Contact Number">
                  {data?.da_contact_number}
                </Descriptions.Item>
                <Descriptions.Item label="DC Contact Number">
                  {data?.dc_contact_number ? data?.dc_contact_number : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="SC Contact Number">
                  <ul>
                    {data?.sc_contact_number.map((number) => (
                      <li>{`${number?.username} (${number?.first_name})`}</li>
                    ))}
                  </ul>
                </Descriptions.Item>

                {/* {userDetails?.is_vendor && (
                  <>
                    <Descriptions.Item label=" Ticket Raised By">
                      {data?.raised_by_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Contact Person Mobile">
                      {data?.ticket_contact_person_mobile}
                    </Descriptions.Item>
                    <Descriptions.Item label="Alternate Mobile">
                      {data?.ticket_contact_person_alternate_mobile
                        ? data.ticket_contact_person_alternate_mobile
                        : "-"}
                    </Descriptions.Item>

                    <Descriptions.Item label="Contact Person Email">
                      {data?.ticket_contact_person_email
                        ? data?.ticket_contact_person_email
                        : "-"}
                    </Descriptions.Item>
                  </>
                )} */}

                <Descriptions.Item label="Additional Details">
                  {data?.other_issue ? data?.other_issue : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Issues">
                  <ul>
                    {data?.issues.map((issue) => (
                      <li>{issue.replaceAll("_", " ")}</li>
                    ))}
                  </ul>
                </Descriptions.Item>
                <Descriptions.Item label="Reference Image">
                  {data?.file_ref === "undefined" ||
                  data?.file_ref === "" ||
                  data?.file_ref === null ? (
                    "-"
                  ) : (
                    <a href={data?.file_ref} target="_blank" rel="noopener">
                      {data?.file_ref}
                    </a>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Vendor Remarks">
                  {data?.v_remarks ? data.v_remarks : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Vendor Doc">
                  {data?.v_doc === "undefined" ||
                  data?.v_doc === "" ||
                  data?.v_doc === null ? (
                    "-"
                  ) : (
                    <a href={data?.v_doc} target="_blank" rel="noopener">
                      {data?.v_doc}
                    </a>
                  )}
                </Descriptions.Item>
              </Descriptions>
              {(userDetails?.is_aek_dc ||
                userDetails?.is_aek_hod ||
                userDetails?.is_aek_hod_dept ||
                userDetails?.is_uidai) &&
                data?.issue_status !== "closed" && (
                  <>
                    <Divider orientation="left">Update Ticket Status</Divider>
                    <AekVendorForm
                      data={data}
                      query={query?.token}
                      params={params}
                      refresh={refreshPage}
                    />
                  </>
                )}
            </Card>
          ) : (
            <Card>
              <Result
                status="warning"
                title="No data found with this Ticket ID"
                extra={
                  <Button
                    type="primary"
                    onClick={params ? `/aek-vendor` : () => history.goBack()}
                  >
                    Go Back
                  </Button>
                }
              />
            </Card>
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

export default AekIssues;
