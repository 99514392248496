import React, { Fragment, useEffect, useState, useMemo } from "react";
import { Form, Button, Select, Tag, message, Result, Typography } from "antd";
import { akDevices, devices } from "../../../constants/devices";
import { getVSInventory } from "../../../services/inventory.services";
import { AddLostInventory } from "../../../services/serviceRequests.services";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";

const { Option } = Select;
const { Text } = Typography;

const LostDevices = ({ id }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const aadharDevices = data?.aadhar_kits_supplied ? akDevices : null;
  const allDevices = { ...devices, ...aadharDevices };
  const [formValues, setFormValues] = useState(null);
  const userDetails = JSON.parse(getItem(USER));
  const selectedDeviceData = useMemo(() => {
    if (data && formValues) {
      const filterData = data.filter(
        (item) => item.inventory_type === formValues?.inventory_type
      );
      return [
        ...filterData,
        {
          id: "no_serial_number",
          inventory_type: formValues?.inventory_type,
          device_id: "No Serial Number",
        },
      ];
    }
  }, [data, formValues]);

  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
    if (changedValues.inventory_type) {
      const inventoryType = changedValues.inventory_type;
      form.resetFields();
      form.setFieldsValue({
        inventory_type: inventoryType,
      });
      setFormValues({ ...changedValues });
    }
  };

  useEffect(() => {
    getVSInventory(id)
      .then((response) => {
        setData(response.data.inventory_data);
      })
      .catch((error) => console.log(error));
  }, [id]);

  const onFinish = (values) => {
    const formData =
      values.inventory_id === "no_serial_number"
        ? { ...values, inventory_id: null }
        : values;

    AddLostInventory(id, formData)
      .then((response) => {
        form.resetFields();
        message.success(response.data.message);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        console.log(error);
      });
  };
  return (
    <Fragment>
      <Form
        name="normal_login"
        className="login-form"
        layout="vertical"
        onValuesChange={onFormValuesChange}
        onFinish={onFinish}
        wrapperCol={{
          span: 10,
        }}
        colon={false}
        form={form}
      >
        <Form.Item
          label="Choose Device Types"
          name="inventory_type"
          rules={[
            {
              required: true,
              message: "Please choose device type",
            },
          ]}
        >
          <Select placeholder="Please choose device type" size="large">
            {Object.keys(allDevices).map((device) => (
              <Option value={device} key={device}>
                {device === "FPScanner"
                  ? "Finger Print Scanner"
                  : device === "LnMachine"
                  ? "Laminating Machine"
                  : device}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {formValues?.inventory_type === "Sim" ? (
          <Result
            title="Lost Sim process"
            extra={
              <Text>
                If your Sim is lost, please visit the respective network store
                then get an empty sim and request for activation in vsws portal.
              </Text>
            }
          />
        ) : (
          <>
            {formValues && (
              <Form.Item
                label="Choose Serial Number"
                name="inventory_id"
                rules={[
                  {
                    required: true,
                    message: "Please choose serial Number",
                  },
                ]}
              >
                <Select placeholder="Please choose serial number" size="large">
                  {selectedDeviceData.map(
                    (item) =>
                      item?.device_source == "provided_by_gsws_dept" && (
                        <Option value={item.id} key={item.id}>
                          {item.inventory_type}
                          <Tag color="purple" size="small">
                            {item.device_id}
                          </Tag>
                        </Option>
                      )
                  )}
                  <Option value="no_serial_number" key={"NoserialNumber"}>
                    {
                      selectedDeviceData[selectedDeviceData.length - 1]
                        .inventory_type
                    }{" "}
                    <Tag color="purple" size="small">
                      No Serial Number
                    </Tag>
                  </Option>
                </Select>
              </Form.Item>
            )}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                disabled={
                  userDetails?.is_village_secretariat &&
                  selectedDeviceData?.length !== 0
                    ? false
                    : true
                }
              >
                Report
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </Fragment>
  );
};
export default LostDevices;
