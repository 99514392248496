import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Table,
  Space,
  DatePicker,
  Button,
  Menu,
  Dropdown,
  Input,
  message,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { DownloadOutlined, DownOutlined } from "@ant-design/icons";
import {
  defaultIssueStatus,
  tableFilterDevices,
  akTableFilterDevices,
} from "../../../constants/defaultKeys";
import VendorTableFilter from "../../Vendor/VendorTable/VendorTableFilter";
import { downloadIssuesForDistrict } from "../../../services/downloadFile.services";
import moment from "moment";

const { RangePicker } = DatePicker;
function TicketsTable({ districtId, tableData, tableLoading, aekUsers }) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const deviceFilters = aekUsers
    ? akTableFilterDevices
    : [...akTableFilterDevices, ...tableFilterDevices];
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleDownload = () => {
    setBtnLoading(true);
    downloadIssuesForDistrict(districtId, { filter_ak: aekUsers ? "-AK" : "" })
      .then((res) => {
        let blob = res.data;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "district-issues.xlsx";
        a.click();
        setBtnLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error("Sometime went wrong, Please try after sometime");
        setBtnLoading(false);
      });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const tabelColumnData = [
    {
      title: "Issued Id",
      dataIndex: "ticket_id",
      key: "ticket_id",
      width: 100,
      fixed: true,
      ...getColumnSearchProps("ticket_id"),
      render: (name, data) => (
        <Link to={`/issues/${data.ticket_id}/`}>{name}</Link>
      ),
    },
    {
      title: "Issue Status",
      dataIndex: "ticket_status",
      key: "ticket_status",
      width: 120,
      filters: [
        ...defaultIssueStatus,
        {
          text: "ReOpened",
          value: "Re-Opened",
        },
        {
          text: "Waiting On User",
          value: "waiting-on-user",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.ticket_status.includes(value),
    },
    {
      title: "Resolution Status",
      dataIndex: "resolved_status",
      key: "resolved_status",
      width: 120,
      render: (data, record) =>
        record.resolved_status ? data : "Not resolved yet",
      filters: [
        {
          text: "Not Under Warranty",
          value: "NotUnderWarranty",
        },
        {
          text: "Replaced",
          value: "Replaced",
        },
        {
          text: "Repaired And Fixed",
          value: "RepairedAndFixed",
        },
        {
          text: "Not An Issue",
          value: "NotAnIssue",
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record?.resolved_status?.includes(value),
    },
    {
      title: "Device type",
      dataIndex: "inventory_type",
      key: "inventory_type",
      width: 100,
      filters: [...deviceFilters],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.inventory_type.includes(value),
    },
    {
      title: "Device",
      dataIndex: "device_id",
      key: "device_id",
      width: 130,
      sorter: (a, b) => a.device_id - b.device_id,
      render: (data, record) => <span>{record.device_id ? data : "-"}</span>,
    },
    {
      title: "Secretariat",
      dataIndex: "village_secretariat_name",
      key: "village_secretariat_name",
      width: 120,
      sorter: (a, b) =>
        a.village_secretariat_name.localeCompare(b.village_secretariat_name),
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      width: 120,
    },
    {
      title: "Issue raised",
      dataIndex: "created_at",
      key: "created_at",
      width: 100,
      render: (date) => moment(date).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
    {
      title: "Issue Closed",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 100,
      render: (date, record) => (
        <span>
          {record.ticket_status === "Closed"
            ? moment(date).format("YYYY-MM-DD")
            : "Not closed yet"}
        </span>
      ),
      sorter: (a, b) =>
        moment(a.updated_at).unix() - moment(b.updated_at).unix(),
    },
    {
      title: "Age of days",
      dataIndex: "age_of_the_ticket",
      key: "age_of_the_ticket",
      width: 120,
      render: (date) => <span>{date} days</span>,
      sorter: (a, b) => a.age_of_the_ticket - b.age_of_the_ticket,
    },
  ];
  const menu = (
    <Menu>
      <Menu.Item
        key="Download-Secretariat-Inventory"
        icon={<DownloadOutlined />}
      >
        Download Tickets
      </Menu.Item>
      <Menu.Item key="Download-Mandal-Inventory" icon={<DownloadOutlined />}>
        Vendor Contact Details
      </Menu.Item>
    </Menu>
  );

  return (
    <Row>
      <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Space>
          <VendorTableFilter
            data_for="district"
            setFilterData={setFilterData}
            setFilterLoading={setFilterLoading}
            districtId={districtId}
            aekUsers={aekUsers}
          />
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            loading={btnLoading}
            onClick={handleDownload}
          >
            Download as excel
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        <Table
          columns={tabelColumnData}
          dataSource={filterData ? filterData?.issues_data : tableData}
          loading={tableLoading}
        />
      </Col>
    </Row>
  );
}
export default TicketsTable;
