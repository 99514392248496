import { useState, useEffect } from "react";
import { Row, Col, Skeleton } from "antd";
import { useParams } from "react-router-dom";
import Charts from "../../Charts/Charts";
import Card from "../../../blocks/Card/Card";
import "../../custom.css";
import { getDistrictIssuesStats } from "../../../services/inventory.services";
import { getAllInventoryIssues } from "../../../services/issue.services";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";
import TicketsTable from "./TicketsTable";
import useViewAccess from "../../../hooks/useViewAccess";
export default function Tickets() {
  const query = useParams();
  const userDetails = JSON.parse(getItem(USER));
  const [districtIssuesStats, setDistrictIssuesStats] = useState({});
  const [issueStatsLoading, setIssueStatsLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const aekUsers = userDetails?.is_aek_dc;
  useViewAccess("is_indent_authority", "is_district_authority", "is_aek_dc");
  useEffect(() => {
    const params = aekUsers
      ? { data_for: "district", filter_ak: "-AK", district_id: query.id }
      : { data_for: "district", filter_ak: "", district_id: query.id };
    getDistrictIssuesStats(query.id)
      .then((response) => {
        setDistrictIssuesStats(response.data);
        setIssueStatsLoading(false);
      })
      .catch((error) => {
        setIssueStatsLoading(false);
        console.error(error);
      });

    setTableLoading(true);
    getAllInventoryIssues(params)
      .then((res) => {
        setTableData(res.data.issues_data);
        setTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setTableLoading(false);
      });
  }, [query]);
  return (
    <Row gutter={[10, 10]}>
      {!aekUsers && (
        <>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Card title="Tickets Pending" className="card-css ticket-pending">
              {issueStatsLoading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <Charts
                  data={districtIssuesStats?.pending_tickets_graph_data}
                />
              )}
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Card title="Tickets Closed" className="card-css ticket-closed">
              {issueStatsLoading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <Charts
                  data={districtIssuesStats?.resolved_tickets_graph_data}
                />
              )}
            </Card>
          </Col>
        </>
      )}

      <Col span={24}>
        <TicketsTable
          districtId={query.id}
          tableData={tableData}
          tableLoading={tableLoading}
          aekUsers={aekUsers}
        />
      </Col>
    </Row>
  );
}
