import { Fragment, useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, message, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Card from "../../blocks/Card/Card";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import Logo from "../../assets/images/Editedlogo.png";
import {
  getEmailCaptchaAPI,
  refreshEmailCaptchaAPI,
  refreshLoginCaptcha,
  resetPassword,
} from "../../services/auth.services";
import { validateCaptcha } from "react-simple-captcha";
import {
  ReCaptchaComponent,
  CaptchaEngine,
  handleCaptchaFail,
} from "../../helpers/utility";
import { SyncOutlined } from "@ant-design/icons";
const { Paragraph } = Typography;

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const [forgotMessageCard, setForgotMessageCard] = useState(false);
  const [username, setUsername] = useState(null);
  const [captchaVal, setCaptchaVal] = useState(null);
  const [addEmailCaptcha, setAddEmailCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  // useEffect(() => {
  //   CaptchaEngine();
  // }, []);

  useEffect(() => {
    if (addEmailCaptcha) {
      var captchaImg = document.getElementById("source");
      console.log("captchaImg", captchaImg);
      var image = new Image();
      image.src = captchaImg.innerHTML || "";
      image.setAttribute("id", `captcha0`);
      insertAfter(captchaImg, image);
      setAddEmailCaptcha(false);
    }
  }, [captchaVal, addEmailCaptcha]);

  useEffect(() => {
    getEmailCaptchaAPI()
      .then((res) => {
        console.log(res);
        setCaptchaVal(res.data.captcha);
        setCaptchaToken(res.data.token);
        setAddEmailCaptcha(true);
      })
      .catch((err) => console.log(err));
  }, []);
  function insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }
  const onFinish = (values) => {
    setUsername(values.username);
    resetPassword({
      ...values,
      token: captchaToken,
    })
      .then(() => setForgotMessageCard(true))
      .catch((error) => {
        form.resetFields();
        message.error(error?.response?.data?.message);
        console.log(error);
        refreshEmailCaptcha();
      });
  };

  async function refreshEmailCaptcha() {
    try {
      const res = await refreshEmailCaptchaAPI({
        token: captchaToken,
      });
      document.getElementById(`captcha0`).remove();
      setCaptchaVal(res.data.captcha);
      setAddEmailCaptcha(true);
    } catch (err) {
      console.log(err);
    }
  }

  // async function getCaptcha() {
  //   try {
  //     const res = await refreshLoginCaptcha({ token: "" });
  //     document.getElementById(`captcha0`).remove();
  //     setCaptchaVal(res.data.captcha);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  return (
    <Fragment>
      <Row type="flex" justify="center" align="middle" className="login">
        <Col
          lg={{ span: 8, offset: 8 }}
          md={{ span: 12, offset: 6 }}
          sm={{ span: 20, offset: 2 }}
          xs={{ span: 20, offset: 2 }}
          style={{ marginLeft: 0 }}
        >
          <Card bordered={false}>
            <div style={{ textAlign: "center", paddingBottom: "20px" }}>
              {isMobileOnly ? (
                <img src={Logo} alt="check logo" height="48px" />
              ) : (
                <img src={Logo} alt="check logo" height="72px" />
              )}
            </div>
            {forgotMessageCard ? (
              <>
                <Paragraph>
                  We've sent a reset password email.
                  {/* to{" "} */}
                  {/* {username && getMaskedEmail()}. */}
                  Please click the reset password link in the email to set your
                  new password.
                </Paragraph>
                <Paragraph>Didn't receive the email yet?</Paragraph>
                <Paragraph>
                  Please check your spam folder, or{" "}
                  <Link
                    onClick={() => {
                      window.location.href = "/forgot-password";
                    }}
                  >
                    try again
                  </Link>
                </Paragraph>
              </>
            ) : (
              <>
                <Form name="login" onFinish={onFinish} form={form}>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                    ]}
                    help="We'll email you the link to reset your password."
                  >
                    <Input
                      autoFocus={true}
                      autoComplete="off"
                      size="large"
                      type="email"
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Email"
                    />
                  </Form.Item>

                  <Form.Item style={{ float: "right" }}></Form.Item>
                  <div>
                    <span id="source" style={{ display: "none" }}>
                      {"data:image/png;base64," + captchaVal}
                    </span>
                    <SyncOutlined
                      style={{ fontSize: "22px", marginLeft: "12px" }}
                      onClick={refreshEmailCaptcha}
                    />
                  </div>
                  <Form.Item
                    rules={[{ required: true, message: "Required" }]}
                    name="captcha"
                  >
                    <Input
                      placeholder="Submit captcha"
                      style={{ padding: "10px", marginTop: "5px" }}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      block
                      className="login-form-button"
                    >
                      Request reset link
                    </Button>
                  </Form.Item>
                </Form>
                <div style={{ textAlign: "center" }}>
                  <Link to="/" className="footer-links">
                    Back to login
                  </Link>
                </div>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
export default ForgotPassword;
