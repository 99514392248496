import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, Input, Select, message, Col, Tag } from "antd";

import {
  getKitStatusApi,
  postAEKStatus,
  updateAEKStatus,
} from "../../services/serviceRequests.services";
import { getRegionsData } from "../../services/metaData.services";
const { Option } = Select;

const KitStatusTab = ({
  isKitDraweVisible,
  setIsKitDraweVisible,
  userDetails,
}) => {
  const [kitStatusData, setKitStatusData] = useState(null);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);
  const [secData, setSecData] = useState(null);
  const [mandals, setMandals] = useState(null);
  const [secretariat, setSecretariat] = useState(null);

  useEffect(() => {
    getKitStatusApi()
      .then((response) => {
        setKitStatusData(response?.data);
        setMandals(response?.data?.vs_list);
        // setSecData(response?.data?.vs_list);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const kitStatusDc = (id) => {
    getKitStatusApi(id)
      .then((response) => {
        setKitStatusData(response?.data);
        setSecretariat(id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(kitStatusData && Object.keys(kitStatusData.data).length !== 0);
    if (kitStatusData && Object.keys(kitStatusData.data).length !== 0) {
      const data = { ...kitStatusData?.data };
      form.setFieldsValue({ ...data });
    } else {
      form.resetFields([
        "station",
        "operator_id",
        "phase",
        "status",
        "reasons",
        "tickets",
        "remarks",
      ]);
    }
  }, [kitStatusData, secretariat, form]);

  const onClose = () => {
    setIsKitDraweVisible(false);
  };

  const onFinish = (value) => {
    console.log(value);
    const formData = {
      ...value,
      tickets:
        value && typeof value.tickets === "string"
          ? value?.tickets?.split(",")?.map((ticket) => ticket?.trim())
          : [],
      remarks: value?.remarks ? value?.remarks : "",
      reasons: value?.reasons ? value?.reasons : [],
    };

    if (kitStatusData && Object.keys(kitStatusData.data).length !== 0) {
      updateAEKStatus(formData)
        .then((response) => {
          message.success(response?.data?.message);
          onClose();
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
        });
    } else {
      postAEKStatus(formData)
        .then((response) => {
          message.success(response?.data?.message);
          onClose();
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
        });
    }
  };

  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };
  return (
    <>
      <Drawer
        title="Kit Status"
        width={520}
        onClose={onClose}
        open={isKitDraweVisible}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onFormValuesChange}
        >
          {userDetails?.is_aek_dc && (
            <>
              <Form.Item
                label="Choose Mandal"
                name="mandal"
                rules={[
                  {
                    required: true,
                    message: "Choose Mandal",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Choose Mandal"
                  showSearch
                  optionFilterProp="label"
                  onChange={(id, children) => {
                    console.log(children);
                    form.resetFields(["secretariat"]);
                    // setSecData(null);
                    setSecData(children?.data?.secretariats);
                  }}
                  filterOption={(input, option) =>
                    option.label &&
                    typeof option.label === "string" &&
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {mandals?.map((mandal) => (
                    <Option
                      value={mandal?.id}
                      key={mandal?.id}
                      label={`${mandal?.name}`}
                      data={mandal}
                    >
                      <div>{mandal?.name}</div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {secData !== null && (
                <Form.Item
                  label="Choose Secretariat"
                  name="secretariat"
                  rules={[
                    {
                      required: true,
                      message: "Choose secretariat",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Choose secretariat"
                    showSearch
                    optionFilterProp="label"
                    onChange={(id) => kitStatusDc(id)}
                    filterOption={(input, option) =>
                      option.label &&
                      typeof option.label === "string" &&
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    }
                  >
                    {secData?.map((sec) => (
                      <Option
                        value={sec?.id}
                        key={sec?.id}
                        label={`${sec?.code} - ${sec?.name}`}
                      >
                        <div>
                          {sec?.name}{" "}
                          <Tag style={{ marginLeft: "5px" }}>{sec?.code}</Tag>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </>
          )}
          {(formValues?.secretariat || !userDetails?.is_aek_dc) && (
            <>
              <Form.Item
                label="Enter Your STATION ID"
                name="station"
                max={5}
                rules={[
                  {
                    required: true,
                    message: "Enter Your STATION ID",
                  },
                  {
                    pattern: /^\d{5}$/,
                    message: "Please enter a 5-digit number for the STATION ID",
                  },
                ]}
              >
                <Input type="number" placeholder="Enter Your STATION ID" />
              </Form.Item>
              <Form.Item
                label="OPERATOR ID"
                name="operator_id"
                rules={[
                  {
                    required: true,
                    message: "Enter Your OPERATOR ID",
                  },
                  {
                    max: 22,
                    message:
                      "Please enter a 22-digit number for the OPERATOR ID",
                  },
                ]}
              >
                <Input placeholder="Enter Your STATION ID" />
              </Form.Item>
              <Form.Item
                label="Select Your Phase"
                name="phase"
                rules={[
                  {
                    required: true,
                    message: "Select Your Phase",
                  },
                ]}
              >
                <Select
                  placeholder="Select a PHASE"
                  optionFilterProp="children"
                  name="phase"
                  options={[
                    {
                      value: "phase1",
                      label: "PHASE I",
                    },
                    {
                      value: "phase2",
                      label: "PHASE II",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="status"
                label="KIT STATUS"
                rules={[
                  {
                    required: true,
                    message: "Select KIT STATUS",
                  },
                ]}
              >
                <Select
                  placeholder="KIT STATUS"
                  optionFilterProp="children"
                  onChange={() =>
                    form.resetFields(["reasons", "tickets", "remarks"])
                  }
                  options={[
                    {
                      value: "active",
                      label: "Active",
                    },
                    {
                      value: "inactive",
                      label: "InActive",
                    },
                  ]}
                />
              </Form.Item>
              {formValues?.status === "inactive" ||
              (kitStatusData?.data?.status === "inactive" &&
                formValues?.status !== "active") ? (
                <>
                  <Form.Item
                    label="Reason"
                    name="reasons"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select your Reason"
                      optionFilterProp="children"
                      options={[
                        {
                          value: "technical issue",
                          label: "TECH ISSUE",
                        },
                        {
                          value: "H/W issue",
                          label: "H/W ISSUE",
                        },
                        {
                          value: "UIDAI",
                          label: "UIDAI ISSUE",
                        },
                        // {
                        //   value: "administration issue",
                        //   label: "ADMINISTRATION ISSUE",
                        // },
                        {
                          value: "Kit Relocation",
                          label: "Kit Relocation",
                        },
                        {
                          value: "other",
                          label: "OTHER",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label="TICKET"
                    name="tickets"
                    rules={[
                      {
                        required: true,
                        message: "Select Your TICKET",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Your Ticket" />
                  </Form.Item>

                  <Form.Item
                    label="Your Remarks"
                    name="remarks"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Remarks",
                      },
                    ]}
                  >
                    <Input.TextArea rows={4} placeholder="Enter your Remarks" />
                  </Form.Item>
                </>
              ) : (
                (formValues?.status === "active" ||
                  (kitStatusData?.data?.status === "active" &&
                    formValues?.status !== "inactive")) && (
                  <Form.Item
                    label="Working Status"
                    name="working_status"
                    rules={[
                      {
                        required: true,
                        message: "Please choose working status",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select working status"
                      optionFilterProp="children"
                      options={[
                        {
                          value: "working",
                          label: "Working",
                        },
                        {
                          value: "not working",
                          label: "Not Working",
                        },
                      ]}
                    />
                  </Form.Item>
                )
              )}
            </>
          )}
          {formValues?.working_status === "not working" && (
            <>
              <Form.Item
                label="Reason for not working"
                name="reason_for_not_working"
                rules={[
                  {
                    required: true,
                    message: "Please choose choices",
                  },
                ]}
              >
                <Select
                  placeholder="Select your Choice"
                  optionFilterProp="children"
                  options={[
                    {
                      value: "Operator TRANSFER (Vacant)",
                      label: "Operator TRANSFER(Vacant)",
                    },
                    {
                      value:
                        "Operator TRANSFER(Existing Operator Not qualified",
                      label:
                        "Operator TRANSFER(Existing Operator Not qualified)",
                    },
                    {
                      value: "Operator Long Leave or Maternity Leave",
                      label: "Operator Long Leave or Maternity Leave",
                    },
                    {
                      value: "Operator  under Deputation",
                      label: "Operator  under Deputation",
                    },
                    {
                      value: "Others",
                      label: "Others",
                    },
                  ]}
                />
              </Form.Item>
              {formValues?.reason_for_not_working === "Others" && (
                <Form.Item
                  label="Others Reason"
                  name="others_reason"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Reason",
                    },
                  ]}
                >
                  <Input.TextArea rows={3} placeholder="Enter your Reason" />
                </Form.Item>
              )}
              <Form.Item
                label="Remarks"
                name="remarks"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Remarks",
                  },
                ]}
              >
                <Input.TextArea rows={3} placeholder="Enter your Remarks" />
              </Form.Item>
            </>
          )}
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};
export default KitStatusTab;
