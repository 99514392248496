import React, { Fragment, useState, useEffect } from "react";
import { Button, Row, Col } from "antd";
import Drawer from "../../Drawer/Drawer";
import { useParams } from "react-router-dom";
import { getSecretariatMeeting } from "../../../services/wednesdayMeeting.services";
import WednesdayMeetingsTable from "./WednesdayMeetingsTable";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";
import useVSAccess from "../../../hooks/useVSAccess";
import useViewAccess from "../../../hooks/useViewAccess";

const WednesdayMeetings = () => {
  const query = useParams();
  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [editList, setEditList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const userDetails = JSON.parse(getItem(USER));

  useViewAccess(
    "is_ward_welfare_data_processing_secretary",
    "is_village_secretariat"
  );
  useVSAccess();
  useEffect(() => {
    if (query?.id) {
      setLoading(true);
      getSecretariatMeeting(query?.id)
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [query, refresh]);

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const openModal = (data) => {
    setEditList(data);
    setVisible(true);
  };

  return (
    <Fragment>
      <Row>
        <Col
          xs={{ span: 0, offset: 0 }}
          sm={{ span: 0, offset: 0 }}
          md={{ span: 22, offset: 1 }}
          lg={{ span: 22, offset: 1 }}
        >
          <div style={{ textAlign: "right", marginBottom: "10px" }}>
            <Button
              type="primary"
              disabled={!userDetails?.is_village_secretariat}
              onClick={() =>
                openModal({
                  status: "create-wednesday-meeting",
                  title: "Add Meeting Notes",
                })
              }
            >
              Add Meeting Notes
            </Button>
          </div>

          <WednesdayMeetingsTable
            loading={loading}
            data={data}
            refresh={refreshPage}
            id={query?.id}
          />
        </Col>
      </Row>

      {visible && (
        <Drawer
          setVisible={setVisible}
          refresh={refreshPage}
          id={query?.id}
          editList={editList}
          setEditList={setEditList}
        />
      )}
    </Fragment>
  );
};

export default WednesdayMeetings;
