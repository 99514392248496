import React, { Fragment, useState } from "react";
import { Table, Select, message, Input, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { addUniform } from "../../../services/serviceRequests.services";
import "./UpdateTable.css";

const { Option } = Select;

const UpdateTable = ({ data }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(false);

  function onChange(e, record) {
    setLoading(true);
    addUniform(record.id, { uniform_supplied: e })
      .then((response) => {
        message.success(response.data.message);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (error.response.data.message) {
          message.error(error?.response?.data?.message);
        } else {
          message.error("Something went wrong, please try after sometime");
        }
      });
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Secretariat Code",
      dataIndex: "village_secretariat_code",
      key: "village_secretariat_code",
      ...getColumnSearchProps("village_secretariat_code"),
      render: (data, record) => (
        <span>{record.village_secretariat_code ? data : "-"}</span>
      ),
    },
    {
      title: "Secretariat Name",
      dataIndex: "village_secretariat_name",
      key: "village_secretariat_name",
      ...getColumnSearchProps("village_secretariat_name"),
      render: (data, record) => (
        <span>{record.village_secretariat_name ? data : "-"}</span>
      ),
    },
    {
      title: "Mandal Name",
      dataIndex: "mandal_name",
      key: "mandal_name",
      sorter: (a, b) => a.mandal_name.localeCompare(b.mandal_name),
      render: (data, record) => <span>{record.mandal_name ? data : "-"}</span>,
    },
    {
      title: "Update Status",
      key: "action",
      render: (text, record) => (
        <Select
          style={{ width: 200 }}
          onChange={(e) => onChange(e, record)}
          defaultValue={record.uniform_supplied}
        >
          <Option value={false} key={record.id}>
            Not Supplied
          </Option>
          <Option value={true} key={record.id}>
            Supplied
          </Option>
        </Select>
      ),
    },
    {
      title: "Confirmation From Secretariat",
      dataIndex: "confirmation",
      key: "confirmation",
      sorter: (a, b) => a.confirmation - b.confirmation,
      render: (data, record) => (
        <span>{record.confirmation ? "Confirmed" : "Not Confirmed"}</span>
      ),
    },
  ];

  return (
    <Fragment>
      {data && (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={loading}
        />
      )}
    </Fragment>
  );
};
export default UpdateTable;
