import React, { useEffect } from "react";
import { getItem } from "../helpers/localStorage";
import { USER } from "../constants/defaultKeys";
import { useLocation, useHistory } from "react-router-dom";
function useViewAccess(...roles) {
  const route = useLocation();
  const history = useHistory();
  const userData = JSON.parse(getItem(USER));
  console.log("userData", userData);
  useEffect(() => {
    let isAllowedToView = false;
    if (roles && userData) {
      roles.forEach((role) => {
        if (userData?.[role]) {
          isAllowedToView = true;
        }
      });
    }
    if (!isAllowedToView) {
      if (
        userData.region === null &&
        !(
          userData.is_vendor ||
          userData?.is_aek_hod_dept ||
          userData?.is_aek_hod
        )
      ) {
        history.push(`/state/dashboard`);
      } else {
        if (userData.is_village_secretariat) {
          history.push(
            `/village-secretariat/${userData.region?.region_name}/${userData.region.region_id}/`
          );
        }
        if (userData.is_mandal_authority)
          history.push(
            `/mandal/${userData.region?.region_name}/${userData.region.region_id}/`
          );
        if (userData.is_district_authority || userData.is_indent_authority)
          history.push(
            `/district/${userData.region?.region_name}/${userData.region.region_id}/`
          );
        if (
          userData?.is_welfare_and_edu_assistant
          // || userData?.is_ward_welfare_data_processing_secretary
        )
          history.push(`/raise-tab-ticket/${userData.region.region_id}`);
        if (userData.is_admin) history.push(`/state/dashboard`);
        if (userData.is_vendor) history.push(`/vendor`);
        if (
          userData?.is_aek_hod_dept ||
          userData?.is_aek_dc ||
          userData?.is_aek_hod ||
          userData?.is_uidai
        )
          history.push(`/aek-vendor`);
        if (userData.is_telecom_operator) history.push(`/sim-requests`);
        if (userData.is_uniform_supplier) history.push(`/uniform-vendor`);
        if (userData.is_office365_vendor) history.push(`/office-365`);
        if (userData.is_internet_vendor) history.push(`/internet-vendor`);
      }
    }
  }, [route.pathname]);
  return null;
}

export default useViewAccess;
