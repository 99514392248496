import React from "react";
import { Col, Row, Typography, Statistic, Avatar, Skeleton, Badge } from "antd";
import Card from "../../blocks/Card/Card";
import workingIcon from "../../assets/images/check.png";
import notWorkingIcon from "../../assets/images/close.png";
import SecurityIcon from "../../assets/devices/policy.png";
import internetLogo from "../../assets/devices/internet.png";
import totalIcon from "../../assets/images/total.png";
import { devices } from "../../constants/devices";
import DeviceBadge from "../../blocks/Badges/DeviceBadge";

const { Paragraph } = Typography;

const InventorySummaryCard = ({ inventoryStatsLoading, inventoryStats }) => {
  return (
    <Card
      title={
        <span>
          Inventory Summary{" "}
          <Badge
            style={{ backgroundColor: "#2A8FF7" }}
            count={`Total: ${
              inventoryStats?.total_inventory_count
                ? inventoryStats?.total_inventory_count
                : 0
            }`}
          />
        </span>
      }
      className="state-inventory-summary"
    >
      {inventoryStatsLoading ? (
        <Skeleton active paragraph={{ rows: 2 }} />
      ) : (
        <Row gutter={[48, 10]} style={{ marginLeft: "25px" }}>
          {Object.keys(devices).map((item) => (
            <Col flex="110px" style={{ padding: "0px" }}>
              <DeviceBadge deviceType={item} badgeType="allCount" />
              <Paragraph style={{ marginTop: "8px" }}>
                <Statistic
                  prefix={<img src={workingIcon} alt="working" height="12px" />}
                  value={
                    inventoryStats !== null
                      ? inventoryStats?.district_overall_inventory_statistics[
                          item
                        ]?.working
                      : 0
                  }
                />
                <Statistic
                  prefix={
                    <img src={notWorkingIcon} alt="notWorking" height="12px" />
                  }
                  value={
                    inventoryStats !== null
                      ? inventoryStats?.district_overall_inventory_statistics[
                          item
                        ]?.not_working
                      : 0
                  }
                />

                <Statistic
                  prefix={<img src={totalIcon} alt="total" height="12px" />}
                  value={
                    inventoryStats !== null
                      ? inventoryStats?.district_overall_inventory_statistics[
                          item
                        ]?.total
                      : 0
                  }
                />
              </Paragraph>
            </Col>
          ))}
          <Col flex="110px" style={{ padding: "0px" }}>
            <Avatar shape="square" size={42} src={SecurityIcon} />
            <Paragraph style={{ marginTop: "8px" }}>
              <Statistic
                prefix={<img src={workingIcon} alt="total" height="12px" />}
                value={
                  inventoryStats !== null
                    ? inventoryStats?.security_certificates?.remaining
                    : 0
                }
              />
              <Statistic
                prefix={
                  <img src={notWorkingIcon} alt="notWorking" height="12px" />
                }
                value={
                  inventoryStats !== null
                    ? inventoryStats?.security_certificates?.damaged
                    : 0
                }
              />
            </Paragraph>
          </Col>
          <Col flex="110px" style={{ padding: "0px" }}>
            <Avatar shape="square" size={42} src={internetLogo} />
            <Paragraph style={{ marginTop: "8px" }}>
              <Statistic
                prefix={<img src={workingIcon} alt="working" height="12px" />}
                value={
                  inventoryStats !== null
                    ? inventoryStats?.internet_connection?.total_connections
                    : 0
                }
              />
              <Statistic
                prefix={
                  <img src={notWorkingIcon} alt="notWorking" height="12px" />
                }
                value={
                  inventoryStats !== null
                    ? inventoryStats?.internet_connection?.poor_connections
                    : 0
                }
              />
            </Paragraph>
          </Col>
        </Row>
      )}
    </Card>
  );
};
export default InventorySummaryCard;
