import React, { Fragment, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Checkbox,
  Alert,
  message,
  InputNumber,
  Typography,
  Radio,
  Space,
} from "antd";
import { devices, akDevices, stationary } from "../../../constants/devices";
import { useParams } from "react-router-dom";
import { requestForInventory } from "../../../services/inventory.services";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";

import "./RequestInventory.css";

const { Option } = Select;
const { TextArea } = Input;
const { Paragraph, Text } = Typography;

const RequestInventory = () => {
  const query = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isChecked, setChecked] = useState([]);
  const [formValues, setFormValues] = useState(null);
  const [checkedValue, setCheckedValue] = useState([]);
  const [isBtnDisabled, setBtnDisabled] = useState(false);
  const [selectedDeviceCategory, setSelectedDeviceCategory] = useState(devices);
  const [isTicketIdChecked, setTicketId] = useState(false);
  const [value, setValue] = useState();
  const [innerRadioValue, setInnerRadioValue] = useState();
  const userDetails = JSON.parse(getItem(USER));

  const showCount =
    formValues?.inventory_type === "Sim" ||
    formValues?.inventory_type === "Mobile" ||
    formValues?.inventory_type === "FPScanner" ||
    formValues?.inventory_category === "Stationary";

  const onFinish = (values) => {
    const data = { ...values, reason: formValues?.reason };
    let formData = values?.other_ticket_id
      ? {
          ...data,
          ticket_id: [
            ...values?.other_ticket_id,
            { ticket_id: values?.ticket_id },
          ]
            .map((ticket) => ticket.ticket_id)
            .join(","),
        }
      : data;
    delete formData["other_ticket_id"];

    setLoading(true);
    requestForInventory(query?.id, formData)
      .then((response) => {
        message.success(response.data.message);
        setLoading(false);
        form.resetFields();
        setCheckedValue([]);
        setChecked([]);
      })
      .catch((error) => {
        console.log(error);
        form.resetFields();
        message.error(error?.response?.data?.message);
        setLoading(false);
      });
  };
  const onFormValuesChange = (changedValues) => {
    if (changedValues?.inventory_category) {
      setFormValues(null);
      form.resetFields();
      form.setFieldsValue({
        inventory_category: changedValues?.inventory_category,
      });
      setFormValues({
        inventory_category: changedValues?.inventory_category,
      });
      setCheckedValue([]);
      setChecked([]);
    } else if (changedValues?.inventory_type) {
      setTicketId(false);
      form.resetFields(["reason", "ticket_id"]);
      setFormValues({
        ...formValues,
        inventory_type: changedValues?.inventory_type,
      });
      delete formValues?.reason;
      delete formValues?.ticket_id;
      delete formValues?.employee_id;
    } else {
      setFormValues({ ...formValues, ...changedValues });
    }
  };

  function handleSelectedDevices(selectedCategory) {
    if (selectedCategory === "GSWS-Inventory") {
      setSelectedDeviceCategory(devices);
    } else if (selectedCategory === "Adhar-Inventory") {
      setSelectedDeviceCategory(akDevices);
    } else {
      setSelectedDeviceCategory(stationary);
    }
  }

  function handleRadioValue(val) {
    if (val === "dont_know_mobile_num" || val === "dont_know_sim_num") {
      setBtnDisabled(true);
      delete formValues.child_group;
    } else {
      setBtnDisabled(false);
    }
    if (val == "Existing device is not working/damaged and is beyond repaird") {
      form.resetFields(["child_group", "checkbox", "ticket_id"]);
    }
    if (
      val == "Unknow mobile number and 20 digit SIM number" ||
      val == "Existing sim with poor signal" ||
      val == "Existing device is not working/damaged and is beyond repair" ||
      val == "New employee recruited" ||
      val == "The device is lost" ||
      val == "Have not received any device till date"
    ) {
      setFormValues({ ...formValues, reason: [val] });
    }
    if (
      val !== "Existing device is not working/damaged and is beyond repaird"
    ) {
      delete formValues?.ticket_id;
      setFormValues({ ...formValues, reason: [val] });
    }
    if (val !== "The device is lost") {
      delete formValues?.employee_id;
      setFormValues({ ...formValues, reason: [val] });
    }
  }
  return (
    <Fragment>
      <Alert
        message="Please note that requesting for inventory wouldn't guarantee the device allocation automatically. 
        Department will consider each request case-on-case basis and the allotment is subjected to availability. "
        type="info"
        showIcon
      />
      <br />
      <Form
        name="request_inventory"
        layout="vertical"
        form={form}
        wrapperCol={{
          span: 13,
        }}
        onFinish={onFinish}
        onValuesChange={onFormValuesChange}
        initialValues={{ count: "1" }}
      >
        <Form.Item
          label="Choose Device Category"
          name="inventory_category"
          rules={[{ required: true, message: "Please choose device category" }]}
        >
          <Select
            placeholder="Please choose device category"
            size="large"
            onSelect={(seletedValue) => {
              handleSelectedDevices(seletedValue);
            }}
          >
            <Option value="GSWS-Inventory">Gsws-Inventory</Option>
            <Option value="Adhar-Inventory">Aadhar Inventory</Option>
            <Option value="Stationary">Stationary</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={
            formValues?.inventory_category === "Stationary"
              ? "Please choose stationary type"
              : "Please choose device type"
          }
          name="inventory_type"
          rules={[
            {
              required: true,
              message: "Please choose device type",
            },
          ]}
        >
          <Select
            placeholder={
              formValues?.inventory_category === "Stationary"
                ? "Please choose stationary type"
                : "Please choose device type"
            }
            size="large"
            onChange={() => {
              setBtnDisabled(false);
              form.resetFields([
                "reason",
                "ticket_id",
                "employee_id",
                "child_group",
                "other_ticket_id",
                "count",
                "device_damage",
              ]);
              setInnerRadioValue("");
            }}
          >
            {formValues?.inventory_category === "Stationary" ? (
              <>
                {stationary.map((device) => (
                  <Option value={device.key} key={device.key}>
                    {device.name}
                  </Option>
                ))}
              </>
            ) : (
              <>
                {Object.keys(selectedDeviceCategory).map((device) => (
                  <>
                    <Option value={device} key={device}>
                      {device === "FPScanner"
                        ? "Finger Print Scanner"
                        : device === "LnMachine"
                        ? "Laminating Machine"
                        : device === "A4-Papers"
                        ? "A4 Papers"
                        : device === "Epson-Printer-Ink"
                        ? "Epson Printer Ink"
                        : device === "Canon-Printer-Ink"
                        ? "Canon Printer Ink"
                        : device}
                    </Option>
                  </>
                ))}
              </>
            )}
          </Select>
        </Form.Item>
        {formValues?.inventory_type === "Sim" && (
          <Form.Item
            label="Choose Carrier Provider(s) which has good network signals"
            name="network"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Select size="large" mode="multiple" allowClear>
              <Option value="Airtel" key="Airtel">
                Airtel
              </Option>
              <Option value="Jio" key="Jio">
                Jio
              </Option>
              <Option value="Vi" key="Vi">
                Vi (Vodafone/Idea)
              </Option>
              <Option value="BSNL" key="BSNL">
                BSNL
              </Option>
            </Select>
          </Form.Item>
        )}
        {showCount && (
          <Form.Item
            label={
              formValues?.inventory_type === "Sim"
                ? "Add required number of SIM's"
                : "Add required number of devices"
            }
            name="count"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <InputNumber size="large" min={1} />
          </Form.Item>
        )}
        {formValues?.inventory_category !== "Stationary" && (
          <>
            <Form.Item
              name="reason"
              label="Choose Applicable Reason"
              rules={[
                {
                  required: true,
                  message: "Please choose atleast one",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => {
                  setValue(e.target.value);
                  handleRadioValue(e.target.value);
                }}
                value={value}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Radio value="Existing device is not working/damaged and is beyond repaird">
                  <Text>
                    {formValues?.inventory_type === "Sim" ? (
                      "Existing SIM damaged/Lost/Not Working"
                    ) : (
                      <span>
                        Existing device is not working/damaged and is beyond
                        repair <strong>(not covered under warranty)</strong>
                      </span>
                    )}
                  </Text>
                  {value ==
                    "Existing device is not working/damaged and is beyond repaird" && (
                    <>
                      {formValues?.inventory_type === "Sim" ? (
                        <Form.Item name="child_group">
                          <Radio.Group
                            name="The_group"
                            style={{ display: "flex", flexDirection: "column" }}
                            onChange={(e) => {
                              setInnerRadioValue(e.target.value);
                              handleRadioValue(e.target.value);
                            }}
                            value={innerRadioValue}
                          >
                            <Radio value="dont_know_mobile_num">
                              We know the mobile number of the Damaged/Lost/Not
                              working sim
                              {innerRadioValue == "dont_know_mobile_num" && (
                                <Paragraph type="danger">
                                  Get an empty sim and request for replacement
                                </Paragraph>
                              )}
                            </Radio>
                            <Radio value="dont_know_sim_num">
                              We don't know the mobile number, but we have
                              20-digit SIM number
                              {innerRadioValue == "dont_know_sim_num" && (
                                <Paragraph type="danger">
                                  Contact support to know the mobile number
                                </Paragraph>
                              )}
                            </Radio>
                            <Radio value="Unknow mobile number and 20 digit SIM number">
                              We don't know the mobile number and 20-digit SIM
                              number
                            </Radio>
                            <Radio value="Existing sim with poor signal">
                              We are not getting proper signal for the existing
                              carrier in our location and want to return the
                              current SIM card(s).
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      ) : (
                        <>
                          <Form.Item
                            style={{ marginBottom: "5px" }}
                            name="checkbox"
                            valuePropName="checked"
                            rules={[
                              {
                                validator: (_, value) =>
                                  value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error(
                                          "Please confirm that the existing device is not working/damaged and is beyond repair"
                                        )
                                      ),
                              },
                            ]}
                          >
                            <Checkbox
                              name="device_damage"
                              value="Existing device is not working/damaged and is beyond repair"
                              key="Existing device is not working/damaged and is beyond repair"
                              onChange={(e) => {
                                setTicketId(e.target.checked);
                                handleRadioValue(
                                  "Existing device is not working/damaged and is beyond repair"
                                );
                              }}
                            >
                              I confirm that a ticket has been raised (or)
                              device taken to service center and received the
                              above response
                            </Checkbox>
                          </Form.Item>
                          <Form.Item
                            name="ticket_id"
                            rules={[
                              {
                                required: true,
                                message: "Please input your ticket id",
                              },
                            ]}
                          >
                            <Input
                              addonBefore="SRID #"
                              placeholder="Ticket ID"
                              maxLength={10}
                            />
                          </Form.Item>
                          {(formValues?.inventory_type === "Mobile" ||
                            formValues?.inventory_type === "FPScanner") && (
                            <Form.List name="other_ticket_id">
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                      key={key}
                                      style={{
                                        display: "flex",
                                      }}
                                      align="start"
                                    >
                                      <Form.Item
                                        {...restField}
                                        name={[name, "ticket_id"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please input your ticket id",
                                          },
                                        ]}
                                      >
                                        <Input
                                          addonBefore="SRID #"
                                          placeholder="Ticket ID"
                                          maxLength={10}
                                        />
                                      </Form.Item>
                                      <MinusCircleOutlined
                                        title="Click to delete this field"
                                        onClick={() => remove(name)}
                                        style={{
                                          color: "red",
                                          fontSize: "20px",
                                          paddingTop: "5px",
                                        }}
                                      />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={add}
                                      block
                                      icon={<PlusOutlined />}
                                    >
                                      Add Other Relavent Tickets
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Radio>
                {formValues?.inventory_type !== "Sim" && (
                  <>
                    <Radio value="The device is lost">
                      The device is lost
                      {value == "The device is lost" && (
                        <Form.Item
                          name="employee_id"
                          rules={[
                            {
                              required: true,
                              message: "This field is required!",
                            },
                          ]}
                        >
                          <Input placeholder="Employee / Volunteer ID " />
                        </Form.Item>
                      )}
                    </Radio>
                  </>
                )}

                <Radio value="Have not received any device till date">
                  Have not received any device till date
                </Radio>
                <Radio value="New employee recruited">
                  New employee recruited
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="explanation">
              <TextArea
                rows={3}
                placeholder="Please elaborate as much as you can (optional)"
              />
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
            disabled={isBtnDisabled || !userDetails?.is_village_secretariat}
          >
            {loading ? "Requesting..." : "Request Inventory"}
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default RequestInventory;
