import React, { Fragment, useEffect, useState } from "react";
import { Select, Form, message, Input, Divider, Button, Alert } from "antd";
import { simNumbers } from "../../../../services/inventory.services";
import * as config from "../../../../config/validation.config";
import { USER } from "../../../../constants/defaultKeys";
import { getItem } from "../../../../helpers/localStorage";
import { addBsnlSimRequests } from "../../../../services/serviceRequests.services";

const { Option } = Select;
const { TextArea } = Input;

const EmptyBsnlSimRequests = ({ id }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const userDetails = JSON.parse(getItem(USER));

  useEffect(() => {
    form.setFieldsValue({
      secretariat_contact_number: userDetails?.contact_number_unmasked,
    });

    simNumbers(id)
      .then((response) => {
        const data = response.data;
        const filterBsnlSim = data.filter((sim) => sim.network === "BSNL");
        setData(filterBsnlSim);
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.response?.data?.message);
      });
  }, [id]);

  const onFinish = (values) => {
    addBsnlSimRequests(id, values)
      .then((response) => {
        message.success(response.data.message);
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.response?.data?.message);
      });
  };

  return (
    <Fragment>
      <Alert
        message="Please note this empty BSNL SIM request will be directly sent to VSWS Department."
        type="info"
      />
      <br />
      <Form
        name="Request For Sim"
        onFinish={onFinish}
        wrapperCol={{
          span: 10,
        }}
        colon={false}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Choose mobile number for which you need an empty SIM"
          name="old_sim_number"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <Select size="large">
            {data.map((numbers) => (
              <Option value={numbers.sim_number} key={numbers.sim_number}>
                {numbers.sim_number}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Divider orientation="left">
          Contact details for courier service
        </Divider>

        <Form.Item
          name="postal_address"
          extra="Please provide the complete secretariat address with landmark and pincode to courier the new empty SIM."
          rules={[
            {
              required: true,
              message: "Please input your secretariat address",
            },
          ]}
        >
          <TextArea size="large" placeholder="Secretariat Address" required />
        </Form.Item>
        <Form.Item
          name="secretariat_contact_number"
          rules={[
            {
              ...config.mobileNumberRegex[0],
              required: true,
            },
          ]}
        >
          <Input
            maxLength="10"
            placeholder="Mobile Number"
            addonBefore={"+91"}
            size="large"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default EmptyBsnlSimRequests;
