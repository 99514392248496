import React, { useState } from "react";
import { Form, Upload, message, Input, Select, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { aekVendorServiceRequestUpdate } from "../../../services/serviceRequests.services";
import {
  convertToFormData,
  handleMultipleFileExt,
  replaceDotsExceptLast,
} from "../../../helpers/utility";
import { uploadFile } from "../../../services/downloadFile.services";
import { getItem } from "../../../helpers/localStorage";
import { USER } from "../../../constants/defaultKeys";

const { TextArea } = Input;
const { Option } = Select;
const AekVendorForm = ({ data, query, refresh, params }) => {
  const userDetails = JSON.parse(getItem(USER));
  console.log(userDetails, "hgjhgjhg");
  const [uploadedFileName, setuploadedFileName] = useState(null);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);

  const uploadProps = {
    beforeUpload: (file, fileList) => {
      if (handleMultipleFileExt(file)) return Upload.LIST_IGNORE;

      const fileType =
        file.type === "application/pdf" ||
        file.type === "application/msword" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      let fileSize = (file.size / 5260006).toFixed(0);
      const multitext = file.name.split(".").length > 2;
      let file_status = false;

      if (fileType && !multitext) {
        file_status = true;
        if (file.size > 5260006) {
          file_status = false;
          message.error(
            `File size should be less than 5MB, uploaded file is ${fileSize}MB, exceeding the maximum 5MB limit`
          );
        }
      } else {
        message.error(`${file.name} is not a valid file`);
      }
      return file_status ? true : Upload.LIST_IGNORE;
    },
    async onChange(info) {
      if (info.file.status === "done") {
        setUploadFileLoading(true);
        try {
          const res = await uploadFile({
            folder: "issues-images",
            filename: replaceDotsExceptLast(info.file.name),
            uploading_to_vsws: true,
            is_private_file: false,
          });
          const formData = convertToFormData(res, info);
          await axios({
            method: "POST",
            url: res?.data?.url?.url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          setuploadedFileName(
            "https://resources.vsws.co.in/" + "" + res?.data?.url?.fields?.key
          );
          message.success(`${info.file.name} file uploaded successfully`);
        } catch (err) {
          console.log(err);
          // message.error(err?.response?.data?.message);
        } finally {
          setUploadFileLoading(false);
        }
      }
    },
  };
  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const onFinish = (values) => {
    const formData = {
      ...values,
      v_doc: uploadedFileName ? uploadedFileName : undefined,
    };
    aekVendorServiceRequestUpdate(query ? query : params, formData)
      .then((response) => {
        message.success(response.data.message);
        form.resetFields();
        refresh();
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        console.log(error);
      });
  };
  return (
    <>
      <Form
        name="issues"
        layout="vertical"
        form={form}
        colon={false}
        className="issues-form"
        onFinish={onFinish}
        onValuesChange={onFormValuesChange}
      >
        <Form.Item
          name="issue_status"
          label="Issue Status"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Status"
            style={{ minWidth: "150px" }}
            disabled={data?.issue_status === "Closed" && true}
          >
            <Option value="open" key="open">
              Open
            </Option>
            <Option value="in-progress" key="in-progress">
              In Progress
            </Option>
            {!userDetails?.is_uidai && (
              <Option value="forward-to-UIDAI" key="forward-to-UIDAI">
                Forwarded to UIDAI
              </Option>
            )}
            <Option value="waiting-on-user" key="waiting-on-user">
              Waiting On User
            </Option>
            <Option value="closed" key="closed">
              Closed
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="v_remarks"
          label="Resolution Remarks"
          rules={[
            {
              required:
                formValues?.issue_status === "waiting-on-user" ? true : false,
              message: "This field is required!",
            },
          ]}
        >
          <TextArea
            rows={3}
            size="large"
            placeholder={
              formValues?.issue_status === "waiting-on-user"
                ? "Resolutioin Message"
                : "Resolutioin Message (Optional)"
            }
            style={{ width: "300px" }}
          />
        </Form.Item>
        <Form.Item
          label="File upload (PDF , Word doc & png/jpg/jpeg only and file size should be less than 5MB)"
          name="v_doc"
          getValueFromEvent={normFile}
        >
          <Upload {...uploadProps} customRequest={dummyRequest} maxCount={1}>
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading || uploadFileLoading}
            disabled={uploadFileLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default AekVendorForm;
