import axiosInstance from "../config/axios/axios.config";

export const addSecretariatMeeting = (id, payload) => {
  return axiosInstance.post(`/add-secretariat-meeting-review/${id}/`, payload);
};

export const getSecretariatMeeting = (id) => {
  return axiosInstance.get(`/get-secretariat-level-meeting-reviews/${id}/`);
};

export const updateSecretariatMeeting = (id, payload) => {
  return axiosInstance.put(
    `/update-secretariat-level-meeting-review/${id}/`,
    payload
  );
};

export const addMandalWednesdayMeeting = (id, payload) => {
  return axiosInstance.put(`/add-mandal-level-meeting-review/${id}/`, payload);
};

export const getMandalWednesdayMeeting = (id, month) => {
  return axiosInstance.get(
    `/get-mandal-level-meeting-reviews/${id}/?month=${month}`
  );
};

export const addMandalMeeting = (id, payload) => {
  return axiosInstance.post(`/add-mandal-meeting/${id}/`, payload);
};

export const getMandalMeeting = (id) => {
  return axiosInstance.get(`/get-mandal-meeting-records/${id}/`);
};

export const addDistrictWednesdayMeeting = (id, payload) => {
  return axiosInstance.post(
    `/add-district-level-meeting-review/${id}/`,
    payload
  );
};

export const updateDistrictWednesdayMeeting = (id, payload) => {
  return axiosInstance.put(
    `/update-district-level-meeting-review/${id}/`,
    payload
  );
};

export const getDistrictWednesdayMeeting = (id, month) => {
  return axiosInstance.get(
    `/get-district-level-meeting-reviews/${id}/?month=${month}`
  );
};

export const getDistrictMeeting = (id) => {
  return axiosInstance.get(`/get-district-meeting-records/${id}/`);
};

export const addDistrictMeeting = (id, payload) => {
  return axiosInstance.post(`/add-district-meeting/${id}/`, payload);
};

export const addStateMeeting = (id, payload) => {
  return axiosInstance.post(`/add-state-level-meeting-review/${id}/`, payload);
};

export const updateStateMeeting = (id, payload) => {
  return axiosInstance.put(
    `/update-state-level-meeting-review/${id}/`,
    payload
  );
};

export const getStateMeeting = (month) => {
  return axiosInstance.get(`/get-state-level-meeting-reviews/?month=${month}`);
};
