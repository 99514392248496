import React from "react";
import { List } from "antd";
import TimeStamp from "../../../blocks/TimeStamp/index";
import Card from "../../../blocks/Card/Card";
import "./ActivityFeed.css";

const ActivityFeed = ({ data }) => {
  return (
    <Card title="Activity Feed" className="activity-feed">
      <List
        className="activity-feed"
        itemLayout="horizontal"
        dataSource={data && data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <span key={item.created_at}>
                <TimeStamp timeStyle="round" value={item.created_at} />
              </span>,
            ]}
          >
            <List.Item.Meta description={item.message} />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default ActivityFeed;
