import axiosInstance from "../config/axios/axios.config";

export const getSecretariatInventoryRequests = (id) => {
  return axiosInstance.get(`/get-secretariat-inventory-requests/${id}/`);
};

export const getSecretariatKMRequests = (id) => {
  return axiosInstance.get(
    `/get-secretariat-keyboard-and-mouse-requests/${id}/`
  );
};

export const getSecretariatSimReplaceRequests = (id) => {
  return axiosInstance.get(`/get-secretariat-sim-replace-requests/${id}/`);
};

export const getDistrictInventoryRequests = (id, payload) => {
  return axiosInstance.get(`/get-district-inventory-requests/${id}/`, {
    params: {
      ...payload,
    },
  });
};

export const reportHighVoltage = (id, payload) => {
  return axiosInstance.post(`/report-high-voltage/${id}/`, payload);
};

export const AddLostInventory = (id, payload) => {
  return axiosInstance.post(`/add-lost-inventory/${id}/`, payload);
};

export const getLostInventory = (id) => {
  return axiosInstance.get(`/get-lost-inventory/${id}/`);
};

export const getDistrictLostInventory = (id) => {
  return axiosInstance.get(`/get-district-lost-inventory/${id}/`);
};

export const getHighVoltage = (payload) => {
  return axiosInstance.get(`/get-high-voltage/`, {
    params: {
      ...payload,
    },
  });
};

export const getSecretariatInventoryIssues = (id) => {
  return axiosInstance.get(`/secretariat-inventory-issues/${id}/`);
};

export const reopenClosedTicket = (id, payload) => {
  return axiosInstance.put(`/reopen-ticket/${id}/`, payload);
};

export const editSimReplaceRequests = (id, payload) => {
  return axiosInstance.put(`/edit-sim-replace-request/${id}/`, payload);
};

export const getOffice365Requests = (id) => {
  return axiosInstance.get(`/get-secretariat-office365-requests/${id}/`);
};

export const addOffice365Requests = (id, payload) => {
  return axiosInstance.post(`/add-office-365-requests/${id}/`, payload);
};
export const damagedSecureCertificateRequests = (id, payload) => {
  return axiosInstance.put(
    `/dameged-secure-certificates-requests/${id}/`,
    payload
  );
};
export const getDamagedSecureCertificateRequests = (id) => {
  return axiosInstance.get(`/dameged-secure-certificates-requests/${id}/`);
};

export const getBsnlSimRequests = (id) => {
  return axiosInstance.get(`/get-empty-bsnl-sim-requests/${id}/`);
};

export const addBsnlSimRequests = (id, payload) => {
  return axiosInstance.post(`/request-empty-bsnl-sim/${id}/`, payload);
};

export const addKMRequests = (id, payload) => {
  return axiosInstance.post(`/raise-keyboard-and-mouse-issues/${id}/`, payload);
};

export const getKeyboardAndMouseIssues = (id) => {
  return axiosInstance.post(`/get-keyboard-and-mouse-issues/${id}/`);
};

export const addUniform = (id, payload) => {
  return axiosInstance.put(`/add-uniform/${id}/`, payload);
};

export const getUniform = (name) => {
  return axiosInstance.get(`/get-uniforms/${name}/`);
};

export const getUniformSummary = () => {
  return axiosInstance.get(`/get-uniforms-summary/`);
};

export const updateInventoryRequest = (id, payload) => {
  return axiosInstance.put(`/update-inventory-request/${id}/`, payload);
};

export const createIndentRequest = (id, payload) => {
  return axiosInstance.post(`create-indent-request/${id}/`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateIndentRequest = (id, payload) => {
  return axiosInstance.put(`/update-indent-request/${id}/`, payload);
};

export const getIndentRequest = (id) => {
  return axiosInstance.get(`/get-indent-requests/?district_id=${id}`);
};

export const getStateIndentRequest = (payload) => {
  return axiosInstance.get("/get-indent-requests/", {
    params: { ...payload },
  });
};

export const resolveInventoryRequest = (id, payload) => {
  return axiosInstance.put(`/resolve-inventory-request/${id}/`, payload);
};

export const getIndentRequestForVendor = () => {
  return axiosInstance.get(`/get-indent-requests-for-vendor/`);
};

export const updateIndentRequestForVendor = (id, payload) => {
  return axiosInstance.put(
    `/update-indent-request-status-by-vendor/${id}/`,
    payload
  );
};

export const raiseAekServiceRequest = (payload) => {
  return axiosInstance.post(`/raise-aek-service-request/`, payload);
};
export const secretariatAekServiceRequests = (id) => {
  return axiosInstance.get(`/secretariat-aek-service-requests-all/${id}`);
};

export const serviceRequestTicketInfo = (id) => {
  return axiosInstance.get(`/aek-service-request-ticket-info/${id}`);
};
export const aekDcHodServiceRequests = (params) => {
  return axiosInstance.get(`/aek-dc-hod-service-requests-all/?${params}`);
};
export const aekVendorServiceRequestUpdate = (id, payload) => {
  return axiosInstance.put(
    `/aek-vendor-service-requests-update/${id}/`,
    payload
  );
};

export const updateTabsInspection = (payload) => {
  return axiosInstance.post(`/tab-questionnaires/`, payload);
};
export const createExamRecords = (payload) => {
  return axiosInstance.post(`/da-aadhaar-exam-records/`, payload);
};
export const updateExamRecords = (payload) => {
  return axiosInstance.patch(`/da-aadhaar-exam-records/`, payload);
};
export const getExamRecords = () => {
  return axiosInstance.get(`/da-aadhaar-exam-records/`);
};
export const getKitStatusApi = (params) => {
  return params
    ? axiosInstance.get(`/aek-da-form/?secretariat=${params}`)
    : axiosInstance.get(`/aek-da-form/`);
};
export const postAEKStatus = (payload) => {
  return axiosInstance.post(`/aek-da-form/`, payload);
};
export const updateAEKStatus = (payload) => {
  return axiosInstance.patch(`/aek-da-form/`, payload);
};
export const getInternetConnection = () => {
  return axiosInstance.get(`/internet-da-form/`);
};
export const updateInternetConnection = (payload) => {
  return axiosInstance.patch(`/internet-da-form/`, payload);
};
export const postInternetConnection = (payload) => {
  return axiosInstance.post(`/internet-da-form/`, payload);
};

export const addSIMCardAPI = (payload) => {
  return axiosInstance.post(`/da-wedps-simcard-details/`, payload);
};

export const getSIMCardDataAPI = () => {
  return axiosInstance.get(`/da-wedps-simcard-details/`);
};

export const validateCFMSIDAPI = (id) => {
  return axiosInstance.get(`/check-cfmsid-validity/${id}/`);
};
