import React, { Fragment } from "react";
import { Dropdown, Menu } from "antd";
import Avatar from "react-avatar";
import { colors } from "../../constants/defaultKeys";
import { clear } from "../../helpers/localStorage";
import { useHistory, Link } from "react-router-dom";

const UserAvatar = ({ user, wea }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/logout");
  };

  const menu = (
    <Menu>
      {!wea && (
        <Menu.Item key="0">
          <Link to="/myprofile">My Profile</Link>
        </Menu.Item>
      )}
      <Menu.Item key="1">
        <div onClick={handleClick}>Logout</div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Avatar
          className="memu-avatar"
          color={user && colors[user.user_id % 24]}
          round={true}
          name={user && user.first_name ? user.first_name : "U"}
          size="40px"
        />
      </Dropdown>
    </Fragment>
  );
};

export default UserAvatar;
