import React, { useState, useEffect } from "react";
import { Table, Col, Row, PageHeader, DatePicker } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import Drawer from "../../Drawer/Drawer";
import { useLocation, useHistory } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { getMandalWednesdayMeeting } from "../../../services/wednesdayMeeting.services";

const monthFormat = "YYYY-MM";

const WednesdayMeetingTable = ({ id }) => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editList, setEditList] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const curDate = moment().format(monthFormat);

  useEffect(() => {
    getMandalMeetingApi(id, curDate);
  }, [id, refresh]);

  const getMandalMeetingApi = (id, date) => {
    setLoading(true);
    getMandalWednesdayMeeting(id, date)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const columns = [
    {
      title: "Secretariat Name",
      dataIndex: "secretariat_name",
      key: "secretariat_name",
      width: 180,
      fixed: "left",
    },
    {
      title: "Secretariat Meeting Conducted Date",
      dataIndex: "meeting_date",
      key: "meeting_date",
      width: 150,
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "Not conducted yet"),
    },
    {
      title: "Mobile Not Working",
      children: [
        {
          title: "Reported Count",
          dataIndex: "mobiles_not_working",
          key: "mobiles_not_working",
          width: 130,
          sorter: (a, b) => a.mobiles_not_working - b.mobiles_not_working,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "mobiles_not_working_redressed_at_secretariat_level",
          key: "mobiles_not_working_redressed_at_secretariat_level",
          width: 180,
          sorter: (a, b) =>
            a.mobiles_not_working_redressed_at_secretariat_level -
            b.mobiles_not_working_redressed_at_secretariat_level,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "mobiles_not_working_redressed_at_mandal_level",
          key: "mobiles_not_working_redressed_at_mandal_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.mobiles_not_working_redressed_at_mandal_level -
            b.mobiles_not_working_redressed_at_mandal_level,
        },
      ],
    },
    {
      title: "Sim Not Working",
      children: [
        {
          title: "Reported Count",
          dataIndex: "sims_not_working",
          key: "sims_not_working",
          width: 130,
          sorter: (a, b) => a.sims_not_working - b.sims_not_working,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "sims_not_working_redressed_at_secretariat_level",
          key: "sims_not_working_redressed_at_secretariat_level",
          width: 180,
          sorter: (a, b) =>
            a.sims_not_working_redressed_at_secretariat_level -
            b.sims_not_working_redressed_at_secretariat_level,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "sims_not_working_redressed_at_mandal_level",
          key: "sims_not_working_redressed_at_mandal_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.sims_not_working_redressed_at_mandal_level -
            b.sims_not_working_redressed_at_mandal_level,
        },
      ],
    },
    {
      title: "FP Scanner Not Working",
      children: [
        {
          title: "Reported Count",
          dataIndex: "fp_scanners_not_working",
          key: "fp_scanners_not_working",
          width: 150,
          sorter: (a, b) => a.fp_scanners_not_working - b.fp_scanners_not_working,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "fp_scanners_not_working_redressed_at_secretariat_level",
          key: "fp_scanners_not_working_redressed_at_secretariat_level",
          width: 190,
          sorter: (a, b) =>
            a.fp_scanners_not_working_redressed_at_secretariat_level -
            b.fp_scanners_not_working_redressed_at_secretariat_level,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "fp_scanners_not_working_redressed_at_mandal_level",
          key: "fp_scanners_not_working_redressed_at_mandal_level",
          width: 190,
          editable: true,
          sorter: (a, b) =>
            a.fp_scanners_not_working_redressed_at_mandal_level -
            b.fp_scanners_not_working_redressed_at_mandal_level,
        },
      ],
    },
    {
      title: "CFMS ID Issues",
      children: [
        {
          title: "Reported Count",
          dataIndex: "cfms_id_issues",
          key: "cfms_id_issues",
          width: 130,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "cfms_id_issues_redressed_at_secretariat_level",
          key: "cfms_id_issues_redressed_at_secretariat_level",
          width: 150,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "cfms_id_issues_redressed_at_mandal_level",
          key: "cfms_id_issues_redressed_at_mandal_level",
          width: 150,
        },
      ],
    },
    {
      title: "Salary Issues",
      children: [
        {
          title: "Reported Count",
          dataIndex: "salary_issues",
          key: "salary_issues",
          width: 130,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "salary_issues_redressed_at_secretariat_level",
          key: "salary_issues_redressed_at_secretariat_level",
          width: 150,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "salary_issues_redressed_at_mandal_level",
          key: "salary_issues_redressed_at_mandal_level",
          width: 150,
        },
      ],
    },
    {
      title: "Other Issues",
      children: [
        {
          title: "Reported Count",
          dataIndex: "other_issues",
          key: "other_issues",
          width: 130,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "other_issues_redressed_at_secretariat_level",
          key: "other_issues_redressed_at_secretariat_level",
          width: 150,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "other_issues_redressed_at_mandal_level",
          key: "other_issues_redressed_at_mandal_level",
          width: 150,
        },
      ],
    },
    {
      title: "Group Photo",
      dataIndex: "group_photo",
      key: "group_photo",
      width: 250,
      render: (photo, record) =>
        record.group_photo === "" ? (
          "-"
        ) : (
          <Link to={{ pathname: record.group_photo }} target="_blank" rel="noopener noreferrer">
            Click here to view the image.
          </Link>
        ),
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 170,
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "-"),
      sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
    },
    {
      title: "Minutes Of Meeting",
      dataIndex: "minutes_of_meeting",
      key: "minutes_of_meeting",
      width: 250,
      sorter: (a, b) => a.minutes_of_meeting.localeCompare(b.minutes_of_meeting),
    },
    {
      title: "Other Issue Types",
      dataIndex: "other_outstanding_issues",
      key: "other_outstanding_issues",
      width: 250,
      render: (data, record) => <span>{record.other_outstanding_issues ? data : "-"}</span>,
      sorter: (a, b) => a.other_outstanding_issues.localeCompare(b.other_outstanding_issues),
    },
    {
      title: "Operation",
      dataIndex: "operation",
      width: 170,
      fixed: "right",
      render: (_, record) => (
        <span
          className="footer-nav-links"
          onClick={() =>
            openModal({
              title: `Secretariat - ${record?.secretariat_name}`,
              status: "wednesdayMandalMeeting",
              data: record,
            })
          }
        >
          Edit
        </span>
      ),
    },
  ];

  const openModal = (data) => {
    setEditList(data);
    setDrawerVisible(true);
  };

  function onChange(date) {
    const selectedDate = moment(date).format(monthFormat);
    getMandalMeetingApi(id, selectedDate);
  }

  return (
    <Row>
      <Col span={20}>
        <PageHeader
          className="service-request-page-header"
          onBack={() => history.push(`${location.pathname}`)}
          title="Wednesday Meetings"
        />
      </Col>
      <Col span={4} style={{ textAlign: "right", marginTop: "10px" }}>
        <DatePicker
          defaultValue={moment(curDate)}
          onChange={onChange}
          picker="month"
          allowClear={false}
        />
      </Col>
      <Col span={24}>
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          pagination={{ pageSize: 25, showSizeChanger: false }}
          scroll={isMobileOnly ? { x: 500, y: 500 } : { x: 1400, y: "80vh" }}
        />
      </Col>
      {drawerVisible && (
        <Drawer setVisible={setDrawerVisible} editList={editList} refresh={refreshPage} />
      )}
    </Row>
  );
};

export default WednesdayMeetingTable;
