import React from "react";
import { Row, Col, Typography, Divider } from "antd";
import YouTubeEmbed from "../../../blocks/YouTubeEmbed/YouTubeEmbed";
import { supportMailId } from "../../../constants/defaultKeys";

const { Title, Paragraph } = Typography;
const AddInventory = () => {
  return (
    <Row type="flex">
      <Col span={24}>
        <Title level={5}>
          After logging in, please add inventory details to the portal.
        </Title>
        <Title level={3}>Adding Inventory</Title>
        <Divider style={{ margin: "4px 0px 15px 0px" }} />
        <Paragraph>
          This includes adding serial numbers of Monitors, CPU, Printers, Mobile
          Devices, SIMs, FingerPrint Scanners, IRIS scanners etc. The only
          exception is Laminating machine which doesn't have a Serial Number.
        </Paragraph>
        <Paragraph>
          Here is a video that demonstrates adding inventory in the portal:
          <YouTubeEmbed embedId="VL__2qt8TeI" />
        </Paragraph>
        <Paragraph>
          If you are not sure which mobile number was registered for your
          secretariat, you could check it by visiting the check mobile number
          page. Ideally, you would have received a welcome message to the
          registered mobile.
        </Paragraph>

        <Title level={3}> Request For New Account</Title>
        <Paragraph>
          If you don't have an account, you may request for one by filling in
          the request for login form.
        </Paragraph>
        <Paragraph>
          By visiting the request for an account page, you will be asked to
          enter your details. We'll validate these details and give you an
          access within 2 business days. If you need something urgently, please
          feel free to contact us by mailing {supportMailId}
        </Paragraph>
        <Title level={3}> Setup Profile</Title>
        <Paragraph>
          As soon as you login to the portal, you will be asked to fill in your
          profile details. This is important to keep you notified about your
          raised requests or send any notifications to your email.{" "}
        </Paragraph>
      </Col>
    </Row>
  );
};

export default AddInventory;
