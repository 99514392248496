import React, { useState, useEffect } from "react";
import {
  Table,
  Col,
  Row,
  DatePicker,
  Menu,
  Dropdown,
  Button,
  message,
  Space,
} from "antd";
import moment from "moment";
import Drawer from "../../Drawer/Drawer";
import { isMobileOnly } from "react-device-detect";
import { useParams } from "react-router-dom";
import {
  downloadSecretariatMeetings,
  downloadMandalMeetings,
} from "../../../services/downloadFile.services";
import { getDistrictWednesdayMeeting } from "../../../services/wednesdayMeeting.services";
import { DownloadOutlined, DownOutlined } from "@ant-design/icons";
const monthFormat = "YYYY-MM";
const WednesdayMeeting = ({ id }) => {
  const query = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editList, setEditList] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(
    moment().format(monthFormat)
  );
  const [drawerVisible, setDrawerVisible] = useState(false);

  const [isDownloading, setDownloading] = useState(false);
  useEffect(() => {
    getDistrictMeetingApi(id, selectedMonth);
  }, [id, refresh]);

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const getDistrictMeetingApi = (id, date) => {
    setLoading(true);
    getDistrictWednesdayMeeting(id, date)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  function onChange(date) {
    const formatedDate = moment(date).format(monthFormat);
    setSelectedMonth(formatedDate);
    getDistrictMeetingApi(id, formatedDate);
  }

  function handleApiDownload(api, fileName, fileType, apiParams) {
    setDownloading(true);
    api(apiParams, selectedMonth)
      .then((res) => {
        let blob = res.data;
        let url = window.URL.createObjectURL(blob);
        let anchorTag = document.createElement("a");
        anchorTag.href = url;
        anchorTag.download = `${fileName}.${fileType}`;
        anchorTag.click();
        setDownloading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong, please try later");
        setDownloading(false);
      });
  }

  const columns = [
    {
      title: "Mandal Name",
      dataIndex: "mandal_name",
      key: "mandal_name",
      width: 170,
      fixed: "left",
    },
    {
      title: "District Meeting Conducted Date",
      dataIndex: "meeting_date",
      key: "meeting_date",
      width: 150,
      render: (date) =>
        date ? moment(date).format("YYYY-MM-DD") : "Not conducted yet",
    },
    {
      title: "Mobile Not Working",
      children: [
        {
          title: "Reported Count",
          dataIndex: "mobiles_not_working",
          key: "mobiles_not_working",
          width: 130,
          sorter: (a, b) => a.mobiles_not_working - b.mobiles_not_working,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "mobiles_not_working_redressed_at_secretariat_level",
          key: "mobiles_not_working_redressed_at_secretariat_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.mobiles_not_working_redressed_at_secretariat_level -
            b.mobiles_not_working_redressed_at_secretariat_level,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "mobiles_not_working_redressed_at_mandal_level",
          key: "mobiles_not_working_redressed_at_mandal_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.mobiles_not_working_redressed_at_mandal_level -
            b.mobiles_not_working_redressed_at_mandal_level,
        },
        {
          title: "Solved @ District Level",
          dataIndex: "mobiles_not_working_redressed_at_district_level",
          key: "mobiles_not_working_redressed_at_district_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.mobiles_not_working_redressed_at_district_level -
            b.mobiles_not_working_redressed_at_district_level,
        },
      ],
    },
    {
      title: "Sim Not Working",
      children: [
        {
          title: "Reported Count",
          dataIndex: "sims_not_working",
          key: "sims_not_working",
          width: 130,
          sorter: (a, b) => a.sims_not_working - b.sims_not_working,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "sims_not_working_redressed_at_secretariat_level",
          key: "sims_not_working_redressed_at_secretariat_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.sims_not_working_redressed_at_secretariat_level -
            b.sims_not_working_redressed_at_secretariat_level,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "sims_not_working_redressed_at_mandal_level",
          key: "sims_not_working_redressed_at_mandal_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.sims_not_working_redressed_at_mandal_level -
            b.sims_not_working_redressed_at_mandal_level,
        },
        {
          title: "Solved @ District Level",
          dataIndex: "sims_not_working_redressed_at_district_level",
          key: "sims_not_working_redressed_at_district_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.sims_not_working_redressed_at_district_level -
            b.sims_not_working_redressed_at_district_level,
        },
      ],
    },
    {
      title: "FP Scanner Not Working",
      children: [
        {
          title: "Reported Count",
          dataIndex: "fp_scanners_not_working",
          key: "fp_scanners_not_working",
          width: 150,
          sorter: (a, b) =>
            a.fp_scanners_not_working - b.fp_scanners_not_working,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "fp_scanners_not_working_redressed_at_secretariat_level",
          key: "fp_scanners_not_working_redressed_at_secretariat_level",
          width: 190,
          editable: true,
          sorter: (a, b) =>
            a.fp_scanners_not_working_redressed_at_secretariat_level -
            b.fp_scanners_not_working_redressed_at_secretariat_level,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "fp_scanners_not_working_redressed_at_mandal_level",
          key: "fp_scanners_not_working_redressed_at_mandal_level",
          width: 190,
          editable: true,
          sorter: (a, b) =>
            a.fp_scanners_not_working_redressed_at_mandal_level -
            b.fp_scanners_not_working_redressed_at_mandal_level,
        },
        {
          title: "Solved @ District Level",
          dataIndex: "fp_scanners_not_working_redressed_at_district_level",
          key: "fp_scanners_not_working_redressed_at_district_level",
          width: 190,
          editable: true,
          sorter: (a, b) =>
            a.fp_scanners_not_working_redressed_at_district_level -
            b.fp_scanners_not_working_redressed_at_district_level,
        },
      ],
    },
    {
      title: "CFMS ID Issues",
      children: [
        {
          title: "Reported Count",
          dataIndex: "cfms_id_issues",
          key: "cfms_id_issues",
          width: 130,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "cfms_id_issues_redressed_at_secretariat_level",
          key: "cfms_id_issues_redressed_at_secretariat_level",
          width: 180,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "cfms_id_issues_redressed_at_mandal_level",
          key: "cfms_id_issues_redressed_at_mandal_level",
          width: 180,
        },
        {
          title: "Solved @ District Level",
          dataIndex: "cfms_id_issues_redressed_at_district_level",
          key: "cfms_id_issues_redressed_at_district_level",
          width: 180,
        },
      ],
    },
    {
      title: "Salary Issues",
      children: [
        {
          title: "Reported Count",
          dataIndex: "salary_issues",
          key: "salary_issues",
          width: 130,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "salary_issues_redressed_at_secretariat_level",
          key: "salary_issues_redressed_at_secretariat_level",
          width: 180,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "salary_issues_redressed_at_mandal_level",
          key: "salary_issues_redressed_at_mandal_level",
          width: 180,
        },
        {
          title: "Solved @ District Level",
          dataIndex: "salary_issues_redressed_at_district_level",
          key: "salary_issues_redressed_at_district_level",
          width: 180,
        },
      ],
    },
    {
      title: "Other Issues",
      children: [
        {
          title: "Reported Count",
          dataIndex: "other_issues",
          key: "other_issues",
          width: 130,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "other_issues_redressed_at_secretariat_level",
          key: "other_issues_redressed_at_secretariat_level",
          width: 180,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "other_issues_redressed_at_mandal_level",
          key: "other_issues_redressed_at_mandal_level",
          width: 180,
        },
        {
          title: "Solved @ District Level",
          dataIndex: "other_issues_redressed_at_district_level",
          key: "other_issues_redressed_at_district_level",
          width: 180,
        },
      ],
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 170,
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "-"),
      sorter: (a, b) =>
        moment(a.updated_at).unix() - moment(b.updated_at).unix(),
    },
    {
      title: "Minutes Of Meeting",
      dataIndex: "minutes_of_meeting",
      key: "minutes_of_meeting",
      width: 250,
      sorter: (a, b) =>
        a.minutes_of_meeting.localeCompare(b.minutes_of_meeting),
    },
    {
      title: "Other Outstanding Issues",
      dataIndex: "other_outstanding_issues",
      key: "other_outstanding_issues",
      width: 250,
      render: (data, record) => (
        <span>{record.other_outstanding_issues ? data : "-"}</span>
      ),
      sorter: (a, b) =>
        a.other_outstanding_issues.localeCompare(b.other_outstanding_issues),
    },
    {
      title: "Enter solved issues @ district level",
      dataIndex: "operation",
      width: 170,
      fixed: "right",
      render: (_, record) => (
        <span
          className="footer-nav-links"
          onClick={() =>
            openModal({
              title: `Mandal - ${record?.mandal_name}`,
              status: "wednesdayDistrictMeeting",
              data: record,
            })
          }
        >
          {record?.id ? "Update" : "Add"}
        </span>
      ),
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item
        key="Download_Mandal_Meeting_Stats"
        icon={<DownloadOutlined />}
        onClick={() => {
          handleApiDownload(
            downloadMandalMeetings,
            "Mandal-meeting-data",
            "xlsx",
            query.id
          );
        }}
      >
        Mandal Meeting Stats
      </Menu.Item>
      <Menu.Item
        key="Download_Secretariat_Meeting_Stats"
        icon={<DownloadOutlined />}
        onClick={() => {
          handleApiDownload(
            downloadSecretariatMeetings,
            "Secretariat-meeting-data",
            "xlsx",
            query.id
          );
        }}
      >
        Secretariat Meeting Stats
      </Menu.Item>
    </Menu>
  );
  const openModal = (data) => {
    setEditList(data);
    setDrawerVisible(true);
  };

  return (
    <Row>
      <Col span={24} style={{ textAlign: "right", marginTop: "10px" }}>
        <Space>
          <DatePicker
            defaultValue={moment(selectedMonth)}
            onChange={onChange}
            picker="month"
            allowClear={false}
          />
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button
              loading={isDownloading}
              type="primary"
              icon={<DownloadOutlined />}
            >
              Downloads <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      </Col>
      <Col span={24}>
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          pagination={{ pageSize: 25, showSizeChanger: false }}
          scroll={isMobileOnly ? { x: 500, y: 500 } : { x: 1400, y: "80vh" }}
        />
      </Col>
      {drawerVisible && (
        <Drawer
          setVisible={setDrawerVisible}
          editList={editList}
          refresh={refreshPage}
        />
      )}
    </Row>
  );
};

export default WednesdayMeeting;
