import React from "react";
import { Drawer } from "antd";
import { isMobileOnly } from "react-device-detect";
import InventoryForm from "../VS/InventoryList/InventoryForm";
import InternetModal from "../VS/Internet/InternetModal";
import CertificateModal from "../VS/Certificates/CertificateModal";
import VendorTableFilter from "../Vendor/VendorTable/VendorTableFilter";
import FileDownloadWithFilters from "../FileDownloadWithFilters/FileDownloadWithFilters";
import DeleteInventoryModal from "../VS/InventoryList/DeleteInventoryModal";
import WednesdayMeetingsForm from "../VS/WednesdayMettings/WednesdayMeetingsForm";
import WednesdayMandalMeetingForm from "../Mandal/WednesdayMeeting/WednesdayMeetingForm";
import WednesdayDistrictMeetingForm from "../District/WednesdayMeeting/WednesdayMeetingForm";
import MandalMeetingForm from "../Mandal/WednesdayMeeting/MandalMeetingForm";
import DistrictMeetingForm from "../District/WednesdayMeeting/DistrictMeetingForm";
import "./Drawer.css";
import CreateClusterForm from "../VS/Cluster/CreateClusterForm";
import FunctionaryDevicesForm from "../VS/FunctionaryDevices/FunctionaryDevicesForm";
import RaiseTicketModal from "../VS/TabTicket/RaiseTicketModal";
import UpdateTicketModal from "../VS/TabTicket/UpdateTicketModal";
import RaiseAekDcTicket from "../Vendor/AekVendor/RaiseAekDcTicket";

const DrawerForm = ({
  setVisible,
  editList,
  data,
  refresh,
  id,
  userDetails,
  inventoryData,
  data_for,
  setFilterData,
  setEditList,
  tab,
  aekUsers,
}) => {
  const onClose = () => {
    setVisible(false);
  };
  return (
    <Drawer
      title={editList && editList.title}
      className={isMobileOnly ? "mobile-drawer" : "desktop-drawer"}
      placement={isMobileOnly ? "bottom" : "right"}
      closable={true}
      onClose={onClose}
      visible={true}
    >
      {editList?.status === "internet" ? (
        <InternetModal
          data={data?.internet_connection}
          internetEventType={editList?.action}
          refresh={refresh}
          id={id}
          setVisible={setVisible}
        />
      ) : editList?.status === "vendorTable" ? (
        <VendorTableFilter
          setVisible={setVisible}
          setFilterData={setFilterData}
          data_for={data_for}
        />
      ) : editList?.status === "fileDownload" ? (
        <FileDownloadWithFilters
          setVisible={setVisible}
          editList={editList}
          id={id}
          aekUsers={aekUsers}
        />
      ) : editList?.status === "delete" ? (
        <DeleteInventoryModal
          editList={editList}
          refresh={refresh}
          setVisible={setVisible}
        />
      ) : editList?.status === "certificates" ? (
        <CertificateModal
          refresh={refresh}
          id={id}
          data={data}
          setVisible={setVisible}
          hideButton={true}
        />
      ) : editList?.status === "create-wednesday-meeting" ? (
        <WednesdayMeetingsForm
          id={id}
          setVisible={setVisible}
          refresh={refresh}
          editList={editList}
        />
      ) : editList?.status === "wednesdayMandalMeeting" ? (
        <WednesdayMandalMeetingForm
          refresh={refresh}
          setVisible={setVisible}
          editList={editList}
        />
      ) : editList?.status === "mandalMeeting" ? (
        <MandalMeetingForm
          refresh={refresh}
          setVisible={setVisible}
          editList={editList}
          id={id}
        />
      ) : editList?.status === "districtMeeting" ? (
        <DistrictMeetingForm
          refresh={refresh}
          setVisible={setVisible}
          editList={editList}
          id={id}
        />
      ) : editList?.status === "wednesdayDistrictMeeting" ? (
        <WednesdayDistrictMeetingForm
          refresh={refresh}
          setVisible={setVisible}
          editList={editList}
        />
      ) : editList?.status.includes("cluster") ? (
        <CreateClusterForm
          refresh={refresh}
          setVisible={setVisible}
          editList={editList}
          id={id}
        />
      ) : editList?.status === "add_functionary_device" ||
        editList?.status === "update_functionary_device" ? (
        <FunctionaryDevicesForm
          refresh={refresh}
          setVisible={setVisible}
          editList={editList}
          id={id}
        />
      ) : editList?.status === "raise-ticket" ? (
        <RaiseTicketModal refresh={refresh} setVisible={setVisible} />
      ) : editList?.status === "update-ticket" ? (
        <UpdateTicketModal
          refresh={refresh}
          setVisible={setVisible}
          id={id}
          editList={editList}
        />
      ) : editList?.status === "sec_ticket" ? (
        <RaiseAekDcTicket tab={tab} setVisible={setVisible} refresh={refresh} />
      ) : (
        <InventoryForm
          editList={editList}
          data={inventoryData}
          refresh={refresh}
          id={id}
          setVisible={setVisible}
          userDetails={userDetails}
          setEditList={setEditList}
        />
      )}
    </Drawer>
  );
};

export default DrawerForm;
