import { Button, Form, Input, message, Select } from "antd";
import { Fragment } from "react";
import { deleteInventory } from "../../../services/inventory.services";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";

const { Option } = Select;

const DeleteInventoryModal = ({ setVisible, editList, refresh }) => {
  const [form] = Form.useForm();
  const userDetails = JSON.parse(getItem(USER));

  const onFinish = (values) => {
    const deleteText = values.deleteText.toLowerCase();
    if (deleteText.trim() === "delete") {
      deleteInventory(editList.id, { reason: values.reason })
        .then((response) => {
          message.success(response.data.message);
          refresh();
          form.resetFields();
          setVisible(false);
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
          console.log(error);
        });
    } else {
      message.error("please enter delete to remove  item from inventory");
    }
  };

  return (
    <Fragment>
      <p>Are you sure to remove the item from inventory?</p>
      <p>If yes, choose reason, type "delete" and submit</p>
      <Form name="basic" onFinish={onFinish} form={form}>
        <Form.Item
          name="reason"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <Select placeholder="Select Reason" size="large">
            <Option value="Submitted To MPDO/Municipal Office" key="mpdo">
              Submitted To MPDO/Municipal Office
            </Option>
            <Option value="Submitted To ZP Office" key="zp_office">
              Submitted To ZP Office
            </Option>
            <Option value="Entered By Mistake" key="mistake">
              Entered By Mistake
            </Option>
            <Option value="Replaced with new device" key="replaced">
              Replaced with new device by vendor
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="deleteText"
          extra="Please type delete to delete inventory"
          rules={[
            {
              required: true,
              message: "Please type delete to delete inventory",
            },
          ]}
        >
          <Input placeholder="Enter delete" size="large" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            disabled={!userDetails?.is_village_secretariat}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default DeleteInventoryModal;
