import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Result,
  Progress,
  Statistic,
  Tooltip,
  Skeleton,
  Typography,
  Button,
  message,
} from "antd";
import {
  InfoCircleOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { getDistrictHealthStats } from "../../../services/inventory.services";
import {
  downloadInternetDetails,
  downloadLEHSecretariats,
} from "../../../services/downloadFile.services";
import SecretariatInventory from "../Inventory/SecretariatInventory";
import Card from "../../../blocks/Card/Card";
import { useParams } from "react-router-dom";
import "../District.css";
import "../../custom.css";
import useViewAccess from "../../../hooks/useViewAccess";

const { Title } = Typography;

const Dashboard = ({
  districtInventoryStats,
  loading,
  data,
  inventoryStatsLoading,
}) => {
  const query = useParams();
  useViewAccess(
    "is_indent_authority",
    "is_district_authority",
    "is_admin",
    "is_state_executive_authority"
  );
  const [districtHealthStats, setDistrictHealthStats] = useState({});
  const [healthStatsLoading, setHealthStatsLoading] = useState(true);
  const [isDownloading, setDownloading] = useState(false);
  const [internetDownload, setInternetDownload] = useState(false);

  useEffect(() => {
    if (query) {
      getDistrictHealthStats(query.id)
        .then((response) => {
          setDistrictHealthStats(response.data);
          setHealthStatsLoading(false);
        })
        .catch((error) => {
          setHealthStatsLoading(false);
          console.error(error);
        });
    }
  }, [query]);

  const download = () => {
    setDownloading(true);
    downloadLEHSecretariats(query.id)
      .then((response) => {
        let blob = response.data;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "LEH-secretariats.xlsx";
        a.click();
        setDownloading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong, Please try after sometime.");
        setDownloading(false);
      });
  };
  const handleInternetDownload = () => {
    setInternetDownload(true);
    downloadInternetDetails(query.id)
      .then((response) => {
        let blob = response.data;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "internet-details.xlsx";
        a.click();
        setInternetDownload(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong, Please try after sometime.");
        setInternetDownload(false);
      });
  };
  return (
    <Row gutter={[16, 16]}>
      {query && query.id !== "null" ? (
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
        >
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 5 }}
            >
              <Card title="Overall Health" className="device-health">
                {healthStatsLoading ? (
                  <Skeleton active paragraph={{ rows: 1 }} />
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <Progress
                      type="dashboard"
                      strokeColor={{
                        "0%": "#108ee9",
                        "100%": "#87d068",
                      }}
                      width={80}
                      strokeWidth={14}
                      percent={
                        districtHealthStats?.device_health?.toFixed(0) || 0
                      }
                    />
                  </div>
                )}
              </Card>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 7 }}
              lg={{ span: 7 }}
            >
              <Card
                title="Secured Base Certificates"
                className="security-certificates"
              >
                {inventoryStatsLoading ? (
                  <Skeleton active paragraph={{ rows: 1 }} />
                ) : (
                  <Row
                    gutter={16}
                    type="flex"
                    align="middle"
                    style={{ textAlign: "center" }}
                  >
                    <Col span={12}>
                      <Statistic
                        title="Remaining"
                        value={
                          districtInventoryStats?.security_certificates
                            ?.remaining
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title="Damaged"
                        value={
                          districtInventoryStats?.security_certificates?.damaged
                        }
                      />
                    </Col>
                  </Row>
                )}
              </Card>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 5 }}
              lg={{ span: 5 }}
              style={{ textAlign: "center" }}
            >
              <Card
                title={
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>
                      LEH Secretariats{" "}
                      <Tooltip title="Secretariats Lacking Essential Hardware">
                        <InfoCircleOutlined style={{ fontSize: "12px" }} />
                      </Tooltip>
                    </span>
                    <Button
                      type="primary"
                      // style={{width:"50px"}}
                      size="small"
                      onClick={download}
                    >
                      {isDownloading ? (
                        <LoadingOutlined />
                      ) : (
                        <DownloadOutlined />
                      )}
                    </Button>
                  </div>
                }
                className="critical-secretariats"
              >
                {healthStatsLoading ? (
                  <Skeleton active paragraph={{ rows: 1 }} />
                ) : (
                  <Statistic
                    value={districtHealthStats?.critical_secretariats?.length}
                  />
                )}
              </Card>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 7 }}
              lg={{ span: 7 }}
            >
              <Card
                title={
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>Internet Connections</span>
                    <Button
                      type="primary"
                      // style={{width:"50px"}}
                      size="small"
                      onClick={handleInternetDownload}
                    >
                      {internetDownload ? (
                        <LoadingOutlined />
                      ) : (
                        <DownloadOutlined />
                      )}
                    </Button>
                  </div>
                }
                className="security-certificates"
              >
                {inventoryStatsLoading ? (
                  <Skeleton active paragraph={{ rows: 1 }} />
                ) : (
                  <Row
                    gutter={16}
                    type="flex"
                    align="middle"
                    style={{ textAlign: "center" }}
                  >
                    <Col span={12}>
                      <Statistic
                        title="Total"
                        value={
                          districtInventoryStats?.internet_connection
                            ?.total_connections
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        title="Poor"
                        value={
                          districtInventoryStats?.internet_connection
                            ?.poor_connections
                        }
                      />
                    </Col>
                  </Row>
                )}
              </Card>
            </Col>
            {/* <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
            >
              <Card title="Raise Indent" className="device-health"></Card>
            </Col> */}
            <Col span={24}>
              <Title level={4}>Secretariat-Wise Inventory Count</Title>
              <SecretariatInventory data={data} loading={loading} />
            </Col>
          </Row>
        </Col>
      ) : (
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 20, offset: 2 }}
          lg={{ span: 18, offset: 3 }}
        >
          <Result title="Your are not part of any district, please contact your admin" />
        </Col>
      )}
    </Row>
  );
};
export default Dashboard;
