import { logDOM } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import {
  downloadVolunteerDeviceReport,
  getVolunteerDeviceWiseReport,
} from "../../../services/inventory.services";
import { InfoCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, message, Row, Select } from "antd";
import { getRegionsData } from "../../../services/metaData.services";
const defaultVal = { label: "ALL", val: "ALL" };
const defaultRegions = { name: "ALL", id: 0 };
const { Option } = Select;
function DeviceWiseReport() {
  const [loading, setLoading] = useState(false);
  const [regionsData, setRegionsData] = useState([defaultRegions]);
  const [modal, setModal] = useState(null);
  const [selectedDist, setSelectedDist] = useState(null);

  const invTypes = [
    { name: "ALL", id: "ALL" },
    { name: "FP Scanner", id: "FP scanner" },
    { name: "Mobile", id: "Mobile" },
    { name: "SIM Card", id: "SIM Card" },
  ];
  useEffect(() => {
    getRegionsData()
      .then((response) => {
        setRegionsData([defaultRegions, ...response?.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onDistrictChange = (values) => {
    const district = regionsData?.find((item) => item?.id === values);

    setSelectedDist(district?.id);
  };
  const onInvChange = (values) => {
    const inv = invTypes?.find((item) => item?.id === values);

    setModal(inv?.id);
  };
  const handleAggregateDownload = (value) => {
    let params = {};
    if (selectedDist !== 0) {
      params.district = selectedDist;
    }
    if (modal !== "ALL") {
      params.inventory = modal;
    }
    setLoading(true);
    downloadVolunteerDeviceReport(params)
      .then((response) => response.data)
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `Inventory Collection Master Report.xlsx`;
        a.click();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);

        message.error("Something went wrong, Please try after sometime.");
      });
  };
  return (
    <Col span={24}>
      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Form.Item>
            <Select
              style={{ minWidth: 150, marginRight: "10px" }}
              placeholder="Select District"
              onChange={onDistrictChange}
              defaultValue={"ALL"}
            >
              {regionsData?.map((district) => (
                <Option value={district?.id} key={district?.id}>
                  {district?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <Select
              style={{ minWidth: 150, marginRight: "10px" }}
              placeholder="Select District"
              onChange={onInvChange}
              defaultValue={"ALL"}
            >
              {invTypes?.map((inv) => (
                <Option value={inv?.id} key={inv?.id}>
                  {inv?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Button
        loading={loading}
        type="primary"
        icon={<DownloadOutlined />}
        onClick={handleAggregateDownload}
      >
        Download Inventory Master Report
      </Button>
    </Col>
  );
}

export default DeviceWiseReport;
