import React, { useEffect, useState } from "react";
import * as config from "../../config/validation.config";
import moment from "moment";
import { getExamRecords } from "../../../src/services/serviceRequests.services";
import { DatePicker, Drawer, Form, Input, Radio, Button, message } from "antd";
import {
  createExamRecords,
  updateExamRecords,
} from "../../../src/services/serviceRequests.services";

const ExamTab = ({ isDrawerVisible, setIsDrawerVisible, curDate }) => {
  const [formValues, setFormValues] = useState(null);
  const [form] = Form.useForm();
  const [examForm, setExamForm] = useState(null);

  useEffect(() => {
    getExamRecords()
      .then((response) => {
        setExamForm(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (examForm) {
      const formValues = { ...examForm };

      if (formValues.exam_date && typeof formValues.exam_date === "string") {
        formValues.exam_date = moment(formValues.exam_date);
      }

      if (
        formValues.reexam_date &&
        typeof formValues.reexam_date === "string"
      ) {
        formValues.reexam_date = moment(formValues.reexam_date);
      }
      form.setFieldsValue(formValues);
    }
  }, [examForm]);

  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };
  const onClose = () => {
    setIsDrawerVisible(false);
  };

  const onFinish = (values) => {
    const formData = {
      ...values,
      exam_date: moment(values?.exam_date).format("YYYY-MM-DD"),
      reexam_date: moment(values?.reexam_date).format("YYYY-MM-DD"),
    };

    if (examForm) {
      updateExamRecords(formData)
        .then((response) => {
          message.success(response?.data?.message);

          onClose();
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
        });
    } else {
      createExamRecords(formData)
        .then((response) => {
          message.success(response?.data?.message);

          onClose();
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
        });
    }
  };

  return (
    <>
      <Drawer
        title="NSEIT Status"
        width={520}
        onClose={onClose}
        open={isDrawerVisible}
      >
        <Form
          scrollToFirstError
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onFormValuesChange}
          form={form}
        >
          <Form.Item
            label="DA/WEDS NAME (as per aadhaar)"
            name="name"
            value={examForm?.name}
            rules={[
              {
                required: true,
                message: "Please Enter your DA/WEDS NAME (as per aadhaar)",
              },
            ]}
          >
            <Input type="text" placeholder="DA/WEDS NAME (as per aadhaar)" />
          </Form.Item>{" "}
          <Form.Item
            label="Mobile Number"
            name="mobile_no"
            rules={config.mobileNumberRegex}
          >
            <Input
              autoFocus={true}
              autoComplete="off"
              size="large"
              maxLength="10"
              placeholder="Mobile Number"
              addonBefore={"+91"}
            />
          </Form.Item>
          <Form.Item
            label="MAIL ID"
            rules={config.emailValidation}
            name="email"
          >
            <Input type="text" placeholder="Enter Your mail id" />
          </Form.Item>
          <Form.Item
            label="AADHAAR Number"
            name="aadhaar"
            rules={[
              {
                required: true,
                message: "Please enter your AADHAAR number",
              },
              {
                pattern: new RegExp("^[\\d]{12}$|^XXXXXXXX[\\d]{4}$"),
                message: "Please enter a valid AADHAAR number",
              },
              {
                whitespace: true,
                message: "Please enter a valid AADHAAR number",
              },
            ]}
          >
            <Input placeholder="Enter your AADHAAR number" />
          </Form.Item>
          <Form.Item
            label="REGISTERED FOR AADHAAR EXAM"
            name="registered_for_aadhaar"
          >
            <Radio.Group>
              <Radio value={true}> Yes </Radio>
              <Radio value={false}> No </Radio>
            </Radio.Group>
          </Form.Item>
          {(formValues?.registered_for_aadhaar ||
            (examForm?.registered_for_aadhaar &&
              formValues?.registered_for_aadhaar !== false)) && (
            <>
              <Form.Item label="NSEIT USER  ID" name="nseit_id">
                <Input placeholder=" Enter NSEIT USER  ID" />
              </Form.Item>
              <Form.Item label="EXAM FEE PAID" name="fee_paid">
                <Radio.Group>
                  <Radio value={true}> Yes </Radio>
                  <Radio value={false}> No </Radio>
                </Radio.Group>
              </Form.Item>
              {(formValues?.fee_paid ||
                (examForm?.fee_paid && formValues?.fee_paid !== false)) && (
                <>
                  <Form.Item label="EXAM SLOT BOOKED" name="slot_booked">
                    <Radio.Group>
                      <Radio value={true}> Yes </Radio>
                      <Radio value={false}> No </Radio>
                    </Radio.Group>
                  </Form.Item>
                  {(formValues?.slot_booked ||
                    (examForm?.slot_booked &&
                      formValues?.slot_booked !== false)) && (
                    <>
                      <Form.Item label="EXAM DATE" name="exam_date">
                        <DatePicker />
                      </Form.Item>

                      <Form.Item
                        label="Did you attend the exam?"
                        name="attended_exam"
                      >
                        <Radio.Group>
                          <Radio value={true}> Yes </Radio>
                          <Radio value={false}> No </Radio>
                        </Radio.Group>
                      </Form.Item>

                      {/* {formValues.attended_exam === true ? (
                        <Form.Item label="RESULT" name="cleared_exam">
                          <Radio.Group>
                            <Radio value={true}> PASS </Radio>
                            <Radio value={false}> FAIL </Radio>
                          </Radio.Group>
                        </Form.Item>
                      ) */}
                      {(formValues?.attended_exam ||
                        (examForm?.attended_exam &&
                          formValues?.attended_exam !== false)) && (
                        <>
                          <Form.Item label="RESULT" name="cleared_exam">
                            <Radio.Group>
                              <Radio value={true}> PASS </Radio>
                              <Radio value={false}> FAIL </Radio>
                            </Radio.Group>
                          </Form.Item>

                          {(formValues?.cleared_exam === false ||
                            (examForm?.cleared_exam === false &&
                              formValues?.cleared_exam !== true)) && (
                            <>
                              <Form.Item
                                label="FEE PAID FOR REEXAM"
                                name="fee_paid_for_reexam"
                              >
                                <Radio.Group>
                                  <Radio value={true}> Yes </Radio>
                                  <Radio value={false}> No </Radio>
                                </Radio.Group>
                              </Form.Item>
                              {(formValues?.fee_paid_for_reexam ||
                                (examForm?.fee_paid_for_reexam &&
                                  formValues?.fee_paid_for_reexam !==
                                    false)) && (
                                <Form.Item
                                  label="RE EXAM DATE (if fail)"
                                  name="reexam_date"
                                >
                                  <DatePicker />
                                </Form.Item>
                              )}
                            </>
                          )}
                          {(formValues?.cleared_exam ||
                            (examForm?.cleared_exam &&
                              formValues?.cleared_exam !== false)) && (
                            <>
                              <Form.Item
                                label="PASS CERTIFICATE"
                                name="certificate_id"
                              >
                                <Input placeholder="Enter Your Certificate Id" />
                              </Form.Item>
                              <Form.Item label="Remarks" name="remarks">
                                <Input.TextArea rows={4} />
                              </Form.Item>{" "}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};
export default ExamTab;
