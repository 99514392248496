import React, { useEffect, useState } from "react";
import { Row, Col, Space, Table, Breadcrumb, Tag } from "antd";
import { useParams, useLocation, Link, useHistory } from "react-router-dom";
import { tabsRegionWiseAggregates } from "../../../services/inventory.services";
import { tabsAggregateColumns } from "../../../helpers/TableColumns";
import "./TabsData.css";
const TabsData = () => {
  const history = useHistory();
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search));
  const comp = Object.keys(params);
  const query = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigation = (data) => {
    history.push(data.path);
  };

  useEffect(() => {
    setLoading(true);
    tabsRegionWiseAggregates(
      comp?.includes("district") && !comp?.includes("mandal")
        ? `district=${params?.district}`
        : comp?.includes("mandal") && `mandal=${params?.mandal}`
    )
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [params?.district, params?.mandal]);
  const columns = [
    {
      title: "District name",
      key: "display_name",
      dataIndex: "display_name",
      defaultSortOrder: "ascend",
      width: 150,
      sorter: (a, b) => a?.display_name?.localeCompare(b?.display_name),
      render: (action, record) => {
        return (
          <Link
            to={`/tabs/aggregate?district_name=${record?.display_name}&district=${record?.row_id}`}
          >
            {action}
          </Link>
        );
      },
    },
    ...tabsAggregateColumns,
  ];
  const mandalColumn = [
    {
      title: "Mandal name",
      key: "display_name",
      dataIndex: "display_name",
      defaultSortOrder: "ascend",
      width: 150,
      render: (action, record) => action,
      sorter: (a, b) => a?.display_name?.localeCompare(b?.display_name),
      // render: (action, record) => {
      //   return (
      //     <Link
      //       to={`/tabs/aggregate?district_name=${params?.district_name}&district=${params?.district}&mandal_name=${record?.display_name}&mandal=${record?.row_id}`}
      //     >
      //       {action}
      //     </Link>
      //   );
      // },
    },
    ...tabsAggregateColumns,
  ];
  const secretariatColumn = [
    {
      title: "Secretariat name",
      key: "display_name",
      dataIndex: "display_name",
      defaultSortOrder: "ascend",
      width: 150,
      // sorter: (a, b) => a?.display_name.localeCompare(b?.display_name),
      render: (action, record) => action,
    },
    ...tabsAggregateColumns,
  ];

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={22} offset={1}>
          {comp?.includes("district") && !comp?.includes("mandal") && (
            <Breadcrumb className="breadcrumb">
              <Space>
                <Breadcrumb.Item
                  style={{ cursor: "pointer" }}
                  onClick={() => navigation({ path: `/tabs/aggregate` })}
                >
                  State
                </Breadcrumb.Item>
                <>{" > "}</>
                <Breadcrumb.Item>
                  <Tag color="blue">{params?.district_name}</Tag>
                  <span className="region-subtext">District</span>
                </Breadcrumb.Item>
              </Space>
            </Breadcrumb>
          )}

          {comp?.includes("mandal") && (
            <Breadcrumb className="breadcrumb">
              <span>
                <Space>
                  <Breadcrumb.Item
                    style={{ cursor: "pointer" }}
                    onClick={() => navigation({ path: `/tabs/aggregate` })}
                  >
                    State
                  </Breadcrumb.Item>
                  <>{" > "}</>
                  <Breadcrumb.Item
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigation({
                        path: `/tabs/aggregate?district=${params?.district}&district_name=${params?.district_name}`,
                      })
                    }
                  >
                    <Tag color="blue">{params?.district_name}</Tag>
                    <span className="region-subtext">District</span>
                  </Breadcrumb.Item>
                  <>{" > "}</>
                  <Breadcrumb.Item>
                    {" "}
                    <Tag color="green">{params?.mandal_name}</Tag>
                    <span className="region-subtext">Mandal</span>
                  </Breadcrumb.Item>
                </Space>
              </span>
            </Breadcrumb>
          )}
        </Col>
        <Col span={22} offset={1}>
          <Table
            className="table-header"
            bordered
            dataSource={data}
            scroll={{ y: 600 }}
            columns={
              comp?.includes("district") && !comp?.includes("mandal")
                ? mandalColumn
                : comp?.includes("district") && comp?.includes("mandal")
                ? secretariatColumn
                : columns
            }
            pagination={false}
            loading={loading}
            summary={(pageData) => {
              let raised = 0;
              let submitted = 0;
              let closed = 0;
              let repair_and_fixed = 0;
              let replaced = 0;
              let not_covered_under_warranty = 0;
              pageData.forEach((item) => {
                raised += item?.raised;
                submitted += item?.submitted;
                closed += item?.closed;
                repair_and_fixed += item?.repair_and_fixed;
                replaced += item?.replaced;
                not_covered_under_warranty += item?.not_covered_under_warranty;
              });
              return (
                <Table.Summary fixed="top">
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell>{raised}</Table.Summary.Cell>
                    <Table.Summary.Cell>{submitted}</Table.Summary.Cell>
                    <Table.Summary.Cell>{closed}</Table.Summary.Cell>
                    <Table.Summary.Cell>{repair_and_fixed}</Table.Summary.Cell>
                    <Table.Summary.Cell>{replaced}</Table.Summary.Cell>
                    <Table.Summary.Cell>
                      {not_covered_under_warranty}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default TabsData;
