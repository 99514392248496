import { Fragment, useEffect } from "react";
import { Form, Button, DatePicker, Select } from "antd";
import { getAllInventoryIssues } from "../../../services/issue.services";
import moment from "moment";
import { defaultIssueStatus } from "../../../constants/defaultKeys";
var startDate = null;
var endDate = null;
var ticketStatus = "none";
const { Option } = Select;
const { RangePicker } = DatePicker;

const VendorTableFilter = ({
  data_for,
  setFilterData,
  setFilterLoading,
  districtId,
  aekUsers,
}) => {
  useEffect(() => {
    setFilterLoading(true);
    getAllInventoryIssues({
      ticket_status: ticketStatus,
      start_date: startDate,
      end_date: endDate,
      data_for: data_for,
      district_id: districtId,
      filter_ak: aekUsers ? "-AK" : "",
    })
      .then((response) => {
        setFilterData(response.data);
        setFilterLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setFilterLoading(false);
      });
  }, [startDate, endDate]);

  function disabledDate(current) {
    return current && current.valueOf() > Date.now();
  }

  const onFinish = (values) => {
    const date = values["date"];
    startDate = date[0].format("YYYY-MM-DD");
    endDate = date[1].format("YYYY-MM-DD");
    const formData = {
      ...values,
      start_date: startDate,
      end_date: endDate,
    };
    ticketStatus = formData.ticket_status;

    setFilterLoading(true);
    getAllInventoryIssues({
      ticket_status: formData.ticket_status,
      start_date: startDate,
      end_date: endDate,
      data_for: data_for,
      district_id: districtId,
      filter_ak: aekUsers ? "-AK" : "",
    })
      .then((response) => {
        setFilterData(response.data);
        setFilterLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setFilterLoading(false);
      });
  };
  const filterKey = [
    ...defaultIssueStatus,
    {
      text: "Reopened",
      value: "Re-Opened",
    },
    {
      text: "Waiting On User",
      value: "waiting-on-user",
    },
  ];

  return (
    <Fragment>
      <Form
        name="basic"
        onFinish={onFinish}
        layout="inline"
        initialValues={{
          ticket_status: [
            "Open",
            "InProgress",
            "Forwarded",
            "Closed",
            "Re-Opened",
            "waiting-on-user",
          ],
          date: [
            startDate ? moment(startDate) : moment().subtract(30, "days"),
            endDate ? moment(endDate) : moment(),
          ],
        }}
      >
        {data_for !== "state" && data_for !== "district" && (
          <Form.Item
            name="ticket_status"
            rules={[
              { required: true, message: "Please choose required fields" },
            ]}
          >
            <Select
              mode="multiple"
              maxTagCount="responsive"
              style={{ minWidth: "400px" }}
              showArrow
            >
              {filterKey.map((item) => (
                <Option value={item.value} key={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="date"
          rules={[{ required: true, message: "Please choose end date" }]}
        >
          <RangePicker disabledDate={disabledDate} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Go
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default VendorTableFilter;
