import React from "react";
import { Result, Button } from "antd";

const SomethingWentWrong = () => {
  const onClick = () => {
    window.history.go(-1);
  };

  return (
    <Result
      status="500"
      subTitle="Sorry, something went wrong."
      extra={
        <Button type="primary" onClick={onClick}>
          Try Again
        </Button>
      }
    />
  );
};

export default SomethingWentWrong;
