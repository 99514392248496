import React from "react";
import { Table } from "antd";
function LGMobile() {
  const cols = [
    {
      title: "District",
      dataIndex: "A",
      key: "A",
      filters: [
        {
          text: "Anantapur",
          value: "Anantapur",
        },
        {
          text: "Chittoor",
          value: "Chittoor",
        },
        {
          text: "East Godavari",
          value: "East Godavari",
        },
        {
          text: "Guntur",
          value: "Guntur",
        },
        {
          text: "Kadapa",
          value: "Kadapa",
        },
        {
          text: "Krishna",
          value: "Krishna",
        },
        {
          text: "Kurnool",
          value: "Kurnool",
        },
        {
          text: "Nellore",
          value: "Nellore",
        },
        {
          text: "Prakasam",
          value: "Prakasam",
        },
        {
          text: "Srikakulam",
          value: "Srikakulam",
        },
        {
          text: "Visakhapatnam",
          value: "Visakhapatnam",
        },
        {
          text: "Vizianagaram",
          value: "Vizianagaram",
        },
        {
          text: "West Godavari",
          value: "West Godavari",
        },
      ],
      onFilter: (value, record) => record.A.indexOf(value) === 0,
    },
    {
      title: "City",
      dataIndex: "B",
      key: "B",
    },
    {
      title: "LSC Name",
      dataIndex: "C",
      key: "C",
    },
    {
      title: "Address",
      dataIndex: "D",
      key: "D",
    },
    {
      title: "PIN-Code",
      dataIndex: "E",
      key: "E",
    },
    {
      title: "Service center Tel",
      dataIndex: "F",
      key: "F",
    },
    {
      title: "State",
      dataIndex: "G",
      key: "G",
    },
    {
      title: "Hours of Operation",
      dataIndex: "H",
      key: "H",
    },
    {
      title: "WeeklyOff",
      dataIndex: "I",
      key: "I",
    },
  ];
  const ServiceCenters = [
    {
      A: "Anantapur",
      B: "Ananthapuram",
      C: "Divya Lakshmi Electronics",
      D: "DOOR NO : 15-151, SHOP NO : 2, LS TOWERS, SUBHASH ROAD,ANANTAPUR -515001",
      E: "515001",
      F: "9133353300 8554222029",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Chittoor",
      B: "Tirupathi",
      C: "Shiva Communications",
      D: "SHOP NO.202,#580 TILAK ROAD,CENTRAL PARK,1ST FLOOR,THIRUPATHI",
      E: "517501",
      F: "9030201414 8776646141",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Guntur",
      B: "Guntur",
      C: "Vasavi Associates",
      D: "Door No.6-7-36, V.S.R.Complex,8Th Line,Arundelpet, Guntur - 522 002",
      E: "522002",
      F: "9395311555 9397602470",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Kadapa",
      B: "Kadapa",
      C: "Vijayalakshmi Communications",
      D: "4/781 siva lingam street,Opp: Nagendra prasad hospital",
      E: "516001",
      F: "9491944077 9491944377",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Krishna",
      B: "Vijayawada",
      C: "Sri Jishnu communications",
      D: "29-5-28.Cherukupalli vari Street Governor pet, Elruru Road Nr. Vijaya Takies, Vijayawada,Pincode:520002",
      E: "520002",
      F: "9030713861 9676822345",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Kurnool",
      B: "Kurnool",
      C: "PADMAJA ELEC & REF SERVICES",
      D: "D.No: 43-8-F-6, Simhapuri Colony,NARASINGARAO PETA KURNOOL -518004",
      E: "518004",
      F: "9704000277 7702202890",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },   
    {
      A: "Nellore",
      B: "Nellore",
      C: "Mobile Communications",
      D: "DR.NO.15/473, SHOP NO.9,AZAD STREET,WORLD OF TITAN LEFT.KANAKAMAHAL CENTRE,NELLORE:524001 NELLORE, ANDHRA PRADESH",
      E: "524001",
      F: "9391998333 8074666169",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },   
    {
      A: "Prakasam",
      B: "Ongole",
      C: "Sai Consumer Service",
      D: "D. No : 34-061-31, 7th Line, Srinagar Colony, Kurnool Road, ONGOLE - 523002",
      E: "523002",
      F: "9866882387",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },       
    {
      A: "Visakhapatnam",
      B: "Visakhapatnam",
      C: "Arun Technologies",
      D: "D.NO: 29-6-25, 1ST FLOOR, SHOP NO: 2, OPP: AMARAVATHI HOTEL, DABAGARDENS",
      E: "530020",
      F: "8519930888 8916008777",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },    
    {
      A: "Vizianagaram",
      B: "Vizianagaram",
      C: "Sri Gayatri Mobiles",
      D: "SHOP NO 8, GVR COMPLEX, BESIDE KK20 & PONDURU CHENETA VASTRALAYAM, MG ROAD, VIZIANAGARAM",
      E: "535003",
      F: "8977178779 8922270777",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },   
    {
      A: "West Godavari",
      B: "Eluru",
      C: "Sri Lakshmi Srinivasa Electronics",
      D: "D. No : 20C-1-17, Bosu Sibiram, Beside Powerpet Railway Gate, Gandhi Nagar, Eluru–534002",
      E: "534002",
      F: "9849841666",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },  
  ];
  return (
    <div>
      <h1>Mobile</h1>

      <p>
        {" "}
        For mobile repairs, please approach one of the authorized service
        centers listed below. If the service center is charging money for
        service other than physical damage, liquid damage and unauthorized
        service repair, please call <a href="tel:9052345678">
          +91 9052345678
        </a>{" "}
        for resolution.
      </p>
      <Table columns={cols} dataSource={ServiceCenters} />
    </div>
  );
}

export default LGMobile;