import React from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Typography,
  Divider,
} from "antd";
import Card from "../../blocks/Card/Card";
import { LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/images/Editedlogo.png";
import { setProfile } from "../../services/auth.services";
import { publicKey, USER } from "../../constants/defaultKeys";
import { getItem } from "../../helpers/localStorage";
import { isMobileOnly } from "react-device-detect";
import { JSEncrypt } from "jsencrypt";
const { Title } = Typography;
var encrypt = new JSEncrypt();
encrypt.setPublicKey(publicKey);
const SetupProfile = () => {
  const history = useHistory();
  const data = JSON.parse(getItem(USER));

  const onSetupProfile = (values) => {
    setProfile({
      ...values,
      new_password: encrypt.encrypt(values?.new_password),
      new_password_confirm: encrypt.encrypt(values?.new_password_confirm),
    })
      .then((response) => {
        message.success(response.data.message);
        if (
          data.region === null &&
          !(
            data.is_vendor ||
            data?.is_aek_hod_dept ||
            data?.is_aek_hod ||
            data?.is_tabs_admin
          )
        ) {
          history.push(`/state/dashboard`);
        } else {
          if (data.is_village_secretariat)
            history.push(
              `/village-secretariat/${data.region.region_name}/${data.region.region_id}/`
            );
          if (data.is_mandal_authority)
            history.push(
              `/mandal/${data.region.region_name}/${data.region.region_id}/`
            );
          if (data.is_district_authority || data.is_indent_authority)
            history.push(
              `/district/${data.region.region_name}/${data.region.region_id}/`
            );
          if (data.is_admin) history.push(`/state/dashboard`);
          if (data?.is_aek_hod_dept || data?.is_aek_dc || data?.is_aek_hod)
            history.push(`/aek-vendor`);
          if (data.is_vendor) history.push(`/vendor`);
          if (data?.is_tabs_admin) history.push(`/tabs/aggregate`);
          if (data.is_telecom_operator) history.push(`/sim-requests`);
          if (data.is_uniform_supplier) history.push(`/uniform-vendor`);
          if (data.is_office365_vendor) history.push(`/office-365`);
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        console.log(error);
      });
  };

  return (
    <Row type="flex" justify="center" align="middle" className="login">
      <Col
        lg={{ span: 8, offset: 8 }}
        md={{ span: 12, offset: 6 }}
        sm={{ span: 20, offset: 2 }}
        xs={{ span: 20, offset: 2 }}
        style={{ marginLeft: 0 }}
      >
        <Card bordered={false}>
          <div style={{ textAlign: "center", paddingBottom: "20px" }}>
            {isMobileOnly ? (
              <img src={Logo} alt="check logo" height="48px" />
            ) : (
              <img src={Logo} alt="check logo" height="72px" />
            )}
          </div>

          <div style={{ textAlign: "center" }}>
            <Title level={3} style={{ color: "#666" }}>
              Set Your Profile
            </Title>
          </div>

          <Form name="login" onFinish={onSetupProfile}>
            <Form.Item
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please input your first name",
                },
              ]}
            >
              <Input autoFocus={true} placeholder="First Name" size="large" />
            </Form.Item>
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please input your last name",
                },
              ]}
            >
              <Input placeholder="Last Name" size="large" />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              <Input size="large" placeholder="Email" />
            </Form.Item>
            <Divider orientation="center" plain>
              Please set your password for email login
            </Divider>

            <Form.Item
              name="new_password"
              rules={[
                {
                  required: true,
                  message:
                    "Use 8 or more characters with a mix of uppercase and lowercase letters, numbers, and symbols.",
                  pattern: new RegExp(
                    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                  ),
                },
              ]}
            >
              <Input.Password
                autoFocus={true}
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="New Password"
                size="large"
                type="password"
              />
            </Form.Item>
            <Form.Item
              name="new_password_confirm"
              rules={[
                { required: true, message: "Please input your Password!" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The new passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Confirm Password"
                size="large"
              />
            </Form.Item>

            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                block
                className="login-form-button"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
export default SetupProfile;
