import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Row,
  message,
  Typography,
  Tooltip,
  Alert,
  Col,
} from "antd";
import {
  addInventory,
  editInventory,
} from "../../../services/inventory.services";
import ReportForm from "./ReportForm";
import { isMobileOnly } from "react-device-detect";
import { akDevices, devices } from "../../../constants/devices";
import * as config from "../../../config/validation.config";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";
import "./InventoryList.css";

const { Option } = Select;
const { Paragraph, Text } = Typography;

const InventoryForm = ({
  setVisible,
  editList,
  data,
  refresh,
  id,
  setEditList,
}) => {
  const aadharDevices = editList?.isAadharSecretariat ? akDevices : null;
  const allDevices = { ...devices, ...aadharDevices };
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);
  const [deviceMake, setDeviceMake] = useState([]);
  const [deviceModel, setDeviceModel] = useState([]);
  const [curSelectedListData, setCurSelectedListData] = useState(null);
  const [buttonDetails, setButtonDetails] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isSelectedDeviceWorking, setSelectedDeviceWorking] =
    useState("working");
  const [selectedInventoryMode, setSelectedInventoryMode] =
    useState("inventory");

  const userDetails = JSON.parse(getItem(USER));

  useEffect(() => {
    if (editList && data) {
      const formData = data.find((item) => item.id === editList.id);
      setCurSelectedListData(formData?.inventory_type);

      if (editList.status === "edit") {
        const makeModelValues =
          allDevices[formData?.inventory_type]?.makenmodels[formData.make]
            ?.models;
        form.setFieldsValue({
          inventory_type: formData.inventory_type,
          device_id: formData.device_id,
          device_source: formData.device_source,
          status: formData.status,
          make: formData.make,
          model: formData.model,
        });
        setDeviceModel(makeModelValues);
      }
    }
  }, [editList, data]);

  useEffect(() => {
    if (curSelectedListData !== null) {
      const makeModalKeys = Object.keys(
        allDevices[curSelectedListData]?.makenmodels
      );
      setDeviceMake(makeModalKeys);

      if (editList.status === "create") {
        const makeModelValues =
          allDevices[curSelectedListData]?.makenmodels[
            makeModalKeys && makeModalKeys[0]
          ]?.models;

        // setDeviceModel(makeModelValues);
        // form.setFieldsValue({
        //   make: makeModalKeys && makeModalKeys[0],
        //   model: makeModelValues && makeModelValues[0],
        // });
      }
    }
  }, [curSelectedListData, editList]);

  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
    if (changedValues.inventory_type) {
      const inventoryType = changedValues.inventory_type;
      form.resetFields();
      setFormValues(null);
      form.setFieldsValue({
        inventory_type: inventoryType,
      });

      setCurSelectedListData(inventoryType);
    }
    if (changedValues?.make) {
      let model = formValues?.model;
      form.setFieldsValue({ model: null });
    }
  };

  const onFinish = (values) => {
    let formData = {
      ...values,
      village_secretariat: id,
    };

    if (editList.status === "create") {
      setButtonLoading(true);
      addInventory(formData)
        .then((response) => {
          setButtonLoading(false);
          const data = response.data;
          if (buttonDetails.status === "submitAndReport") {
            refresh();
            setEditList({
              ...editList,
              id: data.inventory_id,
              status: "report",
              title: "Report an issue",
            });
          } else {
            refresh();
            setVisible(false);
          }
        })
        .catch((error) => {
          setButtonLoading(false);
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    }

    if (editList.status === "edit") {
      setButtonLoading(true);
      editInventory(editList.id, formData)
        .then(() => {
          setButtonLoading(false);
          if (buttonDetails.status === "submitAndReport") {
            refresh();
            setEditList({
              id: editList.id,
              status: "report",
              title: "Report an issue",
            });
          } else {
            refresh();
            setVisible(false);
          }
        })
        .catch((error) => {
          setButtonLoading(false);
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    }
  };

  const chooseDeviceRadioGroup = (selectedDevice) => {
    return (
      <Radio.Group size="large">
        {Object.keys(selectedDevice).map((device) => (
          <Radio.Button value={device}>
            <Tooltip
              title={
                selectedDevice[device]?.title
                  ? selectedDevice[device].title
                  : device
              }
            >
              <img
                src={selectedDevice[device].icon}
                alt="Problem"
                height={isMobileOnly ? "25px" : "40px"}
              />
            </Tooltip>
          </Radio.Button>
        ))}
      </Radio.Group>
    );
  };

  const onMakeChange = (value) => {
    const makeModelValues =
      allDevices[curSelectedListData]?.makenmodels[value]?.models;
    setDeviceModel(makeModelValues);

    // form.setFieldsValue({
    //   //model: makeModelValues && makeModelValues[0],
    // });
  };

  function selectedInventoryType(e) {
    setSelectedInventoryMode(e.target.value);
    form.resetFields();
    setCurSelectedListData(null);
  }

  return (
    <Row>
      <Col span={24}>
        {aadharDevices && editList.status === "create" && (
          <>
            <Paragraph>Select Inventory Type</Paragraph>
            <Radio.Group
              defaultValue="inventory"
              buttonStyle="solid"
              onChange={selectedInventoryType}
              style={{ marginBottom: "10px" }}
            >
              <Radio.Button value="inventory">
                Non-Aadhar Inventory
              </Radio.Button>
              <Radio.Button value="aadhar">Aadhar Inventory</Radio.Button>
            </Radio.Group>
          </>
        )}
        {editList.status === "create" || editList.status === "edit" ? (
          <Form
            name="inventory"
            className="inventory-form"
            form={form}
            onFinish={onFinish}
            onValuesChange={onFormValuesChange}
            initialValues={{
              report: "no",
              ticket_contact_person_email: userDetails?.email,
              ticket_contact_person_mobile:
                userDetails?.contact_number_unmasked,
            }}
          >
            {editList.status === "create" && (
              <Paragraph>Choose Device Type</Paragraph>
            )}
            <Form.Item
              name="inventory_type"
              hidden={editList.status === "edit"}
              rules={[
                {
                  required: true,
                  message: "Please choose device type",
                },
              ]}
            >
              {chooseDeviceRadioGroup(
                selectedInventoryMode === "inventory" ? devices : akDevices
              )}
            </Form.Item>

            {curSelectedListData !== null && (
              <>
                <Paragraph>
                  Choose{" "}
                  {curSelectedListData === "Sim"
                    ? "Network Carrier"
                    : curSelectedListData === "Mobile"
                    ? "Model"
                    : "Manufacturer"}{" "}
                </Paragraph>

                <Form.Item
                  name="make"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder="Please choose device make"
                    onChange={onMakeChange}
                  >
                    {deviceMake.map((make) => (
                      <Option value={make} key={make}>
                        {make === "Vi"
                          ? "Vi (Vodafone/Idea)"
                          : make === "HUANDA"
                          ? "HUANDA (CHANDRAHAS)"
                          : make}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {curSelectedListData === "Printer" ||
                  (curSelectedListData.includes("AK") && (
                    <>
                      <Paragraph>Device Model</Paragraph>
                      <Form.Item
                        name="model"
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          placeholder="Please choose device model"
                        >
                          {deviceModel &&
                            deviceModel.map((make) => (
                              <Option value={make} key={make}>
                                {make}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </>
                  ))}

                <Form.Item
                  name="device_id"
                  // extra={
                  //   <span>
                  //     {
                  //       allDevices[curSelectedListData]?.makenmodels[
                  //         formValues && formValues.make
                  //           ? formValues.make
                  //           : deviceMake[0]
                  //       ]?.sninfo
                  //     }
                  //     <br />

                  //     {allDevices[curSelectedListData]?.makenmodels[
                  //       formValues && formValues.make
                  //         ? formValues.make
                  //         : deviceMake[0]
                  //     ]?.sninfo_image && (
                  //       <img
                  //         style={{ pointerEvents: "none", display: "none" }}
                  //         src={
                  //           allDevices[curSelectedListData]?.makenmodels[
                  //             formValues && formValues.make
                  //               ? formValues.make
                  //               : deviceMake[0]
                  //           ]?.sninfo_image
                  //         }
                  //         alt="device"
                  //         width="300px"
                  //       />
                  //     )}
                  //   </span>
                  // }`
                  rules={
                    curSelectedListData === "Sim"
                      ? config.mobileNumberRegex
                      : curSelectedListData === "LnMachine" ||
                        formValues?.make === "IPSPL"
                      ? [
                          {
                            required: false,
                            message: "Please input your serial no!",
                            min: 5,
                          },
                        ]
                      : [
                          {
                            required: true,
                            message: "Please input your serial no!",
                            min: 5,
                          },
                        ]
                  }
                >
                  <Input
                    addonBefore={
                      editList?.status !== "create" ? (
                        <img
                          src={allDevices[curSelectedListData]?.icon}
                          alt="device"
                          height="25px"
                        />
                      ) : curSelectedListData === "Sim" ? (
                        "+91"
                      ) : null
                    }
                    size="large"
                    placeholder={
                      curSelectedListData === "Mobile"
                        ? "IMEI Number"
                        : curSelectedListData === "Sim"
                        ? "Mobile Number"
                        : "Serial No"
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="device_source"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder="Choose the device source"
                    onSelect={() => {
                      form.resetFields(["status", "report"]);
                      setSelectedDeviceWorking("working");
                    }}
                  >
                    <Option
                      value="provided_by_gsws_dept"
                      key="provided_by_gsws_dept"
                    >
                      Provided by gsws dept
                    </Option>
                    <Option
                      value="bought_by_panchayat_funds"
                      key="bought_by_panchayat_funds"
                    >
                      Bought by panchayat funds
                    </Option>
                    <Option
                      value="received_as_a_donation"
                      key="received_as_a_donation"
                    >
                      Received as a donation
                    </Option>
                    <Option
                      value="bought_by_personal_money"
                      key="bought_by_personal_money"
                    >
                      Bought by personal money
                    </Option>
                    <Option value="others" key="others">
                      Others
                    </Option>
                  </Select>
                </Form.Item>

                {curSelectedListData !== "Sim" && formValues?.device_source && (
                  <Form.Item
                    name="status"
                    rules={[
                      {
                        required: true,
                        message: "Device Status",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select device status"
                      size="large"
                      onSelect={(val) => {
                        setSelectedDeviceWorking(val);
                      }}
                    >
                      <Option value="working">Working</Option>
                      <Option value="not-working">Not Working</Option>
                    </Select>
                  </Form.Item>
                )}

                {isSelectedDeviceWorking === "not-working" &&
                  formValues?.device_source === "provided_by_gsws_dept" && (
                    <>
                      {curSelectedListData !== "Mobile" ? (
                        <Form.Item
                          name="report"
                          label="Do you want to report a problem?"
                        >
                          <Radio.Group>
                            <Radio value="yes">Yes</Radio>
                            <Radio value="no">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      ) : (
                        <>
                          <Alert
                            type="info"
                            message="
                        If your mobile is not working, please visit nearest
                        authorised service center. Once you visit the service
                        center, you'll be provided with a JOB SHEET by the
                        service center. If you're not satisfied with the
                        resolution, please report a issue in the portal"
                          />
                          <br />
                        </>
                      )}
                    </>
                  )}

                {}
                {(formValues?.report !== "yes" ||
                  isSelectedDeviceWorking !== "not-working" ||
                  formValues?.device_source !== "provided_by_gsws_dept") && (
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      disabled={!userDetails?.is_village_secretariat}
                      onClick={() => setButtonDetails({ status: "submit" })}
                      loading={buttonLoading}
                    >
                      {buttonLoading ? "Submitting..." : "Submit"}
                    </Button>
                  </Form.Item>
                )}

                {formValues?.device_source === "provided_by_gsws_dept" &&
                  isSelectedDeviceWorking === "not-working" &&
                  editList.ticket === null && (
                    <>
                      {formValues?.report === "yes" && (
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            disabled={!userDetails?.is_village_secretariat}
                            onClick={() =>
                              setButtonDetails({ status: "submitAndReport" })
                            }
                            loading={buttonLoading}
                          >
                            {buttonLoading
                              ? "Submitting..."
                              : "Submit and report"}
                          </Button>
                        </Form.Item>
                      )}
                    </>
                  )}
              </>
            )}
          </Form>
        ) : (
          <Row gutter={[0, 24]}>
            <ReportForm
              editList={editList}
              data={data}
              refresh={refresh}
              setVisible={setVisible}
              userDetails={userDetails}
            />
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default InventoryForm;
