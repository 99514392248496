import React, { useState } from "react";
import { Col, Radio, Row } from "antd";
import IndentRequest from "./IndentRequest";
import TrackIndent from "./TrackIndent";
import Card from "../../../../blocks/Card/Card";

function Indent() {
  const [showTable, setShowTable] = useState("inventory_requests");

  function onRadioChange(e) {
    setShowTable(e.target.value);
  }
  return (
    <Row gutter={[16, 16]}>
      <Col span={24} style={{ textAlign: "right" }}>
        <Radio.Group
          defaultValue="inventory_requests"
          buttonStyle="solid"
          onChange={onRadioChange}
        >
          <Radio.Button value="inventory_requests">
            Inventory Requests
          </Radio.Button>
          <Radio.Button value="track_requests">Track Requests</Radio.Button>
        </Radio.Group>
      </Col>
      <Col span={24}>
        {showTable === "inventory_requests" ? (
          <Card>
            <IndentRequest />
          </Card>
        ) : (
          <TrackIndent />
        )}
      </Col>
    </Row>
  );
}

export default Indent;
