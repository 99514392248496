import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getIndentRequest,
  updateIndentRequest,
} from "../../../../services/serviceRequests.services";
import {
  message,
  Table,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
} from "antd";
import {
  stationaryFilterDevices,
  tableFilterDevices,
  akTableFilterDevices,
} from "../../../../constants/defaultKeys";
import moment from "moment";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function TrackIndent() {
  const query = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [notAcknowledged, setAcknowlegded] = useState(false);
  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      received_count_by_district_authority: "",
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  useEffect(() => {
    setLoading(false);
    getIndentRequest(query.id)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err);
        setLoading(false);
      });
  }, [, notAcknowledged]);

  const save = async (key) => {
    const row = await form.validateFields();
    const newData = [...data];
    const index = newData.findIndex((item) => key === item.id);

    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });
      const editData = newData[index];

      updateIndentRequest(editData.id, {
        received_count_by_district_authority:
          editData.received_count_by_district_authority,
      })
        .then((res) => {
          setAcknowlegded(true);
          message.success(res.data.message);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
        });
      setEditingKey("");
    } else {
      newData.push(row);
      setEditingKey("");
    }
  };

  const columns = [
    {
      title: "Purchase Order Id ",
      dataIndex: "purchase_order",
      key: "purchase_order",
      width: 150,
      fixed: "left",
      render: (id, record) => <span>{record.purchase_order ? id : "-"}</span>,
    },
    {
      title: "Device Type",
      dataIndex: "inventory_type",
      key: "inventory_type",
      width: 150,
      filters: [
        ...tableFilterDevices,
        {
          text: "Sim",
          value: "Sim",
          children: [
            { text: "Jio", value: "Jio" },
            { text: "Airtel", value: "Airtel" },
            { text: "Vi", value: "Vi" },
            { text: "BSNL", value: "BSNL" },
          ],
        },
        ...akTableFilterDevices,
        ...stationaryFilterDevices,
      ],
      filterMode: "tree",
      onFilter: (value, record) =>
        record.inventory_type === "Sim"
          ? record.network === value
          : record.inventory_type === value,
      render: (inventory_type, record) => (
        <span>
          {record?.inventory_type === "Sim"
            ? inventory_type + ":" + " " + record.network
            : inventory_type}
        </span>
      ),
    },
    {
      title: "Requested Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      render: (created_at) => <p>{moment(created_at).format("YYYY-MM-DD")}</p>,
    },
    {
      title: "Requested Count",
      dataIndex: "raised_count",
      key: "raised_count",
      width: 150,
      sorter: (a, b) => a.raised_count - b.raised_count,
    },
    {
      title: "Allocated Count",
      dataIndex: "allocated_count",
      key: "allocated_count",
      width: 150,
      sorter: (a, b) => a.allocated_count - b.allocated_count,
    },
    {
      title: "Resolution Status By State",
      dataIndex: "resolution_status",
      key: "resolution_status",
      width: 150,
      filters: [
        {
          text: "Approved",
          value: "Approved",
        },
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Rejected",
          value: "Rejected",
        },
      ],
      onFilter: (value, record) => record.resolution_status === value,
    },
    {
      title: "Resolution Status By Vendor",
      dataIndex: "resolution_status_by_vendor",
      key: "resolution_status_by_vendor",
      width: 150,
      filters: [
        {
          text: "Open",
          value: "Open",
        },
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],
      onFilter: (value, record) => record.resolution_status_by_vendor === value,
      render: (status, record) => (
        <span>{record.resolution_status_by_vendor ? status : "-"}</span>
      ),
    },
    {
      title: "Updated Date",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 150,
      render: (updated_at) => <p>{moment(updated_at).format("YYYY-MM-DD")}</p>,
      sorter: (a, b) =>
        moment(a.updated_at).unix() - moment(b.updated_at).unix(),
    },
    {
      title: "Signed document",
      dataIndex: "request_form",
      key: "request_form",
      width: 150,
      render: (request_form) => (
        <a
          onClick={() =>
            window.open(
              `http://docs.google.com/gview?url=${request_form}`,
              "_blank"
            )
          }
        >
          View file
        </a>
      ),
    },
    {
      title: "Acknowledge Received Count",
      width: 150,
      dataIndex: "received_count_by_district_authority",
      key: "received_count_by_district_authority",
      editable: true,
      sorter: (a, b) =>
        a.received_count_by_district_authority -
        b.received_count_by_district_authority,
    },
    {
      title: "Action",
      width: 150,
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "received_count_by_district_authority"
            ? "number"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        loading={loading}
        dataSource={data}
        pagination={{ pageSize: 25, showSizeChanger: false }}
        scroll={{ x: 1200 }}
        columns={mergedColumns}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
      />
    </Form>
  );
}
export default TrackIndent;
