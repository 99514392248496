import React, { useState } from "react";
import { Modal, Row, Col, Alert } from "antd";
import CertificateModal from "../CertificateModal";

const AllCertificateCount = ({ query, refresh, data }) => {
  const [isModalVisible, setIsModalVisible] = useState(true);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title="Notice: Add Secure Based Stationery"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={false}
      style={{ marginTop: "-60px" }}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Alert
            message="Please input the current secure based stationery count in your secretariat, 
            from now on we'll auto deduct daily based on the services delivered 🎉 🎉 "
            type="info"
          />
        </Col>
        <Col>
          <CertificateModal
            id={query?.id}
            data={data}
            refresh={refresh}
            setVisible={setIsModalVisible}
            hideButton={false}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default AllCertificateCount;
