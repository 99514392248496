import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import InventorySummaryCard from "../InventorySummaryCard";
import InventoryTableCard from "./InventoryTableCard";
import {
  getMandalInventory,
  getMandalInventoryStats,
} from "../../../services/inventory.services";

const MandalCard = ({ id }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [inventoryStatsLoading, setInventoryStatsLoading] = useState(true);
  const [mandalInventoryStats, setMandalInventoryStats] = useState({});

  useEffect(() => {
    getMandalInventory(id)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    getMandalInventoryStats(id)
      .then((response) => {
        setMandalInventoryStats(response.data);
        setInventoryStatsLoading(false);
      })
      .catch((error) => {
        setInventoryStatsLoading(false);
        console.error(error);
      });
  }, [id]);

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <InventorySummaryCard
          inventoryStats={mandalInventoryStats}
          inventoryStatsLoading={inventoryStatsLoading}
        />
      </Col>
      <Col span={24}>
        <InventoryTableCard
          data={data?.device_count_wise_inv_data}
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default MandalCard;
