import React, { useState, useCallback } from "react";
import { Input, AutoComplete, Row, Col, Tag } from "antd";
import { getSearchDetails } from "../../services/search.services";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

const searchResult = (query) =>
  query.map((item) => {
    return {
      key: item.instance_id,
      value: item.name,
      label: (
        <Link
          to={
            item.type !== "issue"
              ? `/${
                  item.type === "village_secretariat"
                    ? "village-secretariat"
                    : item.type
                }/${item.name}/${item.instance_id}/`
              : `/${"issues"}/${item.name}`
          }
        >
          <Row gutter={[14, 10]}>
            <Col span={16} style={{ textAlign: "left" }}>
              <span className={isMobileOnly && "mobileItemName"}>
                {item.name}
              </span>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              {item.type === "village_secretariat" ? (
                <Tag color="purple" size="small">
                  <span className={isMobileOnly && "mobileItemName"}>
                    {item.code}
                  </span>
                </Tag>
              ) : item.type === "mandal" ? (
                <Tag color="green">Mandal</Tag>
              ) : item.type === "district" ? (
                <Tag color="blue">District</Tag>
              ) : (
                <Tag color="blue">status:{item?.code}</Tag>
              )}
            </Col>
          </Row>
        </Link>
      ),
    };
  });

const MenuSearch = ({ userDetails }) => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  const handleSearch = (data) => {
    setValue(data);
    let searchQuery = data.toLowerCase();
    getSearchDetails(searchQuery)
      .then((response) => {
        setOptions(searchResult(response.data));
      })
      .catch((error) => console.log(error));
  };
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };
  const optimizedSearch = useCallback(debounce(handleSearch), []);

  function handleSelect() {
    setValue(null);
    setOptions([]);
  }

  return (
    <AutoComplete
      size={isMobileOnly ? "middle" : "large"}
      options={options}
      onSearch={optimizedSearch}
      onSelect={handleSelect}
      onChange={(value) => {
        setValue(value);
        optimizedSearch(value);
      }}
      style={
        isMobileOnly
          ? {
              width: 220,
              paddingLeft: "20px",
            }
          : {
              width: 450,
            }
      }
      value={value}
    >
      <Input.Search
        size="large"
        placeholder={
          userDetails?.is_vendor
            ? "Issue ID or Village or Mandal or District"
            : "Village or Mandal or District"
        }
      />
    </AutoComplete>
  );
};

export default MenuSearch;
