import React from "react";
import { Radio, Table } from "antd";
function mobile() {
  const columns = [
    {
      title: "District",
      dataIndex: "A",
      key: "A",
      filters: [
        {
          text: "Anantapur",
          value: "Anantapur",
        },
        {
          text: "Chittoor",
          value: "Chittoor",
        },
        {
          text: "East Godavari",
          value: "East Godavari",
        },
        {
          text: "Guntur",
          value: "Guntur",
        },
        {
          text: "Kadapa",
          value: "Kadapa",
        },
        {
          text: "Krishna",
          value: "Krishna",
        },
        {
          text: "Kurnool",
          value: "Kurnool",
        },
        {
          text: "Nellore",
          value: "Nellore",
        },
        {
          text: "Prakasam",
          value: "Prakasam",
        },
        {
          text: "Srikakulam",
          value: "Srikakulam",
        },
        {
          text: "Visakhapatnam",
          value: "Visakhapatnam",
        },
        {
          text: "Vizianagaram",
          value: "Vizianagaram",
        },
        {
          text: "West Godavari",
          value: "West Godavari",
        },
      ],
      onFilter: (value, record) => record.A.indexOf(value) === 0,
    },
    {
      title: "City",
      dataIndex: "B",
      key: "B",
    },
    {
      title: "ASC Name",
      dataIndex: "C",
      key: "C",
    },
    {
      title: "Address",
      dataIndex: "D",
      key: "D",
    },
    {
      title: "PIN-Code",
      dataIndex: "E",
      key: "E",
    },
    {
      title: "Service center Tel",
      dataIndex: "F",
      key: "F",
    },
    {
      title: "State",
      dataIndex: "G",
      key: "G",
    },
    {
      title: "Hours of Operation",
      dataIndex: "H",
      key: "H",
    },
    {
      title: "WeeklyOff",
      dataIndex: "I",
      key: "I",
    },
  ];
  const ServiceCenter = [
    {
      A: "Anantapur",
      B: "Ananthapuram",
      C: "Ms Communication",
      D: "Ms Communication, 28/803-1, Sangamesh Nagar, Beside Bruhasptai School, 80 Feet Rd, Ananthapuram, Andhra Pradesh-515001",
      E: "515001",
      F: "9550189506",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Anantapur",
      B: "Dharmavaram",
      C: "As Enterprises",
      D: "as enterprises, 2-730-4,gpr pride,ntr circle,dharmavaram,anantapur, andhra pradesh,515671.",
      E: "515671",
      F: "9000933143",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Anantapur",
      B: "Hindupur",
      C: "T M K Cell Care",
      D: "Tmk Cell Care, 1st Floor, Lakshmi Enclave Dl Road, Hindupur, Andhra Pradesh-515201",
      E: "515201",
      F: "9010855118",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Chittoor",
      B: "Chittoor",
      C: "Ganesh Mobile Care",
      D: "Ganesh Mobile Care, D .No: 10-827, Seshapeeran Street High Road, Chandana Ramesh Shopping Mall, Beside Indian Bank, Chittoor, Andhra Pradesh-517001",
      E: "517001",
      F: "9121933540",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Chittoor",
      B: "Tirupati",
      C: "Radiant E Serve",
      D: "Radiant E Serve, Pvr Arcade, 20-01-M26-145 (Old No . 20-03-126/B2), 1st Floor, Tirumala Bypass Road, Near Leela Mahal Junction, (Sri Krishna Devaraya Statue), Tirupati, Andhra Pradesh-517507",
      E: "517507",
      F: "9962817398",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "East Godavari",
      B: "Amalapuram",
      C: "Mobicom",
      D: "Mobicom, Shop No. 8, Sri Potti Sriramulu Muncipal Circular Bazar, Beside Railway Reservation Counter, Amalapuram, Andhra Pradesh-533201",
      E: "533201",
      F: "7989393949",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "East Godavari",
      B: "Kakinada",
      C: "Sri Sai Surya Enterprises",
      D: "Sri Sai Surya Enterprises, F-9,10,11,17 &18, Upstair Banglore Bakery, First Floor, Mantriprugada Complex, Cinema Road, Kakinada, Andhra Pradesh-533001",
      E: "533001",
      F: "8143440300",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "East Godavari",
      B: "Kakinada",
      C: "Sri Vijaya Durga Communivations",
      D: "Sri Vijaya Durga Communivations, 1st floor, 1-9-23, Purushotham Complex, Kakinada, Andhra Pradesh -533003",
      E: "533003",
      F: "9121098699",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "East Godavari",
      B: "Peddapuram",
      C: "R K Services",
      D: "R K Services, 1st floor, 19-1-307/10, Prakasam Colony, Peddapuram, East Godavari, Andhra Pradesh-533437",
      E: "533437",
      F: "9059897126",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "East Godavari",
      B: "Rajahmundry",
      C: "Suman Telecom",
      D: "Suman Telecom, Ist Floor, GSR Plaza, Beside Old Somala Amma Temple, Near Rtc Complex Road, Above KTM Duke Bike Show Room, Rajahmundry, Andhra Pradesh-533103",
      E: "533104",
      F: "9505338660",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "East Godavari",
      B: "Rajahmundry",
      C: "S-Mobile",
      D: "S-mobile, D/N:29-24-15/3, Lakshimivarapu peta, Near Ramalayam, Rajahmundry",
      E: "533107",
      F: "7569732999",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "East Godavari",
      B: "Tuni",
      C: "Sri Kalyani Agencies",
      D: "Sri Kalyani Agencies, D. No. 8-9-72, Pedda Veedhi, Tuni, Andhra Pradesh-533401",
      E: "533401",
      F: "9985655236",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Guntur",
      B: "Guntur",
      C: "Myva Mobiles",
      D: "Myva Mobiles, 4/1- Brodipet, Raghu Mansion Building, Opp. Sankar Vilas Hotel, Guntur, Andhra Pradesh-522002",
      E: "522002",
      F: "9392184670",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Guntur",
      B: "Narasaraopet",
      C: "Cell Point",
      D: "Cellpoint, Shop No. 4, Divvela Complex, Geeta Mandir Road, Narsaraopet, Andhra Pradesh-522601",
      E: "522601",
      F: "9160268968",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Guntur",
      B: "Tenali",
      C: "Vj Communications",
      D: "VJ Communications, 6-2-31, Putchas Plaza, Shivalayam Temple back street, Opp. HDFC Bank, Tenali, Andhra Pradesh-522201",
      E: "522201",
      F: "9182290032",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Kadapa",
      B: "Cuddapah",
      C: "Av Technologies",
      D: "Av Technologies, # 7/108, 1st Floor, Beside Manappuram Gold Loan, Apsara Circle, Ngo Colony, Cuddapah, Andhra Pradesh-516002",
      E: "516002",
      F: "7396749446",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Kadapa",
      B: "Proddatur",
      C: "Online Instrument",
      D: "Online Instruments, No 7/4019, Srinivasa Nagar, Kadapa Dist., Proddatur, Andhra Pradesh-516360",
      E: "516360",
      F: "08564-251121",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Krishna",
      B: "Gudivada",
      C: "Celphy Techno Services",
      D: "Celphy Techno Services, Shop No 24 & 25, Kinnera Complex, Opp. RTC Bust Stand, Gudiwada, Andhra Pradesh-521301",
      E: "521301",
      F: "9642396524",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Krishna",
      B: "Machilipatnam",
      C: "Lakshmi Ganapathi Communications",
      D: "Lakshmi Ganapathi Communications, Raja Gold Covering OPP street, Near More Super Market, Srinivas Nagar Colony, Machilipatnam, Andhra Pradesh-521001",
      E: "521001",
      F: "9963982143",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Krishna",
      B: "Nandigama",
      C: "Sri Durga Communications",
      D: "Sri Durga Communications, Shop No 1, Veeramachaneni Complex, Bharat Talikes Center, Nandigama, Andhra Pradesh-521185",
      E: "521185",
      F: "08678-275559",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Krishna",
      B: "Vijayawada",
      C: "Qdigi Services Limited",
      D: "Mi Exclusive Service Center, Door No. 71-1-11/1, Mg Road, First Floor, Padamata, Opposite To Sonovision, Vijayawada, Andhra Pradesh-520010",
      E: "520010",
      F: "9667006157",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Krishna",
      B: "Vijayawada",
      C: "Suman Telecom",
      D: "Suman Telecom, D.No. 27-19-91, Congress Office Road, Opposite Bakshi Motors, Governorpet, Vijayawada, Andhra Pradesh-520002",
      E: "520002",
      F: "9885559396",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Kurnool",
      B: "Adoni",
      C: "Raghu Computers & Mobiles",
      D: "Raghu Computers & Mobiles, Shop No- 22-250, 1st Floor, Opposite Krishna Temple, Yemmiganur Road, Adoni, Andhra Pradesh-518301",
      E: "518301",
      F: "0851-2222643",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Kurnool",
      B: "Kurnool",
      C: "Anwar Communications",
      D: "anwar communications, 40-383-a2, first floor, bhupal complex, park road, kurnool-518001",
      E: "518001",
      F: "9866026731",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Kurnool",
      B: "Nandyal",
      C: "Surekha Electronics",
      D: "Surekha Electronics, Shop No 16&17, Bhoganathula Complex, Srinivasa Center, Nandyal, Andhra Pradesh-518501",
      E: "518501",
      F: "9849836159",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Nellore",
      B: "Kavali",
      C: "Ushasree Communications",
      D: "1st Floor, 10-15-35, 35A, GNT Road, AVM Compound Area, SPSR Nellor, Kavali, Andhra Pradesh-524201",
      E: "524201",
      F: "7799345611",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Nellore",
      B: "Naidupet",
      C: "Ss Communications",
      D: "SS Communications, 3-6-147, 1St Floor , Ramakrishna Puram, Andhra Bank Road, Naidupet, Andhra Pradesh 524126",
      E: "524126",
      F: "9494931243",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Nellore",
      B: "Nellore",
      C: "Sreenivasa Electronics",
      D: "Pstairs of LOT Mobiles, Opp KLM Shopping Mall, Trunk Road, Nellore, Andhra Pradesh-524001",
      E: "524001",
      F: "7013099665",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Prakasam",
      B: "Chirala",
      C: "Sarang Technologies",
      D: "Sarang Technologies, 1st Floor, Goli Kotaiah Building, Beside Saidulu Chowdary Hospital, Puthoor Rajula Street Station, Chirala, Andhra Pradesh-523155",
      E: "523155",
      F: "8522885659",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Prakasam",
      B: "Ongole",
      C: "Svsm Spencer Plaza",
      D: "SVSM Spencer Plaza, Shop No. G14, A-Block, Guptas Square, Beside Satyam Theatre, Ongole, Andhra Pradesh-523002",
      E: "523002",
      F: "8592230230",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Srikakulam",
      B: "Srikakulam",
      C: "JAYARAMA MOBIQUEST",
      D: "Jayarama Mobiquest, 12-9-11, Opp. Kinnera Complex, CB Road, Srikakulam, Andhra Pradesh-532001",
      E: "532001",
      F: "9063303339",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Visakhapatnam",
      B: "Visakhapatnam",
      C: "Vasavi Associates",
      D: "Vasavi Associates, Bhuvaneshwari Plaza, D No- 47.10.1/6, Diamond Park Main Road, Dwarakanagar, Visakhapatnam, Andhra Pradesh-530016",
      E: "530016",
      F: "9381095758",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Visakhapatnam",
      B: "Visakhapatnam",
      C: "Bright Solutions",
      D: "Bright Solutions, #7-16-62, Opp Rajiv Gandhi Statue, Old Gajuwaka, NH-5 Jn, Visakhapatnam, Andhra Pradesh-530026",
      E: "530026",
      F: "9059411655",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Visakhapatnam",
      B: "Visakhapatnam",
      C: "Sai Geethika Communications",
      D: "Sai Geethika Communications, D.No: 30-9-16, Sarada Street, Center Point Hotel, Dabagardens, Visakhapatnam, Andhra Pradesh-530020",
      E: "530020",
      F: "7416588199",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Visakhapatnam",
      B: "Visakhapatnam",
      C: "S V Care",
      D: "S V Care, Asgn Complex, 58-1-254, Nad Junction Kotha Road, Visakhapatnam, Andhra Pradesh-530018",
      E: "530018",
      F: "8897908244",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "Vizianagaram",
      B: "Vizianagaram",
      C: "Sri Sai Tulasi Mobiles",
      D: "Sri Sai Tulasi Mobiles, 1st  Floor, D. No. 17-2-28, Srinivasa Plaza, A G Road, Raja Bazaar, Vizianagaram, Andhra Pradesh-535002",
      E: "535002",
      F: "08922-252309",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "West Godavari",
      B: "Bhimavaram",
      C: "Janaki Services",
      D: "janaki services, gvs complex,behind raymond showroom,pp road, bhimavaram-534201",
      E: "534201",
      F: "9000020499",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "West Godavari",
      B: "Eluru",
      C: "Rs Services",
      D: "RS Services, Ml Authorized Service Centre, OPP DTDC courier, Chinta Chettu Road, Near Trinath Fashions, RR Pet, Eluru, Andhra Pradesh-534002",
      E: "534002",
      F: "9701103459",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "West Godavari",
      B: "Tadepalligudem",
      C: "MANI MOBILES",
      D: "Mani Mobiles, Shop No-9, Jubilee Road, KSN Complex, Tadepalligudem, Andhra Pradesh-534101",
      E: "534101",
      F: "08818-220115",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
    {
      A: "West Godavari",
      B: "Tanuku",
      C: "Best Mobiles",
      D: "Best Mobiles, Manikanta Towers, Opp. Town Hall Complex, Rp Road, Tanuku, Andhra Pradesh-534211",
      E: "534211",
      F: "9705557878",
      G: "Andhra Pradesh",
      H: "10:30 AM to 07:00 PM",
      I: "Sunday",
    },
  ];
  return (
    <div>
      <h1>Mobile</h1>  
      <p>
        {" "}
        For mobile repairs, please approach one of the authorized service
        centers listed below. If the service center is charging money for
        service other than physical damage, liquid damage and unauthorized
        service repair, please call <a href="tel:9052345678">
          +91 9052345678
        </a>{" "}
        for resolution.
      </p>
      <Table columns={columns} dataSource={ServiceCenter} />
    </div>
  );
}

export default mobile;
