import React, { Fragment, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import { editProfile } from "../../services/auth.services";

const BasicDetails = ({ user }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    let data = {
      first_name: user?.first_name ? user.first_name : "",
      last_name: user?.last_name ? user.last_name : "",
      email: user?.email ? user.email : "",
    };
    form.setFieldsValue(data);
  }, [user]);

  const onFinish = (values) => {
    editProfile(values)
      .then((response) => message.success(response.data.message))
      .catch((error) => {
        message.error(error?.response?.data?.message);
        console.log(error);
      });
  };

  return (
    <Fragment>
      <Form onFinish={onFinish} form={form}>
        <Form.Item
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please input your first name",
            },
          ]}
        >
          <Input placeholder="First Name" size="large" />
        </Form.Item>
        <Form.Item
          name="last_name"
          rules={[
            {
              required: true,
              message: "Please input your last name",
            },
          ]}
        >
          <Input placeholder="Last Name" size="large" />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Please input your email",
            },
          ]}
        >
          <Input size="large" placeholder="Email" />
        </Form.Item>

        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            block
            className="login-form-button"
          >
            <span>Submit</span>
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default BasicDetails;
