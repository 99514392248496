import React from "react";
import { Row, Col, Typography } from "antd";
import { supportMailId } from "../../../constants/defaultKeys";

const { Title, Paragraph } = Typography;

const UserProfile = () => {
  return (
    <Row type="flex">
      <Col span={20} offset={1}>
        <Title level={5}> Welcome to the User Account section.</Title>
        <Title level={3}> Mobile Login</Title>
        <Paragraph>
          You can access your account through the mobile number registered in
          vsws.co.in portal.
        </Paragraph>
        <Paragraph>
          While logging in if OTPs are getting delayed, please contact us. We
          may be able to help you out. This portal uses C-DAC as the SMS gateway
          and the OTP delay is possibly because of SMS Gateway getting busy.
        </Paragraph>
        <Paragraph>
          If you are not sure which mobile number was registered for your
          secretariat, you could check it by visiting the check mobile number
          page. Ideally, you would have received a welcome message to the
          registered mobile.
        </Paragraph>

        <Title level={3}> Request For New Account</Title>
        <Paragraph>
          If you don't have an account, you may request for one by filling in
          the request for login form.
        </Paragraph>
        <Paragraph>
          By visiting the request for an account page, you will be asked to
          enter your details. We'll validate these details and give you an
          access within 2 business days. If you need something urgently, please
          feel free to contact us by mailing {supportMailId}
        </Paragraph>
        <Title level={3}> Setup Profile</Title>
        <Paragraph>
          As soon as you login to the portal, you will be asked to fill in your
          profile details. This is important to keep you notified about your
          raised requests or send any notifications to your email.{" "}
        </Paragraph>
      </Col>
    </Row>
  );
};

export default UserProfile;
