import React, { useState } from "react";
import { Menu, Row, Col } from "antd";
import { useParams } from "react-router";
import InventoryRequestTable from "./InventoryRequestTable";
import LostDeviceReportsTable from "./LostDeviceReportsTable";
import HighVoltageReportsTable from "./HighVoltageReportsTable";
import Indent from "./Indent/Indent";
import useViewAccess from "../../../hooks/useViewAccess";
const { SubMenu } = Menu;

const ServiceRequest = () => {
  const query = useParams();
  const [selectedMenu, setSelectedMenu] = useState("inventory_request");
  useViewAccess("is_indent_authority", "is_district_authority");
  const onMenuClick = (e) => {
    setSelectedMenu(e.key);
  };

  const apiData = (selectedMenu) => {
    switch (selectedMenu) {
      case "lost_device":
        return <LostDeviceReportsTable id={query?.id} />;
      case "report_high_voltage":
        return <HighVoltageReportsTable id={query?.id} />;
      case "indent":
        return <Indent id={query?.id} />;
      default:
        return <InventoryRequestTable id={query?.id} />;
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Menu
          defaultSelectedKeys={["inventory_request"]}
          defaultOpenKeys={["indent_menu"]}
          mode="horizontal"
          theme="dark"
          onClick={onMenuClick}
        >
          <Menu.Item key="inventory_request">
            Secretariat Inventory Requests
          </Menu.Item>
          <Menu.Item key="indent">Request New Inventory</Menu.Item>
          <Menu.Item key="lost_device">Lost Device Reports</Menu.Item>
          <Menu.Item key="report_high_voltage">High Voltage Reports</Menu.Item>
        </Menu>
      </Col>
      <Col span={24}>{apiData(selectedMenu)}</Col>
    </Row>
  );
};

export default ServiceRequest;
