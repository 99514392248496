import { getItem, clear } from "./localStorage";
import { ACCESS_TOKEN, USER } from "../constants/defaultKeys";
import { useLocation } from "react-router-dom";
import { districtCoordinates } from "../constants";
import { Form, Upload, Input, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import ReloadIcon from "../assets/images/ReloadIcon.png";
import { loadCaptchaEnginge, LoadCanvasTemplate } from "react-simple-captcha";
import "../components/custom.css";
import { uploadFile } from "../services/downloadFile.services";
import axios from "axios";
export const isAuthenticated = () => {
  let isTokenAvailable = false;
  const access_token = getItem(ACCESS_TOKEN);
  if (access_token) {
    isTokenAvailable = true;
  }
  return isTokenAvailable;
};

export function isUserFromNTRorASR() {
  const userDetails = JSON.parse(getItem(USER));
  return (
    userDetails?.region?.district_identifier === 22 ||
    userDetails?.region?.district_identifier === 14
  );
}
export const getTokenIfNotExpired = () => {
  const token = getItem(ACCESS_TOKEN);
  const user = getItem(USER, true);
  if (token && user) {
    const date = new Date();
    const expiredTime = user.exp;
    if (expiredTime < date.getTime() / 1000) {
      clear();
      return null;
    }
  }
  return token;
};

export const getMessageFromResponse = (response) => {
  const message = response && response.data && response.data.message;
  return message || "Something went wrong, Please try after sometime";
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const getDistrictCoordinate = (district) => {
  const districtCoordinate = districtCoordinates.find(
    (coordinate) => coordinate.district === district
  );
  return districtCoordinate.coordinates;
};

export function ImageUploadProps(
  id,
  fileUploadError,
  setFileUploadError,
  params,
  uploadedFileNames,
  setUploadedFileName,
  uploadFileLoading,
  setUploadFileLoading
) {
  return {
    beforeUpload: (file, fileList) => {
      const fileTypes =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg";

      if (handleMultipleFileExt(file)) return Upload.LIST_IGNORE;

      if (!fileTypes) {
        setFileUploadError({
          ...fileUploadError,
          [id]: {
            error: true,
            errorMessage: `${file.name} is not a valid file format`,
            uploadFileLength: fileList?.length || 0,
          },
        });
        return Upload.LIST_IGNORE;
      } else if (file.size > 5260006) {
        let fileSize = (file.size / 5260006).toFixed(0);
        setFileUploadError({
          ...fileUploadError,
          [id]: {
            error: true,
            errorMessage: `Uploaded file is ${fileSize}MB, exceeding the maximum 5MB limit.`,
            uploadFileLength: fileList?.length || 0,
          },
        });
        return Upload.LIST_IGNORE;
      }
      setFileUploadError({
        ...fileUploadError,
        [id]: {
          success: true,
          successMessage: `${file.name} uploaded successfully`,
          uploadFileLength: fileList?.length || 0,
        },
      });
      return true;
    },
    async onChange(info) {
      if (info.file.status === "done") {
        try {
          setUploadFileLoading({
            ...uploadFileLoading,
            [id]: true,
          });
          const res = await uploadFile({
            folder: params?.folder,
            filename: replaceDotsExceptLast(info.file.name),
            uploading_to_vsws: true,
            is_private_file: params?.pvt ? true : false,
          });
          const formData = convertToFormData(res, info);
          await axios({
            method: "POST",
            url: res?.data?.url?.url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          setUploadedFileName({
            ...uploadedFileNames,
            [id]: !params?.pvt
              ? "https://resources.vsws.co.in/" +
                "" +
                res?.data?.url?.fields?.key
              : res?.data?.url?.fields?.key,
          });
          // setUploadFileLoading({
          //   ...uploadFileLoading,
          //   [id]: false,
          // });
          message.success(`${info.file.name} file uploaded successfully`);
          return res?.data?.url?.fields?.key;
        } catch (err) {
          console.log(err);
          message.error(err?.response?.data?.error?.message?.[0]);
        } finally {
          setUploadFileLoading({
            ...uploadFileLoading,
            [id]: false,
          });
        }
      }
    },
    onRemove: () => {
      setFileUploadError({
        ...fileUploadError,
        [id]: {
          error: false,
          errorMessage: null,
          successMessage: null,
          success: false,
          uploadFileLength: 0,
          defaultMessage:
            "Please upload images with .png,.jpg or .jpeg format and file size should be less than 5MB.",
        },
      });
    },
    maxCount: 1,
  };
}

export function sliceAddress(username) {
  let slicedState =
    username.slice(0, username.indexOf(", Andhra Pradesh")) +
    username.slice(
      username.indexOf(", Andhra Pradesh") + ", Andhra Pradesh".length + 1
    );
  let lastCommaIndex = slicedState.lastIndexOf(",");
  let secondLastCommaIndex = slicedState.lastIndexOf(",", lastCommaIndex - 1);
  let docs = slicedState.slice(secondLastCommaIndex + 1);
  return docs;
}

export function handleFileUploadError(id, fileUploadError, setFileUploadError) {
  return !fileUploadError?.[id]?.error ? (
    (fileUploadError?.[id]?.uploadFileLength === 0 ||
      !fileUploadError?.[id]?.success) && (
      <span>
        Please upload images with .png .jpg or .jpeg format and file size should
        be less than 5MB.
      </span>
    )
  ) : (
    <span style={{ color: "red" }}>
      {fileUploadError?.[id]?.errorMessage}
      <CloseOutlined
        onClick={() =>
          setFileUploadError({
            ...fileUploadError,
            [id]: {
              error: false,
              errorMessage: null,
              successMessage: null,
              success: false,
              uploadFileLength: 0,
              defaultMessage:
                "Please upload images with .png .jpg or .jpeg format and file size should be less than 5MB.",
            },
          })
        }
      />
    </span>
  );
}

export function ReCaptchaComponent() {
  return (
    <>
      <div className="captcha-template">
        <LoadCanvasTemplate />
      </div>

      <Form.Item
        className="captcha-form"
        rules={[{ required: true, message: "Fill the Captcha" }]}
        name="fill_captcha_input"
      >
        <Input
          placeholder="Enter the captcha characters shown above."
          id="user_captcha_input"
          style={{ marginBottom: "5px" }}
        />
      </Form.Item>
    </>
  );
}

export function CaptchaEngine() {
  loadCaptchaEnginge(6);
  var reloadText = document.getElementById("reload_href");
  reloadText.setAttribute("title", "Reload Captcha");
  reloadText.innerHTML = "";
  const imgTag = document.createElement("img");
  imgTag.setAttribute("src", ReloadIcon);
  imgTag.setAttribute("width", "20px");
  imgTag.setAttribute("height", "auto");
  reloadText.appendChild(imgTag);
}

export function handleCaptchaFail() {
  message.error("Captcha dosen't match, try again!");
}

export function convertToFormData(res, info) {
  const formData = new FormData();
  for (var key in res.data.url.fields) {
    formData.append(key, res.data.url.fields[key]);
  }
  formData.append("file", info.file.originFileObj);
  return formData;
}

export const debounce = (fn, delay) => {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => fn.apply(this, args), delay);
  };
};
export function timeAgo(utcTimestamp) {
  const currentDate = new Date();
  const pastDate = new Date(utcTimestamp);

  const timeDifference = currentDate.getTime() - pastDate.getTime();
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return days === 1 ? `${days} day ago` : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
  } else {
    return "Just now";
  }
}

export function handleMultipleFileExt(file) {
  const fileName = file.name;
  const parts = fileName.split(".");
  if (parts.length > 2) {
    for (let i = 0; i < parts.length - 1; i++) {
      if (parts[i].includes(".") || parts[i] === parts[i + 1]) {
        console.log("Invalid multiple extensions");
        message.error(
          `${fileName} contains invalid multiple extensions and is not allowed`
        );
        return true;
      }
    }
  }
  return false;
}

export function replaceDotsExceptLast(fileName) {
  const parts = fileName.split(".");
  if (parts.length < 2) return fileName;
  const baseName = parts.slice(0, -1).join("_");
  const extension = parts[parts.length - 1];
  return `${baseName}.${extension}`;
}
