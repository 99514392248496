import React, { Fragment, useState, useMemo } from "react";
import { Tabs } from "antd";
import { useHistory } from "react-router-dom";
import "./NavigationItems.css";

const { TabPane } = Tabs;

const MobileNavigationItems = () => {
  const history = useHistory();
  const [activeKey, setActiveKey] = useState("");
  const defaultTab = useMemo(
    () =>
      history.location.search === ""
        ? setActiveKey(history.location.pathname)
        : setActiveKey(`${history.location.pathname}?tab=inventory`),
    [history]
  );

  function onTabChange(key) {
    history.push(key);
    if (history.location.search !== "") {
      setActiveKey(`${history.location.pathname}?tab=inventory`);
    } else {
      setActiveKey(history.location.pathname);
    }
  }

  return (
    <Fragment>
      <Tabs
        defaultActiveKey={activeKey}
        tabPosition="bottom"
        className="bottom-nav"
        size="small"
        centered={true}
        onChange={onTabChange}
        tabBarStyle={{
          background: "#0b2239",
          color: "#ffffff",
          fontWeight: "600",
          margin: "0px",
          borderTop: "1px solid #eeeeee",
        }}
      >
        <TabPane tab="Home" key={history.location.pathname} />
        <TabPane
          tab="Inventory"
          key={`${history.location.pathname}?tab=inventory`}
        />
      </Tabs>
    </Fragment>
  );
};
export default MobileNavigationItems;
