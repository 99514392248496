import { Breadcrumb, Table } from "antd";
import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  invClusterColumns,
  invColumns,
} from "../../../helpers/inventoryCollectionColumns";

function AggregateTable({ data, loading, params, comp }) {
  const query = useParams();
  console.log(query);
  const stateCols = [
    {
      title: comp?.includes("mandal")
        ? "Secretariat Name"
        : query?.id
        ? "Mandal Name"
        : "District Name",
      dataIndex: "region",
      key: "region",
      render: (value, record) =>
        comp?.includes("mandal") ? (
          <Link
            to={`/district/${query?.region}/${query?.id}?tab=inventory-collection&mandal_name=${params?.mandal_name}&mandal=${params?.mandal}&sec_name=${value}&sec=${record?.region_id}`}
          >
            {value}
          </Link>
        ) : query?.id ? (
          <Link
            to={`/district/${query?.region}/${query?.id}?tab=inventory-collection&mandal_name=${value}&mandal=${record?.region_id}`}
          >
            {value}
          </Link>
        ) : (
          <Link
            to={`/district/${query?.region}/${query?.id}?tab=inventory-collection`}
          >
            {value}
          </Link>
        ),
    },
    ...invColumns,
  ];
  const filtersecCols = stateCols?.filter(
    (cols) => cols?.key !== "total_secretariats"
  );
  console.log(filtersecCols);
  const secCols = [...invClusterColumns];
  const CustomBreadcrumb = ({ paths }) => (
    <Breadcrumb separator=">">
      {paths.map((path, index) => (
        <Breadcrumb.Item key={index} href={path.href}>
          {path.name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
  const paths = [];

  if (comp?.includes("sec")) {
    paths.push(
      {
        name: query?.region,
        href: `/district/${query?.region}/${query?.id}/?tab=inventory-collection`,
      },
      // {
      //   name: params?.district_name,
      //   href: `/state/${query?.tab}/?district_name=${params?.district_name}&district=${params?.district}`,
      // },
      {
        name: params?.mandal_name,
        href: `/district/${query?.region}/${query?.id}/?tab=inventory-collection&mandal_name=${params?.mandal_name}&mandal=${params?.mandal}`,
      },
      { name: params?.sec_name }
    );
  } else if (comp?.includes("mandal")) {
    paths.push(
      {
        name: query?.region,
        href: `/district/${query?.region}/${query?.id}/?tab=inventory-collection`,
      },
      {
        name: params?.district_name,
        href: `/state/${query?.tab}/?district_name=${params?.district_name}&district=${params?.district}`,
      },
      { name: params?.mandal_name }
    );
  }

  return (
    <>
      {paths.length > 0 && <CustomBreadcrumb paths={paths} />}
      <Table
        columns={
          comp?.includes("sec")
            ? secCols
            : comp?.includes("mandal")
            ? filtersecCols
            : stateCols
        }
        dataSource={data}
        loading={loading}
        pagination={false}
      />
    </>
  );
}

export default AggregateTable;
