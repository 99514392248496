import React, { Fragment } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Charts = ({ data }) => {
  return (
    <Fragment>
      <ResponsiveContainer width="100%" height={100}>
        <BarChart
          data={data}
          margin={{
            left: 15,
            bottom: 10,
          }}
        >
          <XAxis
            dataKey="date"
            textAnchor="start"
            interval={1}
            tick={false}
            stroke="#ececec"
          />
          <YAxis domain={["dataMin", "dataMax"]} width={0} />
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </Fragment>
  );
};
export default Charts;
