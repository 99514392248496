import React, { Fragment, useState, useEffect } from "react";
import { List, Empty, Tooltip, Badge } from "antd";
import TimeStamp from "../../../blocks/TimeStamp/index";
import { isMobileOnly } from "react-device-detect";
import Drawer from "../../Drawer/Drawer";
import EditLogo from "../../../assets/images/edit.png";
import DeleteLogo from "../../../assets/images/delete.png";
import DeviceBadge from "../../../blocks/Badges/DeviceBadge";
import IssueIcon from "../../../assets/images/reportissue.png";
import "./InventoryList.css";
import { Link, useLocation } from "react-router-dom";

const InventoryList = ({ id, vsData, refresh, hasPermission, isAadharSecretariat }) => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [editList, setEditList] = useState(null);
  const location = useLocation();
  useEffect(() => {
    setData(vsData);
  }, [vsData, refresh]);

  const openModal = (data) => {
    setEditList({ ...data, isAadharSecretariat: isAadharSecretariat });
    setVisible(true);
  };

  return (
    <Fragment>
      {data?.length !== 0 ? (
        <List
          className="inventory-list"
          itemLayout="horizontal"
          size={isMobileOnly ? "small" : "large"}
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              actions={
                hasPermission && [
                  <span
                    style={{ cursor: "" }}
                    onClick={() =>
                      openModal({
                        status: "edit",
                        id: item.id,
                        title: "Edit Inventory",
                        ticket: item.ticket,
                      })
                    }
                    key={item.id}
                  >
                    <Tooltip title="Edit">
                      <img src={EditLogo} alt="edit" height="18px" />
                    </Tooltip>
                  </span>,
                  <span
                    style={{ cursor: "pointer" }}
                    key={item.id}
                    onClick={() =>
                      openModal({
                        status: "delete",
                        id: item.id,
                        title: "Delete Inventory",
                      })
                    }
                  >
                    <Tooltip title="Remove">
                      <img src={DeleteLogo} alt="remove" height="18px" />
                    </Tooltip>
                  </span>,

                  <span>
                    {item.device_source !== "provided_by_gsws_dept" ? (
                      <img src={IssueIcon} alt="issue" height="18px" style={{ opacity: 0 }} />
                    ) : (
                      <>
                        {item.ticket === null ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              openModal({
                                status: "report",
                                id: item.id,
                                title: "Report an issue",
                              })
                            }
                            key={item.id}
                          >
                            {item.inventory_type === "Sim" ? (
                              <Link
                                to={`${location.pathname}?tab=service-requests&&innerTab=activate_new_sim`}
                              >
                                Replace Sim
                              </Link>
                            ) : (
                              <span style={{ color: "#41A8F7" }}>Report Issue</span>
                            )}
                          </span>
                        ) : (
                          <span key={item.id}>
                            <Tooltip title="Can't raise another issue">
                              <span>Report Issue</span>
                            </Tooltip>
                          </span>
                        )}
                      </>
                    )}
                  </span>,
                ]
              }
            >
              <List.Item.Meta
                avatar={
                  <DeviceBadge
                    deviceType={item.inventory_type}
                    badgeType={item.status}
                    key={item.id}
                  />
                }
                title={item?.inventory_type === "Sim" ? item.masked_device_id : item.device_id}
                description={
                  <div>
                    {item.ticket !== null ? (
                      <Link to={`/issues/${item.ticket.ticket_id}`}>
                        {item.ticket.ticket_status !== "InProgress" ? (
                          <span>
                            {!isMobileOnly && "SRID"} #{item.ticket.ticket_id}
                          </span>
                        ) : (
                          <Badge
                            status="processing"
                            text={
                              <span style={{ color: "#1890ff" }}>
                                {!isMobileOnly && "SRID"} #{item.ticket.ticket_id}
                              </span>
                            }
                          />
                        )}
                      </Link>
                    ) : (
                      <span>
                        {isMobileOnly ? "Updated" : "Last Updated"}{" "}
                        <TimeStamp timeStyle="twitter" value={item.updated_at} />
                      </span>
                    )}
                  </div>
                }
              />
            </List.Item>
          )}
        />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No devices found. Please add inventory"
        />
      )}
      {visible && (
        <Drawer
          setVisible={setVisible}
          refresh={refresh}
          editList={editList}
          inventoryData={data && data}
          id={id}
          setEditList={setEditList}
        />
      )}
    </Fragment>
  );
};

export default InventoryList;
