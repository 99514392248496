import React from "react";
import { Row, Col, Typography } from "antd";
import { supportMailId } from "../../../constants/defaultKeys";
const { Text, Title, Paragraph } = Typography;
const Contact = () => {
  return (
    <Row type="flex">
      <Col span={20} offset={1}>
        <Title level={3}>For any queries, please contact {supportMailId}</Title>
        <Paragraph>Please expect a reply in 1-2 business days</Paragraph>
      </Col>
    </Row>
  );
};

export default Contact;
