import React, { useState, useEffect } from "react";
import Card from "../../../blocks/Card/Card";
import { Table, Col, Row, Skeleton, Button, DatePicker, message } from "antd";
import moment from "moment";
import { getStateMeeting } from "../../../services/wednesdayMeeting.services";
import "./WednesdayMeeting.css";
import useViewAccess from "../../../hooks/useViewAccess";
const monthFormat = "YYYY-MM";
const summaryColumn = [
  {
    title: "District Name",
    dataIndex: "district_name",
    key: "district_name",
    width: 200,
    fixed: "left",
  },
  {
    title: "Meetings Count",
    children: [
      {
        title: "Mandal Meetings Count",
        dataIndex: "mandal_meetings_district_wise_count",
        key: "mandal_meetings_district_wise_count",
        width: 130,
      },
      {
        title: "Secretariat Meetings Count",
        dataIndex: "secretariat_meetings_district_wise_count",
        key: "secretariat_meetings_district_wise_count",
        width: 130,
      },
    ],
  },
  {
    title: "Mobiles Not Working",
    children: [
      {
        title: "Reported Count",
        dataIndex: "mobiles_not_working",
        key: "mobiles_not_working",
        width: 130,
        sorter: (a, b) => a.mobiles_not_working - b.mobiles_not_working,
      },
      {
        title: "Solved Count",
        dataIndex: "total_mobiles_redressed_count",
        key: "total_mobiles_redressed_count",
        width: 130,
      },
    ],
  },
  {
    title: "Sims Not Working",
    children: [
      {
        title: "Reported Count",
        dataIndex: "sims_not_working",
        key: "sims_not_working",
        width: 130,
        sorter: (a, b) => a.sims_not_working - b.sims_not_working,
      },
      {
        title: "Solved Count",
        dataIndex: "total_sims_redressed_count",
        key: "total_sims_redressed_count",
        width: 130,
      },
    ],
  },
  {
    title: "FP Scanner Not Working",
    children: [
      {
        title: "Reported Count",
        dataIndex: "fp_scanners_not_working",
        key: "fp_scanners_not_working",
        width: 130,
        sorter: (a, b) => a.fp_scanners_not_working - b.fp_scanners_not_working,
      },
      {
        title: "Solved Count",
        dataIndex: "total_fp_scanners_redressed_count",
        key: "total_fp_scanners_redressed_count",
        width: 130,
      },
    ],
  },
  {
    title: "CFMS ID Issues",
    children: [
      {
        title: "Reported Count",
        dataIndex: "cfms_id_issues",
        key: "cfms_id_issues",
        width: 130,
      },

      {
        title: "Solved Count",
        dataIndex: "total_cfms_id_issues_redressed_count",
        key: "total_cfms_id_issues_redressed_count",
        width: 130,
      },
    ],
  },
  {
    title: "Salary Issues",
    children: [
      {
        title: "Reported Count",
        dataIndex: "salary_issues",
        key: "salary_issues",
        width: 130,
      },

      {
        title: "Solved Count",
        dataIndex: "total_salary_issues_redressed_count",
        key: "total_salary_issues_redressed_count",
        width: 130,
      },
    ],
  },
  {
    title: "Other Issues",
    children: [
      {
        title: "Reported Count",
        dataIndex: "other_issues",
        key: "other_issues",
        width: 130,
      },

      {
        title: "Solved Count",
        dataIndex: "total_other_issues_redressed_count",
        key: "total_other_issues_redressed_count",
        width: 130,
      },
    ],
  },
  {
    title: "Total",
    children: [
      {
        title: "Raised Count",
        dataIndex: "total_issues_raised_count_district_wise",
        key: "total_issues_raised_count_district_wise",
        width: 130,
      },
      {
        title: "Solved Count",
        dataIndex: "total_redressed_count_district_wise",
        key: "total_redressed_count_district_wise",
        width: 130,
      },
    ],
  },
];
const WednesdayMeeting = () => {
  const [data, setData] = useState(null);
  const [ticketsData, setTicketsData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonType, setButtonType] = useState(false);
  const curDate = moment().format(monthFormat);
  useViewAccess("is_state_executive_authority", "is_admin");

  useEffect(() => {
    getStateMeetingApi(curDate);
  }, []);

  const getStateMeetingApi = (date) => {
    setLoading(true);
    getStateMeeting(date)
      .then((response) => {
        setData(response.data);
        let sumData = JSON.parse(JSON.stringify(response.data));
        let a = sumData.pop();
        sumData.push({
          district_name: "Total",
          mandal_meetings_district_wise_count: a.mandal_meetings_count,
          secretariat_meetings_district_wise_count:
            a.secretariat_meetings_count,
          sims_not_working: a.last_row_sims_not_working,
          total_sims_redressed_count: a.last_row_sims_redressed,
          mobiles_not_working: a.last_row_mobiles_not_working,
          total_mobiles_redressed_count: a.last_row_mobiles_redressed,
          fp_scanners_not_working: a.last_row_fp_scanners_not_working,
          total_fp_scanners_redressed_count: a.last_row_fp_scanners_redressed,
          total_issues_raised_count_district_wise: a.total_issues_raised,
          total_redressed_count_district_wise: a.total_issues_resolved,
          cfms_id_issues: a.last_row_cfms_id_issues,
          total_cfms_id_issues_redressed_count:
            a.last_row_cfms_id_issues_redressed,
          salary_issues: a.last_row_salary_issues,
          total_salary_issues_redressed_count:
            a.last_row_salary_issues_redressed,
          other_issues: a.last_row_other_issues,
          total_other_issues_redressed_count: a.last_row_other_issues_redressed,
        });
        setSummaryData(sumData);
        setTicketsData(response.data[response.data.length - 1]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error(`${error?.response?.data?.message} for ${curDate}`);
        setLoading(false);
        setSummaryData(null);
      });
  };

  function onChange(date) {
    const selectedDate = moment(date).format(monthFormat);
    getStateMeetingApi(selectedDate);
  }

  const comprehensiveColumn = [
    {
      title: "District Name",
      dataIndex: "district_name",
      key: "district_name",
      width: 200,
      fixed: "left",
    },
    {
      title: "Mobiles Not Working",
      children: [
        {
          title: "Mandal Meetings Count",
          dataIndex: "mandal_meetings_district_wise_count",
          key: "mandal_meetings_district_wise_count",
          width: 130,
        },
        {
          title: "Secretariat Meetings Count",
          dataIndex: "secretariat_meetings_district_wise_count",
          key: "secretariat_meetings_district_wise_count",
          width: 130,
        },
        {
          title: "Reported Count",
          dataIndex: "mobiles_not_working",
          key: "mobiles_not_working",
          width: 130,
          sorter: (a, b) => a.mobiles_not_working - b.mobiles_not_working,
        },
        {
          title: "Solved Count",
          dataIndex: "total_mobiles_redressed_count",
          key: "total_mobiles_redressed_count",
          width: 130,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "mobiles_not_working_redressed_at_secretariat_level",
          key: "mobiles_not_working_redressed_at_secretariat_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.mobiles_not_working_redressed_at_secretariat_level -
            b.mobiles_not_working_redressed_at_secretariat_level,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "mobiles_not_working_redressed_at_mandal_level",
          key: "mobiles_not_working_redressed_at_mandal_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.mobiles_not_working_redressed_at_mandal_level -
            b.mobiles_not_working_redressed_at_mandal_level,
        },
        {
          title: "Solved @ District Level",
          dataIndex: "mobiles_not_working_redressed_at_district_level",
          key: "mobiles_not_working_redressed_at_district_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.mobiles_not_working_redressed_at_district_level -
            b.mobiles_not_working_redressed_at_district_level,
        },
      ],
    },
    {
      title: "Sims Not Working",
      children: [
        {
          title: "Reported Count",
          dataIndex: "sims_not_working",
          key: "sims_not_working",
          width: 130,
          sorter: (a, b) => a.sims_not_working - b.sims_not_working,
        },
        {
          title: "Solved Count",
          dataIndex: "total_sims_redressed_count",
          key: "total_sims_redressed_count",
          width: 130,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "sims_not_working_redressed_at_secretariat_level",
          key: "sims_not_working_redressed_at_secretariat_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.sims_not_working_redressed_at_secretariat_level -
            b.sims_not_working_redressed_at_secretariat_level,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "sims_not_working_redressed_at_mandal_level",
          key: "sims_not_working_redressed_at_mandal_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.sims_not_working_redressed_at_mandal_level -
            b.sims_not_working_redressed_at_mandal_level,
        },
        {
          title: "Solved @ District Level",
          dataIndex: "sims_not_working_redressed_at_district_level",
          key: "sims_not_working_redressed_at_district_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.sims_not_working_redressed_at_district_level -
            b.sims_not_working_redressed_at_district_level,
        },
      ],
    },
    {
      title: "FP Scanner Not Working",
      children: [
        {
          title: "Reported Count",
          dataIndex: "fp_scanners_not_working",
          key: "fp_scanners_not_working",
          width: 130,
          sorter: (a, b) =>
            a.fp_scanners_not_working - b.fp_scanners_not_working,
        },
        {
          title: "Solved Count",
          dataIndex: "total_fp_scanners_redressed_count",
          key: "total_fp_scanners_redressed_count",
          width: 130,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "fp_scanners_not_working_redressed_at_secretariat_level",
          key: "fp_scanners_not_working_redressed_at_secretariat_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.fp_scanners_not_working_redressed_at_secretariat_level -
            b.fp_scanners_not_working_redressed_at_secretariat_level,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "fp_scanners_not_working_redressed_at_mandal_level",
          key: "fp_scanners_not_working_redressed_at_mandal_level",
          width: 180,
          editable: true,
          sorter: (a, b) =>
            a.fp_scanners_not_working_redressed_at_mandal_level -
            b.fp_scanners_not_working_redressed_at_mandal_level,
        },
        {
          title: "Solved @ District Level",
          dataIndex: "fp_scanners_not_working_redressed_at_district_level",
          key: "fp_scanners_not_working_redressed_at_district_level",
          width: 190,
          editable: true,
          sorter: (a, b) =>
            a.fp_scanners_not_working_redressed_at_district_level -
            b.fp_scanners_not_working_redressed_at_district_level,
        },
      ],
    },
    {
      title: "CFMS ID Issues",
      children: [
        {
          title: "Reported Count",
          dataIndex: "cfms_id_issues",
          key: "cfms_id_issues",
          width: 130,
        },

        {
          title: "Solved Count",
          dataIndex: "total_cfms_id_issues_redressed_count",
          key: "total_cfms_id_issues_redressed_count",
          width: 130,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "cfms_id_issues_redressed_at_secretariat_level",
          key: "cfms_id_issues_redressed_at_secretariat_level",
          width: 180,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "cfms_id_issues_redressed_at_mandal_level",
          key: "cfms_id_issues_redressed_at_mandal_level",
          width: 180,
        },
        {
          title: "Solved @ District Level",
          dataIndex: "cfms_id_issues_redressed_at_district_level",
          key: "cfms_id_issues_redressed_at_district_level",
          width: 180,
        },
      ],
    },
    {
      title: "Salary Issues",
      children: [
        {
          title: "Reported Count",
          dataIndex: "salary_issues",
          key: "salary_issues",
          width: 130,
        },

        {
          title: "Solved Count",
          dataIndex: "total_cfms_id_issues_redressed_count",
          key: "total_cfms_id_issues_redressed_count",
          width: 130,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "salary_issues_redressed_at_secretariat_level",
          key: "salary_issues_redressed_at_secretariat_level",
          width: 180,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "salary_issues_redressed_at_mandal_level",
          key: "salary_issues_redressed_at_mandal_level",
          width: 180,
        },
        {
          title: "Solved @ District Level",
          dataIndex: "salary_issues_redressed_at_district_level",
          key: "salary_issues_redressed_at_district_level",
          width: 180,
        },
      ],
    },
    {
      title: "Other Issues",
      children: [
        {
          title: "Reported Count",
          dataIndex: "other_issues",
          key: "other_issues",
          width: 130,
        },
        {
          title: "Solved Count",
          dataIndex: "total_cfms_id_issues_redressed_count",
          key: "total_cfms_id_issues_redressed_count",
          width: 130,
        },
        {
          title: "Solved @ Secretariat Level",
          dataIndex: "other_issues_redressed_at_secretariat_level",
          key: "other_issues_redressed_at_secretariat_level",
          width: 180,
        },
        {
          title: "Solved @ Mandal Level",
          dataIndex: "other_issues_redressed_at_mandal_level",
          key: "other_issues_redressed_at_mandal_level",
          width: 180,
        },
        {
          title: "Solved @ District Level",
          dataIndex: "other_issues_redressed_at_district_level",
          key: "other_issues_redressed_at_district_level",
          width: 180,
        },
      ],
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[16, 16]} style={{ paddingBottom: "16px" }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Card title="Mandal Meetings Count" className="ticket-status">
              {loading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <p>{ticketsData?.mandal_meetings_count}</p>
              )}
            </Card>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Card title="Secretariat Meetings Count" className="ticket-status">
              {loading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <p>{ticketsData?.secretariat_meetings_count}</p>
              )}
            </Card>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Card title="Issues Raised" className="ticket-status">
              {loading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <p>{ticketsData?.total_issues_raised}</p>
              )}
            </Card>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Card title="Issues Resolved" className="ticket-status">
              {loading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <p>{ticketsData?.total_issues_resolved}</p>
              )}
            </Card>
          </Col>
        </Row>
      </Col>

      <Col span={24} style={{ display: "flex", paddingBottom: "16px" }}>
        <Col span={12}>
          <Button
            type={buttonType ? "default" : "primary"}
            onClick={() => {
              setButtonType(false);
            }}
            block
          >
            Summary
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type={buttonType ? "primary" : "default"}
            block
            onClick={() => {
              setButtonType(true);
            }}
          >
            Comprehensive
          </Button>
        </Col>
      </Col>
      <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
        <DatePicker
          defaultValue={moment(curDate)}
          onChange={onChange}
          picker="month"
          allowClear={false}
        />
      </Col>
      <Col span={24}>
        {buttonType ? (
          <Table
            loading={loading}
            dataSource={data}
            columns={comprehensiveColumn}
            pagination={{ pageSize: 30, showSizeChanger: false }}
            scroll={{ x: 1000 }}
          />
        ) : (
          <Table
            loading={loading}
            dataSource={summaryData}
            columns={summaryColumn}
            pagination={{ pageSize: 30, showSizeChanger: false }}
            scroll={{ x: 1000 }}
          />
        )}
      </Col>
    </Row>
  );
};

export default WednesdayMeeting;
