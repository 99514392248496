import { Alert, Button, Col, Drawer, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import AddSimCard from "./AddSimCard";
import { getSIMCardDataAPI } from "../../../services/serviceRequests.services";
import useVSAccess from "../../../hooks/useVSAccess";
import useViewAccess from "../../../hooks/useViewAccess";

function SimCardMapping() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [simCardData, setSimCardData] = useState([]);
  useVSAccess();
  useViewAccess(
    "is_ward_welfare_data_processing_secretary",
    "is_village_secretariat"
  );

  useEffect(() => {
    getSIMCardDataAPI()
      .then((res) => {
        setSimCardData(res.data);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  }, [refresh]);

  const refreshData = () => setRefresh((prev) => !prev);

  const showDrawer = () => setOpen(true);
  const onClose = () => {
    if (loading) return;
    setOpen(false);
  };

  const columns = [
    {
      title: "Functionary Name",
      dataIndex: "functionary_name",
      key: "functionary_name",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "CFMS ID",
      dataIndex: "cfms_id",
      key: "cfms_id",
    },
    {
      title: "Sim Card Number",
      dataIndex: "sim_card_number",
      key: "sim_card_number",
    },
    {
      title: "Service Provider",
      dataIndex: "service_provider",
      key: "service_provider",
    },
  ];

  return (
    <Row gutter={[12, 12]}>
      <Col span={12}>
        <Alert
          type="info"
          showIcon
          description="Please enter details ONLY of those Sim Cards provided to Secretariat Functionaries, by the GSWS Department"
        />
      </Col>
      <Col span={12} style={{ textAlign: "right" }}>
        <Button type="primary" onClick={showDrawer}>
          Add Sim Card
        </Button>
      </Col>
      <Col span={24}>
        <Table
          dataSource={simCardData}
          columns={columns}
          pagination={false}
          bordered={true}
          loading={loading}
        />
      </Col>
      <Drawer title="Add Sim Card" onClose={onClose} open={open}>
        <AddSimCard
          closeDrawer={onClose}
          setLoading={setLoading}
          loading={loading}
          refresh={refreshData}
        />
      </Drawer>
    </Row>
  );
}

export default SimCardMapping;
