import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Segmented } from "antd";
import TabsData from "./TabsData";
import DamagedTabs from "./DamagedTabs";
import useViewAccess from "../../../hooks/useViewAccess";

const TabsPage = () => {
  const history = useHistory();
  const [selectedVal, setSelectedVal] = useState("aggregrate");
  useViewAccess("is_state_executive_authority", "is_admin");
  return (
    <Row gutter={[24, 24]}>
      <Col span={22} offset={1} style={{ marginTop: "20px" }}>
        <Segmented
          defaultValue={selectedVal}
          onChange={(val) => {
            setSelectedVal(val);
            history.push(`/tabs/${val}`);
          }}
          options={[
            {
              label: `Tabs Aggregate`,
              value: "aggregrate",
            },
            {
              label: "Region-wise Screen Damaged tickets",
              value: "damaged",
            },
          ]}
        />
      </Col>
      <Col span={24}>
        {selectedVal === "aggregrate" ? <TabsData /> : <DamagedTabs />}
      </Col>
    </Row>
  );
};
export default TabsPage;
