import React, { useEffect, useState } from "react";
import { Form, Button, message, Input, DatePicker } from "antd";
import { addDistrictMeeting } from "../../../services/wednesdayMeeting.services";
import moment from "moment";

const { TextArea } = Input;

const WednesdayMeetingForm = ({ editList, refresh, setVisible, id }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (editList?.data) {
      form.setFieldsValue({
        ...editList?.data,
        meeting_date: moment(editList?.data.meeting_date),
      });
    }
  }, [editList]);

  const onFinish = (values) => {
    const formData = {
      ...values,
      meeting_date: moment(values?.meeting_date).format("YYYY-MM-DD"),
    };
    setBtnLoading(true);
    addDistrictMeeting(id, formData)
      .then((response) => {
        message.success(response.data.message);
        refresh();
        setVisible(false);
        setBtnLoading(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        setBtnLoading(false);
      });
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }

  return (
    <Form
      name="Mandal meeting"
      layout="vertical"
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        name="meeting_date"
        label="Choose meeting date"
        rules={[
          {
            required: true,
            message: "Please choose meeting held date",
          },
        ]}
      >
        <DatePicker
          disabled={editList?.title?.includes("Update Meeting Notes")}
          disabledDate={disabledDate}
        />
      </Form.Item>

      <Form.Item
        label="Minutes Of Meeting"
        name="minutes_of_meeting"
        rules={[
          {
            required: true,
            message: "Please add minutes of meeting!",
          },
        ]}
      >
        <TextArea maxLength={500} />
      </Form.Item>

      <Form.Item
        label="Other Outstanding Issues"
        name="other_outstanding_issues"
      >
        <TextArea maxLength={500} />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={btnLoading}
          disabled={btnLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default WednesdayMeetingForm;
