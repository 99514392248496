import React from "react";
import { Col, Row, Table } from "antd";

const UnifromSummary = ({ data }) => {
  const suppliedColumns = [
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      sorter: (a, b) => a.district.localeCompare(b.district),
      render: (data, record) => <span>{record.district ? data : "-"}</span>,
    },
    {
      title: " Supply Secretariats",
      dataIndex: "supplied_secretariats",
      key: "supplied_secretariats",
      sorter: (a, b) => a.supplied_secretariats - b.supplied_secretariats,
    },
    {
      title: "  Supply Pending Secretariats",
      dataIndex: "supply_pending_secretariats",
      key: "supply_pending_secretariats",
      sorter: (a, b) =>
        a.supply_pending_secretariats - b.supply_pending_secretariats,
    },
    {
      title: "Confirmation  Secretariats",
      dataIndex: "confirmed_secretariats",
      key: "confirmed_secretariats",
      sorter: (a, b) => a.confirmed_secretariats - b.confirmed_secretariats,
    },
    {
      title: "  Confirmation Pending Secretariats",
      dataIndex: "confirmation_pending_secretariats",
      key: "confirmation_pending_secretariats",
      sorter: (a, b) =>
        a.confirmation_pending_secretariats -
        b.confirmation_pending_secretariats,
    },
  ];

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Table columns={suppliedColumns} dataSource={data} pagination={false} />
      </Col>
    </Row>
  );
};

export default UnifromSummary;
