import React, { useState, useEffect, useMemo } from "react";
import { Form, Select, Button, message, Upload, Tag, Input } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getVSInventory } from "../../../../services/inventory.services";
import { addKMRequests } from "../../../../services/serviceRequests.services";
import { USER } from "../../../../constants/defaultKeys";
import { getItem } from "../../../../helpers/localStorage";
import {
  ImageUploadProps,
  handleFileUploadError,
} from "../../../../helpers/utility";

const { Option } = Select;
const { TextArea } = Input;

const KeyboardAndMouse = ({ id }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [fileUploadError, setFileUploadError] = useState();
  const [uploadedFileName, setuploadedFileName] = useState(null);
  const [uploadFileLoading, setUploadFileLoading] = useState(null);

  const userDetails = JSON.parse(getItem(USER));

  const selectedDeviceData = useMemo(() => {
    if (data) {
      return data.filter((item) => item?.inventory_type === "CPU");
    }
  }, [data, formValues]);

  useEffect(() => {
    getVSInventory(id)
      .then((response) => {
        setData(response.data.inventory_data);
      })
      .catch((error) => console.log(error));
  }, [id]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const uploadConfig = {
    folder: "issues-images",
    pvt: false,
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  const onFinish = (values) => {
    const formData = new FormData();
    for (const [field, value] of Object.entries(values)) {
      if (field === "front_image") {
        formData.append("front_image", uploadedFileName?.btn1);
      } else if (field === "back_image") {
        formData.append("back_image", uploadedFileName?.btn2);
      } else {
        formData.append(field, value);
      }
    }

    addKMRequests(id, formData)
      .then((response) => {
        message.success(response.data.message);
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.response?.data?.message);
      });
  };
  return (
    <Form
      name="office_365"
      onFinish={onFinish}
      wrapperCol={{ span: 8 }}
      form={form}
      layout="vertical"
      onValuesChange={onFormValuesChange}
    >
      <Form.Item
        name="inventory_type"
        label="Choose Device Type"
        rules={[
          {
            required: true,
            message: "Please select your device type!",
          },
        ]}
      >
        <Select size="large">
          <Option value="Keyboard">Keyboard</Option>
          <Option value="Mouse">Mouse</Option>
        </Select>
      </Form.Item>
      {formValues && (
        <>
          {formValues?.inventory_type === "Keyboard" && (
            <Form.Item
              name="front_image"
              label={`Upload ${formValues?.inventory_type} front side Image`}
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: `Please upload ${formValues?.inventory_type} front side image!`,
                },
              ]}
              extra={
                fileUploadError?.btn1 ? (
                  handleFileUploadError(
                    "btn1",
                    fileUploadError,
                    setFileUploadError
                  )
                ) : (
                  <span>
                    Please upload images with .png,.jpg or .jpeg format and file
                    size should be less than 5MB
                  </span>
                )
              }
              // extra="Please upload images with .png or .jpg format"
            >
              <Upload
                {...ImageUploadProps(
                  "btn1",
                  fileUploadError,
                  setFileUploadError,
                  uploadConfig,
                  uploadedFileName,
                  setuploadedFileName,
                  uploadFileLoading,
                  setUploadFileLoading
                )}
                customRequest={dummyRequest}
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
          )}
          <Form.Item
            name="back_image"
            label={`Upload ${formValues?.inventory_type} back side image`}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: `Please upload ${formValues?.inventory_type} back side image!`,
              },
            ]}
            extra={
              fileUploadError?.btn2 ? (
                handleFileUploadError(
                  "btn2",
                  fileUploadError,
                  setFileUploadError
                )
              ) : (
                <span>
                  Please upload images with .png,.jpg or .jpeg format and file
                  size should be less than 5MB
                </span>
              )
            }
            // extra="Please upload images with .png or .jpg format"
          >
            <Upload
              {...ImageUploadProps(
                "btn2",
                fileUploadError,
                setFileUploadError,
                uploadConfig,
                uploadedFileName,
                setuploadedFileName,
                uploadFileLoading,
                setUploadFileLoading
              )}
              customRequest={dummyRequest}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Choose CPU Number"
            name="cpu_serial_number"
            extra={`Choose the cpu serial number to which the ${formValues?.inventory_type} is connected`}
            rules={[
              {
                required: true,
                message: "Please choose cpu serial Number",
              },
            ]}
          >
            <Select size="large">
              {selectedDeviceData.map((item) => (
                <Option value={item.device_id} key={item.id}>
                  {item.inventory_type}{" "}
                  <Tag color="purple" size="small">
                    {item.device_id}
                  </Tag>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Issue Description"
            name="issue"
            rules={[
              {
                required: true,
                message: "Please input  issue description",
              },
            ]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            label="Secretariat Address"
            name="address"
            extra="Please provide the complete secretariat address with landmark and pincode."
            rules={[
              {
                required: true,
                message: "Please input your secretariat address",
              },
            ]}
          >
            <TextArea size="large" required />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              disabled={
                !userDetails?.is_village_secretariat ||
                uploadFileLoading?.btn1 ||
                uploadFileLoading?.btn2
              }
              loading={uploadFileLoading?.btn1 || uploadFileLoading?.btn2}
            >
              Submit
            </Button>
          </Form.Item>
        </>
      )}{" "}
    </Form>
  );
};

export default KeyboardAndMouse;
