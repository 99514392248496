import axiosInstance from "../config/axios/axios.config";

export const metaDataApi = () => {
  return axiosInstance.get("/accounts/user-meta-data/");
};

export const getUserMetaDataApi = () => {
  return axiosInstance.get("/accounts/get-user-meta-data/");
};
export const fetchSchoolsData = (id) => {
  return axiosInstance.get(`/cr-v2/fetch-region-wise-schools/?${id}`);
};
export const fetchSecretariatSchools = (id, param) => {
  return axiosInstance.get(`/cr-v2/fetch-secretariat-schools/${id}/?${param}`);
};
export const getRegionsData = () => {
  return axiosInstance.get(`/cr-v2/fetch-regions/`);
};

export const getImgAPI = (payload) => {
  return axiosInstance.post(`/resources/`, payload, { responseType: "blob" });
};
