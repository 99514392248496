import { Button, Form, Select, message, Input } from "antd";
import React, { useEffect } from "react";
import { updateClustersVolunteerInfo } from "../../../../src/services/inventory.services";

const { Option } = Select;

const CreateClusterForm = ({ id, setVisible, refresh, editList }) => {
  const clusterDetails = editList.data;
  const [form] = Form.useForm();

  useEffect(() => {
    if (editList.status === "update_cluster") {
      form.setFieldsValue({
        ...clusterDetails,
      });
    }
  }, [editList]);

  const onSubmit = (values) => {
    const code = values.cluster_code;

    updateClustersVolunteerInfo(code, values)
      .then((res) => {
        message.success(res.data.message);
        setVisible(false);
        refresh();
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        console.log(error);
      });
  };
  const FpsDevices = editList?.inv?.filter(
    (fpsDevice) => fpsDevice?.inventory_type === "FPScanner"
  );
  const MobileDevices = editList?.inv?.filter(
    (mobileDevice) => mobileDevice?.inventory_type === "Mobile"
  );

  return (
    <Form
      onFinish={onSubmit}
      form={form}
      layout="vertical"
      initialValues={{
        cluster_code: clusterDetails.cluster_code,
        volunteer_name: clusterDetails.volunteer_name,
      }}
    >
      <Form.Item label="Cluster Code" name="cluster_code">
        <Input disabled />
      </Form.Item>

      <Form.Item label="Volunteer Name" name="volunteer_name">
        <Input disabled />
      </Form.Item>

      <Form.Item label="FPScanner" name="volunteer_fpscanner">
        <Select placeholder="Finger Print Scanner" allowClear>
          {FpsDevices?.map((fps) => (
            <Option value={fps?.id} key={fps?.device_id}>
              {fps?.device_id}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Mobile IMEI Number" name="volunteer_mobile">
        <Select placeholder="Mobile IMEI Number" allowClear>
          {MobileDevices?.map((mobile) => (
            <Option value={mobile?.id} key={mobile.device_id}>
              {mobile.device_id}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="volunteer_sim" label="SIM Number">
        <Select placeholder="SIM Number" allowClear disabled>
          {editList?.sims?.map((sim) => (
            <Option value={sim?.id} key={sim?.sim_number}>
              {sim?.sim_number}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Button htmlType="submit" type="primary">
        Submit
      </Button>
    </Form>
  );
};

export default CreateClusterForm;
