import React, { useState, useEffect } from "react";
import { Table, Col, Row, Button } from "antd";

import {
  getSecretariatClusters,
  getVSInventory,
  simNumbers,
} from "../../../../src/services/inventory.services";
import Drawer from "../../Drawer/Drawer";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";

const ClusterTable = ({ id, loading }) => {
  const [data, setData] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editList, setEditList] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [dataInv, setInvData] = useState(null);
  const [simNumbersData, setSimNumbersData] = useState();
  const userDetails = JSON.parse(getItem(USER));

  useEffect(() => {
    getSecretariatClusters(id)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => console.log(error));

    getVSInventory(id)
      .then((response) => {
        setInvData(response?.data?.inventory_data);
      })
      .catch((error) => console.log(error));

    simNumbers(id)
      .then((response) => {
        setSimNumbersData(response.data);
      })
      .catch((error) => console.log(error));
  }, [id, refresh]);

  const openModal = (data) => {
    setEditList({ ...data, inv: dataInv, sims: simNumbersData });
    setDrawerVisible(true);
  };
  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const columns = [
    {
      title: "Cluster Code",
      dataIndex: "cluster_code",
      key: "cluster_code",
    },
    {
      title: "Volunteer Name",
      dataIndex: "volunteer_name",
      key: "volunteer_name",
    },
    {
      title: "FPScanner",
      dataIndex: "volunteer_fpscanner_device_id",
      key: "volunteer_fpscanner_device_id",
    },
    {
      title: "Mobile IMEI Number",
      dataIndex: "volunteer_mobile_device_id",
      key: "volunteer_mobile_device_id",
    },
    {
      title: "SIM Number",
      dataIndex: "volunteer_sim_device_id",
      key: "volunteer_sim_device_id",
    },
    {
      title: "Action",
      key: "is_data_added",
      width: "100px",

      render: (_, record) => (
        <>
          <Button
            type="primary"
            block
            disabled={!userDetails?.is_village_secretariat}
            onClick={() => {
              openModal({
                status: record?.is_data_added
                  ? "update_cluster"
                  : "create_cluster",
                title: "Map Volunteer Devices",
                data: record,
              });
            }}
          >
            {record?.is_data_added ? "Edit Mapped Devices" : "Map Devices"}
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={22} offset={1}>
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={false}
          />
        </Col>
      </Row>
      {drawerVisible && (
        <Drawer
          setVisible={setDrawerVisible}
          id={id}
          editList={editList}
          refresh={refreshPage}
        />
      )}
    </>
  );
};
export default ClusterTable;
