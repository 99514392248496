import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  message,
  Typography,
  Divider,
  Tooltip,
  Select,
  Radio,
  Upload,
  Tag,
  Alert,
} from "antd";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { akDevices, devices } from "../../../../constants/devices";
import { useParams, Link } from "react-router-dom";
import { getVSInventory } from "../../../../services/inventory.services";
import { raiseIssue } from "../../../../services/issue.services";
import * as config from "../../../../config/validation.config";
import { USER } from "../../../../constants/defaultKeys";
import { getItem } from "../../../../helpers/localStorage";
import {
  ImageUploadProps,
  handleFileUploadError,
} from "../../../../helpers/utility";

const { Option } = Select;
const { TextArea } = Input;
const { Paragraph } = Typography;

const ReportForm = () => {
  const query = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const userDetails = JSON.parse(getItem(USER));
  const [refresh, setRefresh] = useState(false);
  const [curListItem, setCurListItem] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [aadharDevices, setAadharDevices] = useState(null);
  const [fileUploadError, setFileUploadError] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showQuestioner, setShowQuestioner] = useState(false);
  const [uploadedFileName, setuploadedFileName] = useState(null);
  const [uploadFileLoading, setUploadFileLoading] = useState(null);
  const isAadharDeviceList = aadharDevices ? akDevices : null;
  const allDevices = { ...devices, ...isAadharDeviceList };
  const isAadharDevice = curListItem?.inventory_type.includes("AK");

  useEffect(() => {
    form.setFieldsValue({
      ticket_contact_person_email: userDetails?.email,
      ticket_contact_person_mobile: userDetails?.contact_number_unmasked,
      secretariat_address: userDetails?.region?.region_address,
    });
    getVSInventory(query.id)
      .then((response) => {
        setAadharDevices(response.data?.aadhar_kits_supplied);
        const data = response.data.inventory_data;
        if (data) {
          const formData = data.filter(
            (item) =>
              item.device_source === "provided_by_gsws_dept" &&
              !(item.inventory_type === "Sim") &&
              !item.ticket
          );

          setData(formData);
        }
      })
      .catch((error) => console.log(error));
  }, [query, refresh]);

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const basicTroubleshoot = (e) => {
    setShowQuestioner(e.target.checked);
  };

  const onReportFormSubmit = (values) => {
    setButtonLoading(true);
    const formData = new FormData();
    for (const [field, value] of Object.entries(values)) {
      if (field === "image_for_reference" && value !== undefined) {
        formData.append("image_for_reference", uploadedFileName?.btn2);
      } else if (field === "job_sheet" && value !== undefined) {
        formData.append("job_sheet", uploadedFileName?.btn1);
      } else if (field === "device_serialno_image" && value !== undefined) {
        formData.append("device_serialno_image", uploadedFileName?.btn3);
      } else if (field === "ups_snapshot" && value !== undefined) {
        formData.append("ups_snapshot", uploadedFileName?.btn4);
      } else {
        formData.append(field, value !== undefined ? value : "");
      }
    }

    raiseIssue(values.device_id, formData)
      .then((response) => {
        form.resetFields();
        setShowQuestioner(false);
        message.success(response.data.message);
        setCurListItem(null);
        refreshPage();
        setButtonLoading(false);
      })
      .catch((error) => {
        setButtonLoading(false);
        message.error(error?.response?.data?.message);
        console.log(error);
      });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const uploadConfig = {
    folder: "issues-images",
    pvt: false,
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
    if (changedValues?.device_id) {
      const inventoryType = changedValues.device_id;
      form.resetFields();
      setFormValues(null);
      form.setFieldsValue({
        device_id: inventoryType,
      });
      const filterData = data.find(
        (item) => item.id === changedValues?.device_id
      );
      setCurListItem(filterData);
    }
  };

  const deviceTypeQuestioner = (device) => {
    return (
      <Checkbox.Group>
        <Row>
          {allDevices?.[device]?.questions?.map((item, idx) => (
            <Col span={24}>
              <Checkbox
                key={idx}
                value={item.name}
                style={{
                  lineHeight: "32px",
                }}
              >
                {item.label}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    );
  };

  return (
    <Fragment>
      <Alert
        message="To report an issue, first add device details to inventory and make sure the inventory is provided by GSWS Department."
        type="info"
      />
      <br />
      <Form
        name="report"
        onFinish={onReportFormSubmit}
        form={form}
        onValuesChange={onFormValuesChange}
        wrapperCol={{
          span: 10,
        }}
        layout="vertical"
        initialValues={{
          is_ups_working: false,
          is_system_connected_to_ups: false,
          is_earth_connection_available: false,
        }}
      >
        <Form.Item
          name="device_id"
          label="Choose Device Type"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Select size="large" style={{ minWidth: "150px" }}>
            {data?.map((item) => (
              <Option value={item.id} key={item.id}>
                {item.inventory_type}{" "}
                {item?.device_id && <Tag color="magenta">{item.device_id}</Tag>}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {curListItem && (
          <Form.Item
            name="is_troubleshooted"
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
            valuePropName="checked"
          >
            <Checkbox onChange={basicTroubleshoot}>
              {curListItem?.inventory_type === "Mobile" ? (
                <span>
                  I confirm, I've taken the mobile to an
                  <Link
                    to="/instructions/mobile"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    authorised service center
                  </Link>
                </span>
              ) : (
                "I confirm, I have done basic troubleshooting."
              )}
            </Checkbox>
          </Form.Item>
        )}
        {showQuestioner && (
          <>
            {curListItem?.inventory_type === "Mobile" && (
              <>
                <Form.Item
                  name="job_sheet_id"
                  label="Add Job Sheet Id, which is provided by service center"
                  rules={[
                    {
                      required: true,
                      message: "Please input job sheet id",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Job Sheet ID"
                    minLength={17}
                    maxLength={17}
                  />
                </Form.Item>
                <Form.Item
                  name="job_sheet"
                  label="Upload Job Sheet Image for reference"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  extra={
                    fileUploadError?.btn1 ? (
                      handleFileUploadError(
                        "btn1",
                        fileUploadError,
                        setFileUploadError
                      )
                    ) : (
                      <span>
                        Please upload images with .png,.jpg or .jpeg format and
                        file size should be less than 5MB
                      </span>
                    )
                  }
                  // extra="Please upload images with .png or .jpg format"
                >
                  <Upload
                    {...ImageUploadProps(
                      "btn1",
                      fileUploadError,
                      setFileUploadError,
                      uploadConfig,
                      uploadedFileName,
                      setuploadedFileName,
                      uploadFileLoading,
                      setUploadFileLoading
                    )}
                    customRequest={dummyRequest}
                  >
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                  </Upload>
                </Form.Item>
              </>
            )}
            <Paragraph>
              Please choose applicable issues with the device
            </Paragraph>
            <Form.Item
              name="issues"
              rules={[
                {
                  required: true,
                  message: "Troubleshoot type",
                },
              ]}
            >
              {deviceTypeQuestioner(curListItem?.inventory_type)}
            </Form.Item>
            <Form.Item name="additional_info">
              <TextArea placeholder="Additional Info (Optional)" />
            </Form.Item>
            <Form.Item
              name="image_for_reference"
              label="Upload damaged device image for reference"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              extra={
                fileUploadError?.btn2 ? (
                  handleFileUploadError(
                    "btn2",
                    fileUploadError,
                    setFileUploadError
                  )
                ) : (
                  <span>
                    Please upload images with .png,.jpg or .jpeg format and file
                    size should be less than 5MB
                  </span>
                )
              }
              // extra="Please upload images with .png or .jpg format"
            >
              <Upload
                {...ImageUploadProps(
                  "btn2",
                  fileUploadError,
                  setFileUploadError,
                  uploadConfig,
                  uploadedFileName,
                  setuploadedFileName,
                  uploadFileLoading,
                  setUploadFileLoading
                )}
                customRequest={dummyRequest}
              >
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            {isAadharDevice && (
              <Form.Item
                name="device_serialno_image"
                label="Upload device serial number image"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                extra={
                  fileUploadError?.btn3 ? (
                    handleFileUploadError(
                      "btn3",
                      fileUploadError,
                      setFileUploadError
                    )
                  ) : (
                    <span>
                      Please upload images with .png,.jpg or .jpeg format and
                      file size should be less than 5MB
                    </span>
                  )
                }
                // extra="Please upload images with .png or .jpg format"
              >
                <Upload
                  {...ImageUploadProps(
                    "btn3",
                    fileUploadError,
                    setFileUploadError,
                    uploadConfig,
                    uploadedFileName,
                    setuploadedFileName,
                    uploadFileLoading,
                    setUploadFileLoading
                  )}
                  customRequest={dummyRequest}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            )}
            {isAadharDevice && (
              <Form.Item
                name="anydesk_id"
                label="AnyDesk ID"
                rules={[
                  {
                    required: true,
                    message: "Please input anydesk id!",
                    min: 5,
                  },
                ]}
              >
                <Input size="large" placeholder="AnyDesk ID" />
              </Form.Item>
            )}
            <br />
            {(curListItem?.inventory_type === "Monitor" ||
              curListItem?.inventory_type === "CPU") && (
              <>
                <Divider orientation="left" plain>
                  Additional Info required by OEM Vendor
                </Divider>
                <Form.Item
                  name="associated_device_id"
                  label={`Add the serial number of ${
                    curListItem?.inventory_type === "Monitor"
                      ? "CPU"
                      : "Monitor"
                  }, which is associated to that ${
                    curListItem?.inventory_type === "Monitor"
                      ? "Monitor"
                      : "CPU"
                  }`}
                  rules={[
                    {
                      required: true,
                      message: "Please input your serial no!",
                      min: 5,
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={
                      curListItem?.inventory_type === "Monitor"
                        ? "CPU Serial No"
                        : "Monitor Serial No"
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="is_ups_working"
                  label="UPS available in working condition?"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>

                {formValues?.is_ups_working && (
                  <Form.Item
                    name="ups_working_since"
                    label="Since how many days you are using system on UPS"
                    rules={[
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      placeholder="Status"
                      style={{ minWidth: "150px" }}
                    >
                      <Option value="Less than 2 weeks" key="less_than_2_weeks">
                        Less than 2 weeks
                      </Option>
                      <Option
                        value="2 weeks to 3 months"
                        key="2_weeks_to_3_months"
                      >
                        2 weeks to 3 months
                      </Option>
                      <Option
                        value="Greater than 3 months"
                        key="more_than_3_months"
                      >
                        Greater than 3 months
                      </Option>
                    </Select>
                  </Form.Item>
                )}

                <Form.Item
                  name="is_system_connected_to_ups"
                  label="System connected to UPS?"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>

                {formValues?.is_system_connected_to_ups && (
                  <Form.Item
                    name="ups_snapshot"
                    label="Share the image of UPS that is connected to device"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    extra={
                      fileUploadError?.btn4 ? (
                        handleFileUploadError(
                          "btn4",
                          fileUploadError,
                          setFileUploadError
                        )
                      ) : (
                        <span>
                          Please upload images with .png,.jpg or .jpeg format
                          and file size should be less than 5MB
                        </span>
                      )
                    }
                    // extra="Please upload images with .png or .jpg format"
                    rules={[
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ]}
                  >
                    <Upload
                      {...ImageUploadProps(
                        "btn4",
                        fileUploadError,
                        setFileUploadError,
                        uploadConfig,
                        uploadedFileName,
                        setuploadedFileName,
                        uploadFileLoading,
                        setUploadFileLoading
                      )}
                      customRequest={dummyRequest}
                    >
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                  </Form.Item>
                )}

                <Form.Item
                  name="is_earth_connection_available"
                  label="Is earth connection available?"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            <br />
            <Divider orientation="left" plain>
              Vendor/Service provider will contact using below details
              <Tooltip title="Service vendor will reach out to the provided details below">
                <InfoCircleOutlined />
              </Tooltip>
            </Divider>
            <Form.Item
              name="ticket_contact_person_mobile"
              rules={[
                {
                  ...config.mobileNumberRegex[0],
                  required: true,
                },
              ]}
            >
              <Input
                maxLength="10"
                placeholder="Mobile Number"
                addonBefore={"+91"}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="ticket_contact_person_alternate_mobile"
              rules={[
                {
                  ...config.mobileNumberRegex[0],
                  required: false,
                },
              ]}
            >
              <Input
                maxLength="10"
                placeholder="Alternate Mobile Number (Optional)"
                addonBefore={"+91"}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="ticket_contact_person_email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input your email",
                },
              ]}
            >
              <Input placeholder="Email" size="large" />
            </Form.Item>
            <Form.Item
              name="secretariat_address"
              extra="Please provide the complete secretariat address with landmark and pincode."
              rules={[
                {
                  required: true,
                  message: "Please input your secretariat address",
                },
              ]}
            >
              <TextArea
                size="large"
                placeholder="Secretariat Address"
                required
              />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            disabled={
              (userDetails?.is_village_secretariat && showQuestioner
                ? false
                : true) ||
              uploadFileLoading?.btn1 ||
              uploadFileLoading?.btn2 ||
              uploadFileLoading?.btn3 ||
              uploadFileLoading?.btn4
            }
            loading={
              buttonLoading ||
              uploadFileLoading?.btn1 ||
              uploadFileLoading?.btn2 ||
              uploadFileLoading?.btn3 ||
              uploadFileLoading?.btn4
            }
          >
            Report
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default ReportForm;
