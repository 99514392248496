import React, { useEffect, useState } from "react";
import { Table, Row, Col, Button } from "antd";
import { isMobileOnly } from "react-device-detect";
import { getOffice365Requests } from "../../../services/inventory.services";
import { tableFilterDistricts } from "../../../constants/defaultKeys";
import ActionModal from "./ActionsModal";
import moment from "moment";

const status = [
  {
    text: "Open",
    value: "Open",
  },
  {
    text: "Waiting On User",
    value: "waiting-on-user",
  },
  {
    text: "Closed",
    value: "Closed",
  },
];

const Office365Vendor = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editList, setEditList] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getOffice365Requests()
      .then((response) => setData(response.data))
      .catch((error) => console.log(error));
  }, [, refresh]);

  const openModal = (data) => {
    setEditList(data);
    setShowModal(true);
  };
  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const columns = [
    {
      title: "Request Type",
      dataIndex: "type_of_request",
      key: "type_of_request",
      width: 170,
      render: (data, record) => (
        <span>
          {record.type_of_request === "credentials_request"
            ? "Forgot Username/Password"
            : record.type_of_request === "office365_signin_issue"
            ? "Office 365 Sign-In Issue"
            : record.type_of_request === "office365_not_working"
            ? "Office 365 Not Working"
            : record.type_of_request === "new_installation_request"
            ? "Office 365 Installation"
            : record.type_of_request === "office365_reactivation"
            ? "Office 365 Reactivation"
            : data}
        </span>
      ),
      sorter: (a, b) => a.type_of_request.localeCompare(b.type_of_request),
    },
    {
      title: "Issue Raised By",
      dataIndex: "raised_by_user",
      key: "raised_by_user",
      width: 170,
      sorter: (a, b) => a.raised_by_user.localeCompare(b.raised_by_user),
    },
    {
      title: "User Contact",
      dataIndex: "raised_by_user_contact",
      key: "raised_by_user_contact",
      width: 130,
    },
    {
      title: "Secretariat",
      dataIndex: "secretariat_name",
      key: "secretariat_name",
      width: 150,
      sorter: (a, b) => a.secretariat_name.localeCompare(b.secretariat_name),
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      width: 130,
      filters: [...tableFilterDistricts],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.district.includes(value),
    },
    {
      title: "Issue Raised",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      render: (date) => moment(date).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
    {
      title: "Resolution Status",
      dataIndex: "resolution_status",
      key: "resolution_status",
      width: 200,
      filters: [...status],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.resolution_status.includes(value),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: 200,
      render: (data, record) => <span>{record?.remarks ? data : "-"}</span>,
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (record) => (
        <>
          {!(record?.resolution_status === "Closed") ? (
            <Button
              type="primary"
              size="small"
              onClick={() => openModal(record)}
            >
              Change status
            </Button>
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];

  return (
    <Row>
      <Col span={22} offset={1}>
        <Table
          columns={columns}
          dataSource={data}
          size="small"
          pagination={{ pageSize: 50, showSizeChanger: false }}
          scroll={isMobileOnly && { x: 500, y: 500 }}
        />
      </Col>
      {showModal && (
        <ActionModal
          setVisible={setShowModal}
          editList={editList}
          refresh={refreshPage}
          status={status}
        />
      )}
    </Row>
  );
};

export default Office365Vendor;
