import React, { Fragment, useState } from "react";
import { Row, Col, Button, Space, message, Switch } from "antd";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import DeviceBadge from "../../../blocks/Badges/DeviceBadge";
import Card from "../../../blocks/Card/Card";
import { downloadVillageData } from "../../../services/downloadFile.services";
import { devices, akDevices } from "../../../constants/devices";
import Drawer from "../../Drawer/Drawer";
import useVSAccess from "../../../hooks/useVSAccess";

const DeviceBadges = ({
  hasPermission,
  data,
  userDetails,
  query,
  refresh,
  id,
}) => {
  const [editList, setEditList] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isAadhar, setIsAadhar] = useState(false);
  const [aadharDevices, setShowAadharDevices] = useState(devices);
  const { canVSAccess } = useVSAccess(false);

  const openModal = (modalData) => {
    setEditList({
      ...modalData,
      isAadharSecretariat: data?.aadhar_kits_supplied,
    });
    setDrawerVisible(true);
  };

  const download = () => {
    setBtnLoading(true);
    downloadVillageData(query.id)
      .then((response) => response.data)
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "inventory.xlsx";
        a.click();
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong, Please try after sometime.");
        setBtnLoading(false);
      });
  };

  function onChange(checked) {
    setIsAadhar(checked);
    setShowAadharDevices(checked ? akDevices : devices);
  }

  return (
    <Fragment>
      <Card
        title="Inventory Summary"
        extra={
          hasPermission && (
            <Space>
              {data?.aadhar_kits_supplied && (
                <Switch
                  checkedChildren="Aadhar"
                  unCheckedChildren="Non-Aadhar"
                  defaultChecked={false}
                  onChange={onChange}
                />
              )}
              {canVSAccess && (
                <>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() =>
                      openModal({
                        status: "create",
                        title: "Create Inventory",
                        ticket: null,
                        data: data,
                      })
                    }
                  >
                    Add Inventory
                  </Button>
                  <Button
                    size="small"
                    type="primary"
                    onClick={download}
                    loading={btnLoading}
                  >
                    {btnLoading ? <LoadingOutlined /> : <DownloadOutlined />}
                  </Button>
                </>
              )}
            </Space>
          )
        }
        className="inventory-summary"
      >
        <Row gutter={[24, 10]}>
          {Object.keys(aadharDevices).map((item) => (
            <Col style={{ textAlign: "center" }} span={isAadhar && 3}>
              <DeviceBadge
                deviceType={item}
                badgeType="count"
                workingCount={
                  Object.keys(data).length !== 0
                    ? data?.statistics[item]?.working
                    : 0
                }
                notWorkingCount={
                  Object.keys(data).length !== 0
                    ? data?.statistics[item]?.not_working
                    : 0
                }
              />
            </Col>
          ))}
        </Row>
      </Card>
      {drawerVisible && (
        <Drawer
          setVisible={setDrawerVisible}
          editList={editList}
          id={query?.id}
          refresh={refresh}
          userDetails={userDetails}
          data={data}
          setEditList={setEditList}
        />
      )}
    </Fragment>
  );
};

export default DeviceBadges;
