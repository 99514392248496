import React, { Fragment } from 'react';
import DefaultLayout from './DefaultLayout/DefaultLayout';
import './Layout.css';

function Layout({ children }) {
  return (
    <Fragment>
      <DefaultLayout>{children}</DefaultLayout>
    </Fragment>
  );
}

export default Layout;
