import React from "react";

import { Row, Alert } from "antd";

const Cpu = () => {
  return (
    <Row>
      <Alert
        message="ALWAYS use Cpu with UPS"
        description="Please ensure Cpu is connected to the UPS while you're using it.
         Warranty could become void if you don't connect Cpu to the UPS"
        type="warning"
        showIcon
      />
    </Row>
  );
};

export default Cpu;
