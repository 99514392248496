import React, { useState } from "react";
import {
  Table,
  Row,
  Col,
  Pagination,
  Form,
  Select,
  Tooltip,
  Button,
  DatePicker,
  Typography,
  List,
  Tag,
  Space,
} from "antd";
import { CloseCircleTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import moment from "moment";
import { aekStatus } from "../../../constants/defaultKeys";
import { DownloadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import DrawerForm from "../../Drawer/Drawer";
import KitStatusTab from "../../VS/KitsStatusTab";

const { RangePicker } = DatePicker;
const is_uida = true;
const { Option } = Select;
const { Title } = Typography;
const AekFilters = ({
  tab,
  onStatusChange,
  defaultVal,
  data,
  loading,
  dates,
  handleDownload,
  btnLoading,
  setDates,
  currentPage,
  setCurrentPage,
  statusParam,
  defaultRegions,
  districts,
  regionsData,
  onDistrictChange,
  form,
  onFilterChange,
  onMandalChange,
  mandals,
  secretariats,
  onSecretariatChange,
  selectedFilters,
  userDetails,
  refresh,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [editList, setEditList] = useState(null);
  const [isKitDraweVisible, setIsKitDraweVisible] = useState(false);

  const openModal = (data) => {
    setEditList(data);
    setIsVisible(true);
  };
  const deviceStatus = (status) => {
    switch (status) {
      case "in-progress":
        return (
          <Tag color="orange" style={{ color: "black" }}>
            In Progress
          </Tag>
        );
      case "forward-to-UIDAI":
        return (
          <Tag color="yellow" text="Forwarded" style={{ color: "black" }}>
            Forwarded to UIDAI
          </Tag>
        );
      case "closed":
        return (
          <Tag color="success" style={{ color: "black" }}>
            Closed
          </Tag>
        );
      //   case "Re-Opened":
      //     return <Badge color="red" text="ReOpened" />;
      case "waiting-on-user":
        return (
          <Tag color="cyan" style={{ color: "black" }}>
            Waiting On User
          </Tag>
        );
      default:
        return (
          <Tag color="blue" style={{ color: "black" }}>
            Open
          </Tag>
        );
    }
  };
  const columns = [
    {
      title: "Ticket ID",
      dataIndex: "ticket_id",
      key: "ticket_id",
      width: 150,
      fixed: "left",
      render: (value) => (
        <Link to={`/aek-vendor?ticket=${value}`}>{value}</Link>
      ),
    },
    {
      title: " Station ID",
      key: "station_id",
      dataIndex: "station_id",
      width: 150,
    },
    {
      title: " User ID",
      key: "user_id",
      dataIndex: "user_id",
      width: 150,
    },
    {
      title: " Machine ID",
      key: "machine_id",
      dataIndex: "machine_id",
      width: 150,
    },
    {
      title: " Anydesk ID",
      key: "anydesk_id",
      dataIndex: "anydesk_id",
      width: 150,
    },
    {
      title: "Issue Type",
      key: "issues",
      dataIndex: "issues",
      width: 300,
      render: (value, record) => {
        return (
          <List
            className="project-issues-list"
            size="small"
            bordered={false}
            dataSource={value}
            renderItem={(item) => (
              <List.Item>{item.replaceAll("_", " ")}</List.Item>
            )}
          />
        );
      },
      sorter: (a, b) =>
        b.issues.sort().join("").localeCompare(a.issues.sort().join("")),
    },
    {
      title: "Issue Description",
      dataIndex: "issue_description",
      key: "issue_description",
      width: 300,
    },
    {
      title: "Issue staus",
      dataIndex: "issue_status",
      key: "issue_status",
      width: 150,
      render: (value) => deviceStatus(value),
    },
    // {
    //   title: "Created By DC",
    //   dataIndex: "created_by_dc",
    //   key: "created_by_dc",
    //   width: 150,
    //   render: (value) =>
    //     value ? (
    //       <CheckCircleTwoTone
    //         style={{ fontSize: " 25px" }}
    //         twoToneColor="#32CD32"
    //       />
    //     ) : (
    //       <CloseCircleTwoTone
    //         style={{ fontSize: " 25px" }}
    //         twoToneColor="#FF0000"
    //       />
    //     ),
    // },
    {
      title: "Issue raised date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      width: 180,
    },

    {
      title: "Issue closed date",
      dataIndex: "issue_closed_date",
      key: "issue_closed_date",
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "-"),
      sorter: (a, b) =>
        moment(a.issue_closed_date).unix() - moment(b.issue_closed_date).unix(),
      width: 180,
    },
    {
      title: "File for reference",
      dataIndex: "file_ref",
      key: "file_ref",
      render: (value) => (
        <a href={value} target="_blank">
          {value}
        </a>
      ),
      width: 300,
    },

    {
      title: "LOG File",
      dataIndex: "log_file",
      key: "log_file",
      width: 300,
    },
  ];
  // const hodFilterColumns = columns?.filter(
  //   (col) => col?.key !== "created_by_dc"
  // );
  const modalTitle =
    tab === "self" ? "Raise Ticket" : "Raise Secretariat Ticket";
  return (
    <Row gutter={[12, 12]}>
      <Col span={16} style={{ paddingLeft: "24px" }}>
        <Form form={form} layout="vertical" onValuesChange={onFilterChange}>
          <Row gutter={[12, 12]}>
            {!userDetails?.is_uidai && (
              <Col span={6}>
                <Form.Item
                  name="status"
                  label={<Title level={5}>Status</Title>}
                >
                  <Select onChange={onStatusChange} defaultValue={statusParam}>
                    {[defaultVal, ...aekStatus].map((item) => (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {!userDetails?.region && (
              <Col span={6}>
                <Form.Item
                  name="district"
                  label={<Title level={5}>District</Title>}
                >
                  <Select onChange={onDistrictChange} defaultValue={"ALL"}>
                    {regionsData &&
                      [defaultRegions, ...regionsData]?.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {tab !== "self" && (
              <>
                <Col span={6}>
                  <Form.Item
                    name="mandal"
                    label={<Title level={5}>Mandal</Title>}
                  >
                    <Select onChange={onMandalChange} defaultValue={"ALL"}>
                      {mandals &&
                        mandals?.map((item) => (
                          <Option value={item.id} key={item.id}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="secretariat"
                    label={<Title level={5}>Secretariat</Title>}
                  >
                    <Select onChange={onSecretariatChange} defaultValue={"ALL"}>
                      {secretariats?.secretariats &&
                        [
                          defaultRegions,
                          ...secretariats?.["secretariats"],
                        ]?.map((item) => (
                          <Option value={item.id} key={item.id}>
                            {item.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </Col>
      <Col span={8} style={{ paddingTop: "8px" }}>
        <Title level={5}>
          Date Range
          <Tooltip title="Active From - Active To">
            <InfoCircleOutlined style={{ paddingLeft: "2px" }} />
          </Tooltip>
        </Title>
        <RangePicker
          allowClear={false}
          value={[dates?.startDate, dates?.endDate]}
          onChange={(date) => {
            setDates({
              startDate: date[0],
              endDate: date[1],
            });
          }}
        />
      </Col>
      <Col
        span={24}
        style={{
          textAlign: "right",
          paddingRight: "24px",
        }}
      >
        <Space>
          {userDetails?.is_aek_dc && (
            <>
              <Button type="primary" onClick={() => setIsKitDraweVisible(true)}>
                Kit Status
              </Button>
              <Button
                type="primary"
                onClick={() =>
                  openModal({
                    title: modalTitle,
                    status: "sec_ticket",
                    key: "sec_ticket",
                  })
                }
              >
                {modalTitle}
              </Button>
            </>
          )}
          <Button type="primary" onClick={handleDownload} loading={btnLoading}>
            <DownloadOutlined /> Download AEK Issues
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        <Table
          style={{ padding: "24px" }}
          columns={columns}
          loading={loading}
          dataSource={data?.results}
          size="small"
          scroll={{ x: 600 }}
          pagination={false}
        />
        <div style={{ textAlign: "right" }}>
          <Pagination
            onChange={(page) => {
              setCurrentPage(page);
            }}
            style={{ paddingTop: "10px", alignItems: "right" }}
            current={currentPage}
            total={data?.count}
          />
        </div>
        {isVisible && (
          <DrawerForm
            setVisible={setIsVisible}
            editList={editList}
            tab={tab}
            refresh={refresh}
          />
        )}
        {isKitDraweVisible && (
          <KitStatusTab
            userDetails={userDetails}
            isKitDraweVisible={isKitDraweVisible}
            setIsKitDraweVisible={setIsKitDraweVisible}
          />
        )}
      </Col>
    </Row>
  );
};
export default AekFilters;
