import React, { Fragment, useState } from "react";
import { Modal, Button, Input, Form, message } from "antd";
import { reOpenInternetServiceRequest } from "../../../../services/inventory.services";

const { TextArea } = Input;

const ReOpenInternetForm = ({ data, refresh, id }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onFinish = (values) => {
    reOpenInternetServiceRequest(id, data.ticket_id, values)
      .then((response) => {
        message.success(response.data.message);
        setIsModalVisible(false);
        refresh();
      })
      .catch((error) => message.error(error.response.data.message));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <Button type="primary" onClick={showModal}>
        Reopen Ticket
      </Button>
      <Modal
        title="Reopen Ticket"
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <Form name="basic" onFinish={onFinish}>
          <Form.Item
            name="re_open_reason"
            rules={[
              {
                required: true,
                message: "Please input reason for reopen ticket!",
              },
            ]}
          >
            <TextArea placeholder="Reason for reopen ticket" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Reopen Ticket
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default ReOpenInternetForm;
