import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Input,
  Button,
  message,
  InputNumber,
  Descriptions,
} from "antd";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";
import {
  updateTabPageTicket,
  getServiceCenters,
} from "../../../services/inventory.services";
import * as config from "../../../config/validation.config";
const { Option } = Select;

const UpdateTicketModal = ({ refresh, setVisible, id, editList }) => {
  const userData = JSON.parse(getItem(USER));
  const [formValues, setFormValues] = useState(null);
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [serviceCenter, setServiceCenter] = useState(null);
  const tabData = editList?.data;

  useEffect(() => {
    if (editList?.data) {
      form.setFieldsValue({
        ...editList?.data,
        school: editList?.data?.school_name,
        other_school_name: editList?.data?.other_school_name,
      });
    }
  }, [editList?.data]);

  const onFormValueChange = async (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  useEffect(() => {
    getServiceCenters(userData?.region?.district_identifier)
      .then((res) => {
        setServiceCenter(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const serviceCenterDetails = serviceCenter?.find(
    (item) => item?.id === formValues?.service_center
  );

  const onSubmit = (values) => {
    let payload = {
      ...((values?.school !== "others" || tabData?.school) && {
        school: tabData?.school ? tabData?.school : values?.school,
      }),
      ...((values?.school === "others" || !tabData?.school) && {
        school_name: values?.school_name || tabData?.school_name,
      }),
      serial_no: values?.serial_no ? values?.serial_no : tabData?.serial_no,
      student_name: values?.student_name
        ? values?.student_name
        : tabData?.student_name,
      child_id: values?.child_id ? values?.child_id : tabData?.child_id,
      service_center: values?.service_center
        ? values?.service_center
        : tabData?.service_center,
      mandal: tabData?.mandal,
      ...(!(values?.resolution === "No Resultion Yet") && {
        resolution: values?.resolution
          ? values?.resolution
          : tabData?.resolution,
      }),
      jobsheet_id: values?.jobsheet_id
        ? values?.jobsheet_id
        : tabData?.jobsheet_id,
      status: values?.status ? values?.status : tabData?.status,
      cost_of_repair: values?.cost_of_repair
        ? values?.cost_of_repair
        : tabData?.cost_of_repair,
      name_of_cp: values?.name_of_cp ? values?.name_of_cp : tabData?.name_of_cp,
      mobile_number_of_cp: values?.mobile_number_of_cp
        ? values?.mobile_number_of_cp
        : tabData?.mobile_number_of_cp,
    };

    setBtnLoading(true);
    updateTabPageTicket(id, payload)
      .then((response) => {
        message.success(response.data.message);
        refresh();
        setVisible(false);
        setBtnLoading(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        setBtnLoading(false);
        // setVisible(false);
      });
  };

  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      form={form}
      onValuesChange={onFormValueChange}
      autoComplete="off"
      initialValues={{ school: editList?.data?.school_name }}
    >
      {tabData?.school && (
        <Form.Item label="School" name="school">
          <Input disabled />
        </Form.Item>
      )}

      {(formValues?.school === "others" || !tabData?.school) && (
        <Form.Item label="School Name" name="other_school_name">
          <Input type="text" disabled />
        </Form.Item>
      )}
      <Form.Item label="Student Name" name="student_name">
        <Input type="text" />
      </Form.Item>

      <Form.Item label="Child ID" name="child_id">
        <InputNumber style={{ width: "300px" }} />
      </Form.Item>
      <Form.Item label="Device Serial No" name="serial_no">
        <Input type="text" />
      </Form.Item>
      <Form.Item label="Job Sheet Id" name="jobsheet_id">
        <Input type="text" />
      </Form.Item>
      <Form.Item
        label="Name of Contact Person"
        name="name_of_cp"
        rules={[
          {
            required: true,
            message: "Please enter contact person name",
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>
      <Form.Item
        label="Mobile number"
        name="mobile_number_of_cp"
        rules={config.mobileNumberRegex}
      >
        <Input
          autoComplete="off"
          size="large"
          maxLength="10"
          placeholder="Mobile Number"
          addonBefore={"+91"}
        />
      </Form.Item>
      <Form.Item
        label="Choose Service Centre Name"
        name="service_center"
        rules={[
          {
            required: true,
            message: "Please choose service centre name",
          },
        ]}
      >
        <Select placeholder="Select Service Centre">
          {serviceCenter &&
            serviceCenter?.map((service) => (
              <Option key={service.id} value={service?.id}>
                {service.name}
              </Option>
            ))}
        </Select>
      </Form.Item>
      {formValues?.service_center && (
        <Descriptions>
          <Descriptions.Item label="Name" span={3}>
            {serviceCenterDetails?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Primary Contact Number" span={3}>
            {serviceCenterDetails?.phone1}
          </Descriptions.Item>
          <Descriptions.Item label="Secondary Contact Number" span={3}>
            {serviceCenterDetails?.phone2}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={3}>
            {serviceCenterDetails?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Address" span={3}>
            {serviceCenterDetails?.address}
          </Descriptions.Item>
        </Descriptions>
      )}
      <Form.Item label="Repair Status" name="status">
        <Select>
          <Option key="Pending" value="Pending">
            Pending
          </Option>
          <Option key="Resolved" value="Resolved">
            Repaired
          </Option>
          <Option
            key="Delivered back to the student"
            value="Delivered back to the student"
          >
            Delivered back to the student
          </Option>
        </Select>
      </Form.Item>
      <Form.Item label="Resolution Status" name="resolution">
        <Select>
          <Option key="RepairedAndFixed" value="RepairedAndFixed">
            Repaired And Fixed
          </Option>
          <Option key="Replaced" value="Replaced">
            Replaced
          </Option>
          <Option key="NotCoveredUnderWarranty" value="NotCoveredUnderWarranty">
            Not Covered Under Warranty
          </Option>
        </Select>
      </Form.Item>
      {(formValues?.resolution === "NotCoveredUnderWarranty" ||
        editList?.data?.resolution === "NotCoveredUnderWarranty") && (
        <Form.Item label="Cost Of Repair" name="cost_of_repair">
          <Input type="text" />
        </Form.Item>
      )}

      <Button htmlType="submit" type="primary" loading={btnLoading}>
        Submit
      </Button>
    </Form>
  );
};
export default UpdateTicketModal;
