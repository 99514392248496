import React, { useMemo } from "react";
import Toolbar from "../../components/Toolbar/Toolbar";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";
import "./DefaultLayout.css";
import { isMobileOnly } from "react-device-detect";
import { getItem } from "../../helpers/localStorage";
import { USER, supportMailId } from "../../constants/defaultKeys";
import MobileNavigationItems from "../../components/Toolbar/NavigationItems/MobileNavigationItems";

const { Header, Content, Footer } = Layout;

function DefaultLayout({ children }) {
  const userDetails = JSON.parse(getItem(USER));
  const location = useLocation();
  const hideFooter = useMemo(
    () => location.pathname.includes("state"),
    [location]
  );

  return (
    <Layout className="layout">
      <Header className="layout-header">
        <Toolbar />
      </Header>
      <Content className={isMobileOnly ? "mobile-layout-body" : "layout-body"}>
        {children}
      </Content>

      {isMobileOnly && !userDetails?.is_vendor ? (
        <>{isMobileOnly && !hideFooter && <MobileNavigationItems />}</>
      ) : (
        <Footer className="footer">
          Copyright © {new Date().getFullYear()} VSWS. All Rights
          Reserved.Developed by
          <a
            href="https://beyondscale.tech/"
            target="_blank"
            style={{
              textDecoration: "underline",
              paddingLeft: "3px",
              textUnderlineOffset: "2px",
            }}
            rel="noreferrer"
          >
            BeyondScale.
          </a>
          <br />
          For any issues related to this portal, please contact
          <a href="mailto:support@vsws.co.in"> {supportMailId}</a> or call
          <a href="tel:9154409886"> 9154409886</a> /
          <a href="tel:9154409884"> 9154409884</a>
        </Footer>
      )}
    </Layout>
  );
}

export default DefaultLayout;
