import React, { Fragment, useState, useEffect } from "react";
import { List, Table, Row, Col, Tag } from "antd";
import { Link, useParams } from "react-router-dom";
import { secretariatAekServiceRequests } from "../../../../services/serviceRequests.services";
import moment from "moment";

function AekTrack() {
  const query = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    secretariatAekServiceRequests(query?.id)
      .then((response) => {
        setData(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const deviceStatus = (status) => {
    switch (status) {
      case "in-progress":
        return (
          <Tag color="orange" style={{ color: "black" }}>
            In Progress
          </Tag>
        );
      case "forward-to-UIDAI":
        return (
          <Tag color="yellow" text="Forwarded" style={{ color: "black" }}>
            Forwarded to UIDAI
          </Tag>
        );
      case "closed":
        return (
          <Tag color="success" style={{ color: "black" }}>
            Closed
          </Tag>
        );
      //   case "Re-Opened":
      //     return <Badge color="red" text="ReOpened" />;
      case "waiting-on-user":
        return (
          <Tag color="cyan" style={{ color: "black" }}>
            Waiting On User
          </Tag>
        );
      default:
        return (
          <Tag color="blue" style={{ color: "black" }}>
            Open
          </Tag>
        );
    }
  };
  const columnData = [
    {
      title: " Ticket ID",
      key: "ticket_id",
      dataIndex: "ticket_id",
      width: 150,
      fixed: "left",
      render: (value) => <Link to={`/aek-issues/${value}`}>{value}</Link>,
    },
    {
      title: " Station ID",
      key: "station_id",
      dataIndex: "station_id",
      width: 150,
    },
    {
      title: " User ID",
      key: "user_id",
      dataIndex: "user_id",
      width: 150,
    },
    {
      title: " Machine ID",
      key: "machine_id",
      dataIndex: "machine_id",
      width: 150,
    },
    {
      title: " Anydesk ID",
      key: "anydesk_id",
      dataIndex: "anydesk_id",
      width: 150,
    },
    {
      title: "Issues",
      key: "issues",
      dataIndex: "issues",
      width: 300,
      render: (value, record) => {
        return (
          <List
            className="project-issues-list"
            size="small"
            bordered={false}
            dataSource={value}
            renderItem={(item) => (
              <List.Item>{item.replaceAll("_", " ")}</List.Item>
            )}
          />
        );
      },
    },
    {
      title: "Issue Description",
      dataIndex: "issue_description",
      key: "issue_description",
      width: 300,
    },
    {
      title: "Issue staus",
      dataIndex: "issue_status",
      key: "issue_status",
      filters: [
        {
          text: "Open",
          value: "open",
        },
        {
          text: "In Progress",
          value: "in-progress",
        },
        {
          text: "Waiting On User",
          value: "waiting-on-user",
        },
        {
          text: "Forwarded to UIDAI",
          value: "forward-to-UIDAI",
        },
        {
          text: "Closed",
          value: "closed",
        },
      ],
      onFilter: (value, record) => record.issue_status === value,
      render: (value) => deviceStatus(value),
      width: 150,
    },
    {
      title: "File for reference",
      dataIndex: "file_ref",
      key: "file_ref",
      render: (value) => (
        <a href={value} target="_blank">
          {value}
        </a>
      ),
      width: 300,
    },
    {
      title: "Issue raised date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      width: 180,
    },

    {
      title: "Issue closed date",
      dataIndex: "issue_closed_date",
      key: "issue_closed_date",
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "-"),
      sorter: (a, b) =>
        moment(a.issue_closed_date).unix() - moment(b.issue_closed_date).unix(),
      width: 180,
    },
    {
      title: "LOG File",
      dataIndex: "log_file",
      key: "log_file",
      width: 300,
    },
  ];
  return (
    <Row>
      <Col span={24}>
        <Table
          bordered
          dataSource={data?.results}
          columns={columnData}
          pagination={false}
          scroll={{ x: 1800 }}
        />
      </Col>
    </Row>
  );
}

export default AekTrack;
