import axiosInstance from "../config/axios/axios.config";

export const downloadStateData = (category) => {
  return axiosInstance.get(`/download-state-inventory/?category=${category}`, {
    responseType: "blob",
  });
};

export const downloadDistrictData = (id, payload) => {
  return axiosInstance.get(`/download-district-inventory/${id}/`, {
    responseType: "blob",
    params: {
      region_type: payload,
    },
  });
};

export const downloadMandalData = (id) => {
  return axiosInstance.get(`/download-mandal-inventory/${id}/`, {
    responseType: "blob",
  });
};

export const downloadVillageData = (id) => {
  return axiosInstance.get(`/download-secretariat-inventory/${id}/`, {
    responseType: "blob",
  });
};

export const vendorIssuesFileDownload = (payload) => {
  return axiosInstance.get(`/download-issues-for-vendor/`, {
    responseType: "blob",
    params: {
      ...payload,
    },
  });
};

export const stateIssuesFileDownload = (payload) => {
  return axiosInstance.get(`/download-issues-for-state/`, {
    responseType: "blob",
    params: {
      ...payload,
    },
  });
};

export const downloadSecretariatMeetings = (id, month) => {
  return axiosInstance.get(
    `/download-secretariat-meetings-at-district/${id}/?month=${month}`,
    {
      responseType: "blob",
    }
  );
};
export const downloadMandalMeetings = (id, month) => {
  return axiosInstance.get(
    `/download-mandal-meetings-at-district/${id}/?month=${month}`,
    {
      responseType: "blob",
    }
  );
};

export const downloadIssuesForDistrict = (id, params) => {
  return axiosInstance.get(`/download-issues-for-district/${id}/`, {
    params,
    responseType: "blob",
  });
};

export const downloadDistrictInventoryRequests = (id, payload) => {
  return axiosInstance.get(`/download-district-inventory-requests/${id}`, {
    responseType: "blob",
    params: {
      ...payload,
    },
  });
};

export const downloadLEHSecretariats = (id) => {
  return axiosInstance.get(`/download-leh-secretariats/?district_id=${id}`, {
    responseType: "blob",
  });
};
export const downloadInternetDetails = (id) => {
  return axiosInstance.get(`/download-internet-details/?district_id=${id}`, {
    responseType: "blob",
  });
};

export const downloadStateLEHSecretariats = (id) => {
  return axiosInstance.get(`/download-leh-secretariats/`, {
    responseType: "blob",
  });
};

export const downloadKeyboardMouseTableData = () => {
  return axiosInstance.get(
    `/get-keyboard-and-mouse-requests-for-vendor/?action=download`,
    {
      responseType: "blob",
    }
  );
};
export const downloadSimIssues = () => {
  return axiosInstance.get(
    `/get-sim-replace-requests-for-vendor/?action=download`,
    {
      responseType: "blob",
    }
  );
};

export const uploadFile = (payload) => {
  return axiosInstance.post(`/get-upload-url/`, payload);
};

export const downloadClusterInfo = (payload) => {
  return axiosInstance.post(`/download-cluster-info/`, payload, {
    responseType: "blob",
  });
};

export const aekVendorDownload = (params) => {
  return axiosInstance.get(
    `/aek-dc-hod-service-requests-all-download/?${params}`,
    {
      responseType: "blob",
    }
  );
};
export const downloadTabPdf = (id, params) => {
  return axiosInstance.get(`/tab-services/${id}/?response=pdf`, {
    responseType: "blob",
  });
};
export const downloadAekExamRecords = () => {
  return axiosInstance.get(`/aek-exam-records/`, {
    responseType: "blob",
  });
};
export const downloadAekKitUppdateRecords = () => {
  return axiosInstance.get(`/kit-update-records/`, {
    responseType: "blob",
  });
};
export const downloadInternetConnection = () => {
  return axiosInstance.get(`/internet-connections-status-download/`, {
    responseType: "blob",
  });
};
export const downloadApsflInternetIssues = () => {
  return axiosInstance.get(`/apsfl-report-download/`, {
    responseType: "blob",
  });
};
