import React, { Fragment, useEffect } from "react";
import { InputNumber, Form, Button, message, Space, Alert } from "antd";
import {
  stationeryInventory,
  refillStationeryInventory,
} from "../../../services/inventory.services";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";

const CertificateModal = ({
  setVisible,
  editList,
  refresh,
  id,
  hideButton,
  data,
}) => {
  const [form] = Form.useForm();
  const userDetails = JSON.parse(getItem(USER));

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const onFinish = (values) => {
    {
      hideButton
        ? refillStationeryInventory(id, values)
            .then((response) => {
              message.success(response.data.message);
              setVisible(false);
              refresh();
            })
            .catch((error) => {
              message.error(error?.response?.data?.message);
              console.log(error);
            })
        : stationeryInventory(id, values)
            .then((response) => {
              message.success(response.data.message);
              setVisible(false);
              refresh();
            })
            .catch((error) => {
              message.error(error?.response?.data?.message);
              console.log(error);
            });
    }
  };

  const closeModal = () => {
    setVisible(false);
  };

  return (
    <Fragment>
      {hideButton && (
        <Alert
          message="Please input all the stationery count that are present in your secretariat."
          type="info"
        />
      )}
      <br />
      <Form
        form={form}
        name="security certificates"
        onFinish={onFinish}
        colon={false}
        layout="vertical"
      >
        <Form.Item label="Certificates Count" name="secure_certs_count">
          <InputNumber size="large" style={{ minWidth: "120px" }} />
        </Form.Item>
        <Form.Item
          label="Laminating Covers Count"
          name="laminating_covers_count"
        >
          <InputNumber size="large" style={{ minWidth: "120px" }} />
        </Form.Item>
        <Form.Item label="Ration Cards Count" name="ration_cards_count">
          <InputNumber size="large" style={{ minWidth: "120px" }} />
        </Form.Item>
        <Form.Item label="Pension Cards Count" name="pension_cards_count">
          <InputNumber size="large" style={{ minWidth: "120px" }} />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              disabled={!userDetails?.is_village_secretariat}
            >
              Submit
            </Button>
            {!hideButton && (
              <Button size="large" onClick={closeModal}>
                Remind Later
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default CertificateModal;
