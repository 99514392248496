import React, { useState } from "react";
import {
  Col,
  Row,
  Typography,
  Statistic,
  Avatar,
  Skeleton,
  Select,
  message,
  Badge,
  Space,
  Switch,
  Dropdown,
  Menu,
  Button,
} from "antd";
import {
  LoadingOutlined,
  DownloadOutlined,
  DownOutlined,
} from "@ant-design/icons";
import targetIcon from "../../../assets/images/target.png";
import workingIcon from "../../../assets/images/check.png";
import notWorkingIcon from "../../../assets/images/close.png";
import SecurityIcon from "../../../assets/devices/policy.png";
import internetLogo from "../../../assets/devices/internet.png";
import totalIcon from "../../../assets/images/total.png";
import Card from "../../../blocks/Card/Card";
import {
  downloadDistrictData,
  downloadClusterInfo,
} from "../../../services/downloadFile.services";
import InventoryTable from "./InventoryTable";
import { devices, akDevices } from "../../../constants/devices";
import DeviceBadge from "../../../blocks/Badges/DeviceBadge";

import "../District.css";
import "../../custom.css";
import useViewAccess from "../../../hooks/useViewAccess";

const { Paragraph } = Typography;
const { Option } = Select;

const Inventory = ({
  data,
  districtInventoryStats,
  id,
  loading,
  inventoryStatsLoading,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [aadharDevices, setShowAadharDevices] = useState(devices);
  const [isAadhar, setIsAadhar] = useState(false);
  useViewAccess("is_indent_authority", "is_district_authority");
  const download = (value) => {
    setBtnLoading(true);
    downloadDistrictData(id, value)
      .then((response) => response.data)
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${value}-inventory.xlsx`;
        a.click();
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong, Please try after sometime.");
        setBtnLoading(false);
      });
  };

  const clusterDownload = () => {
    let data = { district_id: id };
    setBtnLoading(true);
    downloadClusterInfo(data)
      .then((response) => response.data)
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `clustor-inventory.xlsx`;
        a.click();
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong, Please try after sometime.");
        setBtnLoading(false);
      });
  };

  function onChange(checked) {
    setIsAadhar(checked);
    setShowAadharDevices(checked ? akDevices : devices);
  }

  const menu = (
    <Menu>
      <Menu.Item
        key="Download-Secretariat-Inventory"
        icon={<DownloadOutlined />}
        onClick={() => {
          download("secretariat");
        }}
      >
        Secretariat Inventory
      </Menu.Item>
      <Menu.Item
        key="Download-Mandal-Inventory"
        icon={<DownloadOutlined />}
        onClick={() => {
          download("mandal");
        }}
      >
        Mandal Inventory
      </Menu.Item>
      <Menu.Item
        key="Download-Cluster-Info"
        icon={<DownloadOutlined />}
        onClick={() => {
          clusterDownload("cluster");
        }}
      >
        Cluster Inventory
      </Menu.Item>
    </Menu>
  );

  return (
    <Row gutter={[8, 8]}>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
      >
        <Card
          title={
            <span>
              Inventory Summary{" "}
              <Badge
                style={{ backgroundColor: "#2A8FF7" }}
                count={`Total: ${
                  isAadhar
                    ? districtInventoryStats?.aadhar_inv_count
                      ? districtInventoryStats.aadhar_inv_count
                      : "-"
                    : districtInventoryStats?.non_aadhar_inv_count
                    ? districtInventoryStats?.non_aadhar_inv_count
                    : "-"
                }`}
              />
            </span>
          }
          className="state-inventory-summary"
          extra={
            <Space>
              <Switch
                checkedChildren="Aadhar"
                unCheckedChildren="Non-Aadhar"
                defaultChecked={false}
                onChange={onChange}
              />
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button
                  loading={btnLoading}
                  type="primary"
                  icon={<DownloadOutlined />}
                >
                  Download Inventory <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          }
        >
          {inventoryStatsLoading ? (
            <Skeleton active paragraph={{ rows: 2 }} />
          ) : (
            <Row gutter={[48, 10]} style={{ marginLeft: "25px" }}>
              {Object.keys(aadharDevices).map((item, index) => (
                <Col flex="110px" key={index} style={{ padding: "0px" }}>
                  <DeviceBadge deviceType={item} badgeType="allCount" />
                  <Paragraph style={{ marginTop: "8px" }}>
                    <Statistic
                      prefix={
                        <img src={workingIcon} alt="working" height="12px" />
                      }
                      value={
                        districtInventoryStats !== null
                          ? districtInventoryStats
                              ?.district_overall_inventory_statistics[item]
                              ?.working
                          : 0
                      }
                    />
                    <Statistic
                      prefix={
                        <img
                          src={notWorkingIcon}
                          alt="notWorking"
                          height="12px"
                        />
                      }
                      value={
                        districtInventoryStats !== null
                          ? districtInventoryStats
                              ?.district_overall_inventory_statistics[item]
                              ?.not_working
                          : 0
                      }
                    />

                    <Statistic
                      prefix={<img src={totalIcon} alt="total" height="12px" />}
                      value={
                        districtInventoryStats !== null
                          ? districtInventoryStats
                              ?.district_overall_inventory_statistics[item]
                              ?.total
                          : 0
                      }
                    />
                    {/* {!item.includes("AK") && (
                      <Statistic
                        prefix={
                          <img src={targetIcon} alt="total" height="12px" />
                        }
                        value={
                          districtInventoryStats !== null
                            ? item === "Monitor"
                              ? districtInventoryStats
                                  ?.district_overall_inventory_statistics["CPU"]
                                  ?.total_delivered
                              : districtInventoryStats
                                  ?.district_overall_inventory_statistics[item]
                                  ?.total_delivered
                            : "-"
                        }
                      //no need of this metric anymore as districts have increased and there is not data from backend itself:Teja

                      />
                    )} */}
                  </Paragraph>
                </Col>
              ))}
              {!isAadhar && (
                <>
                  <Col flex="110px" style={{ padding: "0px" }}>
                    <Avatar shape="square" size={42} src={SecurityIcon} />
                    <Paragraph style={{ marginTop: "8px" }}>
                      <Statistic
                        prefix={
                          <img src={workingIcon} alt="total" height="12px" />
                        }
                        value={
                          districtInventoryStats !== null
                            ? districtInventoryStats?.security_certificates
                                ?.remaining
                            : 0
                        }
                      />
                      <Statistic
                        prefix={
                          <img
                            src={notWorkingIcon}
                            alt="notWorking"
                            height="12px"
                          />
                        }
                        value={
                          districtInventoryStats !== null
                            ? districtInventoryStats?.security_certificates
                                ?.damaged
                            : 0
                        }
                      />
                    </Paragraph>
                  </Col>
                  <Col flex="110px" style={{ padding: "0px" }}>
                    <Avatar shape="square" size={42} src={internetLogo} />
                    <Paragraph style={{ marginTop: "8px" }}>
                      <Statistic
                        prefix={
                          <img src={workingIcon} alt="working" height="12px" />
                        }
                        value={
                          districtInventoryStats !== null
                            ? districtInventoryStats?.internet_connection
                                ?.total_connections -
                              districtInventoryStats?.internet_connection
                                ?.poor_connections
                            : 0
                        }
                      />
                      <Statistic
                        prefix={
                          <img
                            src={notWorkingIcon}
                            alt="notWorking"
                            height="12px"
                          />
                        }
                        value={
                          districtInventoryStats !== null
                            ? districtInventoryStats?.internet_connection
                                ?.poor_connections
                            : 0
                        }
                      />
                    </Paragraph>
                  </Col>
                </>
              )}
            </Row>
          )}
        </Card>
      </Col>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
      >
        <InventoryTable
          data={data?.device_count_status_and_mandal_wise}
          loading={loading}
          aadharState={isAadhar}
        />
      </Col>
    </Row>
  );
};

export default Inventory;
