import { Button, Checkbox, Col, Form, Input, Row, Select, message } from "antd";
import React from "react";
import {
  addSIMCardAPI,
  validateCFMSIDAPI,
} from "../../../services/serviceRequests.services";
import { getItem } from "../../../helpers/localStorage";
import { USER } from "../../../constants/defaultKeys";

function AddSimCard({ closeDrawer, setLoading, loading, refresh }) {
  const userDetails = JSON.parse(getItem(USER));
  const [form] = Form.useForm();
  const designations = [
    "DA/Panchayat Secretary (PS)/WEDPS/WAS",
    "WEA/WWDS",
    "Mahila Police",
  ];

  const networkProviders = ["AIRTEL", "JIO", "BSNL", "VI"];
  function validateCFMSID(values) {
    validateCFMSIDAPI(values.cfms_id)
      .then((res) => {})
      .catch((err) => {
        form.setFields([
          {
            name: "cfms_id",
            errors: ["Invalid CFMS ID"],
          },
        ]);
        setLoading(false);
      });
  }
  const onFinish = (values) => {
    setLoading(true);
    // validateCFMSID(values);
    addSIMCardAPI({
      ...values,
      secretariat: userDetails?.region?.region_id,
    })
      .then((res) => {
        refresh();
        message.success("SIM Card Added Successfully");
        form.resetFields();
        closeDrawer();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || "Failed to Add SIM Card");
      })
      .finally(() => setLoading(false));
  };
  return (
    <Row>
      <Col span={24}>
        <Form
          layout="vertical"
          initialValues={{}}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            label="Mobile number provided by GSWS"
            name="sim_card_number"
            rules={[
              {
                required: true,
                message: "Please Enter Mobile number provided by GSWS",
              },
              {
                whitespace: true,
                message: "Please Enter Mobile number provided by GSWS",
              },
              {
                pattern: /^\d{10}$/,
                message: "Please Enter Valid Mobile number provided by GSWS",
              },
            ]}
          >
            <Input placeholder="Enter Mobile number provided by GSWSr" />
          </Form.Item>
          <Form.Item
            label="Service Provider"
            name="service_provider"
            rules={[
              {
                required: true,
                message: "Please Select Service Provider",
              },
            ]}
          >
            <Select placeholder="Select Service Provider">
              {networkProviders.map((provider) => (
                <Select.Option value={provider} key={provider}>
                  {provider}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Functionary Name"
            name="functionary_name"
            rules={[
              {
                required: true,
                message: "Please Enter Functionary Name",
              },
              { whitespace: true, message: "Please Enter Functionary Name" },
            ]}
          >
            <Input placeholder="Name of the Functionary Using It" />
          </Form.Item>
          <Form.Item
            label="Functionary Designation"
            name="designation"
            rules={[
              {
                required: true,
                message: "Please Select Designation",
              },
            ]}
          >
            <Select placeholder="Select Designation">
              {designations.map((designation) => (
                <Select.Option value={designation} key={designation}>
                  {designation}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="CFMS ID"
            name="cfms_id"
            rules={[
              { required: true, message: "Please Enter CFMS ID" },
              { whitespace: true, message: "Please Enter CFMS ID" },
              {
                pattern: /^\d{6,10}$/,
                message: "Please Enter Valid CFMS ID",
              },
            ]}
          >
            <Input placeholder="Enter CFMS ID" />
          </Form.Item>
          <Form.Item
            label=""
            name="t&c"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          "Please Confirm the above mentioned Sim card is provided by the GSWS department and the details provided are correct"
                        )
                      ),
              },
            ]}
          >
            <Checkbox>
              I confirm that the above mentioned Sim card is provided by the
              GSWS department and the details provided are correct
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default AddSimCard;
