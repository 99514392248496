import React, { Fragment, useState } from "react";
import { Row, Col, Statistic, Button, Space, Typography } from "antd";
import { EditFilled } from "@ant-design/icons";
import Card from "../../../blocks/Card/Card";
import Drawer from "../../Drawer/Drawer";
import useVSAccess from "../../../hooks/useVSAccess";

const { Text } = Typography;

const Certificates = ({ hasPermission, data, userDetails, query, refresh }) => {
  const [editList, setEditList] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const openModal = (data) => {
    setEditList(data);
    setDrawerVisible(true);
  };
  const { canVSAccess } = useVSAccess(false);
  return (
    <Fragment>
      <Card
        title="Secure Base Stationery"
        extra={
          hasPermission && (
            <Space>
              {/* <Button
                type="primary"
                size="small"
                onClick={() =>
                  openModal({
                    status: "certificates",
                    title: "Refill Secure Base Stationery",
                    action: "new",
                  })
                }
              >
                +
              </Button> */}
              {canVSAccess && (
                <Button
                  type="primary"
                  size="small"
                  onClick={() =>
                    openModal({
                      status: "certificates",
                      title: "Update Secure Base Stationery",
                      action: "update",
                    })
                  }
                >
                  <EditFilled />
                </Button>
              )}
            </Space>
          )
        }
        className="security-certificates"
      >
        <Row gutter={16} style={{ textAlign: "center" }}>
          <Col span={6}>
            <Statistic value={data?.secure_certs_count} />
            <Text style={{ color: "#666", fontSize: "12px" }}>
              Secure Certificates
            </Text>
          </Col>
          <Col span={6}>
            <Statistic value={data?.laminating_covers_count} />
            <Text style={{ color: "#666", fontSize: "12px" }}>
              Laminating Covers
            </Text>
          </Col>
          <Col span={6}>
            <Statistic value={data?.ration_cards_count} />
            <Text style={{ color: "#666", fontSize: "12px" }}>
              Ration Cards
            </Text>
          </Col>
          <Col span={6}>
            <Statistic value={data?.pension_cards_count} />
            <Text style={{ color: "#666", fontSize: "12px" }}>
              Pension Cards
            </Text>
          </Col>
        </Row>
      </Card>
      {drawerVisible && (
        <Drawer
          setVisible={setDrawerVisible}
          editList={editList}
          id={query?.id}
          refresh={refresh}
          userDetails={userDetails}
          data={data}
          setEditList={setEditList}
        />
      )}
    </Fragment>
  );
};

export default Certificates;
