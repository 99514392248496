import React, { useState, useEffect } from "react";
import RegionBreadCrumb from "../../RegionBreadCrumb/RegionBreadCrumb";
import { useParams } from "react-router-dom";
import { getVSInventory } from "../../../services/inventory.services";

import {
  Form,
  PageHeader,
  Radio,
  Button,
  Row,
  Col,
  InputNumber,
  Select,
  message,
} from "antd";
import Card from "../../../blocks/Card/Card";
import { fetchSecretariatSchools } from "../../../services/metaData.services";
import { updateTabsInspection } from "../../../services/serviceRequests.services";
import useViewAccess from "../../../hooks/useViewAccess";
const { Option } = Select;
const TabsInspection = () => {
  const query = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [form] = Form.useForm();
  const [schoolData, setSchoolData] = useState(null);
  useViewAccess(
    "is_ward_welfare_data_processing_secretary",
    "is_village_secretariat"
  );
  useEffect(() => {
    fetchSecretariatSchools(query?.id, `survey=tabs_survey`)
      .then((res) => {
        setSchoolData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [query]);

  //   useEffect(() => {
  //     if (query) {
  //       setLoading(true);
  //       getVSInventory(query.id)
  //         .then((response) => {
  //           setData(response?.data);
  //           setLoading(false);
  //         })
  //         .catch((error) => console.log(error));
  //     }
  //   }, [query]);
  const onFormValueChange = async (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    updateTabsInspection(values)
      .then((response) => {
        message.success(response?.data?.message);
        form.resetFields();
        // refresh();
        // setVisible(false);
        setBtnLoading(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        // form.resetFields();
        // setVisible(false);
        setBtnLoading(false);
      });
  };
  return (
    <>
      {/* <RegionBreadCrumb bcrdata={data} /> */}
      <PageHeader title="School Visit for Digital Awareness" />
      <Row>
        <Col span={12}>
          <Card style={{ marginLeft: "20px" }}>
            <Form
              layout="vertical"
              onFinish={onSubmit}
              form={form}
              onValuesChange={onFormValueChange}
              autoComplete="off"
            >
              <Form.Item
                label="Choose School Name"
                name="school"
                rules={[
                  {
                    required: true,
                    message: "Please choose school name",
                  },
                ]}
              >
                <Select
                  placeholder="Select School"
                  style={{
                    width: 350,
                  }}
                >
                  {schoolData &&
                    schoolData?.map((school) => (
                      <Option key={school?.id} value={school?.id}>
                        {school?.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Have you visited all the classrooms?"
                name="visited_all_classroom"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Have you conducted awareness training in all classrooms?"
                name="conducted_awareness_training"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Are the students fully aware of the utilization of the TAB?"
                name="students_aware_of_utilization"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Are the teachers fully aware of the utilization of the TAB?"
                name="teachers_aware_of_utilization"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Have you noticed any technical issues of TABS?"
                name="noticed_technical_issues"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              {formValues?.noticed_technical_issues && (
                <Form.Item
                  label="Please enter the number of tabs with technical issues"
                  name="tabs_with_technical_issues"
                  rules={[
                    {
                      required: true,
                      message: "This field is required!",
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    min={1}
                    style={{
                      width: 300,
                    }}
                  />
                </Form.Item>
              )}
              <Button htmlType="submit" type="primary" loading={btnLoading}>
                Submit
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default TabsInspection;
