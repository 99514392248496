import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../helpers/utility";
let is404 = true;
const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  useEffect(() => {
    is404 = true;
  }, [location]);
  return (
    is404 && (
      <Route
        {...rest}
        render={(props) => {
          is404 = false;
          return isAuthenticated() ? <Component {...props} /> : <Redirect to="/" />;
        }}
      />
    )
  );
};

export default PrivateRoute;
