import React from "react";
import { Table, Row, Col } from "antd";
import { damagedTabsList } from "../../../constants/damagedTabs";
const DamagedTabs = () => {
  const columns = [
    { title: "District", key: "name", dataIndex: "name" },
    {
      title: "Count",
      name: "count",
      dataIndex: "count",
      sorter: (a, b) => a?.count - b?.count,
    },
  ];

  return (
    <Row>
      <Col span={22} offset={1}>
        <Table
          className="table-header"
          columns={columns}
          dataSource={damagedTabsList}
          pagination={false}
          scroll={{ y: 600 }}
          summary={(pageData) => {
            let count = 0;

            pageData.forEach((item) => {
              count += item?.count ?? 0;
            });
            return (
              <Table.Summary fixed="top">
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell>{count}</Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
};
export default DamagedTabs;
