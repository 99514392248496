import React, { Fragment, useEffect, useState } from "react";
import { Modal, Button, Form, message, Select,Input} from "antd";
import { resolveInventoryRequest } from "../../../../services/serviceRequests.services";

const { Option } = Select;
const { TextArea } = Input;

const ActionModal = ({ editList, setVisible, refresh,status }) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);
  useEffect(() => {
    form.setFieldsValue({
      resolution_status: editList?.resolution_status,
    });
    setFormValues({
      ...formValues,
      resolution_status: editList?.resolution_status,
    });
  }, [editList]);

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    resolveInventoryRequest(editList.id, {
      resolution_status: values.resolution_status,
      remarks: values.remarks,
    })
      .then((response) => {
        message.success("Submitted");
        setVisible(false);
        refresh();
      })
      .catch((error) => console.log(error));
  };

  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };
  return (
    <Fragment>
      <Modal
        title="Change Status"
        visible={true}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="basic"
          onFinish={onFinish}
          layout="vertical"
          onValuesChange={onFormValuesChange}
          form={form}
        >
          <Form.Item
            name="resolution_status"
            rules={[
              {
                required: true,
                message: "Please choose resolution status",
              },
            ]}
          >
            <Select
              placeholder="Resolution Status"
              size="large">
              {status.map((item) => (
                <Option value={item.value} key={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {(formValues?.resolution_status === "Pending" ||
            formValues?.resolution_status === "Rejected") && (
                <Form.Item
                name="remarks">
                <TextArea placeholder="Remarks (Optional)" />
              </Form.Item>
            )}
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default ActionModal;
