import { useEffect, useState } from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { convertToMapData } from "../../../helpers/convertor";
import { getStateLevelIssuesStats } from "../../../services/inventory.services";
import { Spin } from "antd";
import SomethingWentWrong from "../../ErrorPage/SomethingWentWrong";
import "./Dashboard.css";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">
          {" "}
          {payload[0].payload.district} : {`${payload[0].payload.count}`}
        </p>
      </div>
    );
  }

  return null;
};
const AndhraMap = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getStateLevelIssuesStats()
      .then((response) => {
        setData(convertToMapData(response?.data?.district_level_issues_count));
        setLoading(false);
      })
      .catch((err) => {
        setError(err.data);
        setLoading(false);
      });
  }, []);

  return (
    <div className="andhramap-container">
      {isLoading ? (
        <Spin size="large" spinning={isLoading} />
      ) : error ? (
        <SomethingWentWrong />
      ) : (
        <div style={{ height: "375px" }}>
          <ResponsiveContainer height="100%" width="100%">
            <ScatterChart style={{ margin: "-10px -10px -10px -10px " }}>
              <XAxis
                type="number"
                dataKey="x"
                tick={false}
                tickLine={false}
                axisLine={false}
                domain={[0, 400]}
              />
              <YAxis
                type="number"
                dataKey="y"
                tick={false}
                tickLine={false}
                axisLine={false}
                domain={[0, 380]}
              />
              <ZAxis type="number" dataKey="count" range={[64, 1600]} />
              <Tooltip content={<CustomTooltip />} cursor={false} />
              <Scatter data={data} fill="rgb(233, 60, 81, .4)" />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default AndhraMap;
