import React from "react";
import { Result, Row, Col, Button } from "antd";
import { useHistory } from "react-router-dom";

function Page500() {
  const history = useHistory();

  function goToHome() {
    history.push("/");
  }
  return (
    <Row>
      <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "90vh",
        }}
      >
        <Result
          status={"500"}
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Button type="primary" onClick={goToHome}>
              Back Home
            </Button>
          }
        />
      </Col>
    </Row>
  );
}

export default Page500;
