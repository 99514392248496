import React, { Fragment, useEffect, useState } from "react";
import { Modal, Button, Form, message, Select, Input } from "antd";
import { approveKMRequest } from "../../../../services/inventory.services";

const { Option } = Select;
const { TextArea } = Input;

const defaultIssueStatus = [
  {
    text: "Open",
    value: "Open",
  },
  {
    text: "In Progress",
    value: "InProgress",
  },
  {
    text: "Waiting On User",
    value: "waiting-on-user",
  },
  {
    text: "Closed",
    value: "Closed",
  },
  {
    text: "Forward",
    value: "Forwarded",
  },
];

const ActionModal = ({ editList, setVisible, refresh }) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    form.setFieldsValue({
      resolution_status: editList?.resolution_status,
    });
    setFormValues({
      ...formValues,
      resolution_status: editList?.resolution_status,
    });
  }, [editList]);

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    approveKMRequest(editList.id, {
      resolution_status: values.resolution_status,
      remarks: values.remarks,
      email: values.email,
    })
      .then((response) => {
        message.success(response.data.message);
        setVisible(false);
        refresh(true);
      })
      .catch((error) => console.log(error));
  };

  const onFormValuesChange = (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  return (
    <Fragment>
      <Modal
        title="Change Status"
        visible={true}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="basic"
          onFinish={onFinish}
          layout="vertical"
          onValuesChange={onFormValuesChange}
          form={form}
        >
          <Form.Item
            name="resolution_status"
            rules={[
              {
                required: true,
                message: "Please choose resolution status",
              },
            ]}
          >
            <Select placeholder="Resolution Status" size="large">
              {defaultIssueStatus.map((item) => (
                <Option value={item.value} key={item.value}>
                  {item.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {!(formValues?.resolution_status === "Open") && (
            <Form.Item
              name="remarks"
              rules={[
                {
                  required: true,
                  message: "Please input remarks",
                },
              ]}
            >
              <TextArea placeholder="Remarks" />
            </Form.Item>
          )}

          {formValues?.resolution_status === "Forwarded" && (
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Email"
                style={{ minWidth: "250px" }}
              />
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit" size="large">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default ActionModal;
