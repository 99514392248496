import { useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  Select,
  message,
  Checkbox,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { uploadFile } from "../../../services/downloadFile.services";
import {
  convertToFormData,
  handleMultipleFileExt,
  replaceDotsExceptLast,
} from "../../../helpers/utility";
import axios from "axios";
import { addVolunteerInventoryAcknowledgement } from "../../../services/inventory.services";

const { Option } = Select;

const roles = ["MRO", "MPDO", "RDO", "Others"];

export default function VolunteerInventoryAcknowledgementForm({
  open,
  onClose,
  userDetails,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [uploadedFileName, setuploadedFileName] = useState(null);

  const onFinish = async (values) => {
    values = {
      ...values,
      document: uploadedFileName,
    };
    delete values.remember;
    setLoading(true);
    try {
      const response = await addVolunteerInventoryAcknowledgement(values);
      message.success(response?.data?.message);
      onClose();
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const onFormValuesChange = async (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const uploadProps = {
    beforeUpload: (file) => {
      if (handleMultipleFileExt(file)) return Upload.LIST_IGNORE;

      const fileType = file.type === "application/pdf";
      if (!fileType) {
        message.error(`${file.name} is not a valid file`);
      }

      // 1mb restriction
      if (file.size > 1000000) {
        message.error("File size should be less than 1mb");
        return Upload.LIST_IGNORE;
      }

      return fileType ? true : Upload.LIST_IGNORE;
    },
    async onChange(info) {
      if (info.file.status === "done") {
        try {
          const res = await uploadFile({
            folder: "vsws-resources",
            filename: replaceDotsExceptLast(info.file.name),
            uploading_to_vsws: true,
            is_private_file: true,
          });
          const formData = convertToFormData(res, info);
          const newRes = await axios({
            method: "POST",
            url: res?.data?.url?.url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          setuploadedFileName(res?.data?.url?.fields?.key);
          message.success(`${info.file.name} file uploaded successfully`);
        } catch (err) {
          console.log(err);
          message.error(err?.response?.data?.message);
        }
      }
    },
  };

  return (
    <Drawer
      title="Inventory Acknowledgment"
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Form
        name="Volunteer Inventory Collection"
        onValuesChange={onFormValuesChange}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Form.Item
          name="inventory_authority"
          label="Inventory Submitted To"
          rules={[
            {
              required: true,
              message: "Please select inventory submitted to!",
            },
          ]}
        >
          <Select>
            {roles?.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {formValues?.inventory_authority === "Others" && (
          <Form.Item
            name="inventory_authority_designation"
            label="Enter Authorized Person Designation"
            rules={[
              {
                required: true,
                message: "Please enter authorized person designation!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}

        <Form.Item
          label="Upload Acknowledgement"
          name="document"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "Please upload acknowledgement document",
            },
          ]}
          help=" If you have  multiple Acknowledgments , please combine them into one single pdf and upload it"
        >
          <Upload {...uploadProps} customRequest={dummyRequest} maxCount={1}>
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          rules={[{ required: true, message: "Please confirm!" }]}
        >
          <Checkbox style={{ marginTop: "20px" }}>
            I confirm that I have collected all the inventory from Volunteers of
            all clusters in my Secretariat and submitted to above mentioned
            Authority
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
