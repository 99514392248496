import React, { useState, useEffect } from "react";
import { Table, Button, Input, Space, Select, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  tableFilterDistricts,
  tableFilterDevices,
  akTableFilterDevices,
} from "../../../constants/defaultKeys";
import {
  getIndentRequestForVendor,
  updateIndentRequestForVendor,
} from "../../../services/serviceRequests.services";
import moment from "moment";

const { Option } = Select;

const defaultIssueStatus = [
  {
    text: "Open",
    value: "Open",
  },
  {
    text: "Pending",
    value: "Pending",
  },
  {
    text: "Completed",
    value: "Completed",
  },
];

const VendorInventoryRequests = () => {
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  useEffect(() => {
    getIndentRequestForVendor()
      .then((response) => setData(response.data))
      .catch((error) => console.log(error));
  }, [refresh]);

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleChange = (id, selectedValue) => {
    updateIndentRequestForVendor(id, {
      resolution_status_by_vendor: selectedValue,
    })
      .then((response) => {
        message.success(response.data.message);
        refreshPage();
      })
      .catch((error) => console.log(error));
  };

  const columns = [
    {
      title: "Purchase Order Id",
      dataIndex: "purchase_order",
      key: "purchase_order",
      width: 100,
      fixed: true,
      ...getColumnSearchProps("purchase_order"),
      render: (name) => <span>{name}</span>,
    },
    {
      title: "Device Type",
      dataIndex: "inventory_type",
      key: "inventory_type",
      width: 100,
      filters: [...tableFilterDevices, ...akTableFilterDevices],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.inventory_type.includes(value),
    },
    {
      title: "Requested Count",
      dataIndex: "allocated_count",
      key: "allocated_count",
      width: 100,
      sorter: (a, b) => a.allocated_count - b.allocated_count,
      render: (data, record) => (
        <span>{record.allocated_count ? data : "-"}</span>
      ),
    },
    {
      title: "District",
      dataIndex: "district_name",
      key: "district_name",
      width: 120,
      filters: [...tableFilterDistricts],
      onFilter: (value, record) => record.district_name.includes(value),
    },
    {
      title: "Issue Raised",
      dataIndex: "created_at",
      key: "created_at",
      width: 100,
      render: (date) => moment(date).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
    {
      title: "Issue Closed",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 100,
      render: (date, record) => (
        <span>
          {record.resolution_status_by_vendor === "Completed"
            ? moment(date).format("YYYY-MM-DD")
            : "-"}
        </span>
      ),
      sorter: (a, b) =>
        moment(a.updated_at).unix() - moment(b.updated_at).unix(),
    },
    {
      title: "Signed document",
      dataIndex: "request_form",
      key: "request_form",
      width: 150,
      render: (request_form) => (
        <a
          onClick={() =>
            window.open(
              `http://docs.google.com/gview?url=${request_form}`,
              "_blank"
            )
          }
        >
          View file
        </a>
      ),
    },
    {
      title: "Action",
      width: 150,
      dataIndex: "resolution_status_by_vendor",
      key: "resolution_status_by_vendor",
      fixed: "right",
      filters: [...defaultIssueStatus],
      onFilter: (value, record) => {
        let shouldReturn = value === record.resolution_status_by_vendor;
        return shouldReturn;
      },
      render: (_, record) => {
        return (
          <>
            {!(record?.resolution_status_by_vendor === "Completed") ? (
              <Select
                placeholder="Provide your action"
                value={record.resolution_status_by_vendor}
                style={{ width: 120 }}
                onChange={(value) => handleChange(record?.id, value)}
              >
                {defaultIssueStatus.map((item) => (
                  <Option value={item.value}>{item.text}</Option>
                ))}
              </Select>
            ) : (
              "Completed"
            )}
          </>
        );
      },
    },
  ];

  return <Table dataSource={data} columns={columns} pagination={false} />;
};

export default VendorInventoryRequests;
