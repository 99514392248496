import axiosInstance from "../config/axios/axios.config";

export const getIssue = (id) => {
  return axiosInstance.get(`/get-issue/${id}/`);
};

export const raiseIssue = (id, payload) => {
  return axiosInstance.post(`/raise-issue/${id}/`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteIssue = (id) => {
  return axiosInstance.delete(`/delete-issue/${id}/`);
};

export const updateIssue = (id, payload) => {
  return axiosInstance.put(`/update-issue/${id}/`, payload);
};

export const getInventoryIssues = (id) => {
  return axiosInstance.get(`/get-inventory-issues/${id}/`);
};

export const getAllInventoryIssues = (payload) => {
  return axiosInstance.get("/inventory-issues/", {
    params: {
      ...payload,
    },
  });
};

export const getVendorIssueStatistics = () => {
  return axiosInstance.get(`/vendor-issues-statistics/`);
};

export const forwardTicketDetails = (id, payload) => {
  return axiosInstance.post(`/forward-ticket-details/${id}/`, payload);
};

export const getVendorKMIssues = () => {
  return axiosInstance.get(`/get-keyboard-and-mouse-requests-for-vendor/`);
};

export const IssueClosedByDAApi = (ticketId, payload) => {
  return axiosInstance.put(`/close-ticket-by-da/${ticketId}/`, payload);
};
