import { Fragment, useState,useEffect } from "react";
import { Table, Input, Button, Space, Spin } from "antd";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import {
  nonAkDeviceColumns,
  akDeviceColumns,
} from "../../../constants/defaultKeys";
const DistrictTable = ({ data, loading ,aadharState}) => {

  useEffect(() => {
    aadharState
      ? setTabelData(akDeviceColumns)
      : setTabelData(nonAkDeviceColumns);
  }, [aadharState]);
  
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [tableData, setTabelData] = useState(nonAkDeviceColumns);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Mandal Name",
      dataIndex: "mandal_name",
      fixed: true,
      width: 200,
      ...getColumnSearchProps("mandal_name"),
      render: (regionName, row) => (
        <Link to={`/mandal/${regionName}/${row.mandal_id}/`}>{regionName}</Link>
      ),
    },
    ...tableData,
  ];
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  return (
    <Fragment>
      <Table
        loading={
          loading && {
            indicator: (
              <Spin
                style={{
                  fontSize: "14px",
                  width: "350px",
                  marginLeft: "-180px",
                }}
                tip="Fetching results.. please wait for a min or two..."
              />
            ),
          }
        }
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={isMobileOnly ? { x: 500, y: 500 } : { x: 1300, y: "80vh" }}
      />
    </Fragment>
  );
};

export default DistrictTable;
