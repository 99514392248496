import Typography from "antd/lib/typography/Typography";

export const invColumns = [
  {
    title: "Total Secretariats",
    dataIndex: "total_secretariats",
    key: "total_secretariats",
  },
  {
    title: "Total Clusters",
    dataIndex: "total_clusters",
    key: "total_clusters",
  },
  {
    title: "Clusters Submitted Inventory",
    dataIndex: "clusters_submitted",
    key: "clusters_submitted",
  },
  {
    title: "Clusters Pending to submit Inventory",
    dataIndex: "clusters_pending",
    key: "clusters_pending",
  },
];

export const invClusterColumns = [
  {
    title: "Cluster Code",
    dataIndex: "cluster_code",
    key: "cluster_code",
  },
  {
    title: "Submitted Inventory",
    dataIndex: "submitted",
    key: "submitted",
    render: (value) =>
      value ? (
        <Typography style={{ color: "green" }}>Yes</Typography>
      ) : (
        <Typography style={{ color: "red" }}>NO</Typography>
      ),
  },
];

// District Name, Total Secretariats, Total Clusters, Clusters Submitted Inventory, Clusters Pending to submit Inventory
// Mandal Name, Total Secretariats, Total Clusters, Clusters Submitted Inventory, Clusters Pending to submit Inventory
// Secretariat Name, Total Clusters, Clusters Submitted Inventory, Clusters Pending to submit Inventory
// Cluster Code, Submitted Inventory (yes/No)
