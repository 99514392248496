import React from "react";
import { Row, Col, Alert, Typography } from "antd";
const { Title, Paragraph } = Typography;

const Overview = () => {
  return (
    <Row type="flex">
      <Col span={20} offset={1}>
        <Alert
          message="About VSWS.co.in"
          description="VSWS.co.in is the Hardware Maintenance Service portal (HMS) developed by the Govt of AP to track 
         hardware available at each Village/Ward Secretariat, and to enable the secretariats to raise service issues"
          type="info"
          showIcon
        />
        <br />
        <Title level={3}>Background</Title>
        <Paragraph>
          Government of Andhrapradesh has established 15004 village/ward
          secretariats and positioned 1.34 lakh functionaries and 2.66 lakh
          volunteers to deliver services and welfare programmes at the
          doorsteps. VSWS dept supplied sufficient IT- Hardware to the
          secretariats and volunteers.
        </Paragraph>
        <Paragraph>
          Government supplied @ 2 Desktops, 1 UPS, 1 Multifunctional Printer, 1
          Laminating Machine, 1 Smart Mobile with 4G Sim, 1 Iris scanner and 1
          finger print scanner to each of the secretariats. Government also
          supplied 1 Smart mobile, 1 fingerprint scanner and a 4G Sim to each
          Volunteer and 1 Smart Phone and a 4G Sim to each Mahila Police.{" "}
        </Paragraph>
        <Paragraph>
          The functioning of this IT-Hardware plays an important role in
          delivery of services and welfare programmes at the doorsteps of
          citizen by the secretariats. In view of the importance of these
          equipment, VSWS.co.in serves as the single point of portal for
          department to track and ensure hardware issues are being resolved
          proactively.
        </Paragraph>
      </Col>
    </Row>
  );
};

export default Overview;
