import React, { useState, useEffect } from "react";
import { Col, Row, Radio } from "antd";
import { getStateLevelIssuesStats } from "../../services/inventory.services";
import { useParams, useHistory } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import Inventory from "./Inventory/Inventory";
import Tickets from "./Tickets/Tickets";
import WednesdayMeeting from "./WednesdayMeeting/WednesdayMeeting";
import "./Admin.css";
import "../custom.css";
import SimStats from "./SimStatistics/SimStats";
import TabsData from "./TabsData/TabsData";
import { USER } from "../../constants/defaultKeys";
import { getItem } from "../../helpers/localStorage";
import { isUserFromNTRorASR } from "../../helpers/utility";
import VolunteerInventoryTracking from "../VS/VolunteerInventoryTracking/VolunteerInventoryTracking";
import CollectionTabs from "./InventoryCollection/CollectionTabs";
import useViewAccess from "../../hooks/useViewAccess";
const Admin = () => {
  const history = useHistory();
  const query = useParams();
  const userDetails = JSON.parse(getItem(USER));
  const [loading, setLoading] = useState(true);
  const [stateLevelIssues, setStateLevelIssues] = useState(null);
  const [currentTab, setCurrentTab] = useState("dashboard");
  const allowAMCAccess = isUserFromNTRorASR();
  useEffect(() => {
    setCurrentTab(query?.tab);
  }, [query]);

  useEffect(() => {
    getStateLevelIssuesStats()
      .then((response) => {
        setStateLevelIssues(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  function changeTab(e) {
    setCurrentTab(e.target.value);
    history.push(`/state/${e.target.value}`);
  }

  return (
    <Row gutter={[16, 16]}>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 22, offset: 1 }}
        lg={{ span: 22, offset: 1 }}
        style={{ textAlign: "right" }}
      >
        {!(
          userDetails?.is_aek_hod ||
          userDetails?.is_aek_hod_dept ||
          userDetails?.is_aek_dc
        ) && (
          <Radio.Group
            value={currentTab}
            onChange={changeTab}
            buttonStyle="solid"
          >
            <Radio.Button value="dashboard">Dashboard</Radio.Button>
            <Radio.Button value="inventory">Inventory</Radio.Button>
            <Radio.Button value="tickets">Tickets</Radio.Button>
            <Radio.Button value="wednesday-meeting">
              Wednesday Meetings
            </Radio.Button>
            <Radio.Button value="sim-info-stats">Sim Stats</Radio.Button>
            {(userDetails?.is_admin ||
              userDetails?.is_state_executive_authority) && (
              <Radio.Button value="inventory-collection">
                Inventory Collection
              </Radio.Button>
            )}

            {/* {userDetails?.is_service_center_representative && allowAMCAccess ? (
              <Radio.Button value={"service-center-amc-tickets"}>
                Mobile services
              </Radio.Button>
            ) : userDetails?.is_amc_district_incharge && allowAMCAccess ? (
              <Radio.Button value={"district-amc-tickets"}>
                Mobile services
              </Radio.Button>
            ) : (
              <></>
            )} */}
          </Radio.Group>
        )}
      </Col>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 22, offset: 1 }}
        lg={{ span: 22, offset: 1 }}
      >
        {query.tab === "dashboard" ? (
          <Dashboard loading={loading} stateLevelIssues={stateLevelIssues} />
        ) : query.tab === "inventory" ? (
          <Inventory userDetails={userDetails} />
        ) : query.tab === "wednesday-meeting" ? (
          <WednesdayMeeting />
        ) : query.tab === "tickets" ? (
          <Tickets stateLevelIssues={stateLevelIssues} />
        ) : query?.tab === "inventory-collection" ? (
          <CollectionTabs />
        ) : (
          query.tab === "sim-info-stats" && <SimStats />
        )}
      </Col>
    </Row>
  );
};

export default Admin;
