import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Segmented } from "antd";
import AggregateReport from "./AggregateReport";
import DeviceWiseReport from "./DeviceWiseReport";
import useViewAccess from "../../../hooks/useViewAccess";

const CollectionTabs = () => {
  const history = useHistory();
  const [selectedVal, setSelectedVal] = useState("aggregrate");
  useViewAccess("is_indent_authority", "is_district_authority");
  return (
    <Row gutter={[24, 24]}>
      <Col span={24} style={{ marginTop: "20px" }}>
        <Segmented
          defaultValue={selectedVal}
          onChange={(val) => {
            setSelectedVal(val);
            // history.push(`/state/inventory-collection/${val}`);
          }}
          options={[
            {
              label: `Regionwise Aggregate Report`,
              value: "aggregrate",
            },
            {
              label: "Inventory Master Report",
              value: "device-wise",
            },
          ]}
        />
      </Col>
      <Col span={24}>
        {selectedVal === "aggregrate" ? (
          <AggregateReport />
        ) : (
          <DeviceWiseReport />
        )}
      </Col>
    </Row>
  );
};
export default CollectionTabs;
