import React, { useEffect, useState } from "react";
import { Descriptions, Row, Col } from "antd";

import Card from "../../../../blocks/Card/Card";
import useViewAccess from "../../../../hooks/useViewAccess";
const VendorServiceContacts = () => {
  useViewAccess("is_indent_authority");

  return (
    <>
      <Row gutter={[1, 32]}>
        <Col span={18} offset={2}>
          <Card>
            <Descriptions title="Mobile " bordered>
              <Descriptions.Item>Service for MI</Descriptions.Item>
              <Descriptions.Item>Prasad</Descriptions.Item>
              <Descriptions.Item>8019160606</Descriptions.Item>
              <Descriptions.Item>Service for LG</Descriptions.Item>
              <Descriptions.Item>Kiran</Descriptions.Item>
              <Descriptions.Item>9985067827</Descriptions.Item>
              <Descriptions.Item>Procurement</Descriptions.Item>
              <Descriptions.Item>Suresh</Descriptions.Item>
              <Descriptions.Item>9704084008</Descriptions.Item>
              <Descriptions.Item>
                *Mobile service related issues are solved through phone calls
                (if minor) and through service camps at Divisional level (if
                major)
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={18} offset={2}>
          <Card>
            <Descriptions title="Monitor&CPU" bordered>
              <Descriptions.Item>Lenovo Service</Descriptions.Item>
              <Descriptions.Item>Raviteja</Descriptions.Item>
              <Descriptions.Item>9848665954</Descriptions.Item>
              <Descriptions.Item>Lenovo Service</Descriptions.Item>
              <Descriptions.Item>Tejaswini</Descriptions.Item>
              <Descriptions.Item>9866759760</Descriptions.Item>
              <Descriptions.Item
                style={{ textAlign: "left", paddingTop: "10px" }}
              >
                *All minor service issues are resolved through phone calls, and
                major issues are resolved through site visit by technician
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={18} offset={2}>
          <Card>
            <Descriptions title="UPS (BPE UPS)" bordered>
              <Descriptions.Item>Ananthapur</Descriptions.Item>
              <Descriptions.Item>Sekhar</Descriptions.Item>
              <Descriptions.Item>9182778109</Descriptions.Item>
              <Descriptions.Item>Chittoor</Descriptions.Item>
              <Descriptions.Item>Ramesh</Descriptions.Item>
              <Descriptions.Item>9010818038</Descriptions.Item>
              <Descriptions.Item>East Godavari</Descriptions.Item>
              <Descriptions.Item>Suresh</Descriptions.Item>
              <Descriptions.Item>9666087635</Descriptions.Item>
              <Descriptions.Item>Guntur</Descriptions.Item>
              <Descriptions.Item>Baburao</Descriptions.Item>
              <Descriptions.Item>9177007475</Descriptions.Item>
              <Descriptions.Item>Krishna</Descriptions.Item>
              <Descriptions.Item>Baburao</Descriptions.Item>
              <Descriptions.Item>9177007475</Descriptions.Item>
              <Descriptions.Item>Kurnool</Descriptions.Item>
              <Descriptions.Item>Sekhar</Descriptions.Item>
              <Descriptions.Item>9182778109</Descriptions.Item>
              <Descriptions.Item>Prakasam</Descriptions.Item>
              <Descriptions.Item>Mahesh</Descriptions.Item>
              <Descriptions.Item>8688071153</Descriptions.Item>
              <Descriptions.Item>SPSR Nellore</Descriptions.Item>
              <Descriptions.Item>Mahesh</Descriptions.Item>
              <Descriptions.Item>8688071153</Descriptions.Item>
              <Descriptions.Item>Srikakulam</Descriptions.Item>
              <Descriptions.Item>Raju</Descriptions.Item>
              <Descriptions.Item>9966620201</Descriptions.Item>
              <Descriptions.Item>Visakhapatnam</Descriptions.Item>
              <Descriptions.Item>Sekhar</Descriptions.Item>
              <Descriptions.Item>9885739504</Descriptions.Item>
              <Descriptions.Item>Vizianagaram</Descriptions.Item>
              <Descriptions.Item>Raju</Descriptions.Item>
              <Descriptions.Item>9966620201</Descriptions.Item>
              <Descriptions.Item>West Godavari</Descriptions.Item>
              <Descriptions.Item>Suresh</Descriptions.Item>
              <Descriptions.Item>9666087635</Descriptions.Item>
              <Descriptions.Item>YSR Kadapa</Descriptions.Item>
              <Descriptions.Item>Ramesh</Descriptions.Item>
              <Descriptions.Item>9010818038</Descriptions.Item>
              <Descriptions.Item
                style={{ textAlign: "left", paddingTop: "10px" }}
              >
                *UPS Support Coordinator: P. Raviteja - 9848665954
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={18} offset={2}>
          <Card>
            <Descriptions title="FPS, Printers & Laminating Machine" bordered>
              <Descriptions.Item>Ananthapur</Descriptions.Item>
              <Descriptions.Item>Mahender</Descriptions.Item>
              <Descriptions.Item>9000071287</Descriptions.Item>
              <Descriptions.Item>Chittoor</Descriptions.Item>
              <Descriptions.Item>Venkatesh</Descriptions.Item>
              <Descriptions.Item>8074950133</Descriptions.Item>
              <Descriptions.Item>East Godavari</Descriptions.Item>
              <Descriptions.Item>Raju</Descriptions.Item>
              <Descriptions.Item>9492211084</Descriptions.Item>
              <Descriptions.Item>Guntur</Descriptions.Item>
              <Descriptions.Item>Chaitanya</Descriptions.Item>
              <Descriptions.Item>6305630701</Descriptions.Item>
              <Descriptions.Item>Krishna</Descriptions.Item>
              <Descriptions.Item>Bharath</Descriptions.Item>
              <Descriptions.Item>9704494488</Descriptions.Item>
              <Descriptions.Item>Kurnool</Descriptions.Item>
              <Descriptions.Item>Naveen</Descriptions.Item>
              <Descriptions.Item>9618524570</Descriptions.Item>
              <Descriptions.Item>Prakasam</Descriptions.Item>
              <Descriptions.Item>Laxman</Descriptions.Item>
              <Descriptions.Item>7729063285</Descriptions.Item>
              <Descriptions.Item>SPSR Nellore</Descriptions.Item>
              <Descriptions.Item>Sreekar</Descriptions.Item>
              <Descriptions.Item>8919237681</Descriptions.Item>
              <Descriptions.Item>Srikakulam</Descriptions.Item>
              <Descriptions.Item>Santosh</Descriptions.Item>
              <Descriptions.Item>9441756688</Descriptions.Item>
              <Descriptions.Item>Visakhapatnam</Descriptions.Item>
              <Descriptions.Item>Uday Kiran</Descriptions.Item>
              <Descriptions.Item>9398682103</Descriptions.Item>
              <Descriptions.Item>Vizianagaram</Descriptions.Item>
              <Descriptions.Item>Ravi Prasad</Descriptions.Item>
              <Descriptions.Item>9652401523</Descriptions.Item>
              <Descriptions.Item>West Godavari</Descriptions.Item>
              <Descriptions.Item>Manikanta</Descriptions.Item>
              <Descriptions.Item>7032450231</Descriptions.Item>
              <Descriptions.Item>YSR Kadapa</Descriptions.Item>
              <Descriptions.Item>Praveen</Descriptions.Item>
              <Descriptions.Item>9014483058</Descriptions.Item>
              <Descriptions.Item>
                Procurement for FPS&Printers
              </Descriptions.Item>
              <Descriptions.Item>Srikanth</Descriptions.Item>
              <Descriptions.Item>7013344601</Descriptions.Item>
              <Descriptions.Item>
                Procurement for Laminating Machines
              </Descriptions.Item>
              <Descriptions.Item>Harsha</Descriptions.Item>
              <Descriptions.Item>8886636659</Descriptions.Item>
              <Descriptions.Item
                style={{ textAlign: "left", paddingTop: "10px" }}
              >
                * All the service technicians are stationed at Joint Collectors'
                Office in all Districts
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={18} offset={2}>
          <Card>
            <Descriptions title="Iris Scanners(Irishield)" bordered>
              <Descriptions.Item>Ananthapur</Descriptions.Item>
              <Descriptions.Item>Albakash</Descriptions.Item>
              <Descriptions.Item>9133302241</Descriptions.Item>
              <Descriptions.Item>Chittoor</Descriptions.Item>
              <Descriptions.Item>Veerendra</Descriptions.Item>
              <Descriptions.Item>9701601318</Descriptions.Item>
              <Descriptions.Item>East Godavari</Descriptions.Item>
              <Descriptions.Item>Shiva</Descriptions.Item>
              <Descriptions.Item>9133302247</Descriptions.Item>
              <Descriptions.Item>Guntur</Descriptions.Item>
              <Descriptions.Item>Nagoor Basha</Descriptions.Item>
              <Descriptions.Item>9133302248</Descriptions.Item>
              <Descriptions.Item>Krishna</Descriptions.Item>
              <Descriptions.Item>Hemanth</Descriptions.Item>
              <Descriptions.Item>9701601320</Descriptions.Item>
              <Descriptions.Item>Kurnool</Descriptions.Item>
              <Descriptions.Item>Nagaraju</Descriptions.Item>
              <Descriptions.Item>9133302243</Descriptions.Item>
              <Descriptions.Item>Prakasam</Descriptions.Item>
              <Descriptions.Item>Danaiah</Descriptions.Item>
              <Descriptions.Item>9133302246</Descriptions.Item>
              <Descriptions.Item>SPSR Nellore</Descriptions.Item>
              <Descriptions.Item>Venkatesh</Descriptions.Item>
              <Descriptions.Item>9701601414</Descriptions.Item>
              <Descriptions.Item>Srikakulam</Descriptions.Item>
              <Descriptions.Item>Srinivasa</Descriptions.Item>
              <Descriptions.Item>9701601416</Descriptions.Item>
              <Descriptions.Item>Visakhapatnam</Descriptions.Item>
              <Descriptions.Item>Somesh</Descriptions.Item>
              <Descriptions.Item>9701601428</Descriptions.Item>
              <Descriptions.Item>Vizianagaram</Descriptions.Item>
              <Descriptions.Item>Suresh</Descriptions.Item>
              <Descriptions.Item>9133302258</Descriptions.Item>
              <Descriptions.Item>West Godavari</Descriptions.Item>
              <Descriptions.Item>Durga Prasad</Descriptions.Item>
              <Descriptions.Item>9133302259</Descriptions.Item>
              <Descriptions.Item>YSR Kadapa</Descriptions.Item>
              <Descriptions.Item>Sudheer</Descriptions.Item>
              <Descriptions.Item>9133302242</Descriptions.Item>
              <Descriptions.Item
                style={{ textAlign: "left", paddingTop: "10px" }}
              >
                * All the service technicians are stationed at Joint Collectors'
                Office in all Districts
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={18} offset={2}>
          <Card>
            <Descriptions title="Sim Cards" bordered>
              <Descriptions.Item>Airtel</Descriptions.Item>
              <Descriptions.Item>Sai</Descriptions.Item>
              <Descriptions.Item>9550046666</Descriptions.Item>
              <Descriptions.Item>BSNL</Descriptions.Item>
              <Descriptions.Item>Krishna Rao</Descriptions.Item>
              <Descriptions.Item>9490143855</Descriptions.Item>
              <Descriptions.Item>JIO(activation queries)</Descriptions.Item>
              <Descriptions.Item>Harish Varma</Descriptions.Item>
              <Descriptions.Item>7013670570</Descriptions.Item>
              <Descriptions.Item>JIO(procurement queries)</Descriptions.Item>
              <Descriptions.Item>Samba Siva Rao</Descriptions.Item>
              <Descriptions.Item>7013104355</Descriptions.Item>
              <Descriptions.Item>Idea(activation queries)</Descriptions.Item>
              <Descriptions.Item>Vishnu Sekaran</Descriptions.Item>
              <Descriptions.Item>9848102032</Descriptions.Item>
              <Descriptions.Item>Idea(procurement queries)</Descriptions.Item>
              <Descriptions.Item>Giri Babu</Descriptions.Item>
              <Descriptions.Item>9885018030</Descriptions.Item>
              <Descriptions.Item
                style={{ textAlign: "left", paddingTop: "10px" }}
              >
                *All the issues related to SIM Card lost and re-activation are
                raised by the Digital Assistants through vsws.co.in portal,
                which are tracked and solved by the vendors through the portal
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={18} offset={2}>
          <Card>
            <Descriptions title="Internet Connectivity" bordered>
              <Descriptions.Item>BBNL</Descriptions.Item>
              <Descriptions.Item>Prashanth</Descriptions.Item>
              <Descriptions.Item>9700436548</Descriptions.Item>
              <Descriptions.Item>BSNL</Descriptions.Item>
              <Descriptions.Item>BSNL</Descriptions.Item>
              <Descriptions.Item>9490188010</Descriptions.Item>
              <Descriptions.Item>Excell Broadband</Descriptions.Item>
              <Descriptions.Item>Excell Broadband</Descriptions.Item>
              <Descriptions.Item>9676048888</Descriptions.Item>
              <Descriptions.Item>APSFL</Descriptions.Item>
              <Descriptions.Item>Naga Babu</Descriptions.Item>
              <Descriptions.Item>8977945108</Descriptions.Item>
              <Descriptions.Item>APSFL</Descriptions.Item>
              <Descriptions.Item>Naga Lakshmi</Descriptions.Item>
              <Descriptions.Item>9154143250</Descriptions.Item>
              <Descriptions.Item
                style={{ textAlign: "left", paddingTop: "10px" }}
              >
                *For other than the above mentioned Internet Service Providers,
                please contact your local service providers for any issues
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={18} offset={2}>
          <Card>
            <Descriptions title="Secured base certificates" bordered>
              <Descriptions.Item>Ananthapur</Descriptions.Item>
              <Descriptions.Item>Mahender</Descriptions.Item>
              <Descriptions.Item>9000071287</Descriptions.Item>
              <Descriptions.Item>Chittoor</Descriptions.Item>
              <Descriptions.Item>Venkatesh</Descriptions.Item>
              <Descriptions.Item>8074950133</Descriptions.Item>
              <Descriptions.Item>East Godavari</Descriptions.Item>
              <Descriptions.Item>Raju</Descriptions.Item>
              <Descriptions.Item>9492211084</Descriptions.Item>
              <Descriptions.Item>Guntur</Descriptions.Item>
              <Descriptions.Item>Sunil</Descriptions.Item>
              <Descriptions.Item>7337364692</Descriptions.Item>
              <Descriptions.Item>Krishna</Descriptions.Item>
              <Descriptions.Item>Bharath</Descriptions.Item>
              <Descriptions.Item>9704494488</Descriptions.Item>
              <Descriptions.Item>Kurnool</Descriptions.Item>
              <Descriptions.Item>Naveen</Descriptions.Item>
              <Descriptions.Item>9618524570</Descriptions.Item>
              <Descriptions.Item>Prakasam</Descriptions.Item>
              <Descriptions.Item>Laxman</Descriptions.Item>
              <Descriptions.Item>7729063285</Descriptions.Item>
              <Descriptions.Item>SPSR Nellore</Descriptions.Item>
              <Descriptions.Item>Sreekar</Descriptions.Item>
              <Descriptions.Item>8919237681</Descriptions.Item>
              <Descriptions.Item>Srikakulam</Descriptions.Item>
              <Descriptions.Item>Raju</Descriptions.Item>
              <Descriptions.Item>8886860833</Descriptions.Item>
              <Descriptions.Item>Visakhapatnam</Descriptions.Item>
              <Descriptions.Item>Uday Kiran</Descriptions.Item>
              <Descriptions.Item>9398682103</Descriptions.Item>
              <Descriptions.Item>Vizianagaram</Descriptions.Item>
              <Descriptions.Item>Ravi Prasad</Descriptions.Item>
              <Descriptions.Item>9652401523</Descriptions.Item>
              <Descriptions.Item>West Godavari</Descriptions.Item>
              <Descriptions.Item>Manikanta</Descriptions.Item>
              <Descriptions.Item>7032450231</Descriptions.Item>
              <Descriptions.Item>YSR Kadapa</Descriptions.Item>
              <Descriptions.Item>Praveen</Descriptions.Item>
              <Descriptions.Item>9014483058</Descriptions.Item>
              <Descriptions.Item>
                Procurement for FPS&Printers
              </Descriptions.Item>
              <Descriptions.Item>Srikanth</Descriptions.Item>
              <Descriptions.Item>7013344601</Descriptions.Item>
              <Descriptions.Item>
                Procurement for Laminating Machines
              </Descriptions.Item>
              <Descriptions.Item>Harsha</Descriptions.Item>
              <Descriptions.Item>8886636659</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={18} offset={2}>
          <Card>
            <Descriptions title="Laminating Pouches" bordered>
              <Descriptions.Item>Procurement</Descriptions.Item>
              <Descriptions.Item>Lakshmi Narayana</Descriptions.Item>
              <Descriptions.Item>9441346658</Descriptions.Item>
              <Descriptions.Item
                style={{ textAlign: "left", paddingTop: "10px" }}
              >
                *Laminating pouches used for Rice Cards, Pension Cards and
                Aadhar Cards
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={18} offset={2}>
          <Card>
            <Descriptions title="Printer Toner Ink" bordered>
              <Descriptions.Item>Procurement</Descriptions.Item>
              <Descriptions.Item>Srikanth</Descriptions.Item>
              <Descriptions.Item>7013344601</Descriptions.Item>
              <Descriptions.Item
                style={{ textAlign: "left", paddingTop: "10px" }}
              >
                *Tender is in process. Will update details once done
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={18} offset={2}>
          <Card>
            <Descriptions title="Uniforms" bordered>
              <Descriptions.Item>Ananthapur</Descriptions.Item>
              <Descriptions.Item>Fayaz</Descriptions.Item>
              <Descriptions.Item>9440717320</Descriptions.Item>
              <Descriptions.Item>Chittoor</Descriptions.Item>
              <Descriptions.Item>Fayaz</Descriptions.Item>
              <Descriptions.Item>9440717320</Descriptions.Item>
              <Descriptions.Item>East Godavari</Descriptions.Item>
              <Descriptions.Item>Fayaz</Descriptions.Item>
              <Descriptions.Item>9440717320</Descriptions.Item>
              <Descriptions.Item>Guntur</Descriptions.Item>
              <Descriptions.Item>Fayaz</Descriptions.Item>
              <Descriptions.Item>9440717320</Descriptions.Item>
              <Descriptions.Item>Krishna</Descriptions.Item>
              <Descriptions.Item>Mohan Rao</Descriptions.Item>
              <Descriptions.Item>8886636660</Descriptions.Item>
              <Descriptions.Item>Kurnool</Descriptions.Item>
              <Descriptions.Item>Fayaz</Descriptions.Item>
              <Descriptions.Item>9440717320</Descriptions.Item>
              <Descriptions.Item>Prakasam</Descriptions.Item>
              <Descriptions.Item>Mohan Rao</Descriptions.Item>
              <Descriptions.Item>8886636660</Descriptions.Item>
              <Descriptions.Item>SPSR Nellore</Descriptions.Item>
              <Descriptions.Item>Fayaz</Descriptions.Item>
              <Descriptions.Item>9440717320</Descriptions.Item>
              <Descriptions.Item>Srikakulam</Descriptions.Item>
              <Descriptions.Item>Mohan Rao</Descriptions.Item>
              <Descriptions.Item>8886636660</Descriptions.Item>
              <Descriptions.Item>Visakhapatnam</Descriptions.Item>
              <Descriptions.Item>Mohan Rao</Descriptions.Item>
              <Descriptions.Item>8886636660</Descriptions.Item>
              <Descriptions.Item>Vizianagaram</Descriptions.Item>
              <Descriptions.Item>Mohan Rao</Descriptions.Item>
              <Descriptions.Item>8886636660</Descriptions.Item>
              <Descriptions.Item>West Godavari</Descriptions.Item>
              <Descriptions.Item>Mohan Rao</Descriptions.Item>
              <Descriptions.Item>8886636660</Descriptions.Item>
              <Descriptions.Item>YSR Kadapa</Descriptions.Item>
              <Descriptions.Item>Mohan Rao</Descriptions.Item>
              <Descriptions.Item>8886636660</Descriptions.Item>
              <Descriptions.Item
                style={{ textAlign: "left", paddingTop: "10px" }}
              >
                *Uniforms are delivered at Constituency level and are
                distributed to Secretariats, the receipt of which can be
                acknowledged by the DA through vsws.co.in portal
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default VendorServiceContacts;
