import React, { Fragment } from "react";
import { Form, Input, Button, message } from "antd";
import { changePassword } from "../../services/auth.services";
import { LockOutlined } from "@ant-design/icons";
import { JSEncrypt } from "jsencrypt";
import { USER, publicKey } from "../../constants/defaultKeys";
import { getItem } from "../../helpers/localStorage";

const ChangePassword = () => {
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  const onFinish = (values) => {
    changePassword({
      ...values,
      old_password: encrypt.encrypt(values?.old_password),
      new_password: encrypt.encrypt(values?.new_password),
      confirm_password: encrypt.encrypt(values?.confirm_password),
      token: encrypt.encrypt(
        JSON.parse(getItem(USER))?.contact_number_unmasked
      ),
    })
      .then((response) => message.success(response.data.message))
      .catch((error) => {
        message.error(error?.response?.data?.message);
        console.log(error);
      });
  };
  return (
    <Fragment>
      <Form name="setpassword" onFinish={onFinish}>
        <Form.Item
          name="old_password"
          rules={[
            {
              required: true,
              message: "Please input your old password",
            },
          ]}
        >
          <Input.Password
            autoFocus={true}
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Old Password"
            size="large"
            type="password"
          />
        </Form.Item>
        <Form.Item
          name="new_password"
          rules={[
            {
              required: true,
              message:
                "Use 8 or more characters with a mix of uppercase and lowercase letters, numbers, and symbols.",
              pattern: new RegExp(
                "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
              ),
            },
          ]}
          // rules={[
          //   {
          //     required: true,
          //     message:
          //       "Use 8 or more characters with a mix of letters, numbers & symbols.",
          //     pattern: new RegExp(
          //       "^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
          //     ),
          //   },
          // ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="New Password"
            size="large"
            type="password"
          />
        </Form.Item>
        <Form.Item
          name="confirm_password"
          rules={[
            { required: true, message: "Please input your Password!" },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The new passwords that you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Confirm Password"
            size="large"
          />
        </Form.Item>

        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            block
            className="login-form-button"
          >
            <span>Submit</span>
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default ChangePassword;
