import { Result, Row, Col, Button } from "antd";
import { useHistory } from "react-router-dom";
import React from "react";
function Page404() {
  const history = useHistory();
  function goToHome() {
    history.push("/");
  }
  return (
    <Row>
      <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "70vh",
        }}
      >
        <Result
          status={"404"}
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button type="primary" onClick={goToHome}>
              Back Home
            </Button>
          }
        />
      </Col>
    </Row>
  );
}

export default Page404;
