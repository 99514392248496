import React, { Fragment, useState, useEffect } from "react";
import {
  Select,
  Form,
  message,
  Input,
  Radio,
  Button,
  Alert,
  Typography,
  Divider,
  Tooltip,
  Upload,
} from "antd";
import {
  ImageUploadProps,
  handleFileUploadError,
} from "../../../../helpers/utility";
import * as config from "../../../../config/validation.config";
import { USER } from "../../../../constants/defaultKeys";
import { getItem } from "../../../../helpers/localStorage";
import Apsfl from "../../../../assets/devices/Apsfl.png";

import {
  InfoCircleOutlined,
  UploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { raiseInternetServiceRequest } from "../../../../services/inventory.services";
const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;
const InternetIssuesForm = ({ id }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [formValues, setFormValues] = useState(null);

  const userDetails = JSON.parse(getItem(USER));
  const [fileUploadError, setFileUploadError] = useState();
  const [uploadedFileName, setuploadedFileName] = useState(null);
  const [uploadFileLoading, setUploadFileLoading] = useState(null);
  const uploadConfig = {
    folder: "issues-images",
    pvt: false,
  };

  useEffect(() => {
    form.setFieldsValue({
      primary_number: userDetails?.contact_number_unmasked,
      email: userDetails?.email,
      address: userDetails?.region?.region_address,
    });
  }, [id]);

  const onFinish = (values) => {
    const formData = new FormData();
    for (const [field, value] of Object.entries(values)) {
      if (field === "image_for_reference") {
        formData.append("image_for_reference", uploadedFileName?.btn1);
      } else {
        formData.append(field, value !== undefined ? value : "");
      }
    }
    raiseInternetServiceRequest(id, formData)
      .then((response) => {
        message.success(response.data.message);
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.response?.data?.message);
      });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onFormValueChange = async (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  return (
    <Fragment>
      <Alert
        message="To report an issue, first add your internet details in the portal and make sure it is AP-Fibernet and provided by GSWS Department."
        type="info"
        showIcon
      />
      <br />
      <Form
        name="Report Internet Issue"
        onFinish={onFinish}
        onValuesChange={onFormValueChange}
        wrapperCol={{
          span: 10,
        }}
        colon={false}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Issue Type"
          name="issue_type"
          rules={[
            {
              required: true,
              message: "This field is required!",
            },
          ]}
        >
          <Select size="large">
            <Option value="no_internet">No Internet</Option>
            <Option value="slow_internet">Slow Internet</Option>
            <Option value="location_changed">Location Changed</Option>
            <Option value="disconnected_due_to_bill">
              Disconnected Due To Non Payment Of Bill
            </Option>
            <Option value="outher">Other</Option>
          </Select>
        </Form.Item>
        {formValues?.issue_type === "outher" && (
          <Form.Item name="outher_issue_query">
            <TextArea placeholder="Additional Info (Optional)" />
          </Form.Item>
        )}
        <Form.Item
          label="Box Signal Status"
          name="box_signal_status"
          rules={[
            {
              required: true,
              message: "Please select box signal status",
            },
          ]}
        >
          <Select size="large">
            <Option value="red">Red</Option>
            <Option value="yellow_and_green">Yellow and Green</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="GPON S/N"
          name="gpon_serial_number"
          // extra={[
          //   <span>
          //     Please see below image for reference <br />
          //     <img src={Apsfl} height="200px" />,
          //   </span>,
          // ]}
          rules={[
            {
              required: true,
              message: "Please input mac GPON S/N",
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Image For Reference"
          name="image_for_reference"
          valuePropName="fileList"
          rules={[
            {
              required: true,
              message: "Please upload a image for reference",
            },
          ]}
          getValueFromEvent={normFile}
          extra={
            fileUploadError?.btn1 ? (
              handleFileUploadError("btn1", fileUploadError, setFileUploadError)
            ) : (
              <span>
                Please upload images with .png,.jpg or .jpeg format and file
                size should be less than 5MB
              </span>
            )
          }
        >
          <Upload
            {...ImageUploadProps(
              "btn1",
              fileUploadError,
              setFileUploadError,
              uploadConfig,
              uploadedFileName,
              setuploadedFileName,
              uploadFileLoading,
              setUploadFileLoading
            )}
            customRequest={dummyRequest}
          >
            <Button icon={<UploadOutlined />} loading={uploadFileLoading?.btn2}>
              Click to upload
            </Button>
          </Upload>
        </Form.Item>
        <Divider orientation="left" plain>
          Vendor/Service provider will contact using below details{" "}
          <Tooltip title="Service vendor will reach out to the provided details below">
            <InfoCircleOutlined />
          </Tooltip>
        </Divider>
        <Form.Item
          name="primary_number"
          rules={[
            {
              ...config.mobileNumberRegex[0],
              required: true,
            },
          ]}
        >
          <Input
            maxLength="10"
            placeholder="Mobile Number"
            addonBefore={"+91"}
            size="large"
          />
        </Form.Item>
        <Form.Item
          label="Alternative Number"
          name="alternative_number"
          rules={[
            {
              ...config.mobileNumberRegex[0],
              required: false,
            },
          ]}
        >
          <Input
            type="text"
            placeholder="Alternate Mobile Number (Optional)"
            addonBefore={"+91"}
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your email",
            },
          ]}
        >
          <Input placeholder="Email" size="large" />
        </Form.Item>
        <Form.Item
          name="address"
          extra="Please provide the complete secretariat address with landmark and pincode."
          rules={[
            {
              required: true,
              message: "Please input your secretariat address",
            },
          ]}
        >
          <TextArea size="large" placeholder="Secretariat Address" required />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};
export default InternetIssuesForm;
