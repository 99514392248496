export const districtCoordinates = [
  {
    district: "PARVATHIPURAM MANYAM",
    coordinates: [340, 360],
    mobileCoordinates: [],
  },

  { district: "VIZIANAGARAM", coordinates: [330, 320], mobileCoordinates: [] },
  {
    district: "ALLURI SITHARAMA RAJU",
    coordinates: [260, 285],
    mobileCoordinates: [],
  },
  { district: "VISAKHAPATNAM", coordinates: [340, 300], mobileCoordinates: [] },
  { district: "ANAKAPALLI", coordinates: [310, 280], mobileCoordinates: [] },
  { district: "KAKINADA", coordinates: [280, 250], mobileCoordinates: [] },
  { district: "EAST GODAVARI", coordinates: [250, 240], mobileCoordinates: [] },
  { district: "KONASEEMA", coordinates: [260, 210], mobileCoordinates: [] },
  { district: "WEST GODAVARI", coordinates: [240, 210], mobileCoordinates: [] },
  { district: "ELURU", coordinates: [210, 235], mobileCoordinates: [] },
  { district: "NTR", coordinates: [180, 220], mobileCoordinates: [] },
  { district: "KRISHNA", coordinates: [210, 195], mobileCoordinates: [] },
  { district: "GUNTUR", coordinates: [185, 195], mobileCoordinates: [] },
  { district: "PALNADU", coordinates: [150, 200], mobileCoordinates: [] },
  { district: "BAPATLA", coordinates: [175, 170], mobileCoordinates: [] },
  { district: "PRAKASAM", coordinates: [120, 150], mobileCoordinates: [] },
  { district: "NANDYAL", coordinates: [75, 140], mobileCoordinates: [] },
  { district: "SPSR NELLORE", coordinates: [140, 90], mobileCoordinates: [] },
  { district: "YSR KADAPA", coordinates: [85, 90], mobileCoordinates: [] },
  { district: "ANNAMAYYA", coordinates: [110, 50], mobileCoordinates: [] },
  { district: "TIRUPATI", coordinates: [150, 35], mobileCoordinates: [] },
  { district: "CHITTOOR", coordinates: [105, 0], mobileCoordinates: [] },
  {
    district: "SRI SATHYA SAI",
    coordinates: [45, 60],
    mobileCoordinates: [],
  },
  { district: "ANANTHAPUR", coordinates: [20, 100], mobileCoordinates: [] },
  { district: "KURNOOL", coordinates: [30, 160], mobileCoordinates: [] },
  { district: "SRIKAKULAM", coordinates: [380, 350], mobileCoordinates: [] },
];
