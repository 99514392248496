import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Input,
  InputNumber,
  Button,
  message,
  Descriptions,
} from "antd";
import {
  fetchSchoolsData,
  getRegionsData,
} from "../../../services/metaData.services";
import { sliceAddress } from "../../../helpers/utility";
import { USER } from "../../../constants/defaultKeys";
import { getItem } from "../../../helpers/localStorage";
import {
  createTabTicket,
  getServiceCenters,
} from "../../../services/inventory.services";
import * as config from "../../../config/validation.config";
const { Option } = Select;
const defaultRegions = { name: "ALL", id: 0 };
const defaultSchool = { name: "OTHERS", id: "others" };
const RaiseTicketModal = ({ refresh, setVisible }) => {
  const userData = JSON.parse(getItem(USER));
  const [form] = Form.useForm();
  const [schoolData, setSchoolData] = useState([defaultSchool]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [regionsData, setRegionsData] = useState(null);
  const [districts, setDistricts] = useState([defaultRegions]);
  const [mandals, setMandals] = useState([defaultRegions]);
  const [formValues, setFormValues] = useState(null);
  const [serviceCenter, setServiceCenter] = useState(null);
  console.log(userData);
  useEffect(() => {
    getRegionsData()
      .then((res) => {
        setRegionsData(res?.data);
        setDistricts([...districts, ...res?.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    getServiceCenters(userData?.region?.district_identifier)
      .then((res) => {
        setServiceCenter(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onFormValueChange = async (changedValues) => {
    setFormValues({ ...formValues, ...changedValues });
  };

  const onDistrictChange = (val) => {
    form.resetFields(["mandal"]);
    form.resetFields(["school"]);
    setSchoolData([defaultSchool]);
    setMandals([defaultRegions]);
    const res = regionsData?.find((item) => item?.id === val);
    if (res === undefined || val === 0) {
      setDistricts([defaultRegions]);
      setMandals([defaultRegions]);
    } else {
      setDistricts([res]);
      setMandals([defaultRegions, ...res?.mandals]);
    }
  };
  const onMandalChange = (val) => {
    form.resetFields(["school"]);
    const mandal = mandals?.find((item) => item?.id === val);
    if (mandal === undefined || val === 0) {
      setSchoolData([defaultSchool]);
    } else if (mandal?.id) {
      fetchSchoolsData(`mandal=${mandal?.id}`)
        .then((response) => {
          setSchoolData([...response?.data?.data, ...schoolData]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const serviceCenterDetails = serviceCenter?.find(
    (item) => item?.id === formValues?.service_center
  );

  const onSubmit = (values) => {
    let payload = {
      ...(values?.school !== "others" && { school: values?.school }),
      ...(values?.school === "others" && { school_name: values?.school_name }),
      serial_no: values?.serial_no,
      student_name: values?.student_name,
      child_id: values?.child_id,
      service_center: values?.service_center,
      mandal: values?.mandal,
      district: values?.district,
      name_of_cp: values?.name_of_cp,
      mobile_number_of_cp: values?.mobile_number_of_cp,
    };
    setBtnLoading(true);
    createTabTicket(payload)
      .then((response) => {
        message.success(response.data.message);
        refresh();
        setVisible(false);
        setBtnLoading(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        // setVisible(false);
        setBtnLoading(false);
      });
  };

  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      form={form}
      onValuesChange={onFormValueChange}
      autoComplete="off"
    >
      <Form.Item
        label="Choose District"
        name="district"
        rules={[
          {
            required: true,
            message: "Please choose District",
          },
        ]}
      >
        <Select
          placeholder="Select District"
          onChange={onDistrictChange}
          defaultValue={"ALL"}
        >
          {regionsData &&
            [defaultRegions, ...regionsData]?.map((dist) => (
              <Option key={dist?.id} value={dist?.id}>
                {dist?.name}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Choose Mandal"
        name="mandal"
        rules={[
          {
            required: true,
            message: "Please choose Mandal",
          },
        ]}
      >
        <Select
          placeholder="Select Mandal"
          defaultValue={"ALL"}
          onChange={onMandalChange}
        >
          {mandals?.map((school) => (
            <Option key={school.id} value={school?.id}>
              {school.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Choose School Name"
        name="school"
        rules={[
          {
            required: true,
            message: "Please choose school name",
          },
        ]}
      >
        <Select placeholder="Select School">
          {schoolData &&
            schoolData?.map((school) => (
              <Option key={school?.id} value={school?.id}>
                {school?.name}
              </Option>
            ))}
        </Select>
      </Form.Item>
      {formValues?.school === "others" && (
        <Form.Item
          label="School Name"
          name="school_name"
          rules={[
            {
              required: formValues?.school === "others" && true,
              message: "Please enter school name",
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
      )}

      <Form.Item
        label="Student Name"
        name="student_name"
        rules={[
          {
            required: true,
            message: "Please enter student name",
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Child ID"
        name="child_id"
        rules={[
          {
            required: true,
            message: "Please enter child id",
          },
        ]}
      >
        <InputNumber style={{ width: "300px" }} />
      </Form.Item>
      <Form.Item
        label="Name of Contact Person"
        name="name_of_cp"
        rules={[
          {
            required: true,
            message: "Please enter contact person name",
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>
      <Form.Item
        label="Mobile number"
        name="mobile_number_of_cp"
        rules={config.mobileNumberRegex}
      >
        <Input
          autoComplete="off"
          size="large"
          maxLength="10"
          placeholder="Mobile Number"
          addonBefore={"+91"}
        />
      </Form.Item>
      <Form.Item
        label="Device Serial No"
        name="serial_no"
        rules={[
          {
            required: true,
            message: "Please enter device serial no",
          },
        ]}
      >
        <Input type="text" />
      </Form.Item>
      <Form.Item
        label="Choose Service Centre Name"
        name="service_center"
        rules={[
          {
            required: true,
            message: "Please choose service centre name",
          },
        ]}
      >
        <Select placeholder="Select Service Centre">
          {serviceCenter &&
            serviceCenter?.map((service) => (
              <Option key={service.id} value={service?.id}>
                {`${service.name},${sliceAddress(service?.address)}`}
              </Option>
            ))}
        </Select>
      </Form.Item>
      {formValues?.service_center && (
        <Descriptions>
          <Descriptions.Item label="Name" span={3}>
            {serviceCenterDetails?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Primary Contact Number" span={3}>
            {serviceCenterDetails?.phone1}
          </Descriptions.Item>
          <Descriptions.Item label="Secondary Contact Number" span={3}>
            {serviceCenterDetails?.phone2}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={3}>
            {serviceCenterDetails?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Address" span={3}>
            {serviceCenterDetails?.address}
          </Descriptions.Item>
        </Descriptions>
      )}
      <Button htmlType="submit" type="primary" loading={btnLoading}>
        Submit
      </Button>
    </Form>
  );
};
export default RaiseTicketModal;
