import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Button,
  Row,
  Col,
  Input,
  Radio,
  Pagination,
  message,
} from "antd";
import Card from "../../../blocks/Card/Card";
import { getSimRequests } from "../../../services/inventory.services";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { isMobileOnly } from "react-device-detect";
import ActionModal from "./ActionsModal";
import { tableFilterDistricts } from "../../../constants/defaultKeys";
import VendorInventoryRequests from "../VendorInventoryRequests/VendorInventoryRequests";
import { downloadSimIssues } from "../../../services/downloadFile.services";
import moment from "moment";
import "./SimRequests.css";
import useViewAccess from "../../../hooks/useViewAccess";
const status = [
  {
    text: "Open",
    value: "Open",
  },
  {
    text: "Activated",
    value: "Activated",
  },
  {
    text: "Pending",
    value: "Pending",
  },
  {
    text: "Rejected",
    value: "Rejected",
  },
];
const { Search } = Input;
const SimRequests = () => {
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [editList, setEditList] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [mobileNumber, setMobileNumber] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [ticketStatus, setTicketStatus] = useState({});
  const [searchedColumn, setSearchedColumn] = useState("");
  const [onRadioChange, setOnRadioChange] = useState("inventory");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  useViewAccess("is_vendor");
  useEffect(() => {
    setLoading(true);
    getSimRequests(currentPage, mobileNumber)
      .then((response) => {
        const data = response?.data;
        handleTicketsData(data?.data);
        if (!Boolean(String(data?.data))) {
          message.error(
            "You have search for an invalid number, please search with a valid number!"
          );
        }
        // const filterData = data?.filter(
        //   (status) =>
        //     status?.resolution_status !== "Activated" &&
        //     status?.resolution_status !== "Rejected"
        // );
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [refresh, mobileNumber, currentPage]);

  const download = () => {
    setBtnLoading(true);
    downloadSimIssues()
      .then((response) => {
        let blob = response.data;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "sim-issues.xlsx";
        a.click();
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong, Please try after sometime.");
        setBtnLoading(false);
      });
  };

  const handleTicketsData = (apiData) => {
    let ticketsStatus = {
      open: 0,
      pending: 0,
      rejected: 0,
      activated: 0,
    };

    if (apiData) {
      apiData.map((ticketData) => {
        if (ticketData.resolution_status === "Open") {
          ticketsStatus.open++;
        } else if (ticketData.resolution_status === "Pending") {
          ticketsStatus.pending++;
        } else if (ticketData.resolution_status === "Activated") {
          ticketsStatus.activated++;
        } else if (ticketData.resolution_status === "Rejected") {
          ticketsStatus.rejected++;
        }
      });
      setTicketStatus(ticketsStatus);
    } else {
      setTicketStatus({
        ...ticketStatus,
        open: "-",
        pending: "-",
        rejected: "-",
        activated: "-",
      });
    }
  };

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const openModal = (data) => {
    setEditList(data);
    setShowModal(true);
  };

  const columns = [
    {
      title: "Old Phone Number",
      dataIndex: "old_sim_number",
      key: "old_sim_number",
      width: 150,
      ...getColumnSearchProps("old_sim_number"),
      render: (data) => <span>{data}</span>,
    },
    {
      title: "New Sim Number",
      dataIndex: "new_sim_number",
      key: "new_sim_number",
      width: 220,
      sorter: (a, b) => a.new_sim_number - b.new_sim_number,
    },
    {
      title: "Contact Number",
      dataIndex: "raised_by_contact_number",
      key: "raised_by_contact_number",
      width: 150,
      sorter: (a, b) => a.raised_by_contact_number - b.raised_by_contact_number,
    },

    {
      title: "Secretariat",
      dataIndex: "village_secretariat_name",
      key: "village_secretariat_name",
      width: 150,
      sorter: (a, b) =>
        a.village_secretariat_name.localeCompare(b.village_secretariat_name),
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      width: 130,
      filters: [...tableFilterDistricts],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.district.includes(value),
    },
    {
      title: "Issue Raised",
      dataIndex: "created_at_date",
      key: "created_at_date",
      width: 180,
      render: (date) => moment(date).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.created_at_date).unix() - moment(b.created_at_date).unix(),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at_date",
      key: "updated_at_date",
      width: 180,
      render: (date) => moment(date).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        moment(a.updated_at_date).unix() - moment(b.updated_at_date).unix(),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: 180,
      render: (data, record) => <span>{record?.remarks ? data : "-"}</span>,
    },
    {
      title: "Status",
      dataIndex: "resolution_status",
      key: "resolution_status",
      width: 100,
      filters: [...status],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.resolution_status.includes(value),
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (record) => (
        <>
          {!(
            record?.resolution_status === "Rejected" ||
            record?.resolution_status === "Activated"
          ) ? (
            <Button
              type="primary"
              size="small"
              onClick={() => openModal(record)}
            >
              Change status
            </Button>
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];

  const onChange = (e) => {
    setOnRadioChange(e.target.value);
  };
  const onSearch = (value) => {
    setMobileNumber(value);
  };
  return (
    <Row gutter={[16, 16]}>
      <Col span={22} offset={1}>
        <Row gutter={[16, 16]} style={{ paddingBottom: "16px" }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Card title={"Open"} className="ticket-stats">
              <p> {data?.open}</p>
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Card title={"Activated"} className="ticket-stats">
              <p> {data?.activated}</p>
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Card title={"Pending"} className="ticket-stats">
              <p> {data?.pending}</p>
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Card title={"Rejected"} className="ticket-stats">
              <p> {data?.rejected}</p>
            </Card>
          </Col>
          <Col
            sm={{ span: 8 }}
            md={{ spna: 4 }}
            lg={{ span: 24 }}
            style={{ textAlign: "right" }}
          >
            <Space>
              <Search
                addonBefore="+91"
                maxLength={10}
                placeholder="Old phone number"
                onSearch={onSearch}
                style={{ width: 250 }}
                enterButton
              />
              <Radio.Group
                defaultValue="a"
                buttonStyle="solid"
                style={{ float: "right" }}
                onChange={onChange}
                defaultValue={onRadioChange}
              >
                <Radio.Button value="inventory">Sim Issues</Radio.Button>
                <Radio.Button value="requests">Sim Requests</Radio.Button>
              </Radio.Group>
            </Space>
          </Col>
        </Row>
        {onRadioChange === "inventory" ? (
          <div style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              loading={btnLoading}
              onClick={download}
            >
              Download as excel
            </Button>
            <Table
              loading={loading}
              columns={columns}
              dataSource={data?.data}
              pagination={false}
              scroll={isMobileOnly && { x: 500, y: 500 }}
            />
            <Pagination
              onChange={(page) => setCurrentPage(page)}
              style={{ paddingTop: "10px" }}
              defaultCurrent={1}
              total={data?.count}
              showSizeChanger={false}
              showQuickJumper={false}
            />
          </div>
        ) : (
          <VendorInventoryRequests />
        )}
      </Col>
      {showModal && (
        <ActionModal
          setVisible={setShowModal}
          editList={editList}
          refresh={refreshPage}
          status={status}
        />
      )}
    </Row>
  );
};
export default SimRequests;
