import React, { useEffect, useState } from "react";
import { Button, Select, Drawer, Form, Input, Spin, message, Tag } from "antd";

import * as config from "../../../config/validation.config";
import { debounce } from "../../../helpers/utility";
import {
  raiseATicketAPI,
  searchIMEINumbersAPI,
} from "../../../services/amc.services";
import { getItem } from "../../../helpers/localStorage";
import { useForm } from "antd/lib/form/Form";

const { Option } = Select;

function TicketDrawer({ open, closeDrawer, serviceCenters }) {
  const [form] = useForm();
  const [services, setServices] = useState(null);
  const [selectedIMEINumber, setSelectedIMEINumber] = useState(null);
  const [IMEINumbers, setIMEINumbers] = useState([]);
  const [IMEINumbersLoading, setIMEINumbersLoading] = useState(false);

  function onSearch(searchText) {
    if (searchText?.length > 0) {
      setIMEINumbersLoading(true);
      searchIMEINumbersAPI(searchText)
        .then((res) => {
          const data = (res.data || []).map((eachNum) => ({
            value: eachNum.instance_id,
            label: eachNum.imei_number,
          }));
          setIMEINumbers(data);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIMEINumbersLoading(false);
        });
    }
  }

  function handleSelect(values) {
    const res = serviceCenters.find((item) => item?.id === values);
    setServices(res?.services);
    form.setFieldValue("services", undefined);
    form.setFieldValue("dept_estimated_cost", 0);
  }

  function onFinish(values) {
    raiseATicketAPI({
      ...values,
      mobile_imei_id: selectedIMEINumber?.value,
      mobile_imei: selectedIMEINumber?.label,
    })
      .then((res) => {
        message.success(res?.data?.message || "Ticket created successfully");
      })
      .catch((err) => {
        message.error(
          err.response?.data?.message ||
            "Something went wrong, please try again"
        );
      })
      .finally(() => {
        closeDrawer();
      });
  }

  useEffect(() => {
    if (IMEINumbers.length === 0) {
      setIMEINumbersLoading(false);
    }
  }, [IMEINumbers]);

  return (
    <Drawer
      maskClosable={false}
      title="Raise Ticket"
      placement="right"
      onClose={closeDrawer}
      open={open}
      width={"30vw"}
    >
      <Form
        form={form}
        layout="vertical"
        name="IssueForm"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Cluster code"
          name="volunteer_cluster_code"
          rules={[
            {
              required: true,
              message: "Please enter cluster code!",
            },
          ]}
        >
          <Input size="large" type="number" placeholder={"Cluster code"} />
        </Form.Item>
        <Form.Item
          label="Volunteer Name"
          name="volunteer_name"
          rules={[
            {
              required: true,
              message: "Please enter volunteer name!",
            },
          ]}
        >
          <Input size="large" placeholder={"Volunteer name"} />
        </Form.Item>

        <Form.Item
          label="Volunteer Contact"
          name="volunteer_contact"
          rules={config.mobileNumberRegex}
        >
          <Input
            placeholder={"Volunteer contact number"}
            size="large"
            autoComplete="off"
            maxLength="10"
            addonBefore={"+91"}
          />
        </Form.Item>

        <Form.Item
          label="IMEI"
          name="mobile_imei_id"
          rules={[
            {
              required: true,
              message: "Please enter IMEI number!",
            },
          ]}
        >
          <Spin spinning={IMEINumbersLoading} tip="Searching.....">
            <Select
              showSearch
              options={IMEINumbers}
              placeholder={"Search with an IMEI number"}
              filterOption={false}
              onSearch={debounce(onSearch, 300)}
              value={selectedIMEINumber?.label}
              onChange={(value, option) => setSelectedIMEINumber(option)}
            />
          </Spin>
        </Form.Item>

        <Form.Item
          label="Select Service Center"
          name="service_center_id"
          rules={[{ required: true, message: "Please select service center" }]}
        >
          <Select
            size="large"
            allowClear
            onChange={handleSelect}
            value={services?.name}
            placeholder={"Select a service center"}
          >
            {serviceCenters?.map((item) => (
              <Option value={item.id} key={item.id}>
                {item?.name} - <Tag>{item?.district_name}</Tag>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Select Services"
          name="services"
          rules={[{ required: true, message: "Please select services" }]}
        >
          <Select
            size="large"
            mode="multiple"
            disabled={!services}
            placeholder={
              !services ? "Select a service center" : "Selecter a service"
            }
            onChange={(value, options) => {
              let cost = 0;
              options.map((option) => (cost += option.sevice_cost));
              form.setFieldValue("dept_estimated_cost", cost);
            }}
          >
            {services?.map((item) => (
              <Option
                value={item.id}
                key={item.id}
                sevice_cost={item.service_cost}
              >
                {item?.service_name.charAt(0).toUpperCase() +
                  item?.service_name.slice(1).replaceAll("_", " ")}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Dept Estimated cost" name="dept_estimated_cost">
          <Input size="large" disabled defaultValue={"0"} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" block>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
export default TicketDrawer;
