import React from "react";
import { Form, Select, Button, message } from "antd";
import { addOffice365Requests } from "../../../../services/serviceRequests.services";
import { USER } from "../../../../constants/defaultKeys";
import { getItem } from "../../../../helpers/localStorage";

const { Option } = Select;

const Office365Request = ({ id }) => {
  const [form] = Form.useForm();
  const userDetails = JSON.parse(getItem(USER));

  const onFinish = (values) => {
    addOffice365Requests(id, values)
      .then((response) => {
        form.resetFields();
        message.success(response.data.message);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        console.log(error);
      });
  };

  return (
    <Form
      name="office_365"
      onFinish={onFinish}
      wrapperCol={{ span: 8 }}
      form={form}
      layout="vertical"
    >
      <Form.Item
        name="type_of_request"
        label="Type of issue"
        rules={[
          {
            required: true,
            message: "Please select your type of request!",
          },
        ]}
      >
        <Select size="large">
          <Option value="credentials_request">Forgot Username/Password</Option>
          <Option value="office365_not_working">Office 365 Not Working</Option>
          <Option value="office365_signin_issue">
            Office 365 Sign-In Issue
          </Option>
          <Option value="new_installation_request">
            Office 365 Installation
          </Option>
          <Option value="office365_reactivation">
            Office 365 Reactivation
          </Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          disabled={!userDetails?.is_village_secretariat}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Office365Request;
