import axios from "axios";
import { clear, getItem, setItem } from "../../helpers/localStorage";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../constants/defaultKeys";
import { getTokenIfNotExpired } from "../../helpers/utility";
import { message } from "antd";

const axiosConfig = {
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

const axiosInstance = axios.create(axiosConfig);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.request.use((config) => {
  const token = getTokenIfNotExpired();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const refreshTokenApi = "/accounts/refresh-token/";
    const errorMessage = error?.response?.data?.code;
    const errorStatusCode = error?.response?.status;
    const tokenInvalid = "token_not_valid";
    const invalidCred = "invalid_credentials";
    const accountNotFound = "user_not_found";

    // console.log(originalRequest.url, "originalRequest.url");

    // Prevent infinite loops
    if (errorStatusCode === 401 && originalRequest.url === refreshTokenApi) {
      clear();
      window.location.href = "/";
      return Promise.reject(error);
    }

    // Invalid credentials or user not exist
    if (
      (errorMessage === invalidCred || errorMessage === accountNotFound) &&
      errorStatusCode === 401
    ) {
      clear();
      window.location.href = "/";
      return Promise.reject(error);
    }

    if (errorStatusCode === 404) {
      window.location.href = "/page-not-found";
      return Promise.reject(error);
    }

    // if (errorStatusCode === 403) {
    //   window.location.href = "/not-authorized";
    //   return Promise.reject(error);
    // }

    if (errorStatusCode === 401 && errorMessage === tokenInvalid) {
      // Triggers when user session is expired
      const refreshToken = getItem(REFRESH_TOKEN);

      if (!refreshToken) {
        clear();
        window.location.href = "/";
        message.error("Your session has been expired, please login again", 8);
        return Promise.reject(error);
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;

      return new Promise((resolve, reject) => {
        const regex = new RegExp(
          "^[A-Za-z0-9-_=]+\\.[A-Za-z0-9-_=]+\\.?[A-Za-z0-9-_.+/=]*$"
        );

        if (regex.test(refreshToken)) {
          const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

          // exp date in token is expressed in seconds, while now() returns milliseconds:
          const now = Math.ceil(Date.now() / 1000);

          // Triggers if refresh token is not expired
          if (tokenParts.exp > now) {
            fetch(`${process.env.REACT_APP_BASE_URL}accounts/refresh-token/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ refresh: refreshToken }),
            })
              .then((res) => res.json())
              .then((response) => {
                if (response.access) {
                  setItem(ACCESS_TOKEN, response.access);
                  axiosInstance.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${response.access}`;
                  processQueue(null, response.access);
                  resolve(axiosInstance(originalRequest));
                } else {
                  processQueue(new Error("Failed to refresh token"), null);
                  reject(new Error("Failed to refresh token"));
                  clear();
                  window.location.href = "/";
                }
              })
              .catch((err) => {
                processQueue(err, null);
                reject(err);
                message.error(err?.response?.data?.message);
                clear();
                window.location.href = "/";
              })
              .finally(() => {
                isRefreshing = false;
              });
          } else {
            clear();
            console.log("Your session has been expired, please login again");
            window.location.href = "/";
            message.error(
              "Your session has been expired, please login again",
              8
            );
            processQueue("Your session has been expired, please login again");
            reject("Your session has been expired, please login again");
            isRefreshing = false;
          }
        } else {
          clear();
          window.location.href = "/";
          message.error("Your session has been expired, please login again", 8);
          processQueue("Invalid refresh token");
          reject("Invalid refresh token");
          isRefreshing = false;
        }
      });
    }

    // Specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
