import React, { Fragment } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import VS from "../components/VS/VS";
import Page500 from "../components/ErrorHandlingRoutes/500";
import Page404 from "../components/ErrorHandlingRoutes/404";
import Layout from "../layout/Layout";
import Mfa from "../components/Mfa/Mfa";
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import Login from "../components/Login/Login";
import Admin from "../components/Admin/Admin";
import Mandal from "../components/Mandal/Mandal";
import Logout from "../components/Logout/Logout";
import Vendor from "../components/Vendor/Vendor";
import District from "../components/District/District";
import MyProfile from "../components/MyProfile/MyProfile";
import Issues from "../components/Vendor/IssuesPage/Issues";
import SetPassword from "../components/SetPassword/SetPassword";
import Instructions from "../components/Instructions/Instructions";
import SetupProfile from "../components/SetupProfile/SetupProfile";
import InventoryStats from "../components/InventoryStats/Dashboard";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import SimRequests from "../components/Vendor/SimRequestsVendor/SimRequests";
import UniformVendor from "../components/Vendor/UniformVendor/UniformVendor";
import InternetVendor from "../components/Vendor/Internet/InternetVendor";
import TabTicketTable from "../components/VS/TabTicket/TabTicketTable";
import Office365Vendor from "../components/Vendor/Office365Vendor/Office365Vendor";
import Sessionexpiry from "../components/ErrorHandlingRoutes/Sessionexpiry";
import InternetIssue from "../components/Vendor/IssuesPage/InternetIssue";
import TicketPage from "../components/VS/TabTicket/TicketPage";
import AekIssues from "../components/Vendor/IssuesPage/AekIssues";
import AekType from "../components/Vendor/AekVendor/AekType";
import TabsInspection from "../components/VS/TabTicket/TabsInspection";
import TabsPage from "../components/Admin/TabsData/TabsPage";
import ServiceCenter from "../components/Amc/ServiceCenter/ServiceCenter";
import DistrictIncharge from "../components/Amc/DistrictIncharge/DistrictIncharge";
import ViewImg from "../components/Images/ViewImg";

const Routes = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <Switch>
          <PublicRoutes exact restricted={false} path="/" component={Login} />
          <PublicRoutes
            exact
            restricted={false}
            path="/forgot-password"
            component={ForgotPassword}
          />
          <PublicRoutes
            exact
            restricted={false}
            path="/setpassword/:token"
            component={SetPassword}
          />
          {/* <PublicRoutes
            exact
            restricted={false}
            path="/check-login-number"
            component={CheckLoginNumber}
          /> */}
          {/* <PublicRoutes
            exact
            restricted={false}
            path="/request-for-login"
            component={RequestLogin}
          /> */}
          <PublicRoutes
            exact
            restricted={false}
            path="/logout"
            component={Logout}
          />
          <PublicRoutes exact path="/verify-otp" component={Mfa} />
          <PublicRoutes
            exact
            path="/instructions/:tab"
            component={Instructions}
          />
          <PublicRoutes
            exact
            path="/inventory-stats"
            component={InventoryStats}
          />
          <PublicRoutes exact path="/page500" component={Page500} />
          <PublicRoutes
            exact
            path="/sessionexpired"
            component={Sessionexpiry}
          />
          <PrivateRoutes exact path="/set-profile/" component={SetupProfile} />
          <Layout>
            <PrivateRoutes
              exact
              path="/uniform-vendor"
              component={UniformVendor}
            />
            <PrivateRoutes
              exact
              path="/village-secretariat/:region/:id"
              component={VS}
            />
            <PrivateRoutes
              exact
              path="/mandal/:region/:id"
              component={Mandal}
            />
            <PrivateRoutes
              exact
              path="/district/:region/:id"
              component={District}
            />
            <PrivateRoutes exact path="/issues/:id" component={Issues} />
            <PrivateRoutes exact path="/resources/*" component={ViewImg} />
            <PrivateRoutes
              exact
              path="/internet-issues/:id"
              component={InternetIssue}
            />
            {/* <PrivateRoutes
              exact
              path="/state/service-center-amc-tickets"
              component={ServiceCenter}
            /> */}
            {/* <PrivateRoutes
              exact
              path="/state/district-amc-tickets"
              component={DistrictIncharge}
            /> */}
            <PrivateRoutes exact path="/vendor" component={Vendor} />
            <PrivateRoutes
              exact
              path="/village-secretariat/:region/:id/tabs_inspection"
              component={TabsInspection}
            />
            <PrivateRoutes
              exact
              path="/aek-issues/:token"
              component={AekIssues}
            />
            <PrivateRoutes
              exact
              path="/raise-tab-ticket/:id"
              component={TabTicketTable}
            />
            <PrivateRoutes exact path="/tabs/:tab" component={TabsPage} />
            <PrivateRoutes
              exact
              path="/ticket-details/:id"
              component={TicketPage}
            />
            <PrivateRoutes exact path="/aek-vendor" component={AekType} />
            <PrivateRoutes
              exact
              path="/office-365"
              component={Office365Vendor}
            />
            <PrivateRoutes
              exact
              path="/internet-vendor"
              component={InternetVendor}
            />
            <PrivateRoutes exact path="/state/:tab" component={Admin} />
            <PrivateRoutes exact path="/myprofile" component={MyProfile} />
            <PrivateRoutes exact path="/sim-requests" component={SimRequests} />
            <PrivateRoutes exact path="/page500" component={Page500} />
            <PrivateRoutes exact path="/page404" component={Page404} />
            <PrivateRoutes exact path="*" component={Page404} />
          </Layout>
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
};

export default Routes;
