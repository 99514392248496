import React, { useEffect, useState } from "react";
import Drawer from "../../Drawer/Drawer";
import { Table, Button, Row, Col } from "antd";
import moment from "moment";
import { getMandalMeeting } from "../../../services/wednesdayMeeting.services";

const MandalMeetingTable = ({ id }) => {
  const [editList, setEditList] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getMandalMeeting(id)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [refresh]);

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const columns = [
    {
      title: "Mandal Meeting Conducted date",
      dataIndex: "meeting_date",
      key: "meeting_date",
      width: 150,
      render: (date) =>
        date ? moment(date).format("YYYY-MM-DD") : "Not conducted yet",
    },
    {
      title: "Minutes Of Meeting",
      dataIndex: "minutes_of_meeting",
      key: "minutes_of_meeting",
      width: 250,
      sorter: (a, b) =>
        a.minutes_of_meeting.localeCompare(b.minutes_of_meeting),
    },
    {
      title: "Other Outstanding Issues",
      dataIndex: "other_outstanding_issues",
      key: "other_outstanding_issues",
      width: 180,
      render: (data, record) => (
        <span>{record.other_outstanding_issues ? data : "-"}</span>
      ),
      sorter: (a, b) =>
        a.other_outstanding_issues.localeCompare(b.other_outstanding_issues),
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 170,
      render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "-"),
      sorter: (a, b) =>
        moment(a.updated_at).unix() - moment(b.updated_at).unix(),
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      fixed: "right",
      render: (text, record) => (
        <span
          className="footer-nav-links"
          onClick={() =>
            openModal({
              status: "mandalMeeting",
              title: "Update Meeting Notes",
              data: record,
            })
          }
        >
          Modify
        </span>
      ),
    },
  ];

  const openModal = (data) => {
    setEditList(data);
    setDrawerVisible(true);
  };

  return (
    <Row>
      <Col span={24} style={{ textAlign: "right", marginTop: "10px" }}>
        <Button
          type="primary"
          onClick={() =>
            openModal({
              title: `Add Meeting Notes`,
              status: "mandalMeeting",
            })
          }
        >
          Add Meeting
        </Button>
      </Col>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={loading}
        />
      </Col>
      {drawerVisible && (
        <Drawer
          setVisible={setDrawerVisible}
          editList={editList}
          refresh={refreshPage}
          id={id}
        />
      )}
    </Row>
  );
};

export default MandalMeetingTable;
