import React, { useEffect, useState } from "react";
import { Form, Button, InputNumber, message, Divider, Row, Col } from "antd";
import {
  addDistrictWednesdayMeeting,
  updateDistrictWednesdayMeeting,
} from "../../../services/wednesdayMeeting.services";

const WednesdayMeetingForm = ({ editList, refresh, setVisible }) => {
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  useEffect(() => {
    if (editList?.data) form.setFieldsValue(editList?.data);
  }, [editList]);

  const inputNumberField = (
    <InputNumber
      size="large"
      min={0}
      style={{
        width: 80,
      }}
    />
  );

  const onFinish = (values) => {
    setBtnLoading(true);
    if (editList?.data?.id) {
      updateDistrictWednesdayMeeting(editList?.data?.id, values)
        .then((response) => {
          message.success(response.data.message);
          refresh();
          setBtnLoading(false);
          setVisible(false);
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
          setBtnLoading(false);
        });
    } else {
      addDistrictWednesdayMeeting(editList?.data?.mandal_id, values)
        .then((response) => {
          setBtnLoading(false);
          message.success(response.data.message);
          refresh();
          setVisible(false);
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
          setBtnLoading(false);
        });
    }
  };

  return (
    <Form
      name="weekly meeting"
      layout="vertical"
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        label="Mobiles Not Working Redressed"
        name="mobiles_not_working_redressed_at_district_level"
        rules={[
          {
            required: true,
            message: "Please input mobiles not working redressed!",
          },
        ]}
      >
        {inputNumberField}
      </Form.Item>

      <Form.Item
        label="SIMs Not Working Redressed"
        name="sims_not_working_redressed_at_district_level"
        rules={[
          {
            required: true,
            message: "Please input sims not working redressed!",
          },
        ]}
      >
        {inputNumberField}
      </Form.Item>

      <Form.Item
        label="FP Scanners Not Working Redressed"
        name="fp_scanners_not_working_redressed_at_district_level"
        rules={[
          {
            required: true,
            message: "Please input fp scanner not working redressed!",
          },
        ]}
      >
        {inputNumberField}
      </Form.Item>
      <Form.Item
        label="CFMS ID Issues Redressed "
        name="cfms_id_issues_redressed_at_district_level"
        rules={[
          {
            required: true,
            message: "Please input fp scanner not working redressed!",
          },
        ]}
      >
        {inputNumberField}
      </Form.Item>
      <Form.Item
        label="Salary Issues Redressed "
        name="salary_issues_redressed_at_district_level"
        rules={[
          {
            required: true,
            message: "Please input fp scanner not working redressed!",
          },
        ]}
      >
        {inputNumberField}
      </Form.Item>
      <Form.Item
        label="Other Issues Redressed"
        name="other_issues_redressed_at_district_level"
        rules={[
          {
            required: true,
            message: "Please input fp scanner not working redressed!",
          },
        ]}
      >
        {inputNumberField}
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={btnLoading}
          disabled={btnLoading}
        >
          Submit1
        </Button>
      </Form.Item>
    </Form>
  );
};

export default WednesdayMeetingForm;
