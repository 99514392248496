import React, { useState } from "react";
import { Table, Input, Popconfirm, Form, Typography, message } from "antd";
import { editSimReplaceRequests } from "../../../../services/serviceRequests.services";
import moment from "moment";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              pattern: /^([A-Za-z0-9]{20,20}d*)$/,
              message: "Please input 20 digit new sim number",
            },
          ]}
        >
          <Input maxLength={20} type="text" />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const SimRequestsTable = ({ data, refresh }) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      new_sim_number: "",
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    const row = await form.validateFields();
    const newData = [...data];
    const index = newData.findIndex((item) => key === item.id);

    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });
      const editData = newData[index];
      editSimReplaceRequests(editData.id, editData)
        .then((response) => {
          message.success(response.data.message);
          refresh();
        })
        .catch((error) => message.error(error.response.data.message));

      setEditingKey("");
    } else {
      newData.push(row);
      setEditingKey("");
    }
  };

  const columns = [
    {
      title: "Mobile Number",
      dataIndex: "old_sim_number",
      key: "old_sim_number",
    },
    {
      title: "New SIM Number",
      dataIndex: "new_sim_number",
      key: "new_sim_number",
      editable: true,
    },
    {
      title: "Carrier",
      dataIndex: "network",
      key: "network",
    },
    {
      title: "Requested Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("YYYY-MM-DD h:mm:a"),
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
    {
      title: "Resolved By",
      dataIndex: "resolved_by_first_name",
      key: "resolved_by_first_name",
      render: (name, record) => (
        <span>
          {record?.resolved_by_first_name !== null
            ? name + record.resolved_by_last_name
            : "-"}
        </span>
      ),
    },
    {
      title: "Resolution Status",
      dataIndex: "resolution_status",
      key: "resolution_status",
      render: (status, record) => (
        <span>{record?.resolution_status ? status : "-"}</span>
      ),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (status, record) => <span>{record?.remarks ? status : "-"}</span>,
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (date) => moment(date).format("YYYY-MM-DD h:mm:a"),
      sorter: (a, b) =>
        moment(a.updated_at).unix() - moment(b.updated_at).unix(),
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <span
              className="footer-nav-links"
              onClick={() => save(record.id)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </span>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <span className="footer-nav-links">Cancel</span>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={
              editingKey !== "" ||
              record?.resolution_status === "activated" ||
              record?.resolution_status === "rejected"
            }
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{ pageSize: 25, showSizeChanger: false }}
        scroll={{ x: 1000 }}
      />
    </Form>
  );
};

export default SimRequestsTable;
