import React, { useState, useEffect } from "react";
import {
  Descriptions,
  Row,
  Col,
  Button,
  PageHeader,
  Space,
  Popconfirm,
  message,
  Typography,
  Skeleton,
} from "antd";
import Card from "../../../blocks/Card/Card";
import Drawer from "../../Drawer/Drawer";
import { useParams, useHistory } from "react-router-dom";
import {
  deleteTabTicket,
  getTabPageTicket,
} from "../../../services/inventory.services";
import moment from "moment";
import "./Ticket.css";
import { downloadTabPdf } from "../../../services/downloadFile.services";
import { DownloadOutlined } from "@ant-design/icons";
const { Title } = Typography;
const TicketPage = () => {
  const query = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editList, setEditList] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);

  const openModal = (data) => {
    setEditList({ ...data });
    setDrawerVisible(true);
  };

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };
  useEffect(() => {
    setLoading(true);
    getTabPageTicket(query?.id)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refresh]);

  const download = () => {
    setBtnLoading(true);
    downloadTabPdf(query?.id)
      .then((response) => {
        let blob = response.data;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "Tab-Ticket.pdf";
        a.click();
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong, Please try after sometime.");
        setBtnLoading(false);
      });
  };

  const onFinish = (id) => {
    deleteTabTicket(id)
      .then((response) => {
        message.success(response.data.message);
        refreshPage();
        history.goBack();
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.response?.data?.message);
      });
  };

  return (
    <Row gutter={[4, 4]}>
      <Col span={20} offset={2}>
        <Col span={8} className="back-with-title">
          <PageHeader onBack={() => history.goBack()} title="Back" />
        </Col>
        {loading ? (
          <Skeleton />
        ) : (
          <>
            <Row>
              <Col
                span={24}
                style={{ textAlign: "right", paddingBottom: "10px" }}
              >
                <Button
                  type="primary"
                  onClick={download}
                  icon={<DownloadOutlined />}
                  loading={btnLoading}
                >
                  Download Ticket
                </Button>
              </Col>
            </Row>
            <Card className="card-background">
              <Row>
                <Col
                  span={24}
                  style={{ textAlign: "right", paddingBottom: "10px" }}
                >
                  <Space>
                    <Button
                      type="primary"
                      onClick={() => {
                        openModal({
                          title: "Update Ticket",
                          status: "update-ticket",
                          data: data,
                        });
                      }}
                    >
                      Update
                    </Button>
                    <Popconfirm
                      title="sure to delete?"
                      onConfirm={() => onFinish(query?.id)}
                    >
                      <Button size="small" type="danger">
                        Delete Ticket
                      </Button>
                    </Popconfirm>
                  </Space>
                </Col>
              </Row>
              <Descriptions
                bordered
                title={<Title level={3}>{data?.ticket_id}</Title>}
                className="action-description"
                contentStyle={{ width: "10%" }}
                labelStyle={{ width: "10%" }}
              >
                <Descriptions.Item label="Child ID">
                  {data?.child_id ? data?.child_id : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Student Name">
                  {data?.student_name ? data?.student_name : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="School">
                  {data?.school_name
                    ? data?.school_name
                    : data?.other_school_name}
                </Descriptions.Item>
                <Descriptions.Item label="District">
                  {data?.school_district_name
                    ? data?.school_district_name
                    : data?.other_district_name}
                </Descriptions.Item>
                <Descriptions.Item label="Mandal">
                  {data?.school_mandal_name
                    ? data?.school_mandal_name
                    : data?.other_mandal_name}
                </Descriptions.Item>
                <Descriptions.Item label="Secretariat">
                  {data?.school_secretariat_name
                    ? data?.school_secretariat_name
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Device Serial No">
                  {data?.serial_no ? data.serial_no : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Created At">
                  {data?.created_at
                    ? moment(data?.created_at).format("MMM D, YYYY")
                    : ""}
                </Descriptions.Item>
                <Descriptions.Item label="Updated At">
                  {data?.updated_at
                    ? moment(data?.updated_at).format("MMM D, YYYY")
                    : ""}
                </Descriptions.Item>
                <Descriptions.Item label="Job sheet Id">
                  {data?.jobsheet_id ? data?.jobsheet_id : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Repair Status">
                  {data?.status === "Resolved"
                    ? "Repaired"
                    : data?.status
                    ? data?.status
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Resolution">
                  {data?.resolution === "RepairedAndFixed"
                    ? "Repaired And Fixed"
                    : data?.resolution === "Replaced"
                    ? "Replaced"
                    : data?.resolution === "NotCoveredUnderWarranty"
                    ? "Not Covered Under Warranty"
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Name of Contact Person">
                  {data?.name_of_cp ? `${data?.name_of_cp} ` : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Mobile Number of Contact Person">
                  {data?.mobile_number_of_cp
                    ? `${data?.mobile_number_of_cp} `
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Cost Of Repair">
                  {data?.cost_of_repair ? `${data?.cost_of_repair} Rs` : "-"}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </>
        )}
      </Col>
      {drawerVisible && (
        <Drawer
          setVisible={setDrawerVisible}
          id={query?.id}
          editList={editList}
          refresh={refreshPage}
        />
      )}
    </Row>
  );
};
export default TicketPage;
