import DeviceBadge from "../blocks/Badges/DeviceBadge";

export const TOKEN_KEY = "token";
export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const USER = "user";
export const colors = [
  "#2c699a",
  "#048ba8",
  "#0db39e",
  "#83e377",
  "#54478c",
  "#d81159ff",
  "#8f2d56ff",
  "#cc4400",
  "#000077",
  "#b9e769",
  "#efea5a",
  "#f1c453",
  "#f29e4c",
  "#d66915",
  "#e08e29",
  "#f0c761",
  "#7cc6de",
  "#3890bc",
  "#be1e2dff",
  "#1c489a",
  "#006ba6ff",
  "#471ca8ff",
  "#884ab2ff",
  "#72bad5ff",
  "#03324eff",
  "#ef4043ff",
  "#16db93",
  "#c43240ff",
  "#52ace4",
];

export const districts = [
  "TIRUPATI",
  "SRI SATHYA SAI",
  "PARVATHIPURAM MANYAM",
  "PALNADU",
  "NTR",
  "NANDYAL",
  "KONASEEMA",
  "KAKINADA",
  "ELURU",
  "BAPATLA",
  "ANNAMAYYA",
  "ANAKAPALLI",
  "ALLURI SITHARAMA RAJU",
  "PRAKASAM",
  "CHITTOOR",
  "GUNTUR",
  "VIZIANAGARAM",
  "KRISHNA",
  "KURNOOL",
  "WEST GODAVARI",
  "SPSR NELLORE",
  "SRIKAKULAM",
  "ANANTHAPUR",
  "EAST GODAVARI",
  "VISAKHAPATNAM",
  "YSR KADAPA",
];

export const defaultIssueStatus = [
  {
    text: "Open",
    value: "Open",
  },
  {
    text: "In Progress",
    value: "InProgress",
  },
  {
    text: "Forwarded",
    value: "Forwarded",
  },
  {
    text: "Closed",
    value: "Closed",
  },
];

export const aadhaarIssuesList = [
  { id: "SYNC_OTP_SUBMISSION_ISSUE", label: "SYNC OTP SUBMISSION ISSUE" },
  { id: "SYNC_OTP_ISSUE", label: "SYNC OTP ISSUE" },
  { id: "BIOMETRIC_CAPTURE_ISSUE", label: "BIOMETRIC CAPTURE ISSUE" },
  { id: "SLAP_SCAN_CAPTURE_ISSUE", label: "SLAP SCAN CAPTURE ISSUE" },
  { id: "OFFLINE_UPDATE_ISSUE", label: "OFFLINE UPDATE ISSUE" },
  { id: "OPERATOR_DISCLOSER_ISSUE", label: "OPERATOR DISCLOSER ISSUE" },
  { id: "CAM_PHOTO_CAPTURE_ISSUE", label: "CAM PHOTO CAPTURE ISSUE" },
  { id: "OTP_SUBMISSION_ISSUE", label: "OTP SUBMISSION ISSUE" },
  { id: "APPLICATION_FORM_ISSUE", label: "APPLICATION FORM ISSUE" },
  { id: "GPS_CAPTURE_ISSUE", label: "GPS CAPTURE ISSUE" },
  { id: "ACKNOWLEDGEMENT_ISSUE", label: "ACKNOWLEDGEMENT ISSUE" },
  { id: "ECMP_REPORT_ISSUE", label: "ECMP REPORT ISSUE" },
  { id: "PRINT_ACKNOWLEDGEMENT_ISSUE", label: "PRINT ACKNOWLEDGEMENT ISSUE" },
  { id: "ECMP_D_REGISTER", label: "ECMP D REGISTER" },
  { id: "SERVICE_DOCUMENTS_ISSUE", label: "SERVICE DOCUMENTS ISSUE" },
  { id: "PRINT_AADHAR", label: "PRINT AADHAR" },
  { id: "PACKET_UPLOAD_ISSUES", label: "PACKET UPLOAD ISSUES" },
  {
    id: "MANDATE_BIOMETRIC_CHECK_ISSUE",
    label: "MANDATE BIOMETRIC CHECK ISSUE",
  },
  { id: "L1_ISSUE", label: "L1 ISSUE" },
  { id: "ONLINE_DOCUMENT_UPDATE_ISSUE", label: "ONLINE DOCUMENT UPDATE ISSUE" },
  { id: "L2_ISSUE_PENIDNG", label: "L2 ISSUE PENIDNG" },
  {
    id: "OFFLINE_DOCUMENT_UPDATE_ISSSUE",
    label: "OFFLINE DOCUMENT UPDATE ISSUE",
  },
  { id: "OB_ISSUE", label: "OB ISSUE" },
  { id: "NEW_ENROLEMENT_UPDATE_ISSUE", label: "NEW ENROLEMENT UPDATE ISSUE" },
  { id: "UC_ISSUE", label: "UC ISSUE" },
  { id: "ONLINE_UPDATE_ISSUE", label: "ONLINE UPDATE ISSUE" },
  {
    id: "SINGLE_FINGER_PRINT_DEVICE_ISSUES",
    label: "SINGLE FINGER PRINT DEVICE ISSUES",
  },
  { id: "ISSUES_FM 220_ARATEK", label: "ISSUES FM 220 ARATEK" },
  {
    id: "WINDOWS_TASK_MANAGER_SERVICES",
    label: "WINDOWS TASK MANAGER SERVICES",
  },
  { id: "ECMP_LOGIN_ISSUE", label: "ECMP LOGIN ISSUE" },
  { id: "ECMP_CLINT_VER_ISSUE", label: "ECMP CLINT VER ISSUE" },
  { id: "IRISH_CAPTURE_ISSUE", label: "IRISH CAPTURE ISSUE" },
  { id: "OTHERS", label: "OTHERS" },
];

export const aekStatus = [
  { label: "Open", value: "open" },
  { label: "In Progress", value: "in-progress" },
  { label: "Waiting On User", value: "waiting-on-user" },
  { label: "Forwarded to UIDAI", value: "forward-to-UIDAI" },
  { label: "Closed", value: "closed" },
];

export const nonAkDeviceColumns = [
  {
    title: <DeviceBadge badgeType="working" deviceType="SecurityCerts" />,
    width: 100,
    dataIndex: ["security_certificates", "remaining"],
    render: (remaining) => <span>{remaining ? remaining : 0}</span>,
    sorter: (a, b) =>
      a.security_certificates?.remaining - b.security_certificates?.remaining,
  },
  {
    title: <DeviceBadge badgeType="working" deviceType="Monitor" />,
    dataIndex: ["monitor", "working"],
    width: 100,
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) => a.monitor?.working - b.monitor?.working,
  },
  {
    title: <DeviceBadge badgeType="not-working" deviceType="Monitor" />,
    dataIndex: ["monitor", "not_working"],
    width: 100,
    render: (not_working) => <span>{not_working ? not_working : 0}</span>,
    sorter: (a, b) => a.monitor?.not_working - b.monitor?.not_working,
  },

  {
    title: <DeviceBadge badgeType="working" deviceType="CPU" />,
    width: 100,
    dataIndex: ["cpu", "working"],
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) => a.cpu?.working - b.cpu?.working,
  },
  {
    title: <DeviceBadge badgeType="not-working" deviceType="CPU" />,
    width: 100,
    dataIndex: ["cpu", "not_working"],
    render: (notWorking) => <span>{notWorking ? notWorking : 0}</span>,
    sorter: (a, b) => a.cpu?.not_working - b.cpu?.not_working,
  },
  {
    title: <DeviceBadge badgeType="working" deviceType="UPS" />,
    width: 100,
    dataIndex: ["ups", "working"],
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) => a.ups?.working - b.ups?.working,
  },
  {
    title: <DeviceBadge badgeType="not-working" deviceType="UPS" />,
    width: 100,
    dataIndex: ["ups", "not_working"],
    render: (notWorking) => <span>{notWorking ? notWorking : 0}</span>,
    sorter: (a, b) => a.ups?.not_working - b.ups?.not_working,
  },

  {
    title: <DeviceBadge badgeType="working" deviceType="FPScanner" />,
    width: 100,
    dataIndex: ["fpscanner", "working"],
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) => a.fpscanner?.working - b.fpscanner?.working,
  },
  {
    title: <DeviceBadge badgeType="not-working" deviceType="FPScanner" />,
    width: 100,
    dataIndex: ["fpscanner", "not_working"],
    render: (notWorking) => <span>{notWorking ? notWorking : 0}</span>,
    sorter: (a, b) => a.fpscanner?.not_working - b.fpscanner?.not_working,
  },

  {
    title: <DeviceBadge badgeType="working" deviceType="LnMachine" />,
    width: 100,
    dataIndex: ["lnmachine", "working"],
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) => a.lnmachine?.working - b.lnmachine?.working,
  },
  {
    title: <DeviceBadge badgeType="not-working" deviceType="LnMachine" />,
    width: 100,
    dataIndex: ["lnmachine", "not_working"],
    render: (notWorking) => <span>{notWorking ? notWorking : 0}</span>,
    sorter: (a, b) => a.lnmachine?.not_working - b.lnmachine?.not_working,
  },

  {
    title: <DeviceBadge badgeType="working" deviceType="Mobile" />,
    width: 100,
    dataIndex: ["mobile", "working"],
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) => a.mobile?.working - b.mobile?.working,
  },
  {
    title: <DeviceBadge badgeType="not-working" deviceType="Mobile" />,
    width: 100,
    dataIndex: ["mobile", "not_working"],
    render: (notWorking) => <span>{notWorking ? notWorking : 0}</span>,
    sorter: (a, b) => a.mobile?.not_working - b.mobile?.not_working,
  },

  {
    title: <DeviceBadge badgeType="working" deviceType="Sim" />,
    width: 100,
    dataIndex: ["sim", "working"],
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) => a.sim?.working - b.sim?.working,
  },

  {
    title: <DeviceBadge badgeType="working" deviceType="IrisScanner" />,
    width: 100,
    dataIndex: ["irisscanner", "working"],
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) => a.irisscanner?.working - b.irisscanner?.working,
  },
  {
    title: <DeviceBadge badgeType="not-working" deviceType="IrisScanner" />,
    width: 100,
    dataIndex: ["irisscanner", "not_working"],
    render: (notWorking) => <span>{notWorking ? notWorking : 0}</span>,
    sorter: (a, b) => a.irisscanner?.not_working - b.irisscanner?.not_working,
  },

  {
    title: <DeviceBadge badgeType="working" deviceType="Printer" />,
    width: 100,
    dataIndex: ["printer", "working"],
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) => a.printer?.working - b.printer?.working,
  },
  {
    title: <DeviceBadge badgeType="not-working" deviceType="Printer" />,
    width: 100,
    dataIndex: ["printer", "not_working"],
    render: (notWorking) => <span>{notWorking ? notWorking : 0}</span>,
    sorter: (a, b) => a.printer?.not_working - b.printer?.not_working,
  },
];
export const akDeviceColumns = [
  {
    title: <DeviceBadge badgeType="working" deviceType="Monitor-AK" />,
    dataIndex: ["monitor-ak", "working"],
    width: 100,
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) => a["monitor-ak"]?.working - b["monitor-ak"]?.working,
  },
  {
    title: <DeviceBadge badgeType="not-working" deviceType="Monitor-AK" />,
    dataIndex: ["monitor-ak", "not_working"],
    width: 100,
    render: (not_working) => <span>{not_working ? not_working : 0}</span>,
    sorter: (a, b) =>
      a["monitor-ak"]?.not_working - b["monitor-ak"]?.not_working,
  },

  {
    title: <DeviceBadge badgeType="working" deviceType="Laptop-AK" />,
    dataIndex: ["laptop-ak", "working"],
    width: 100,
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) => a["laptop-ak"]?.working - b["laptop-ak"]?.working,
  },
  {
    title: <DeviceBadge badgeType="not-working" deviceType="Laptop-AK" />,
    dataIndex: ["laptop-ak", "not_working"],
    width: 100,
    render: (not_working) => <span>{not_working ? not_working : 0}</span>,
    sorter: (a, b) => a["laptop-ak"]?.not_working - b["laptop-ak"]?.not_working,
  },

  {
    title: <DeviceBadge badgeType="working" deviceType="Printer-AK" />,
    dataIndex: ["printer-ak", "working"],
    width: 100,
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) => a["printer-ak"]?.working - b["printer-ak"]?.working,
  },
  {
    title: <DeviceBadge badgeType="not-working" deviceType="Printer-AK" />,
    dataIndex: ["printer-ak", "not_working"],
    width: 100,
    render: (not_working) => <span>{not_working ? not_working : 0}</span>,
    sorter: (a, b) =>
      a["printer-ak"]?.not_working - b["printer-ak"]?.not_working,
  },

  {
    title: <DeviceBadge badgeType="working" deviceType="GPSDevice-AK" />,
    dataIndex: ["gpsdevice-ak", "working"],
    width: 100,
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) => a["gpsdevice-ak"]?.working - b["gpsdevice-ak"]?.working,
  },
  {
    title: <DeviceBadge badgeType="not-working" deviceType="GPSDevice-AK" />,
    dataIndex: ["gpsdevice-ak", "not_working"],
    width: 100,
    render: (not_working) => <span>{not_working ? not_working : 0}</span>,
    sorter: (a, b) =>
      a["gpsdevice-ak"]?.not_working - b["gpsdevice-ak"]?.not_working,
  },

  {
    title: <DeviceBadge badgeType="working" deviceType="SlapScanner-AK" />,
    dataIndex: ["slapscanner-ak", "working"],
    width: 100,
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) =>
      a["slapscanner-ak"]?.working - b["slapscanner-ak"]?.working,
  },
  {
    title: <DeviceBadge badgeType="not-working" deviceType="SlapScanner-AK" />,
    dataIndex: ["slapscanner-ak", "not_working"],
    width: 100,
    render: (not_working) => <span>{not_working ? not_working : 0}</span>,
    sorter: (a, b) =>
      a["slapscanner-ak"]?.not_working - b["slapscanner-ak"]?.not_working,
  },

  {
    title: <DeviceBadge badgeType="working" deviceType="IrisScanner-AK" />,
    dataIndex: ["irisscanner-ak", "working"],
    width: 100,
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) =>
      a["irisscanner-ak"]?.working - b["irisscanner-ak"]?.working,
  },
  {
    title: <DeviceBadge badgeType="not-working" deviceType="IrisScanner-AK" />,
    dataIndex: ["irisscanner-ak", "not_working"],
    width: 100,
    render: (not_working) => <span>{not_working ? not_working : 0}</span>,
    sorter: (a, b) =>
      a["irisscanner-ak"]?.not_working - b["irisscanner-ak"]?.not_working,
  },

  {
    title: <DeviceBadge badgeType="working" deviceType="WebCamera-AK" />,
    dataIndex: ["webcamera-ak", "working"],
    width: 100,
    render: (working) => <span>{working ? working : 0}</span>,
    sorter: (a, b) => a["webcamera-ak"]?.working - b["webcamera-ak"]?.working,
  },
  {
    title: <DeviceBadge badgeType="not-working" deviceType="WebCamera-AK" />,
    dataIndex: ["webcamera-ak", "not_working"],
    width: 100,
    render: (not_working) => <span>{not_working ? not_working : 0}</span>,
    sorter: (a, b) =>
      a["webcamera-ak"]?.not_working - b["webcamera-ak"]?.not_working,
  },
];
export const tableColumns = [...nonAkDeviceColumns, ...akDeviceColumns];
export const akColumns = [...akDeviceColumns];

export const tableFilterDistricts = [
  {
    text: "TIRUPATI",
    value: "TIRUPATI",
  },
  {
    text: "SRI SATHYA SAI",
    value: "SRI SATHYA SAI",
  },
  {
    text: "PARVATHIPURAM MANYAM",
    value: "PARVATHIPURAM MANYAM",
  },

  {
    text: "PALNADU",
    value: "PALNADU",
  },
  {
    text: "NTR",
    value: "NTR",
  },
  {
    text: "NANDYAL",
    value: "NANDYAL",
  },

  {
    text: "KONASEEMA",
    value: "KONASEEMA",
  },
  {
    text: "KAKINADA",
    value: "KAKINADA",
  },
  {
    text: "ELURU",
    value: "ELURU",
  },

  {
    text: "BAPATLA",
    value: "BAPATLA",
  },
  {
    text: "ANNAMAYYA",
    value: "ANNAMAYYA",
  },
  {
    text: "ANAKAPALLI",
    value: "ANAKAPALLI",
  },

  {
    text: "ALLURI SITHARAMA RAJU",
    value: "ALLURI SITHARAMA RAJU",
  },

  {
    text: "ANANTHAPUR",
    value: "ANANTHAPUR",
  },
  {
    text: "CHITTOOR",
    value: "CHITTOOR",
  },
  {
    text: "EAST GODAVARI",
    value: "EAST GODAVARI",
  },
  {
    text: "GUNTUR",
    value: "GUNTUR",
  },
  {
    text: "KRISHNA",
    value: "KRISHNA",
  },
  {
    text: "KURNOOL",
    value: "KURNOOL",
  },
  {
    text: "PRAKASAM",
    value: "PRAKASAM",
  },
  {
    text: "SPSR NELLORE",
    value: "SPSR NELLORE",
  },
  {
    text: "SRIKAKULAM",
    value: "SRIKAKULAM",
  },
  {
    text: "VISAKHAPATNAM",
    value: "VISAKHAPATNAM",
  },
  {
    text: "VIZIANAGARAM",
    value: "VIZIANAGARAM",
  },
  {
    text: "WEST GODAVARI",
    value: "WEST GODAVARI",
  },
  {
    text: "YSR KADAPA",
    value: "YSR KADAPA",
  },
];

export const tableFilterDevices = [
  {
    text: "Monitor",
    value: "Monitor",
  },
  {
    text: "CPU",
    value: "CPU",
  },
  {
    text: "UPS",
    value: "UPS",
  },
  {
    text: "Printer",
    value: "Printer",
  },
  {
    text: "Fingerprint Scanner",
    value: "FPScanner",
  },
  {
    text: "Iris Scanner",
    value: "IrisScanner",
  },
  {
    text: "Laminating Machine",
    value: "LnMachine",
  },
  {
    text: "Mobile",
    value: "Mobile",
  },
];

export const akTableFilterDevices = [
  {
    text: "Monitor-AK",
    value: "Monitor-AK",
  },
  {
    text: "Laptop-AK",
    value: "Laptop-AK",
  },
  {
    text: "Printer-AK",
    value: "Printer-AK",
  },
  {
    text: "GPSDevice-AK",
    value: "GPSDevice-AK",
  },
  {
    text: "SlapScanner-AK",
    value: "SlapScanner-AK",
  },
  {
    text: "IrisScanner-AK",
    value: "IrisScanner-AK",
  },
  {
    text: "WebCamera-AK",
    value: "WebCamera-AK",
  },
];

export const stationaryFilterDevices = [
  {
    text: "Secure Based Certificates",
    value: "Secure-Based-Certificates",
  },
  {
    text: "Canon Printer Ink",
    value: "Cannon-Printer-Ink",
  },
  {
    text: "Epson Printer Ink",
    value: "Epson-Printer-Ink",
  },
  {
    text: "Rice Cards",
    value: "Rice-Cards",
  },
  {
    text: "Pension Cards",
    value: "Pension-Cards",
  },
  {
    text: "Laminating Pouch (Rice Card / Pension Card)",
    value: "Laminating-Pouch(Rice-Card&Pension-Card)",
  },
  {
    text: "Laminating Pouch (Aadhar Card)",
    value: "Laminating-Pouch(Aadhar-Card)",
  },
  // {
  //   text: "Laminating Pouch (Arogyasri Card)",
  //   value: "Laminating-Pouch(Arogyasri-Card)",
  // },
];

export const publicKey = `
-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAkxN4UYbm4Cdnaveq09A8
IWKfIhNQdCUEe22zw53Vf4KwlefcgJkCE0jUuB/NKxUTGZw4c7n3pbk36rARb4hC
FSIW7ZmDuHExP046I1L84TaPESwRJoGXxJDwJAJJsDCXda1eTCUqY//QKGA61VgC
CyI2+IMlKzYLMPy2YB+juVJAqcb9wByScEs+MCiX8UAJjGtctLmlPaWyMCQp8Mm8
Uwirrc6zlAdpRll9LS8kpnHLeHlDkKbxo0YtcJGbYtUj9x/Ax+V/qeZQHbL/RxSN
xljBin2xZNDrMK4TVB5/uKIGYERvEXpzMQ3b9DLBXQvdWftS1AbYh7vlwwmy4UXx
zukCwBnOZPYXdIJt6e1PuFaYF7BBQCY9gLhCwkfqsTRc7VCAyQt3VUSBLGK/m7v8
s4nTSZu4Nl+7y6pVtiafvRn2PJqrl97o+hNUPCf8F1FhCdi0079NYfjcZtQv0E1n
VyH2TW1GP6ueYIIYKHcPkhbv5h4pCNkrCU4cvFg/NtDKMReUliRHxN3pcUPuf/m1
jKf9OpDuohXbzVWL0NF/ccuCZWATvmDKGSQDW7xwz0VQI2mH4/5ukKqKSoFmXvFd
n+8XjFRw1xUlA4YJVpoirDhcO7UZsnJCnxUmwFiGlMxUtmWOwBbkqk42VMYObfxM
zAUmSSWoko+Bto2vErPB5ekCAwEAAQ==
-----END PUBLIC KEY-----
`;
export const supportMailId = "support[at]vsws[dot]co[dot]in";
