import React, { useEffect, useState, Fragment } from "react";
import { Row, Col, Table, Skeleton } from "antd";
import { getSimStatistics } from "../../../services/inventory.services";
import Card from "../../../blocks/Card/Card";
import useViewAccess from "../../../hooks/useViewAccess";
function SimStats() {
  const [simStatData, setSimStatData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [networkWiseData, setNetworkWiseData] = useState(null);
  useViewAccess("is_state_executive_authority", "is_admin");
  useEffect(() => {
    setLoading(true);
    getSimStatistics()
      .then((res) => {
        setSimStatData(res.data);
        res.data.network_wise_data.shift();
        setNetworkWiseData(res.data.network_wise_data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const simStatColumn = [
    {
      title: "Network Carrier",
      dataIndex: "network",
      key: "network",
      sorter: (a, b) => a.network.localeCompare(b.network),
    },
    {
      title: "Open",
      dataIndex: "open",
      key: "open",
    },
    {
      title: "Activated",
      dataIndex: "activated",
      key: "activated",
    },
    {
      title: "Pending",
      dataIndex: "pending",
      key: "pending",
    },
    {
      title: "Rejected",
      dataIndex: "rejected",
      key: "rejected",
    },
  ];
  return (
    <Fragment>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[16, 16]} style={{ paddingBottom: "16px" }}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Card title="Open" className="ticket-status">
                {loading ? (
                  <Skeleton active paragraph={{ rows: 1 }} />
                ) : (
                  <p>{simStatData?.total_open}</p>
                )}
              </Card>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Card title="Activated" className="ticket-status">
                {loading ? (
                  <Skeleton active paragraph={{ rows: 1 }} />
                ) : (
                  <p>{simStatData?.total_activated}</p>
                )}
              </Card>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Card title="Pending" className="ticket-status">
                {loading ? (
                  <Skeleton active paragraph={{ rows: 1 }} />
                ) : (
                  <p>{simStatData?.total_pending}</p>
                )}
              </Card>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Card title="Rejected" className="ticket-status">
                {loading ? (
                  <Skeleton active paragraph={{ rows: 1 }} />
                ) : (
                  <p>{simStatData?.total_rejected}</p>
                )}
              </Card>
            </Col>
          </Row>

          {networkWiseData && (
            <Table
              loading={loading}
              dataSource={networkWiseData}
              columns={simStatColumn}
              pagination={false}
            />
          )}
        </Col>
      </Row>
    </Fragment>
  );
}
export default SimStats;
