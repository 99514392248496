import { Avatar, Badge } from "antd";
import { akDevices, devices } from "../../constants/devices";
import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import SecurityIcon from "../../assets/devices/policy.png";
import "./Badges.css";

const DeviceBadge = (props) => {
  const allDevices = { ...devices, ...akDevices };
  let classes = "default-card";
  if (props.className) {
    classes = classes.concat(" ").concat(props.className);
  }

  const deviceType = props.deviceType || "Monitor";
  const badgeType = props.badgeType || "greyed-icon";
  const workingCount =
    props.workingCount !== undefined ? props.workingCount : 0;
  const notWorkingCount = props.notWorkingCount || 0;

  const deviceAvatar = (size) => {
    return (
      <Avatar
        shape="square"
        size={size}
        src={
          deviceType === "SecurityCerts"
            ? SecurityIcon
            : allDevices[deviceType]?.icon
        }
      />
    );
  };

  const deviceAvatarGrey = (size) => {
    return (
      <Avatar
        className="grey-icon"
        shape="square"
        size={size}
        src={allDevices[deviceType]?.icon}
      />
    );
  };

  const getBadge = () => {
    switch (badgeType) {
      case "working":
        return (
          <Badge
            count={
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: "18px" }}
              />
            }
            offset={[0, 10]}
          >
            {deviceAvatar(deviceType.includes("AK") ? 42 : 36)}
          </Badge>
        );
      case "not-working":
        return (
          <Badge
            count={
              <WarningTwoTone
                twoToneColor="#ff6700"
                style={{ fontSize: "18px" }}
              />
            }
            offset={[0, 10]}
          >
            {deviceAvatar(deviceType.includes("AK") ? 42 : 36)}
          </Badge>
        );
      case "count":
        return (
          <Badge count={workingCount} style={{ backgroundColor: "#52c41a" }}>
            <Badge count={notWorkingCount} offset={[0, 30]}>
              {workingCount + notWorkingCount
                ? deviceAvatar(deviceType.includes("AK") ? 42 : 36)
                : deviceAvatarGrey(deviceType.includes("AK") ? 42 : 36)}
            </Badge>
          </Badge>
        );
      case "allCount":
        return deviceAvatar(deviceType.includes("AK") ? 48 : 42);

      default:
        return deviceAvatarGrey(deviceType.includes("AK") ? 42 : 36);
    }
  };

  return getBadge();
};

export default DeviceBadge;
