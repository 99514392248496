import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getItem } from "../helpers/localStorage";
import { USER } from "../constants/defaultKeys";

function useVSAccess(route = true) {
  const history = useHistory();
  const userDetails = JSON.parse(getItem(USER));
  const onlyVSCanAccess =
    userDetails?.is_village_secretariat ||
    userDetails?.is_ward_welfare_data_processing_secretary;

  useEffect(() => {
    if (!onlyVSCanAccess && route) {
      history.push("/");
    }
  }, [onlyVSCanAccess, history, route]);

  return { canVSAccess: onlyVSCanAccess };
}

export default useVSAccess;
