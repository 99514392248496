import React, { useEffect, useState } from "react";
import { Form, Button, InputNumber, message, Divider, Row, Col } from "antd";
import { addMandalWednesdayMeeting } from "../../../services/wednesdayMeeting.services";

const WednesdayMeetingForm = ({ editList, refresh, setVisible }) => {
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  useEffect(() => {
    if (editList?.data) form.setFieldsValue(editList?.data);
  }, [editList]);

  const inputNumberField = (
    <InputNumber
      size="large"
      min={0}
      style={{
        width: 80,
      }}
    />
  );

  const onFinish = (values) => {
    setBtnLoading(true);
    addMandalWednesdayMeeting(editList?.data?.id, values)
      .then((response) => {
        message.success(response.data.message);
        setBtnLoading(false);
        refresh();
        setVisible(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        setBtnLoading(false);
      });
  };

  return (
    <Form
      name="weekly meeting"
      layout="vertical"
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        label="Mobiles Not Working Solved"
        name="mobiles_not_working_redressed_at_mandal_level"
        rules={[
          {
            required: true,
            message: "Please input mobiles not working Solved!",
          },
        ]}
      >
        {inputNumberField}
      </Form.Item>

      <Form.Item
        label="SIMs Not Working Solved"
        name="sims_not_working_redressed_at_mandal_level"
        rules={[
          {
            required: true,
            message: "Please input sims not working Solved!",
          },
        ]}
      >
        {inputNumberField}
      </Form.Item>

      <Form.Item
        label="FP Scanners Not Working Solved"
        name="fp_scanners_not_working_redressed_at_mandal_level"
        rules={[
          {
            required: true,
            message: "Please input fp scanner not working solved!",
          },
        ]}
      >
        {inputNumberField}
      </Form.Item>
      <Form.Item
        label="CFMS ID Issues Solved "
        name="cfms_id_issues_redressed_at_mandal_level"
        rules={[
          {
            required: true,
            message: "Please input fp scanner not working solved!",
          },
        ]}
      >
        {inputNumberField}
      </Form.Item>

      <Form.Item
        label="Salary Issues Solved"
        name="salary_issues_redressed_at_mandal_level"
        rules={[
          {
            required: true,
            message: "Please input fp scanner not working solved!",
          },
        ]}
      >
        {inputNumberField}
      </Form.Item>

      <Form.Item
        label="Other Issues Solved"
        name="other_issues_redressed_at_mandal_level"
        rules={[
          {
            required: true,
            message: "Please input fp scanner not working solved!",
          },
        ]}
      >
        {inputNumberField}
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={btnLoading}
          disabled={btnLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default WednesdayMeetingForm;
