import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Result,
  Button,
  message,
  Space,
  Alert,
  Radio,
  Typography,
} from "antd";
import { devices, akDevices } from "../../constants/devices";
import {
  getVSInventory,
  getStationeryInventory,
} from "../../services/inventory.services";
import { getActivityFeed } from "../../services/activityFeed.services";
import {
  getUserMetaDataApi,
  metaDataApi,
} from "../../services/metaData.services";
import { isMobileOnly } from "react-device-detect";
import Card from "../../blocks/Card/Card";
import InventoryList from "./InventoryList/InventoryList";
import { useParams, useHistory } from "react-router-dom";
import ActivityFeed from "./ActivityFeed/ActivityFeed";
import { USER } from "../../constants/defaultKeys";
import { getItem } from "../../helpers/localStorage";
import RegionBreadCrumb from "../RegionBreadCrumb/RegionBreadCrumb";
import ServiceRequest from "./ServiceRequest/ServiceRequest";
import InventoryFilters from "./InventoryFilters";
import Internet from "./Internet/Internet";
import Certificates from "./Certificates/Certificates";
import DeviceBadges from "./DeviceBadges/DeviceBadges";
import queryString from "query-string";
import WednesdayMettings from "./WednesdayMettings/WednesdayMettings";
import AllCertificateCount from "./Certificates/AllCertificateCount/AllCertificateCount";
import PageHeaders from "./PageHeaders";
import UniformAlert from "./UniformAlert/UniformAlert";
import Marquee from "react-fast-marquee";
import LocationSurvey from "./LocationSurvey";
import LocationSurveySemi from "./LocationSurveySemi";
import ClusterTable from "./Cluster/ClusterTable";
import "./VS.css";
import "../custom.css";
import { InfoCircleTwoTone, PhoneOutlined } from "@ant-design/icons";
import FunctionaryDevices from "./FunctionaryDevices/FunctionaryDevices";
import TabTicketTable from "./TabTicket/TabTicketTable";
import ExamTab from "./ExamTab";
import InternetConnection from "./InternetConnection";
import KitStatusTab from "./KitsStatusTab";
import DAWedps from "../Amc/DAWedps/DAWedps";
import { isUserFromNTRorASR, timeAgo } from "../../helpers/utility";
import VolunteerInventoryTracking from "./VolunteerInventoryTracking/VolunteerInventoryTracking";
import moment from "moment";
import SimCardMapping from "./SimCardMapping/SimCardMapping";
import useViewAccess from "../../hooks/useViewAccess";
const VS = () => {
  const query = useParams();
  const history = useHistory();
  const urlQueryParams = history?.location?.search;
  let param = queryString.parse(urlQueryParams);
  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const userDetails = JSON.parse(getItem(USER));
  const [hasPermission, setHasPermission] = useState(false);
  const [activityFeedData, setActivityFeedData] = useState([]);
  const [inventoryFilterData, setInventoryFilterData] = useState(null);
  const [stationery, setStationery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeDeviceCategory, setActiveDeviceCategory] = useState("volunteer");
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isKitDraweVisible, setIsKitDraweVisible] = useState(false);
  const [isNetDrawerVisible, setNetDrawerVisible] = useState(false);
  const onlyVSCanAccess =
    userDetails?.is_village_secretariat ||
    userDetails?.is_ward_welfare_data_processing_secretary;

  useViewAccess(
    "is_village_secretariat",
    "is_ward_welfare_data_processing_secretary",
    "is_state_executive_authority",
    "is_admin"
  );
  useEffect(() => {
    if (query) {
      setLoading(true);
      getVSInventory(query.id)
        .then((response) => {
          setData(response?.data);
          setLoading(false);
        })
        .catch((error) => console.log(error));

      getStationeryInventory(query.id)
        .then((response) => {
          setStationery(response?.data);
        })
        .catch((error) => console.log(error));

      getActivityFeed(query.id)
        .then((response) => {
          setActivityFeedData(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });

      metaDataApi()
        .then((response) => {
          userAccess(response?.data);
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });

      getUserMetaDataApi()
        .then((res) => {
          console.log("RES--->", res);
          // console
          // setItem(USER, res.data.);
        })
        .catch((err) => {});
    }
  }, [query, refresh]);

  function renderLocalityDetailsForm() {
    if (userDetails?.is_village_secretariat) {
      if (userDetails?.first_time_details_filled) {
        return <LocationSurveySemi id={query?.id} />;
      } else {
        return <LocationSurvey id={query?.id} />;
      }
    }
  }

  function userAccess(metaData) {
    if (userDetails.is_village_secretariat) {
      //village secretariat
      const access = metaData?.regions.filter(
        (village) => village.uid == query?.id
      );
      setHasPermission(access?.length !== 0 ? true : false);
    }

    //mandal authority
    if (userDetails.is_mandal_authority) {
      const access = metaData?.regions[0]?.village_secretariats.filter(
        (village) => village.uid == query?.id
      );
      setHasPermission(access.length !== 0 ? true : false);
    }

    //district authority
    if (userDetails.is_district_authority || userDetails.is_indent_authority) {
      const access = metaData?.regions[0]?.mandals?.filter((mandal) =>
        mandal?.village_secretariats?.find(
          (village) => village.uid == query?.id
        )
      );
      setHasPermission(access.length !== 0 ? true : false);
    }

    //admin
    if (userDetails.is_admin) {
      setHasPermission(true);
    }
  }

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const serviceRequests = () => {
    history.push(
      `${history.location.pathname}?tab=service-requests&&innerTab=${
        userDetails?.region?.is_aadhar_kit_enable ? "aek" : "reopen_ticket"
      }`
    );
  };
  const tabs = () => {
    history.push(`${history.location.pathname}?tab=raise-tab-ticket`);
  };

  const wednesdayMettingClick = () => {
    history.push(`${history.location.pathname}?tab=wednesday-meeting`);
  };

  const mapVolunteer = () => {
    history.push(`${history.location.pathname}?tab=map-volunteer-devices`);
  };

  const simCardMapping = () => {
    history.push(`${history.location.pathname}?tab=sim-card-mapping`);
  };
  const volunteerInventory = () => {
    history.push(
      `${history.location.pathname}?tab=volunteer-inventory-collection`
    );
  };
  const amc = () => {
    history.push(`${history.location.pathname}?tab=amc`);
  };

  const inventoryList = (
    <InventoryList
      id={query.id}
      vsData={inventoryFilterData ? inventoryFilterData : data?.inventory_data}
      isAadharSecretariat={data?.aadhar_kits_supplied}
      refresh={refreshPage}
      hasPermission={hasPermission}
    />
  );

  const inventoryFilter = (
    <InventoryFilters
      devices={
        data?.aadhar_kits_supplied ? { ...devices, ...akDevices } : devices
      }
      data={data?.inventory_data}
      setInventoryFilterData={setInventoryFilterData}
      id={query?.id}
    />
  );

  return (
    <Fragment>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 22, offset: 1 }}
          lg={{ span: 22, offset: 1 }}
        >
          {JSON?.parse(getItem(USER))?.last_login && (
            <Typography style={{ marginBottom: "10px" }}>
              Last Logged In -{" "}
              {moment(JSON.parse(getItem(USER))?.last_login)?.format(
                "DD/MM/YYYY HH:mm:ss"
              )}
            </Typography>
          )}
          {Object.keys(param).length === 0 && (
            <>
              {hasPermission && (
                <Alert
                  type="warning"
                  closable
                  style={{ margin: "20px", opacity: 0.8 }}
                  message={
                    <Marquee pauseOnHover gradient={false}>
                      <span style={{ paddingRight: "5px" }}>
                        <InfoCircleTwoTone />
                      </span>
                      For mobile Annual Maintenance Contract (service/repair)
                      coverage details please
                      <a
                        href="https://resources.vsws.co.in/Mobile-AMC-details.PDF"
                        target="blank"
                      >
                        <span style={{ paddingLeft: "6px" }}>click here.</span>
                      </a>
                    </Marquee>
                  }
                />
              )}
            </>
          )}
        </Col>
      </Row>

      <Row gutter={[16, 6]}>
        {data?.is_uniform_modal && (
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 22, offset: 1 }}
          >
            {!loading && <UniformAlert id={query?.id} refresh={refreshPage} />}
            <br />
          </Col>
        )}
        <Col
          xs={{ span: 0, offset: 0 }}
          sm={{ span: 0, offset: 0 }}
          md={{ span: 10, offset: 0 }}
          lg={{ span: 12, offset: 1 }}
        >
          <RegionBreadCrumb bcrdata={data} />
          {param?.tab === "map-volunteer-devices" && (
            <PageHeaders title="Map Devices" />
          )}
          {param?.tab === "wednesday-meeting" && (
            <PageHeaders title="Wednesday Meetings" />
          )}
          {param?.tab === "raise-tab-ticket" && (
            <PageHeaders title="Raise Ticket For Tabs" />
          )}
          {param?.tab === "sim-card-mapping" && (
            <PageHeaders title="Sim Card Mapping" />
          )}
        </Col>

        <Col
          xs={{ span: 0 }}
          sm={{ span: 0 }}
          md={{ span: 6 }}
          lg={{ span: 10 }}
          style={{ textAlign: "right" }}
        >
          {Object.keys(param).length === 0 && (
            <>
              {hasPermission && onlyVSCanAccess && (
                <>
                  <Space>
                    {/* <Button type="primary" onClick={mapVolunteer}>
                      Map Devices
                    </Button> */}

                    <Button type="primary" onClick={wednesdayMettingClick}>
                      Wednesday Meetings
                    </Button>
                    <Button type="primary" onClick={serviceRequests}>
                      Service Requests
                    </Button>

                    {!userDetails?.region?.is_rural && (
                      <Button type="primary" onClick={tabs}>
                        Samsung Tabs
                      </Button>
                    )}
                  </Space>

                  <Space style={{ marginTop: "10px" }}>
                    {userDetails?.region?.is_aadhar_kit_enable && (
                      <Button
                        type="primary"
                        onClick={() => setIsKitDraweVisible(true)}
                      >
                        Kit Status
                      </Button>
                    )}
                    <Button
                      type="primary"
                      onClick={() => setNetDrawerVisible(true)}
                      // style={{ marginRight: "10px", marginLeft: "10px" }}
                    >
                      Internet Status
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => setIsDrawerVisible(true)}
                    >
                      NSEIT Status
                    </Button>

                    <Button type="primary" onClick={volunteerInventory}>
                      Volunteer Inventory Collection
                    </Button>

                    {/* {isUserFromNTRorASR() && (
                      <Button type="primary" onClick={amc}>
                        Mobile services
                      </Button>
                    )} */}
                  </Space>
                  {(userDetails?.is_village_secretariat ||
                    userDetails?.Is_ward_welfare_data_processing_secretary) && (
                    <Space style={{ marginTop: "10px", marginLeft: "10px" }}>
                      <Button type="primary" onClick={simCardMapping}>
                        Sim Card Mapping
                      </Button>
                    </Space>
                  )}
                </>
              )}
            </>
          )}
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 22, offset: 1 }}
          lg={{ span: 22, offset: 1 }}
        >
          {param?.tab === "service-requests" && (
            <Row gutter={[16, 16]}>
              <Col span={10}>
                <PageHeaders title="Service Requests" />
              </Col>
              <Col
                span={14}
                style={{
                  textAlign: "right",
                  paddingTop: "15px",
                }}
              >
                {userDetails?.is_village_secretariat && (
                  <span>
                    <Space>
                      For any critical issues, please contact your district
                      inventory manager
                      <PhoneOutlined rotate={105} />
                    </Space>
                    {userDetails?.inventory_manager_contact[0]}
                  </span>
                )}
              </Col>
            </Row>
          )}
        </Col>
        {query && query.id !== "null" ? (
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 22, offset: 1 }}
          >
            {param?.tab === "inventory" && (
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <div style={{ textAlign: "right", padding: "10px 20px" }}>
                    {inventoryFilter}
                  </div>
                  {inventoryList}
                </Col>
              </Row>
            )}
            {Object.keys(param).length === 0 && (
              <Row gutter={[16, 16]}>
                {userDetails?.is_village_secretariat &&
                  hasPermission &&
                  userDetails?.is_location_survey_added &&
                  !stationery?.is_stationery_added && (
                    <Col span={24}>
                      {
                        <AllCertificateCount
                          data={stationery}
                          query={query}
                          refresh={refreshPage}
                        />
                      }
                    </Col>
                  )}
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <DeviceBadges
                    hasPermission={hasPermission}
                    data={data}
                    userDetails={userDetails}
                    query={query}
                    refresh={refreshPage}
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 3 }}
                  lg={{ span: 3 }}
                >
                  <Internet
                    hasPermission={hasPermission}
                    data={data}
                    userDetails={userDetails}
                    query={query}
                    refresh={refreshPage}
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 9 }}
                  lg={{ span: 9 }}
                >
                  <Certificates
                    hasPermission={hasPermission}
                    data={stationery}
                    userDetails={userDetails}
                    query={query}
                    refresh={refreshPage}
                  />
                </Col>
                {!isMobileOnly && (
                  <>
                    <Col span={12}>
                      <Card
                        title="Device List"
                        className="inventory-card"
                        extra={inventoryFilter}
                      >
                        {inventoryList}
                      </Card>
                    </Col>
                    <Col span={12}>
                      <ActivityFeed
                        data={activityFeedData && activityFeedData}
                      />
                    </Col>
                  </>
                )}
              </Row>
            )}
          </Col>
        ) : (
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 20, offset: 2 }}
            lg={{ span: 18, offset: 3 }}
          >
            <Result title="Your are not part of any village, please contact your admin" />
          </Col>
        )}

        {param?.tab === "service-requests" && (
          <Col span={24}>
            <ServiceRequest data={userDetails} />
          </Col>
        )}
        {param?.tab === "wednesday-meeting" && (
          <Col span={24}>
            <WednesdayMettings />
          </Col>
        )}
        {param?.tab === "raise-tab-ticket" && (
          <Col span={24}>
            <TabTicketTable />
          </Col>
        )}
        {param?.tab === "volunteer-inventory-collection" && (
          <Col span={24}>
            <VolunteerInventoryTracking userDetails={userDetails} />
          </Col>
        )}

        {/* {param?.tab === "amc" && isUserFromNTRorASR() && (
          <Col span={24}>
            <DAWedps />
          </Col>
        )} */}

        {param?.tab === "map-volunteer-devices" && (
          <>
            <Col span={22} offset={1} style={{ textAlign: "right" }}>
              <Radio.Group
                buttonStyle="solid"
                value={activeDeviceCategory}
                onChange={(e) => setActiveDeviceCategory(e.target.value)}
              >
                <Radio.Button value="volunteer">Volunteer devices</Radio.Button>

                <Radio.Button value="functionary">
                  Functionary devices
                </Radio.Button>
              </Radio.Group>
            </Col>

            <Col span={24}>
              {activeDeviceCategory === "volunteer" ? (
                <ClusterTable id={query.id} activeTab={activeDeviceCategory} />
              ) : (
                <FunctionaryDevices />
              )}
            </Col>
          </>
        )}

        {param?.tab === "sim-card-mapping" && (
          <Col span={22} offset={1}>
            <SimCardMapping />
          </Col>
        )}
        {isDrawerVisible && (
          <ExamTab
            isDrawerVisible={isDrawerVisible}
            setIsDrawerVisible={setIsDrawerVisible}
          />
        )}

        {isKitDraweVisible && (
          <KitStatusTab
            isKitDraweVisible={isKitDraweVisible}
            setIsKitDraweVisible={setIsKitDraweVisible}
          />
        )}
        {isNetDrawerVisible && (
          <InternetConnection
            isNetDrawerVisible={isNetDrawerVisible}
            setNetDrawerVisible={setNetDrawerVisible}
          />
        )}
      </Row>
    </Fragment>
  );
};
export default VS;
