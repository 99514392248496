import { Fragment } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import Card from "../../blocks/Card/Card";
import { useParams, useHistory } from "react-router-dom";
import Logo from "../../assets/images/Editedlogo.png";
import { isMobileOnly } from "react-device-detect";
import { setPassword } from "../../services/auth.services";
import { JSEncrypt } from "jsencrypt";
import { publicKey } from "../../constants/defaultKeys";
import { decryptToken } from "../../helpers/localStorage";
const SetPassword = () => {
  const query = useParams();
  const history = useHistory();
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);

  const onFinish = (values) => {
    const data = {
      ...values,
      token: query && decryptToken(query.token),
      new_password: encrypt.encrypt(values?.new_password),
      new_password_confirm: encrypt.encrypt(values?.new_password_confirm),
    };
    setPassword(data)
      .then((response) => {
        message.success(response.data.message);
        history.push("/");
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        console.error(error);
      });
  };

  return (
    <Fragment>
      <Row type="flex" justify="center" align="middle" className="login">
        <Col
          lg={{ span: 8, offset: 8 }}
          md={{ span: 12, offset: 6 }}
          sm={{ span: 20, offset: 2 }}
          xs={{ span: 20, offset: 2 }}
          style={{ marginLeft: 0 }}
        >
          <Card bordered={false}>
            <div style={{ textAlign: "center", paddingBottom: "20px" }}>
              {isMobileOnly ? (
                <img src={Logo} alt="check logo" height="48px" />
              ) : (
                <img src={Logo} alt="check logo" height="72px" />
              )}
            </div>
            <Form name="setpassword" onFinish={onFinish}>
              <Form.Item
                name="new_password"
                rules={[
                  {
                    required: true,
                    message:
                      "Use 8 or more characters with a mix of uppercase and lowercase letters, numbers, and symbols.",
                    pattern: new RegExp(
                      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                    ),
                  },
                ]}
              >
                <Input.Password
                  autoFocus={true}
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="New Password"
                  size="large"
                  type="password"
                />
              </Form.Item>
              <Form.Item
                name="new_password_confirm"
                rules={[
                  { required: true, message: "Please input your Password!" },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The new passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Confirm Password"
                  size="large"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  className="login-form-button"
                >
                  <span>Submit</span>
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
export default SetPassword;
