import React, { Fragment } from "react";
import {
  Row,
  Col,
  Badge,
  Space,
  Select,
  Menu,
  Dropdown,
  Typography,
  message,
} from "antd";
import MenuSearch from "./MenuSearch";
import UserAvatar from "./UserAvatar";
import { useHistory } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import Logo from "../../Logo/Logo";
import MobileLogo from "../../Logo/MobileLogo";
import { USER } from "../../constants/defaultKeys";
import { getItem } from "../../helpers/localStorage";
import {
  HomeOutlined,
  BulbOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import "./Toolbar.css";

import {
  downloadAekExamRecords,
  downloadAekKitUppdateRecords,
  downloadInternetConnection,
  downloadApsflInternetIssues,
} from "../../services/downloadFile.services";
import { isUserFromNTRorASR } from "../../helpers/utility";

const Toolbar = () => {
  const history = useHistory();
  const userDetails = JSON.parse(getItem(USER));

  const redirectURL = () => {
    if (
      userDetails.region === null &&
      !(
        userDetails.is_vendor ||
        userDetails?.is_aek_hod_dept ||
        userDetails?.is_aek_hod ||
        userDetails?.is_aek_hod ||
        userDetails?.is_tabs_admin ||
        userDetails?.is_uidai ||
        userDetails?.is_service_center_representative
      )
    ) {
      history.push(`/state/dashboard`);
    } else {
      if (userDetails.is_service_center_representative) {
        history.push("/state/dashboard");
        return;
      }
      if (userDetails.is_amc_district_incharge) {
        history.push("/state/dashboard");
        return;
      }
      if (userDetails.is_village_secretariat)
        history.push(
          `/village-secretariat/${userDetails.region.region_name}/${userDetails.region.region_id}/`
        );
      if (userDetails.is_mandal_authority)
        history.push(
          `/mandal/${userDetails.region.region_name}/${userDetails.region.region_id}/`
        );
      if (
        userDetails?.is_welfare_and_edu_assistant
        // || userDetails?.is_ward_welfare_data_processing_secretary
      )
        history.push(`/raise-tab-ticket/${userDetails.region.region_id}`);
      if (userDetails.is_district_authority || userDetails.is_indent_authority)
        history.push(
          `/district/${userDetails.region.region_name}/${userDetails.region.region_id}/`
        );
      if (userDetails.is_admin) history.push(`/state/dashboard`);
      if (userDetails.is_vendor) history.push(`/vendor`);
      if (
        userDetails?.is_aek_hod_dept ||
        userDetails?.is_aek_dc ||
        userDetails?.is_aek_hod ||
        userDetails?.is_uidai
      )
        history.push(`/aek-vendor`);
      if (userDetails?.is_tabs_admin) history.push(`/tabs/aggregate`);
      if (userDetails.is_telecom_operator) history.push(`/sim-requests`);
      if (userDetails.is_uniform_supplier) history.push(`/uniform-vendor`);
      if (userDetails.is_office365_vendor) history.push(`/office-365`);
      if (userDetails?.is_service_center_representative)
        history.push(`/state/service-center-amc-tickets`);
    }
  };

  function openInstructions() {
    let win = window.open("/instructions/overview", "_blank");
    win.focus();
  }
  const aekDownload = () => {
    downloadAekExamRecords()
      .then((res) => {
        let blob = res.data;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "NSEIT-Exam.xlsx";
        a.click();
        message.success("File Downloaded Successfully");
      })
      .catch((err) => console.log(err));
  };

  const kitDownload = () => {
    downloadAekKitUppdateRecords()
      .then((response) => {
        let blob = response.data;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "KIT-Status.xlsx";
        a.click();
        message.success("File Downloaded Successfully");
      })
      .catch((err) => console.log(err));
  };
  const internetDownload = () => {
    downloadInternetConnection()
      .then((response) => {
        let blob = response.data;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "Internet-Connectivity-Status.xlsx";
        a.click();
        message.success("File Downloaded Successfully");
      })
      .catch((err) => console.log(err));
  };
  const apsflTicketsDownload = () => {
    downloadApsflInternetIssues()
      .then((response) => {
        let blob = response.data;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "APSFL-TICKETS-ISSUES.xlsx";
        a.click();
        message.success("File Downloaded Successfully");
      })
      .catch((err) => console.log(err));
  };
  const menu = (
    <Menu>
      {" "}
      <Menu.Item onClick={aekDownload} key="nseit" icon={<DownloadOutlined />}>
        NSEIT Exam
      </Menu.Item>
      <Menu.Item onClick={kitDownload} key="kit" icon={<DownloadOutlined />}>
        Kit Status
      </Menu.Item>
      <Menu.Item
        onClick={internetDownload}
        key="internet"
        icon={<DownloadOutlined />}
      >
        Internet Connectivity
      </Menu.Item>
      <Menu.Item
        onClick={apsflTicketsDownload}
        key="apsfl"
        icon={<DownloadOutlined />}
      >
        APSFL Tickets
      </Menu.Item>
    </Menu>
  );
  return (
    <Fragment>
      <Row>
        <Col xs={3} sm={3} md={6} lg={6} xl={6}>
          <div className="menu-logo" onClick={redirectURL}>
            {isMobileOnly ? <MobileLogo /> : <Logo />}
          </div>
        </Col>
        <Col
          xs={18}
          sm={14}
          md={6}
          lg={7}
          xl={9}
          // style={{ marginTop: "-10px" }}
        >
          {!(
            (
              userDetails?.is_telecom_operator ||
              userDetails?.is_office365_vendor ||
              userDetails?.is_uniform_supplier ||
              userDetails?.is_internet_vendor ||
              userDetails?.is_welfare_and_edu_assistant ||
              userDetails?.is_tabs_admin ||
              userDetails?.is_uidai ||
              userDetails?.is_service_center_representative
            )
            // userDetails?.is_ward_welfare_data_processing_secretary
          ) && <MenuSearch userDetails={userDetails} />}
        </Col>
        <Col
          xs={0}
          sm={0}
          md={2}
          lg={2}
          xl={2}
          className="home"
          // style={{ textAlign: "end" }}
        >
          <span onClick={redirectURL}>
            {!userDetails?.is_tabs_admin &&
              !userDetails?.is_service_center_representative && (
                <>
                  <HomeOutlined /> Home
                </>
              )}
          </span>
        </Col>

        <Col xs={0} sm={0} md={4} lg={4} xl={4} className="home">
          {userDetails?.is_village_secretariat && (
            <>
              <span
                onClick={() =>
                  history.push(
                    `/village-secretariat/${userDetails?.region?.region_name}/${userDetails?.region?.region_id}/tabs_inspection`
                  )
                }
              >
                <span style={{ color: "#fff", marginRight: "10px" }}>
                  School Visits
                </span>
              </span>
            </>
          )}
          <Space size="large">
            {(userDetails?.is_aek_hod ||
              userDetails?.is_aek_hod_dept ||
              userDetails?.is_aek_dc) && (
              <>
                <span
                  style={{ color: "#fff" }}
                  onClick={() => history.push("/state/inventory")}
                >
                  Inventory
                </span>
                <span
                  style={{ color: "#fff" }}
                  onClick={() =>
                    history.push(
                      userDetails?.is_aek_dc
                        ? `/district/${userDetails?.region?.region_name}/${userDetails?.region?.region_id}/?tab=tickets`
                        : "/state/tickets"
                    )
                  }
                >
                  Tickets
                </span>
              </>
            )}
            {!(
              userDetails?.is_uidai ||
              userDetails?.is_aek_hod ||
              userDetails?.is_aek_hod_dept ||
              userDetails?.is_aek_dc ||
              userDetails?.is_service_center_representative
            ) && (
              <span onClick={() => history.push("/tabs/menuaggregate")}>
                Tabs
              </span>
            )}
            {!userDetails?.is_service_center_representative && (
              <Dropdown overlay={menu} trigger={["click"]}>
                <span style={{ color: "#fff" }}>Reports</span>
              </Dropdown>
            )}
          </Space>
        </Col>
        <Col xs={0} sm={0} md={1} lg={2} xl={2} className="home">
          {!(
            userDetails?.is_welfare_and_edu_assistant ||
            userDetails?.is_tabs_admin ||
            userDetails?.is_uidai
          ) && (
            <span onClick={openInstructions}>
              <BulbOutlined className="home" />
            </span>
          )}
        </Col>

        <Col xs={3} sm={3} md={1} lg={1} xl={1} className="menu-dropdown">
          <UserAvatar
            user={userDetails}
            wea={userDetails?.is_welfare_and_edu_assistant}
          />
        </Col>
      </Row>
    </Fragment>
  );
};
export default Toolbar;
