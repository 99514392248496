import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Typography,
  message,
  Divider,
  InputNumber,
  Modal,
  Result,
} from "antd";
import Card from "../../blocks/Card/Card";
import { useHistory, Link } from "react-router-dom";
import Logo from "../../assets/images/Editedlogo.png";
import { getItem, setItem, clear } from "../../helpers/localStorage";
import {
  USER,
  TOKEN_KEY,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  publicKey,
} from "../../constants/defaultKeys";
import {
  verifyOtp,
  resendOtp,
  refreshEmailCaptchaAPI,
  getEmailCaptchaAPI,
} from "../../services/auth.services";
import { isMobileOnly } from "react-device-detect";
import { SyncOutlined } from "@ant-design/icons";
import JSEncrypt from "jsencrypt";
const { Title, Paragraph } = Typography;

let verified = 0;
const Mfa = () => {
  let userDetails = JSON.parse(getItem(USER));
  const [form] = Form.useForm();
  const history = useHistory();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [token, setToken] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  // const [captchaVal, setCaptchaVal] = useState(userDetails?.captcha);
  const [limitExceed, setlimitExceed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [captchaVal, setCaptchaVal] = useState(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  const formRef = useRef();
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  useEffect(() => {
    verified = 0;
    setMobileNumber(userDetails && userDetails.contact_number);
    setToken(getItem(TOKEN_KEY));
  }, []);

  useEffect(() => {
    if (captchaVal) {
      var captchaImg = document.getElementById("source");
      var image = new Image();
      image.src = captchaImg.innerHTML || "";
      image.setAttribute("id", `captcha0`);
      insertAfter(captchaImg, image);
    }
  }, [captchaVal]);

  function insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }
  async function refreshCaptcha() {
    try {
      const res = await refreshEmailCaptchaAPI({
        token: captchaToken,
      });
      document.getElementById(`captcha0`).remove();
      setCaptchaVal(res.data.captcha);
    } catch (err) {
      console.log(err);
    }
  }
  const onFinish = (values) => {
    verified += 1;
    setButtonLoading(true);
    const payload = {
      token: token,
      otp: encrypt.encrypt(values.otp),
      is_logout: showModal ? showModal : undefined,
      captcha_token: captchaToken,
      captcha_code: values?.captcha,
    };
    verifyOtp(payload)
      .then((response) => {
        setButtonLoading(false);
        clear();
        const data = response.data.payload;
        setItem(ACCESS_TOKEN, data.access_token);
        setItem(REFRESH_TOKEN, data.refresh_token);
        setItem(USER, response.data.encrypted_data);
        setItem("encrypted_data", response.data.encrypted_data);
        if (data.is_set_profile_completed) {
          if (
            data.region === null &&
            !(
              data.is_vendor ||
              data?.is_aek_hod_dept ||
              data?.is_aek_hod ||
              data?.is_tabs_admin ||
              data?.is_uidai
            )
          ) {
            history.push(`/state/dashboard`);
          } else {
            if (data.is_village_secretariat) {
              history.push(
                `/village-secretariat/${data.region.region_name}/${data.region.region_id}/`
              );
            }
            if (data.is_mandal_authority)
              history.push(
                `/mandal/${data.region.region_name}/${data.region.region_id}/`
              );
            if (data.is_district_authority || data.is_indent_authority)
              history.push(
                `/district/${data.region.region_name}/${data.region.region_id}/`
              );
            if (
              data?.is_welfare_and_edu_assistant
              // || data?.is_ward_welfare_data_processing_secretary
            )
              history.push(`/raise-tab-ticket/${data.region.region_id}`);
            if (
              data?.is_aek_hod_dept ||
              data?.is_aek_dc ||
              data?.is_aek_hod ||
              data?.is_uidai
            )
              history.push(`/aek-vendor`);
            if (data?.is_tabs_admin) history.push(`/tabs/aggregate`);
            if (data.is_admin) history.push(`/state/dashboard`);
            if (data.is_vendor) history.push(`/vendor`);
            if (data.is_telecom_operator) history.push(`/sim-requests`);
            if (data.is_uniform_supplier) history.push(`/uniform-vendor`);
            if (data.is_office365_vendor) history.push(`/office-365`);
          }
        } else {
          history.push("/set-profile");
        }
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.code === "user_already_loggedin"
        ) {
          setShowModal(true);
          return;
        }
        setButtonLoading(false);
        form.resetFields(["otp"]);
        message.error(error?.response?.data?.message);
        console.log(error);
        if (error?.response?.data?.limit_exceeded) {
          setlimitExceed(true);
        }
      });
  };

  const resendCode = () => {
    resendOtp({ token: token })
      .then((response) => {
        const data = response.data;
        setToken(data.token);
        setItem(TOKEN_KEY, data.token);
        setItem(USER, JSON.stringify(data));
        message.success(data.message);
        form.resetFields();
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        console.log(error);
      });
  };

  useEffect(() => {
    getEmailCaptchaAPI()
      .then((res) => {
        console.log(res);
        setCaptchaVal(res.data.captcha);
        setCaptchaToken(res.data.token);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Fragment>
      <Row type="flex" justify="center" align="middle" className="login">
        <Col
          lg={{ span: 8, offset: 8 }}
          md={{ span: 12, offset: 6 }}
          sm={{ span: 20, offset: 2 }}
          xs={{ span: 20, offset: 2 }}
          style={{ marginLeft: 0 }}
        >
          <Card bordered={false} style={{ textAlign: "left" }}>
            <div style={{ textAlign: "center", paddingBottom: "20px" }}>
              {isMobileOnly ? (
                <img src={Logo} alt="check logo" height="48px" />
              ) : (
                <img src={Logo} alt="check logo" height="72px" />
              )}
            </div>

            {!limitExceed ? (
              <>
                <div style={{ textAlign: "center" }}>
                  <Title level={5} style={{ color: "#666" }}>
                    {" "}
                    Enter 6-digit OTP sent to {mobileNumber && mobileNumber}
                  </Title>
                </div>

                <Form
                  name="basic"
                  form={form}
                  ref={formRef}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: "Please input otp!",
                      },
                    ]}
                    help="OTP is valid for 15 mins"
                  >
                    <Input
                      autoFocus={true}
                      size="large"
                      placeholder="OTP"
                      maxLength="6"
                      autoComplete="off"
                    />
                  </Form.Item>
                  <div>
                    <span id="source" style={{ display: "none" }}>
                      {"data:image/png;base64," + captchaVal}
                    </span>
                    <SyncOutlined
                      style={{ fontSize: "22px", marginLeft: "12px" }}
                      onClick={refreshCaptcha}
                    />
                  </div>
                  <Form.Item
                    rules={[{ required: true, message: "Required" }]}
                    name="captcha"
                  >
                    <Input
                      placeholder="Submit captcha"
                      style={{ padding: "10px", marginTop: "5px" }}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      block
                      className="login-form-button"
                      loading={buttonLoading}
                      disabled={verified > 4}
                    >
                      {verified > 4 ? (
                        <span style={{ color: "red" }}>
                          Exceeded OTP verification limit, try login again.
                        </span>
                      ) : (
                        <span>Verify</span>
                      )}
                    </Button>
                  </Form.Item>
                </Form>
                <div style={{ textAlign: "center" }}>
                  <span>Haven't received OTP yet? </span>
                  <span
                    onClick={resendCode}
                    className="footer-links"
                    style={{ color: "#41A8F7" }}
                  >
                    Resend OTP
                  </span>
                </div>
              </>
            ) : (
              <Paragraph>
                You've exceeded maximum number of OTP Retries. Please return to{" "}
                <Link to="/">login page</Link> and try again after a few mins.
                Thank you!
              </Paragraph>
            )}
            <Divider>Or</Divider>
            <div style={{ textAlign: "center" }}>
              <Link to="/" className="footer-links">
                Back to login
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
      {showModal && (
        <Modal open={showModal} footer={false} closable={false}>
          <Result
            status="warning"
            title={
              <p>
                {
                  "Oops! It seems you're already logged in from another device. Please log out from the other device before attempting to log in again."
                }
              </p>
            }
            extra={
              <>
                <Button
                  type="default"
                  onClick={() => {
                    setShowModal(false);
                    setButtonLoading(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    formRef?.current?.submit();
                  }}
                >
                  Continue
                </Button>
              </>
            }
          />
        </Modal>
      )}
    </Fragment>
  );
};
export default Mfa;
