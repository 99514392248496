import axiosInstance from "../config/axios/axios.config";

export function getTicketListAPI(params) {
  return axiosInstance.get(`/amc/fetch-tickets-list/`, {
    params,
  });
}

export function getServiceCenterAndServicesAPI() {
  return axiosInstance.get("/amc/fetch-service-center-services");
}

export function raiseATicketAPI(payload) {
  return axiosInstance.post("/amc/create-amc-ticket/", payload);
}

export function searchIMEINumbersAPI(imeinum) {
  return axiosInstance.get(`/amc/search-imei-numbers/?query=${imeinum}`);
}

export function takeTicketActionAPI(ticketId, payload) {
  return axiosInstance.put(
    `/amc/update-amc-ticket/indent-action/${ticketId}/`,
    payload
  );
}

export function serviceCenterCostEstimationAPI(ticketId, payload) {
  return axiosInstance.put(
    `/amc/update-amc-ticket/sc-estimation/${ticketId}/`,
    payload
  );
}

export function verifyIMEINumberAPI(ticketId, payload) {
  return axiosInstance.put(
    `/amc/update-amc-ticket/sc-imei-verify/${ticketId}/`,
    payload
  );
}

export function repairDoneAtServiceCenterAPI(ticketId, payload) {
  return axiosInstance.put(
    `/amc/update-amc-ticket/sc-ticket-done/${ticketId}/`,
    payload
  );
}

export function markBillAsPaidByDistrcitINCAPI(ticketId, payload) {
  return axiosInstance.put(
    `/amc/update-amc-ticket/incharge-action/${ticketId}/`,
    payload
  );
}
// List tickets: /api/amc/fetch-tickets-list/, for all roles
// List service centers alogn with services: /api/amc/amc/fetch-service-center-services
// Create a Ticket: /api/amc/create-amc-ticket/
// Search IMEI number: /api/amc/search-imei-numbers/

// Logins

// 9154409615 => inv_manager
// 8125946044 => DA
// 7989892901 => Service center login
// 9515768704 => District Incharge

// password => test@123

// DA/ WEDPS:
// List tickets: /api/amc/fetch-tickets-list/, for all roles
// List service centers alogn with services: /api/amc/amc/fetch-service-center-services
// Create a Ticket: /api/amc/create-amc-ticket/
// Search IMEI number: /api/amc/search-imei-numbers/

// Service center:
// List tickets: /api/amc/fetch-tickets-list/, for all roles
// Update a ticket:?

// InventoryManager:

// List tickets: /api/amc/fetch-tickets-list/, for all roles
// Update a ticket:?

// Dist Inch

// List tickets: /api/amc/fetch-tickets-list/, for all roles
// Update a ticket:?

// Rural(11390800)--- nannur1sachivalayam@gmail.com
// urban(21144002)---21144002-wedps
// password: test@123

//  inventory manger : patansalamkhan@gmail.com

// For Both Prod and Dev
